import { createUniqueId } from 'solid-js';
import { createStore } from 'solid-js/store';
import type { JSX } from 'solid-js';

type ToastType = 'normal' | 'success' | 'error';

type ToastOptions = {
  title?: JSX.Element;
  message: JSX.Element | string[];
  type?: ToastType;
  timeout?: number;
  color?: string;
  showProgress?: boolean;
};

type ToastItem = Required<ToastOptions> & { id: string };

interface ToastFn {
  (message: JSX.Element, type?: ToastType): void;
  (options: ToastOptions): void;
}

type ToastTypeFn = (message: JSX.Element, options?: Omit<ToastOptions, 'message' | 'type'>) => void;

type ToastWrap = ToastFn & { [K in ToastType]: ToastTypeFn };

const [toasts, setToasts] = createStore<ToastItem[]>([]);

const remove = (id: string) => setToasts((prev) => prev.filter((i) => i.id !== id));

const isToastOptions = (x: unknown): x is ToastOptions => x != null && typeof x === 'object' && 'message' in x;

const toastFn: ToastFn = (...args) => {
  const [message, type] = args;
  const item = {
    id: createUniqueId(),
    type: typeof type === 'string' ? (type as ToastType) : 'normal',
    timeout: 3000,
    ...(isToastOptions(message) ? message : { message }),
  } as ToastItem;
  setToasts((prev) => [...prev, item].slice(-3));
  setTimeout(() => remove(item.id), item.timeout);
};

const toast = new Proxy(toastFn, {
  get: (_, type: ToastType): ToastTypeFn => {
    return (message, options) => toastFn({ message, type, ...options });
  },
}) as ToastWrap;

export { toasts, toast, remove };
export type { ToastOptions, ToastItem, ToastWrap, ToastType };
