import { MagicUseCase } from '~/pdfsigner/usecases/baseUseCases/magicUseCase';
import { AnnotationType } from '~/pdfsigner/usecases/types/annotation';
import { SelectAnnotationUseCase } from './selectAnnotationUseCase';
import type { StartEditingRequest } from '~/pdfsigner/usecases/types/startEditingRequest';

export class StartEditingUseCase extends MagicUseCase {
  public async runLogic(request: StartEditingRequest) {
    if (this.isPageWithingRange(request.page)) {
      return;
    }
    const selectAnnotationUseCase = new SelectAnnotationUseCase();
    await selectAnnotationUseCase.execute({ id: request.id, page: request.page });
    const selectedAnnotation = this.getState().selectedAnnotation;
    if (selectedAnnotation && selectedAnnotation.type === AnnotationType.TEXT) {
      this.getState().isEditing = true;
    }
  }
}
