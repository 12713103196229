import { MagicUseCase } from '~/pdfsigner/usecases/baseUseCases/magicUseCase';
import type { AnnotatedPdfPage } from '~/pdfsigner/usecases/types/annotatedPdfPage';
import type { Annotation } from '~/pdfsigner/usecases/types/annotation';
import type { AnnotationSelectionRequest } from '~/pdfsigner/usecases/types/annotationSelectionRequest';

export class SelectAnnotationUseCase extends MagicUseCase {
  public async runLogic(request: AnnotationSelectionRequest) {
    if (this.isPageWithingRange(request.page) || !this.isPdfLoaded()) {
      return;
    }
    this.getState().selectedAnnotationType = undefined;
    const annotation = this.findAnnotationWithIdOnPage(request.id, this.getState().selectedDocument?.pdfPages[request.page]);
    this.getState().selectedAnnotation = annotation;

    if (
      annotation &&
      this.getState().dataPathManager.doesDataPathWithKeyExist(annotation.dataPathKey) &&
      this.getState().dataPathManager.getSelectedNodeKey() !== annotation.dataPathKey
    ) {
      this.getState().dataPathManager.selectNodeWithKey(annotation.dataPathKey);
    }
  }

  private findAnnotationWithIdOnPage(id: string, page?: AnnotatedPdfPage): Annotation | undefined {
    if (!page) {
      return undefined;
    }
    const annotations = page.annotations.filter((annotation) => {
      return annotation.id === id;
    });
    if (annotations && annotations.length > 0) {
      return annotations[0];
    }
    return undefined;
  }
}
