import { A } from '@solidjs/router';
import { For, splitProps, Show, createSignal } from 'solid-js';
import { Dynamic } from 'solid-js/web';
import { IconChevronRight, IconCircleEllipsis, Popover } from '~/components/ui';
import { cn } from '~/utils/classnames';
import type { Component, JSX, ComponentProps, ValidComponent } from 'solid-js';

export type DropdownAction = {
  icon?: ValidComponent;
  jsxIcon?: JSX.Element;
  label: JSX.Element;
  href?: string;
  childrenStyle?: string;
  childrenPosition?: 'left' | 'right';
  noScroll?: boolean;
  hideSelection?: boolean;
} & ({ onClick?: () => void; children?: never } | { children: JSX.Element; onClick?: never });

type DropdownActionsProps = Omit<ComponentProps<typeof Popover>, 'children'> & {
  children?: JSX.Element;
  actions: DropdownAction[];
  triggerClass?: string;
  align?: 'start' | 'end';
};

export const DropdownActions: Component<DropdownActionsProps> = (props) => {
  const [params, rest] = splitProps(props, ['actions', 'align', 'children', 'class', 'triggerClass']);
  const [openIndex, setOpenIndex] = createSignal<number | null>(null);

  const handleItemClick = (index: number, onClick?: () => void) => {
    if (onClick) {
      onClick();
    } else {
      setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
    }
  };

  return (
    <Popover {...rest} class={cn('flex', params.class)}>
      {({ setOpen }) => (
        <>
          {' '}
          <Popover.Trigger
            class={cn(
              'p-1.5 text-title-gray opacity-80 transition-opacity hover:opacity-100 aria-expanded:opacity-100',
              params.triggerClass
            )}>
            <Show when={params.children == null} fallback={params.children}>
              <IconCircleEllipsis class="size-4" />
            </Show>
          </Popover.Trigger>
          <Popover.Content
            as="ul"
            align={params.align ?? 'end'}
            class="my-1 flex w-fit flex-col gap-1 rounded-md bg-white p-2 text-xs text-title-gray shadow-lg ring-1 ring-partingline md:min-w-44 md:text-sm">
            <For each={params.actions.filter((item) => !item.hideSelection)}>
              {(item, index) => (
                <li class="group relative">
                  <Show when={item.href}>
                    <Popover.Trigger
                      as={A}
                      class="flex w-full items-center gap-2 rounded-md px-3 py-2.5 ring-1 ring-input-border transition-colors group-hover:bg-partingline"
                      href={item.href ?? ''}
                      noScroll={item.noScroll}>
                      <Show when={item.icon}>
                        <Dynamic component={item.icon} class="size-4 text-text-level03" />
                      </Show>
                      {item.label}
                    </Popover.Trigger>
                  </Show>
                  <Show when={!item.href}>
                    <Popover.Trigger
                      onClick={() => handleItemClick(index(), item.onClick)}
                      class="flex w-full items-center gap-2 rounded-md px-3 py-2.5 ring-1 ring-input-border transition-colors group-hover:bg-partingline">
                      <Show when={item.icon}>
                        <Dynamic component={item.icon} class="size-4 text-text-level03" />
                      </Show>
                      <Show when={item.jsxIcon}>{item.jsxIcon}</Show>
                      {item.label}
                      <Show when={item.children}>
                        <IconChevronRight class="size-4" stroke-width={1} />
                      </Show>
                    </Popover.Trigger>
                    <Show when={item.children}>
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          setTimeout(() => {
                            setOpen(false);
                          }, 100);
                        }}
                        class={cn(
                          `absolute ${
                            item.childrenPosition === 'right' ? 'left-full' : 'right-full'
                          } top-0 hidden rounded-md bg-white p-3 text-xs shadow-lg ring-1 ring-partingline group-hover:block`,
                          item.childrenStyle,
                          openIndex() === index() ? 'block' : 'hidden'
                        )}>
                        {item.children}
                      </div>
                    </Show>
                  </Show>
                </li>
              )}
            </For>
          </Popover.Content>
        </>
      )}
    </Popover>
  );
};
