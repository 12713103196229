import { signerRepository } from '~/pdfsigner/repositories/signerRepository';
import { MagicUseCase } from '~/pdfsigner/usecases/baseUseCases/magicUseCase';

export class GetSignersUseCase extends MagicUseCase {
  public async runLogic(leaseId?: string) {
    if (!leaseId) {
      return;
    }
    this.getState().leaseDocumentSigners = await signerRepository.getSignersForLease(leaseId);
  }
}
