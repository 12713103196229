import { createEffect, createSignal } from 'solid-js';
import IconAirplane from '~/assets/images/common/airplane.svg?component-solid';
import { Button } from '~/components/common/Buttons';
import { useLocalization } from '~/contexts/global';
import { resizeTextArea } from '~/utils/hooks';
import { getChatInput } from './chatInputStore';
import type { Accessor } from 'solid-js';

interface NewMessageInputProps {
  newMessage: string;
  setNewMessage: (message: string) => void;
  handleSendMessage: (messageContent: string) => void;
  isSendingMessage: boolean;
  files: File[];
  setFiles: (files: File[]) => void;
  messageStore: (chatId: string, message: string) => void;
  chatId: string;
  requestFocus: Accessor<boolean>;
  setRequestFocus: (requestFocus: boolean) => void;
}

const NewMessageInput = (props: NewMessageInputProps) => {
  const { t } = useLocalization();

  let textAreaRef: HTMLTextAreaElement;
  const [localMessage, setLocalMessage] = createSignal<string>('');
  const [isComposing, setIsComposing] = createSignal(false);

  const handleCompositionStart = () => {
    setIsComposing(true);
  };

  const handleCompositionEnd = (e: CompositionEvent) => {
    setIsComposing(false);
    const target = e.target as HTMLInputElement;
    setLocalMessage(target.value);
  };

  const handleInput = (e: Event) => {
    const target = e.target as HTMLTextAreaElement;
    setLocalMessage(target.value);
    resizeTextArea(textAreaRef);
  };

  const handleBlur = () => {
    props.setNewMessage(localMessage());
    props.messageStore(props.chatId, localMessage());
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (
      e.key === 'Enter' &&
      !e.shiftKey &&
      !props.isSendingMessage &&
      !isComposing() &&
      (localMessage().trim() !== '' || props.files.length > 0)
    ) {
      e.preventDefault();
      props.handleSendMessage(localMessage());
      setLocalMessage('');
      resizeTextArea(textAreaRef);
    }
  };

  const handleSubmit = () => {
    props.handleSendMessage(localMessage());
    setLocalMessage('');
    resizeTextArea(textAreaRef);
  };

  createEffect(() => {
    const storedMessage = getChatInput(props.chatId);
    setLocalMessage(storedMessage);
    props.setNewMessage(storedMessage);
  });

  createEffect(() => {
    if (props.requestFocus()) {
      textAreaRef.focus();
      props.setRequestFocus(false);
    }
  });

  createEffect(() => {
    resizeTextArea(textAreaRef);
  });

  return (
    <div class="border-t border-gray-200 bg-white px-8 pt-4">
      <div class="flex items-end gap-2 rounded-lg">
        <textarea
          ref={(textArea) => (textAreaRef = textArea)}
          class="thinscroll max-h-48 flex-1 resize-none rounded-lg bg-inputbox-bg p-4 text-gray-800 focus:outline-none"
          placeholder={t('Type Something')}
          value={localMessage()}
          onInput={handleInput}
          onBlur={handleBlur}
          onCompositionStart={handleCompositionStart}
          onCompositionEnd={handleCompositionEnd}
          onKeyDown={handleKeyDown}
          disabled={props.isSendingMessage}
          rows={1}
        />

        <Button
          disabled={props.isSendingMessage}
          onClick={handleSubmit}
          loading={props.isSendingMessage}
          class="flex size-14 items-center justify-center p-2">
          <IconAirplane class="size-7 text-white" />
        </Button>
      </div>
    </div>
  );
};

export default NewMessageInput;
