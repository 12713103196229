import { Route, Routes, useParams, useNavigate } from '@solidjs/router';
import { Show, createEffect, createMemo } from 'solid-js';
import Breadcrumb from '~/components/common/Breadcrumb';
import { Empty } from '~/components/common/Empty';
import { Panel } from '~/components/common/Panels';
import { TabLinks } from '~/components/ui';
import { useLocalization, useVendors } from '~/contexts/global';
import { BillsProvider } from '~/contexts/local';
import { DeleteVendor } from '~/pages/vendors/components/DeleteVendor';
import VendorBills from '~/pages/vendors/vendor-details/view-components/detail-view/VendorBills';
import { VendorNotes } from '~/pages/vendors/vendor-details/view-components/detail-view/VendorNotes';
import { ListVendorPayments } from '~/pages/vendors/vendor-details/view-components/detail-view/VendorPaymentsTab/ListVendorPayments';
import { VendorsEmailsTab } from '~/pages/vendors/vendor-details/view-components/detail-view/VendorsEmailsTab';
import VendorDetailsViewSkeleton from '~/pages/vendors/vendor-details/view-components/skeleton/VendorDetailsViewSkeleton';
import VendorCommunicationsTab from './VendorCommunicationsTab';
import VendorGeneralTab from './VendorGeneralTab';
import VendorMemo from './VendorMemo';
import VendorInformation from './vendor-information/VendorInformation';

const VendorDetailsView = () => {
  const params = useParams();
  const { current, getVendorOverview } = useVendors();
  const navigate = useNavigate();

  const { t } = useLocalization();

  const breadcrumbItems = createMemo(() => [
    { label: t('Vendor'), link: '/maintenance/vendors' },
    { label: current()?.name || t('Loading') },
  ]);

  createEffect(() => {
    getVendorOverview(params.vendorId);
  });

  return (
    <>
      <Breadcrumb backLink={() => navigate(-1)} items={breadcrumbItems()} />
      <Show when={!current.loading} fallback={<VendorDetailsViewSkeleton />}>
        <div class="flex flex-col gap-4 p-8 xl:flex-row">
          <div class="flex w-full flex-col gap-y-4 xl:w-1/4">
            <div class="flex flex-col gap-y-4">
              <VendorInformation />
              <VendorMemo />
            </div>
            <DeleteVendor vendorId={params.vendorId} />
          </div>

          <div class="flex w-full flex-col gap-3 xl:w-3/4">
            <TabLinks
              class="mb-4 rounded-lg border border-partingline bg-white"
              items={[
                { label: t('General'), href: '' },
                { label: t('Bills'), href: 'bills' },
                // { label: t('Activity logs'), href: 'activity-logs' },
                { label: t('Communications'), href: 'communications' },
                { label: t('Emails'), href: `emails${location.search}`, end: false },
                { label: t('Files'), href: 'files' },
                { label: t('Payment accounts'), href: `payments-accounts${location.search}`, end: false },
                { label: t('Notes'), href: 'notes' },
              ]}
              replace
            />

            <Routes>
              <Route path="/" element={<VendorGeneralTab />} />
              {/* <Route path="/activity-logs" element={<VendorActivityLog />} /> */}

              <Route
                path="/communications"
                element={<VendorCommunicationsTab vendorChatId={current()?.chatId?.toString()} vendorId={params.vendorId} />}
              />
              <Route path="/emails/*" component={VendorsEmailsTab} />
              <Route
                path="/files"
                element={
                  <Panel>
                    <Empty description={t('🚧 This section is under construction')} />
                  </Panel>
                }
              />
              <Route path="/payments-accounts/*" component={ListVendorPayments} />
              <Route path="/notes/*" element={<VendorNotes />} />

              <Route
                path="/bills"
                element={
                  <BillsProvider>
                    <VendorBills />
                  </BillsProvider>
                }
              />
            </Routes>
          </div>
        </div>
      </Show>
    </>
  );
};

export default VendorDetailsView;
