import { A, useParams } from '@solidjs/router';
import { For, Show, createMemo, mergeProps, onMount } from 'solid-js';
import IconTransaction from '~/assets/images/common/transaction.svg';
import UseMaintenanceImg from '~/assets/images/leases/useMaintenance.svg';
import { Empty } from '~/components/common/Empty';
import { useLocalization } from '~/contexts/global';
import { useMaintenanceRequests } from '~/contexts/local';
import Card from '~/pages/dashboard/components/Card';
import { formatAgo } from '~/utils/date';
import type { Component } from 'solid-js';

export type CrossMaintenanceRequestListDto = MagicDoor.Api.MaintenanceRequestListDto & {
  unit?: MagicDoor.Api.UnitDto;
  property?: MagicDoor.Api.PropertyDto;
  propertyManager?: MagicDoor.Api.PropertyManagerDto;
};

type MaintenanceRequestProps = {
  item: CrossMaintenanceRequestListDto;
};

const MaintenanceRequestTable: Component<MaintenanceRequestProps> = (props) => {
  const { t } = useLocalization();

  return (
    <A
      href={`/maintenance/maintenance-request/${props.item.id}`}
      class="flex justify-between rounded-lg px-3 py-2.5 text-xs "
      classList={{
        'bg-[#F1FFFB] hover:bg-[#c2e4d8]/50': props.item.urgency === 'low',
        'bg-[#FFF1F6] hover:bg-[#f7d4df]/50': props.item.urgency === 'urgent',
        'bg-[#FFFBF1] hover:bg-[#ebe4d3]/50': props.item.urgency === 'medium',
        'bg-[#FBF1FF] hover:bg-[#e4d3eb]/50': props.item.urgency === 'high',
        'bg-[#F1F2FF] hover:bg-[#d3d4eb]/50': props.item.urgency === 'none',
      }}>
      <div class="flex items-center space-x-2">
        <div class="bg-gree flex size-7 items-center justify-center rounded-lg bg-warning p-1">
          <img src={IconTransaction} />
        </div>
        <div>
          <div class="font-medium text-text-level01">{props.item.title}</div>
          <div>
            <span class="text-text-level03">{t('AI score')}: </span>
            <span class="text-text-level01">{props.item.aiUrgencyScore}</span>
          </div>
        </div>
      </div>
      <div class="text-text-level03">{formatAgo(props.item.lastUpdated)}</div>
    </A>
  );
};

type MaintenanceRequestsTableProps = {
  class?: string;
  use?: boolean;
};

export const MaintenanceRequestsTable: Component<MaintenanceRequestsTableProps> = (originProps) => {
  const props = mergeProps(
    {
      use: true,
    },
    originProps
  );

  const { t } = useLocalization();
  const { unitId, leaseId, propertyId } = useParams();
  const { maintenanceRequests, getMaintenanceRequests } = useMaintenanceRequests();

  const emptyDescription = createMemo<string>(() => {
    if (unitId) return t('There are no maintenance requests under this {name}', { name: t('Unit') });
    return t('There are no maintenance requests under this {name}', { name: t('Lease') });
  });

  onMount(() => {
    getMaintenanceRequests({
      unitId: unitId,
      leaseId: leaseId,
      propertyId: propertyId,
    });
  });

  const filterd = createMemo(() => maintenanceRequests()?.items.filter((m) => m.status !== 'closed'));

  return (
    <Card title={t('Maintenance requests')} class={props.class}>
      <div class="flex flex-1 flex-col overflow-hidden">
        <div class="thinscroll flex flex-1 flex-col space-y-2 overflow-auto px-6 py-4">
          <Show
            when={props.use}
            fallback={
              <Empty
                imgSrc={UseMaintenanceImg}
                class="py-4"
                size="106px"
                descriptionClass="mt-1 text-xs"
                description={t('Available after activating the lease')}
              />
            }>
            <For each={filterd()}>{(item) => <MaintenanceRequestTable item={item} />}</For>
            <Show when={!filterd()?.length && !maintenanceRequests.loading}>
              <Empty class="py-5" description={emptyDescription()} />
            </Show>
          </Show>
        </div>
      </div>
    </Card>
  );
};
