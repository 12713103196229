import { MagicUseCase } from '~/pdfsigner/usecases/baseUseCases/magicUseCase';
import { isUndefined } from '~/utils/tool';
import type { SetAnnotationDataPathKeyRequest } from '~/pdfsigner/usecases/types/setAnnotationDataPathKeyRequest';

export class SetAnnotationDataPathKeyUseCase extends MagicUseCase {
  public async runLogic(request: SetAnnotationDataPathKeyRequest) {
    if (request.id != this.getState().selectedAnnotation?.id) {
      return;
    }
    const selectedAnnotation = this.getState().selectedAnnotation;
    if (selectedAnnotation) {
      selectedAnnotation.dataPathKey = request.value;
      const pageIndex = this.getState().selectedDocument?.pdfPages.findIndex((page) =>
        page.annotations.some((annotation) => annotation.id === request.id)
      );
      if (isUndefined(pageIndex) || pageIndex === -1) return;
      const annotationIndex = this.getState().selectedDocument?.pdfPages[pageIndex].annotations.findIndex(
        (annotation) => annotation.id === request.id
      );
      if (isUndefined(annotationIndex) || annotationIndex === -1) return;
      this.getState().selectedDocument!.pdfPages[pageIndex].annotations[annotationIndex].dataPathKey = request.value;
    }
  }
}
