import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { MaintenanceRequestSearch } from '~/components/maintenance-requests';
import { OwnerSearch } from '~/components/owner/OwnerSearch';
import { CustomLevelSearch } from '~/components/search-select/LevelSearchComponents/SearchLevels';
import { LeveledSearchSelect } from '~/components/search-select/LevelSearchSelect';
import { SearchWithTab } from '~/components/search-select/SearchWithTab';
import { Tabs } from '~/components/ui';
import { VendorSearch } from '~/components/vendors';
import { useLocalization } from '~/contexts/global';
import { twc } from '~/utils/classnames';
import {
  IconAddCommunicationLease,
  IconAddCommunicationMaintenance,
  IconAddCommunicationTextMessage,
  IconAddCommunicationVendor,
  IconAddCommunicationOwner,
  IconAddCommunicationTenant,
} from './assets';
import AddCommunicationBanner from './assets/add-communication-banner.png';
import type { SetStoreFunction } from 'solid-js/store';
import type { Variants } from '~/components/search-select/Context';

const OptionButton = twc.button`flex flex-1 flex-col items-center justify-center rounded-lg border p-2 capitalize outline-none aria-selected:border-current aria-selected:font-medium aria-selected:text-primary`;

export const AddNewCommunication = (props: {
  onTypeChange: (value: string | null) => void;
  onTargetChange: (value: string | null) => void;
  onTextMessageChange: SetStoreFunction<{ phone?: string | undefined; name?: string | undefined; subject?: string | undefined }>;
}) => {
  const { t } = useLocalization();

  const handleSelect = (entry: { id: string }) => {
    props.onTargetChange(entry.id);
  };

  const handleTypeChange = (value: string) => {
    props.onTypeChange(value);
  };

  const handleLeaseChange = (selected: any[], variants: Variants) => {
    if (!variants.singleType) {
      props.onTargetChange(null);
      return;
    }
    props.onTargetChange(selected[0].id);
  };

  const handleTenantChange = (selected: MagicDoor.Api.HydratedTenantDto[]) => {
    props.onTargetChange(selected[0]?.id);
  };

  return (
    <div class="thinscroll flex-1 overflow-auto">
      <div class="h-[125px] bg-safe-green">
        <img src={AddCommunicationBanner} />
      </div>
      <div class="px-6 py-8">
        <p class="mb-3 text-center font-medium text-text-level02">{t('I want to chat with')}:</p>
        <Tabs onChange={handleTypeChange}>
          <Tabs.List class="mb-5 grid grid-cols-3 gap-1 text-xs text-title-gray">
            <Tabs.Trigger value="textMessage" as={OptionButton}>
              <IconAddCommunicationTextMessage />
              <span>{t('Text message')}</span>
            </Tabs.Trigger>
            <Tabs.Trigger value="lease" as={OptionButton}>
              <IconAddCommunicationLease />
              <span>{t('Lease')}</span>
            </Tabs.Trigger>
            <Tabs.Trigger value="vendor" as={OptionButton}>
              <IconAddCommunicationVendor />
              <span>{t('Vendor')}</span>
            </Tabs.Trigger>
            <Tabs.Trigger value="maintenanceRequest" as={OptionButton}>
              <IconAddCommunicationMaintenance />
              <span>{t('Maintenance')}</span>
            </Tabs.Trigger>
            <Tabs.Trigger value="owner" as={OptionButton}>
              <IconAddCommunicationOwner />
              <span>{t('Owner')}</span>
            </Tabs.Trigger>
            <Tabs.Trigger value="tenant" as={OptionButton}>
              <IconAddCommunicationTenant />
              <span>{t('Tenant')}</span>
            </Tabs.Trigger>
          </Tabs.List>
          <Tabs.Content value="textMessage">
            <div class="flex w-full flex-wrap gap-3">
              <LabeledTextInput
                class="min-w-56 flex-1"
                label={t('Phone')}
                placeholder={t('Please enter')}
                onInput={(value) => props.onTextMessageChange('phone', value)}
                required
              />
              <LabeledTextInput
                class="min-w-56 flex-1"
                label={t('To')}
                placeholder={t('Please enter')}
                onInput={(value) => props.onTextMessageChange('name', value)}
              />
              <LabeledTextInput
                class="min-w-80 flex-1"
                label={t('Subject')}
                placeholder={t('Please enter')}
                onInput={(value) => props.onTextMessageChange('subject', value)}
              />
            </div>
          </Tabs.Content>
          <Tabs.Content value="lease">
            <LeveledSearchSelect
              placeholder="Select a lease to start a conversation"
              onChange={handleLeaseChange}
              enabledTypes={[CustomLevelSearch.Lease]}
            />
          </Tabs.Content>
          <Tabs.Content value="vendor">
            <VendorSearch placeholder="Select a vendor to start a conversation" onSelect={handleSelect} />
          </Tabs.Content>
          <Tabs.Content value="maintenanceRequest">
            <MaintenanceRequestSearch placeholder="Select a maintenance request to start a conversation" onSelect={handleSelect} />
          </Tabs.Content>
          <Tabs.Content value="owner">
            <OwnerSearch placeholder="Select an owner to start a conversation" onSelect={handleSelect} />
          </Tabs.Content>
          <Tabs.Content value="tenant">
            <SearchWithTab<MagicDoor.Api.HydratedTenantDto>
              selectType={CustomLevelSearch.Tenant}
              onChange={handleTenantChange}
              placeholder="Select a tenant to start a conversation"
            />
          </Tabs.Content>
        </Tabs>
      </div>
    </div>
  );
};
