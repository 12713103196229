import { Show, For } from 'solid-js';
import IconLoadingChat from '~/assets/images/chat/loadingChat.gif';
import { useLocalization } from '~/contexts/global';
import { ChatContentPrint } from '~/pages/chats/chat-details/components/ChatContentPrint';
import type { ChatMessageDto, HydratedMagicChatDto } from '~/swagger/Api';

interface PrintContainerProps {
  isInitialLoading: boolean;
  chatMessages: ChatMessageDto[];
  chat: HydratedMagicChatDto | undefined;
  chatId: string | undefined;
  showNewMessageIndicator: boolean;
  firstUnreadMessageIndex: number | null;
  lastSeenMessageId: string | undefined;
}

export const PrintContainer = (props: PrintContainerProps) => {
  const { t } = useLocalization();

  return (
    <div class="thinscroll flex flex-1 flex-col space-y-2 overflow-y-auto p-6">
      <Show
        when={!props.isInitialLoading && props.chatId}
        fallback={
          <div class="flex w-full justify-center text-text-level03">
            <img src={IconLoadingChat} />
          </div>
        }>
        <For each={props.chatMessages?.slice().reverse() || []}>
          {(message, index) => (
            <>
              <Show
                when={
                  (props.showNewMessageIndicator && index() === props.firstUnreadMessageIndex) ||
                  (message.id === props.lastSeenMessageId && message.messageType !== 'propertyManager')
                }>
                <div class="relative mb-8 flex w-full items-center justify-center border-b">
                  <div class="absolute rounded-full bg-input px-2 py-1 text-xs text-text-level03">{t('New message')}</div>
                </div>
              </Show>
              <ChatContentPrint message={message} allMessage={props.chatMessages} chatDetails={props.chat} />
            </>
          )}
        </For>
      </Show>
    </div>
  );
};
