import { Presenter } from '~/framework/Presenter';
import type { PdfState } from '~/pdfsigner/state/pdfAppState';

export interface PresentablePdfReport {
  pdf?: Blob;
}

export class PdfReportPresenter extends Presenter<PresentablePdfReport> {
  createModel(state: PdfState): PresentablePdfReport {
    return {
      pdf: state.reports.pdf,
    };
  }
}
