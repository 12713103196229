import { createSignal, createEffect } from 'solid-js';

export interface Pagination {
  currentPage: number;
  pageSize: number;
  totalPages: number;
  totalCount: number;
}

export const useLocalPagination = (name: string, data?: Partial<Pagination>) => {
  const storedPage = localStorage.getItem(`${name}LocalPage`);
  const initialPage = storedPage ? Math.max(1, parseInt(storedPage)) : 1;

  const getPageSize = (defaultValue?: number) => {
    return Number(localStorage.getItem(`${name}LocalPageSize`)) || defaultValue || 50;
  };

  const [page, setPage] = createSignal(initialPage);
  const [pageSize, setPageSize] = createSignal(getPageSize(data?.pageSize));

  const updatePage = (newPage: number) => {
    localStorage.setItem(`${name}LocalPage`, `${newPage}`);
    setPage(newPage);
  };

  const updatePageSize = (newPageSize: number) => {
    if (newPageSize !== pageSize()) {
      updatePage(1);
    }

    localStorage.setItem(`${name}LocalPageSize`, `${newPageSize}`);
    setPageSize(newPageSize);
  };

  createEffect(() => {
    if (data?.totalPages !== undefined) {
      const currentPage = page();
      if (currentPage > data.totalPages && data.totalPages > 0) {
        updatePage(data.totalPages);
      }
    }
  });

  const syncLocalPagination = (pagination: Pagination) => {
    console.log('syncLocalPagination', pagination);
    if (pagination.currentPage !== undefined) {
      updatePage(pagination.currentPage);
    }

    if (pagination.pageSize !== undefined) {
      updatePageSize(pagination.pageSize);
    }
  };

  return {
    page,
    setPage: updatePage,
    pageSize,
    setPageSize: updatePageSize,
    syncLocalPagination,
    get localPage() {
      return page();
    },
    get localPageSize() {
      return getPageSize();
    },
  };
};
