import { useNavigate, useParams } from '@solidjs/router';
import { createMemo, onMount, Show } from 'solid-js';
import { Empty } from '~/components/common/Empty';
import { LeasePeriod, LeaseStatus } from '~/components/leases';
import { Table } from '~/components/ui';
import { useLocalization, useUnits } from '~/contexts/global';
import Card from '~/pages/dashboard/components/Card';
import { dateFormat } from '~/utils/date';
import type { Component } from 'solid-js';
import type { TableColumns } from '~/components/ui';

type LatestPaymentsTableProps = {
  class?: string;
};

export const LatestPaymentsTable: Component<LatestPaymentsTableProps> = (props) => {
  const { t } = useLocalization();
  const params = useParams();
  const navigate = useNavigate();
  const { unitPayments, getUnitPayments } = useUnits();

  onMount(() => {
    getUnitPayments({ unitId: params.unitId, page: 1, pageSize: 10 });
  });

  const handleRowClick = (item: MagicDoor.Api.HydratedTransactionDto) => {
    navigate(`/leasing/leases/${item.leaseId}/transactions/${item.id}`);
  };

  const columns = createMemo<TableColumns<MagicDoor.Api.HydratedTransactionDto>>(() => [
    {
      title: t('Date'),
      render: (item) => <div>{dateFormat('', item.transactionDate)}</div>,
    },
    {
      title: t('Description'),
      render: (item) => (
        <div>
          {item.transactionIdentity.charAt(0).toUpperCase() + item.transactionIdentity.slice(1)} payment ({item.transactionPaymentMethod})
        </div>
      ),
    },
    {
      title: t('From'),
      render: (item) => (
        <>
          <LeaseStatus lease={item.lease ?? undefined} />
          <LeasePeriod lease={item.lease ?? undefined} />
        </>
      ),
    },
    {
      title: t('To'),
      render: (item) => <div>{item.bankAccount?.name}</div>,
    },
    {
      title: t('Amount'),
      render: (item) => (
        <div class="text-right">
          ${item.amount.toFixed(2)}
          <div class="text-xs text-gray-500">{item.status}</div>
        </div>
      ),
    },
  ]);

  return (
    <Card title={t('Latest payments')} class={props.class}>
      <Show when={unitPayments()?.items?.length} fallback={<Empty description={t('There are no latest payments under this unit')} />}>
        <Table columns={columns()} data={unitPayments()?.items ?? []} onRowClick={handleRowClick} />
      </Show>
    </Card>
  );
};
