import { onMount, createMemo, For, createEffect } from 'solid-js';
import IconChargeLateFees from '~/assets/images/settings/rental/fees.svg';
import { Form, useForm } from '~/components/common/BetterForm';
import ThreeSectionBox from '~/components/common/ThreeSectionBox/ThreeSectionBox';
import { toast } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useRentalApplicationPaymentSettings } from '~/contexts/local';
import { SettingTitle } from '~/pages/settings/SettingTitle';
import { EditFormItem } from '~/pages/settings/components/edit-form/EditForm';
import { SaveBtn } from '~/pages/settings/components/edit-form/SaveBtn';
import { currency } from '~/utils/number';

export const ApplicationFee = () => {
  const { t } = useLocalization();
  const { rentalApplicationPaymentSettings, getRentalApplicationPaymentSettings, updateRentalApplicationPaymentSettings } =
    useRentalApplicationPaymentSettings();
  const form = useForm();

  createEffect(() => form.setFormStore(rentalApplicationPaymentSettings() || ({} as MagicDoor.Api.RentalApplicationPaymentSettingsDto)));

  onMount(async () => {
    await getRentalApplicationPaymentSettings();
  });

  const handleSave = async () => {
    await updateRentalApplicationPaymentSettings(form.formStore as MagicDoor.Api.RentalApplicationPaymentSettingsDto);
    toast(t('Application fees settings saved successfully'), 'success');
  };

  const onCancel = () => {
    form.setFormStore(rentalApplicationPaymentSettings() || ({} as MagicDoor.Api.RentalApplicationPaymentSettingsDto));
  };

  const isDirty = () => {
    const data = (rentalApplicationPaymentSettings() as MagicDoor.Api.RentalApplicationPaymentSettingsDto) || {};
    const keys = Object.keys(data) as (keyof typeof data)[];
    return keys.some((key) => JSON.stringify(form.formStore[key as string]) !== JSON.stringify(data[key]));
  };

  const transunionReportsCost = createMemo(() => form.formStore.applicationFee - form.formStore.applicationFee * 0.03 - 30);

  const sections = createMemo(() => {
    return [
      {
        label: 'Require payment for application',
        field: 'requirePaymentForApplication',
        type: 'boolean',
        class: 'col-span-2',
        labelContainerClass: 'flex-row justify-between',
      },
      {
        field: 'applicationFee',
        label: 'How much would you like to charge for an rental application fee? ',
        type: 'number',
        prepend: '$',
        rules: [
          {
            validator: (value: number) => value <= 1000 && value >= 0,
            message: t('The value of applicationFee is between 0 and 1000'),
          },
        ],
      },
    ];
  });

  return (
    <>
      <ThreeSectionBox
        class="h-fit"
        topTitle={<SettingTitle title={t('Application fees')} icon={IconChargeLateFees} />}
        middle={
          <div class="flex flex-col gap-5 p-4">
            <div class="flex flex-col gap-4 p-2 text-left">
              <Form defaultForm={form} class="my-3 grid gap-x-5 gap-y-6 text-left md:grid-cols-2">
                <For each={sections()}>{(section) => <EditFormItem {...section} />}</For>
              </Form>
              <div class="text-base font-medium capitalize text-text-level01">
                <div class="mb-4">{t('Application fee summary')}</div>
                <div class="input-border flex flex-col gap-y-4 rounded-lg border px-6 py-4 capitalize">
                  <div class="flex items-center justify-between text-xs">
                    <span class=" text-text-level03">{t('Application fee')}</span>
                    <span class="font-medium text-text-level01">{currency(form.formStore.applicationFee)}</span>
                  </div>
                  <div class="flex items-center justify-between text-xs">
                    <span class=" text-text-level03">{t('Online credit card processing cost')} ( 3%) </span>
                    <span class="font-medium text-text-level01">{currency(form.formStore.applicationFee * 0.03)}</span>
                  </div>
                  <div class="flex items-center justify-between text-xs">
                    <span class=" text-text-level03">{t('TransUnion reports cost')}</span>
                    <span class="font-medium text-text-level01">{currency(30)}</span>
                  </div>
                  <div class="h-px w-full bg-input-border" />
                  <div class="flex items-center justify-between text-sm">
                    <span class="text-text-level01 ">{t('Your profit')}</span>
                    <span class="font-medium text-primary">{currency(transunionReportsCost())}</span>
                  </div>
                </div>
              </div>
              <SaveBtn dirty={isDirty()} onCancel={onCancel} onSave={handleSave} />
            </div>
          </div>
        }
      />
    </>
  );
};
