import { useNavigate, useParams } from '@solidjs/router';
import { Show, createMemo } from 'solid-js';
import Breadcrumb from '~/components/common/Breadcrumb';
import { IconLoader } from '~/components/ui';
import { useLocalization, useProperties } from '~/contexts/global';
import { EditProperty } from '~/pages/properties/components/EditProperty';

export const EditPropertyPage = () => {
  const navigate = useNavigate();
  const { t } = useLocalization();

  const params = useParams<{ propertyId: string; portfolioId: string }>();
  const { setCurrentId, current, loading } = useProperties();

  const breadcrumbItems = createMemo(() => [
    { label: t('Properties'), link: '/portfolios' },
    { label: `${current()?.portfolio?.name || 'Loading'}`, link: `/portfolios/${params.portfolioId}` },
    { label: `${current()?.name || t('Loading')}`, link: `/portfolios/${params.portfolioId}/properties/${params.propertyId}` },
    { label: t('Edit') },
  ]);

  setCurrentId(params.propertyId);

  return (
    <Show
      when={!loading()}
      fallback={
        <div class="grid min-h-[300px] place-items-center">
          <IconLoader class="animate-spin text-title-gray" />
        </div>
      }>
      <Breadcrumb backLink={() => navigate(-1)} items={breadcrumbItems()} />
      <EditProperty onClose={() => navigate(`/portfolios/${params.portfolioId}/properties/${params.propertyId}`)} />
    </Show>
  );
};
