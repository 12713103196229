import { leaseDraftRepository } from '~/pdfsigner/repositories/leaseDraftRepository';
import { MagicUseCase } from '~/pdfsigner/usecases/baseUseCases/magicUseCase';
import { ValidateDocumentUseCase } from './validateDocumentUseCase';

export class SendRemindersUseCase extends MagicUseCase {
  public async runLogic() {
    new ValidateDocumentUseCase().execute();
    const selectedDocument = this.getState().selectedDocument;
    if (selectedDocument?.id) {
      await leaseDraftRepository.sendReminders(selectedDocument.id);
    }
  }
}
