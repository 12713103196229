import { Presenter } from '~/framework/Presenter';
import type { PdfState } from '~/pdfsigner/state/pdfAppState';

type PresentableTitle = {
  title?: string;
};

export class TitlePresenter extends Presenter<PresentableTitle> {
  createModel(state: PdfState): PresentableTitle {
    return {
      title: state.selectedDocument?.title ? state.selectedDocument.title : '',
    };
  }
}
