import { For } from 'solid-js';
import { useLocalization } from '~/contexts/global';
import type { Component } from 'solid-js';
import type { SectionProps } from '~/components/common/Panels/MultiSectionPanel';

type FactsAndFeaturesProps = SectionProps<MagicDoor.Api.HydratedUnitDto>;

type SubSectionProps = {
  title: string;
  options: string[];
};

export const SubSection: Component<SubSectionProps> = (props) => {
  return (
    <div class="w-full rounded-lg border border-partingline px-5">
      <div class="border-b border-partingline py-3 text-base font-medium">{props.title}</div>
      <div class="grid grid-cols-2 gap-3 py-3">
        <For each={props.options}>
          {(option) => (
            <div class="flex items-center gap-1">
              <input type="checkbox" id={option} />
              <label for={option} class="text-xs text-text-level02">
                {option}
              </label>
            </div>
          )}
        </For>
      </div>
    </div>
  );
};

export const FactsAndFeatures: Component<FactsAndFeaturesProps> = () => {
  const { t } = useLocalization();
  return (
    <div class="grid gap-4">
      <SubSection title={t('Pet policy')} options={['No Pets Allowed', 'Cats Allowed', 'Small Dogs Allowed', 'Large Dogs Allowed']} />
      <SubSection
        title={t('Included utilities')}
        options={['Tenant Pay All', 'Electric', 'Water', 'Sewer', 'Garbage', 'Gas', 'Lawn', 'Internet']}
      />
      <SubSection title={t('Kitchen Amenities')} options={['Dishwasher', 'Refrigerator', 'Disposal', 'Island']} />
    </div>
  );
};
