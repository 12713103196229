import { A } from '@solidjs/router';
import { For, createMemo, Show } from 'solid-js';
import Tooltip from '~/components/common/Tooltip';
import { cn } from '~/utils/classnames';
import { useMenu } from './MenuContext';
import type { MenuItem } from './types/menuItem';

interface MainMenuItemsProps {
  setIsSidebarOpen: (value: boolean) => void;
  isSidebarOpen: boolean;
  isMenuCollapsed: boolean;
}

const MainMenuItems = (props: MainMenuItemsProps) => {
  const menu = useMenu();

  return (
    <div
      class="z-20 h-full pl-3 text-left text-sm text-white"
      classList={{
        'min-w-[68px] max-w-[68px]': menu?.isManuallyCollapsed() || menu?.isCollapsed(),
        'w-64': !menu?.isManuallyCollapsed() && !menu?.isCollapsed(),
        'w-full pr-2': props.isSidebarOpen && !menu?.isManuallyCollapsed() && !menu?.isCollapsed(),
      }}>
      <ul>
        <For each={menu?.menuItems()}>
          {(item) => <MainMenuItem item={item} setIsSidebarOpen={props.setIsSidebarOpen} isMenuCollapsed={props.isMenuCollapsed} />}
        </For>
      </ul>
    </div>
  );
};

interface MainMenuItemProps {
  item: MenuItem;
  setIsSidebarOpen: (value: boolean) => void;
  isMenuCollapsed: boolean;
}

const MainMenuItem = (props: MainMenuItemProps) => {
  const menu = useMenu();

  const hasSubMenus = createMemo(() => props.item.subMenuItems && props.item.subMenuItems.length > 0);

  const clickHandler = () => {
    const isCurrentlyActive = menu?.isActive(props.item);
    menu?.setActiveMenuItem(props.item);

    if (hasSubMenus() && menu?.isManuallyCollapsed()) {
      if (!isCurrentlyActive) {
        menu?.setIsManuallyCollapsed(false);
      }
    }

    if (!hasSubMenus() && window.innerWidth <= 767) {
      props.setIsSidebarOpen(false);
    }
  };

  return (
    <Tooltip
      class="w-full"
      bgColor="#8712CF"
      message={props.item.text}
      align="right"
      disabled={(!menu?.isManuallyCollapsed() && !menu?.isCollapsed()) || menu?.isActive(props.item)}>
      <li
        class={cn(
          'my-1 cursor-pointer rounded-lg border-2 border-transparent',
          { 'bg-essential-colour': menu?.isSelected(props.item) },
          { 'bg-black/20 mr-0 rounded-r-none': menu?.isActive(props.item) && !menu?.isSelected(props.item) },
          { 'bg-essential-colour ': menu?.isActive(props.item) && !menu?.isCollapsed() },
          {
            'hover:hover:border-essential-colour': !menu?.isSelected(props.item) && !menu?.isActive(props.item),
          },
          { 'mr-2': menu?.isManuallyCollapsed() },
          {
            'hover:mr-2': menu?.isCollapsed() && !menu?.isActive(props.item),
          },
          {
            'mr-2': menu?.isSelected(props.item) && menu?.isCollapsed(),
          },
          { 'bg-black/20 mr-0 rounded-r-none': menu?.isActive(props.item) && menu?.isSelected(props.item) && menu?.isCollapsed() }
        )}
        onClick={clickHandler}>
        <span class={cn('flex w-fit items-center p-3 text-left')}>
          <div class="group relative z-10 inline-block">
            <MainMenuButton item={props.item} setIsSidebarOpen={props.setIsSidebarOpen} isMenuCollapsed={props.isMenuCollapsed} />
          </div>
        </span>
      </li>
    </Tooltip>
  );
};

const MainMenuButton = (props: MainMenuItemProps) => {
  const menu = useMenu();

  const onALinkClick = (e: MouseEvent) => {
    e.preventDefault();
  };

  return (
    <div class="flex items-center ">
      <A href={props.item.realPath || props.item.path} onClick={onALinkClick}>
        <button
          class="flex w-full items-center gap-4 text-left"
          classList={{
            'font-medium': menu?.isSelected(props.item),
          }}>
          <img
            src={menu?.isSelected(props.item) ? props.item.selectedIcon : props.item.icon}
            alt={props.item.text + ' Icon'}
            class="size-5"
          />
          <Show when={!menu?.isManuallyCollapsed() && !menu?.isCollapsed()}>{props.item.text}</Show>
        </button>
      </A>
    </div>
  );
};

export default MainMenuItems;
