import { useNavigate, useParams } from '@solidjs/router';
import { createMemo, onMount, Show } from 'solid-js';
import IconPay from '~/assets/images/bill/pay.svg?component-solid';
import IconPlus from '~/assets/images/common/plus.svg?component-solid';
import IconDelete from '~/assets/images/units/delete.svg?component-solid';
import IconPencil from '~/assets/images/units/lease/editLease.svg?component-solid';
import { Button } from '~/components/common/Buttons';
import CnMoney from '~/components/common/CnMoney';
import { Empty } from '~/components/common/Empty';
import TextOverflow from '~/components/common/TextOverflow';
import { PropertyTitle } from '~/components/properties';
import { ListView, TableActions, toast } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useBills } from '~/contexts/local';
import { useLocalPagination } from '~/hooks';
import { useLeaseFilter } from '~/hooks/useLeaseFilter';
import VendorBill from '~/pages/vendors/vendor-details/view-components/quick-view/bills/VendorBills';
import { DeleteBillPaymentMethod } from '~/swagger/Api';
import { dateFormat } from '~/utils/date';
import { currency } from '~/utils/number';

function VendorBills() {
  const navigate = useNavigate();
  const { vendorId } = useParams();
  const { t } = useLocalization();
  const { page, pageSize, setPage, setPageSize } = useLocalPagination('VendorBillsListView');
  const { deleteBill, filtered, setFilter } = useBills();
  const { filterItems, filterValue, handleFilterReset } = useLeaseFilter({ status: false, date: false });

  const allFilterParams = createMemo(() => ({
    vendorId: vendorId,
    page: page(),
    pageSize: pageSize(),
  }));

  onMount(() => {
    setFilter(allFilterParams());
  });

  const onDeleteBill = async (id: string) => {
    await deleteBill(id, DeleteBillPaymentMethod.AdjustTransaction);
    toast.success(t('{name} has been deleted successfully', { name: 'Bill' }));
  };

  const getChartOfAccountText = (lineItems: MagicDoor.Api.HydratedBillDto['lineItems']) => {
    return lineItems.map((item) => item.chartOfAccount.name).toString();
  };

  const getTotalAmount = (lineItems: MagicDoor.Api.HydratedBillDto['lineItems']) => {
    return lineItems.reduce((previous, current) => previous + current.amount, 0);
  };

  return (
    <div class="flex  flex-col gap-[20px]">
      <VendorBill />
      <ListView
        onChange={({ page, pageSize, filter }) => {
          setPage(page);
          setPageSize(pageSize);
          setFilter({
            ...allFilterParams(),
            portfolioId: filter.portfolio,
            propertyId: filter.property,
          });
        }}
        title={t('Bills')}
        totalPages={filtered()?.totalPages}
        page={filtered()?.page}
        pageSize={filtered()?.pageSize}
        data={filtered()?.items}
        loading={filtered.loading}
        skeletonSize={10}
        filterValue={filterValue()}
        filterItems={filterItems()}
        onFilterReset={handleFilterReset}
        empty={<Empty description={t('No bill yet')} />}
        titleActions={
          <div class="flex gap-2">
            <Button href={'/accounting/bills/add'} class="font-medium capitalize">
              <IconPlus class="size-5 text-white" />
              {t('Add a bill')}
            </Button>
          </div>
        }
        rowLink={(item) => `/accounting/bills/${item.id}${item?.id ? `?leaseId=${item.id}` : ''}`}
        columns={[
          {
            title: t('Date'),
            render: (item) => <div class="w-20">{dateFormat('', item.billDate)} </div>,
          },
          {
            title: t('Chart of account'),
            render: (item) => <div class="w-40 break-words">{getChartOfAccountText(item.lineItems)} </div>,
          },
          {
            title: t('Property'),
            render: (item) => (
              <>
                <Show when={item.property} fallback={<span class="text-xs capitalize text-text-level03">{t('Unknown property')}</span>}>
                  <PropertyTitle class="w-60" property={item.property ?? undefined} />
                </Show>
              </>
            ),
          },

          {
            title: t('Memo'),
            render: (item) => (
              <div class="w-60">
                <Show when={item.memo} fallback={<span class="text-xs text-text-level03">--</span>}>
                  <TextOverflow text={item.memo as string} />
                </Show>
                {/* The current version does not require */}
                {/* <div class="mt-1 text-xs text-text-level03">#{item.id}</div> */}
              </div>
            ),
          },
          {
            title: t('Amount'),
            headerClass: 'text-right w-36',
            class: 'text-right',
            render: (item) => <div>{currency(getTotalAmount(item.lineItems))}</div>,
          },
          {
            title: t('Unpaid balance'),
            headerClass: 'text-right w-36',
            class: 'text-right',
            render: (item) => <CnMoney money={item.due} />,
          },
          {
            headerClass: 'w-[6%]',
            class: 'text-sm',
            render: (item) => (
              <TableActions
                actions={[
                  {
                    label: t('Edit bill'),
                    icon: IconPencil,
                    onClick: () => {
                      navigate(`/accounting/bills/${item.id}/edit`);
                    },
                  },
                  {
                    label: t('Pay bill'),
                    icon: IconPay,
                    onClick: () => {
                      navigate(`/accounting/bills/pay?propertyId=${item.propertyId}&vendorId=${item.vendor?.id}`);
                    },
                  },
                  {
                    label: t('Delete'),
                    icon: IconDelete,
                    onClick: () => onDeleteBill(item.id),
                  },
                ]}
              />
            ),
          },
        ]}
      />
    </div>
  );
}

export default VendorBills;
