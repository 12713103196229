import { useNavigate } from '@solidjs/router';
import { createMemo, Show, onMount } from 'solid-js';
import IconPlus from '~/assets/images/common/plus.svg?component-solid';
import IconDelete from '~/assets/images/units/delete.svg?component-solid';
import IconPencil from '~/assets/images/units/lease/editLease.svg?component-solid';
import { Avatar } from '~/components/common/Avatar';
import { Button } from '~/components/common/Buttons';
import { getTenantFullName } from '~/components/tenants/utils';
import { ListView, TableActions } from '~/components/ui';
import { useLocalization, useNotes } from '~/contexts/global';
import { useLocalPagination } from '~/hooks';
import { dateFormat } from '~/utils/date';
import type { TableColumns } from '~/components/ui';

type NotesTableListProps = {
  type: string;
  id: string;
};
export const NotesTableList = (props: NotesTableListProps) => {
  const { t } = useLocalization();
  const { filtered, setFilter, deleteNote } = useNotes();
  const navigate = useNavigate();
  const { page, pageSize, setPage, setPageSize } = useLocalPagination('NotesListView');

  const handleDeleteNote = async (noteId: string) => {
    await deleteNote({ entityId: props.id, entityType: props.type, noteId });
  };
  const handleEditNote = (noteId: string) => {
    navigate(`${noteId}/edit`);
  };

  const columns = createMemo<TableColumns<MagicDoor.Api.NoteListDto>>(() => [
    {
      title: t('Note'),
      render: (item) => <div class="w-48 truncate">{item.content}</div>,
    },
    {
      title: t('Date updated'),
      render: (item) => dateFormat('', item.createdAt),
    },
    {
      title: t('Updated by'),
      render: (item) => (
        <div class="flex items-center gap-1">
          <Show when={item.propertyManager?.firstName}>
            <div class="hidden md:flex">
              <Avatar size="default" name={getTenantFullName(item.propertyManager)} />
            </div>
            <span class="text-xs font-medium text-text-level02">
              {item.propertyManager?.firstName} {item.propertyManager?.lastName}
            </span>
          </Show>
        </div>
      ),
    },
    {
      class: 'text-sm',
      render: (item) => (
        <TableActions
          actions={[
            { label: t('Edit'), icon: IconPencil, onClick: () => handleEditNote(item.id) },
            { label: t('Delete'), icon: IconDelete, onClick: () => handleDeleteNote(item.id) },
          ]}
        />
      ),
    },
  ]);

  onMount(() => setFilter({ page: page(), pageSize: pageSize(), entityId: props.id, entityType: props.type }));

  return (
    <ListView
      title={t('Notes')}
      loading={filtered.loading}
      data={filtered()?.items}
      columns={columns()}
      onChange={({ page, pageSize }) => {
        setPage(page);
        setPageSize(pageSize);
        setFilter({ page, pageSize, entityId: props.id, entityType: props.type });
      }}
      totalPages={filtered()?.totalPages}
      page={filtered()?.currentPage}
      pageSize={filtered()?.pageSize}
      titleActions={
        <div class="flex gap-2">
          <Button href={'add'} class="font-medium capitalize">
            <IconPlus class="size-5 text-white" />
            {t('Add note')}
          </Button>
        </div>
      }
    />
  );
};
