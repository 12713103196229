import { leaseDraftRepository } from '~/pdfsigner/repositories/leaseDraftRepository';
import { MagicUseCase } from '~/pdfsigner/usecases/baseUseCases/magicUseCase';
import { GetDataPathsUseCase } from './getDataPathsUseCase';
import type { Document } from '~/pdfsigner/usecases/types/document';

export class LoadLeaseDraftUseCase extends MagicUseCase {
  public async runLogic(draftId?: string) {
    if (!draftId) {
      return;
    }
    const document: Document = await leaseDraftRepository.getLeaseDraft(draftId);
    await new GetDataPathsUseCase().execute(document.leaseId);
    this.getState().selectedDocument = document;
    const selectedSigner = document.signers.find((signer) => signer.id === this.getState().selectedSigner?.id);
    if (!selectedSigner) {
      this.getState().selectedSigner = document.signers[0];
    }
  }
}
