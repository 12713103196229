import { createSignal } from 'solid-js';
import { createMagicDoorContext } from '~/contexts/utils';
import { LeaseRepository } from '~/repositories/leaseRepository';
import { sendRequestsInPool } from '~/utils/requestLoop';
import type { Accessor } from 'solid-js';
import type { Pagination } from '~/hooks';
import type { ExpiringLeaseListFilter, LeaseListFilter } from '~/repositories/leaseRepository';

interface LeasesListContextProps {
  filter: Accessor<LeaseListFilter | undefined>;
  leases: Accessor<MagicDoor.Api.HydratedLeaseDto[]>;
  loading: Accessor<boolean>;
  error: Accessor<Error | undefined>;
  getLeases: (params: LeaseListFilter) => Promise<void>;
  refetchLeases: () => Promise<void>;
  getExpiringLeases: (params: ExpiringLeaseListFilter) => Promise<void>;
  onLeaseAdded: (lease: MagicDoor.Api.HydratedLeaseDto) => void;
  pagination: Accessor<Pagination>;
  sendNotifyBalanceDue: (leaseIds: string[]) => Promise<void>;
  sendNotifyBalanceDueList: Accessor<string[]>;
}

export const [LeasesListProvider, useLeasesList] = createMagicDoorContext<LeasesListContextProps>('LeasesList', () => {
  const repo = new LeaseRepository();
  const [filter, setFilter] = createSignal<LeaseListFilter>();
  const [leases, setLeases] = createSignal<MagicDoor.Api.HydratedLeaseDto[]>([]);
  const [loading, setLoading] = createSignal<boolean>(false);
  const [error, setError] = createSignal<Error | undefined>(undefined);
  const [pagination, setPagination] = createSignal<Pagination>({
    currentPage: 1,
    pageSize: 10,
    totalCount: 0,
    totalPages: 0,
  });
  const [sendNotifyBalanceDueList, setSendNotifyBalanceDueList] = createSignal<string[]>([]);

  const getLeases = async (params: LeaseListFilter) => {
    setLoading(true);
    try {
      setFilter(params);
      const response = await repo.getLeases(params);

      if (response.totalPages > 0 && params.page && params.page > response.totalPages) {
        return await getLeases({ ...params, page: response.totalPages });
      }

      setLeases(response.items);
      setPagination({
        currentPage: response.currentPage,
        pageSize: response.pageSize,
        totalCount: response.totalCount,
        totalPages: response.totalPages,
      });
    } catch (err: any) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  const refetchLeases = async () => {
    if (filter() == null) return;
    await getLeases({ ...filter() });
  };

  const onLeaseAdded = (lease: MagicDoor.Api.HydratedLeaseDto) => {
    setLeases((prev) => [lease, ...prev]);
  };

  const sendNotifyBalanceDue = async (leaseIds: string[]) => {
    try {
      const requests = leaseIds.map((leaseId) => () => repo.sendNotifyBalanceDue(leaseId));
      await sendRequestsInPool(requests, requests?.length < 6 ? requests?.length : 6);
    } catch (error: any) {
      setError(error);
    } finally {
      setSendNotifyBalanceDueList((prev) => [...prev, ...leaseIds]);
    }
  };

  const getExpiringLeases = async (params: ExpiringLeaseListFilter) => {
    setLoading(true);
    try {
      setFilter(params);
      const response = await repo.getExpiringLeases(params);
      setLeases(response.items);
      setPagination({
        currentPage: response.currentPage,
        pageSize: response.pageSize,
        totalCount: response.totalCount,
        totalPages: response.totalPages,
      });
    } catch (err: any) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  return {
    sendNotifyBalanceDueList,
    filter,
    leases,
    getExpiringLeases,
    loading,
    error,
    getLeases,
    refetchLeases,
    onLeaseAdded,
    pagination,
    sendNotifyBalanceDue,
  };
});
