import { createEffect, createResource, createSignal, on } from 'solid-js';
import { useLocalization } from '~/contexts/localization';
import { createMagicDoorContext } from '~/contexts/utils';
import { BillRepository } from '~/repositories/billRepository';
import { createLazyResource, createMutation } from '~/utils/resource';
import type { NewFileReadyToUpload } from '~/components/file-attachments';
import type { BillListFilter } from '~/repositories/billRepository';

const repo = new BillRepository();

export const [BillsProvider, useBills] = createMagicDoorContext('Bills', () => {
  const { t } = useLocalization();
  const [filter, setFilter] = createSignal<BillListFilter>({});

  const [bills, { fetch, refetch, mutate: mutateBills }] = createLazyResource(filter, async (filters) => {
    const pagination = await repo.getBills(filters);
    return {
      items: pagination.items,
      page: pagination.currentPage,
      pageSize: pagination.pageSize,
      totalPages: pagination.totalPages,
      totalCount: pagination.totalCount,
    };
  });

  const [fileList, setFileList] = createSignal<NewFileReadyToUpload[]>([]);

  createEffect(on(filter, refetch));

  const [currentId, setCurrentId] = createSignal<string | undefined>(undefined);
  const [current, currentActions] = createResource(currentId, (id) => repo.getBill(id));

  const addBill = createMutation(async (model: MagicDoor.Api.CreateVendorBillDto, callback?: any) => {
    const created = await repo.createBill(model);
    mutateBills((prev) => {
      if (prev?.items) {
        prev.items = [...prev.items, created];
      }
      return prev;
    });

    for (const file of fileList()) {
      callback?.(`${t('Uploading')}  ${file.fileName}...`);
      await addBillFile(created.id, file.file, file.description);
    }

    return created;
  });

  const updateBill = createMutation(
    async (billId: string, model: MagicDoor.Api.UpdateVendorBillDto): Promise<MagicDoor.Api.HydratedBillDto> => {
      const updated = await repo.updateBill(billId, model);

      if (billId === currentId()) currentActions.mutate(updated);
      mutateBills((prev) => {
        if (prev?.items) {
          prev.items = prev?.items.map((t) => (t.id === billId ? updated : t));
        }
        return prev;
      });
      return updated;
    }
  );

  const deleteBill = createMutation(async (billId: string, deleteMethod: MagicDoor.Api.DeleteBillPaymentMethod) => {
    await repo.deleteBill(billId, deleteMethod);
    mutateBills((prev) => {
      if (prev?.items) {
        prev.items = prev?.items.filter((t) => t.id !== billId);
      }
      return prev;
    });
  });

  const deleteBillItem = createMutation(
    async (billId: string, transactionId: string, deleteMethod: MagicDoor.Api.DeleteBillPaymentMethod) => {
      await repo.deleteBillItem(billId, transactionId, deleteMethod);
      mutateBills((prev) => {
        if (prev?.items) {
          prev.items = prev?.items.filter((t) => t.id !== billId);
        }
        return prev;
      });
      currentActions.refetch();
    }
  );

  const payBill = createMutation(async (payload: MagicDoor.Api.PayVendorBillsDto) => {
    const result = await repo.payBill(payload);
    return result;
  });

  const addBillFile = createMutation(async (billId: string, file: File, description?: string) => {
    const created = await repo.addBillFile(billId, file, description);
    currentActions.mutate(created);
  });

  const deleteBillFile = createMutation(async (billId: string, fileId: string) => {
    await repo.deleteBillFile(billId, fileId);

    const update = {
      ...current(),
      files: current()?.files?.filter((file) => file.fileId !== fileId),
    };
    currentActions.mutate(update as MagicDoor.Api.HydratedBillDto);
  });

  return {
    get loading() {
      fetch();
      return () => bills.loading;
    },
    get filtered() {
      fetch();
      return bills;
    },
    refetch,
    setFilter,
    setCurrentId,
    current,
    addBill,
    updateBill,
    deleteBill,
    deleteBillItem,
    payBill,
    addBillFile,
    deleteBillFile,
    setFileList,
    fileList,
  };
});
