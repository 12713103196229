import { MagicUseCase } from '~/pdfsigner/usecases/baseUseCases/magicUseCase';
import type { AnnotationType } from '~/pdfsigner/usecases/types/annotation';

export class SetAnnotationTypeUseCase extends MagicUseCase {
  public async runLogic(type: AnnotationType) {
    if (type === undefined || !this.isPdfLoaded()) {
      return;
    }
    this.getState().selectedAnnotation = undefined;
    if (this.getState().selectedAnnotationType === type) {
      this.getState().selectedAnnotationType = undefined;
      return;
    }
    this.getState().selectedAnnotationType = type;
  }
}
