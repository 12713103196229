import { useSearchParams } from '@solidjs/router';
import { createMemo } from 'solid-js';
import { useLocalization } from '~/contexts/global';
import { RentalApplicationListView } from './list-components/RentalApplicationListView';
import { RentalApplicationStatusFilterTabs } from './list-components/RentalApplicationStatusFilterTabs';
import { statusFilters } from './list-components/statusFilters';

export const RentalApplicationsPage = () => {
  const { t } = useLocalization();
  const [searchParams] = useSearchParams();

  const currentStatusKey = createMemo(() => {
    const valid: Record<keyof MagicDoor.Api.RentalApplicationCountsDto, true> = {
      newRentalApplications: true,
      allApplications: true,
      approvedApplications: true,
      deniedApplications: true,
      cachedAt: true,
    };
    const status = searchParams.status as keyof MagicDoor.Api.RentalApplicationCountsDto;
    return valid[status] ? status : 'newRentalApplications';
  });

  const currentStatus = createMemo(() => statusFilters.find((s) => s.key === currentStatusKey()) ?? statusFilters[0]);
  return (
    <div class="space-y-5 p-8">
      <RentalApplicationStatusFilterTabs current={currentStatusKey()} />
      <RentalApplicationListView title={t(currentStatus().label)} filter={currentStatus().filter} />
    </div>
  );
};
