import { createMemo, createSignal, For, Show } from 'solid-js';
import { produce } from 'solid-js/store';
import IconCircleArrow from '~/assets/images/common/circleArrow.svg?component-solid';
import IconDelete from '~/assets/images/common/deleteRegular.svg?component-solid';
import { DropdownMenu } from '~/components/common/DropdownMenu';
import { Empty } from '~/components/common/Empty';
import DueDateInputField from '~/components/common/Inputs/DueDateInputField';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { Table } from '~/components/ui';
import { useCompanies, useLocalization } from '~/contexts/global';
import { cn } from '~/utils/classnames';
import { emptyPlaceholder, paymentMethodOptions, paymentTypeOptions } from '~/utils/constant';
import { currency } from '~/utils/number';
import type { JSX } from 'solid-js';
import type { SetStoreFunction } from 'solid-js/store';
import type { BillPayment, PayBillsForm } from '~/pages/bills/pay-bill/types';
import type { TBillStore } from '~/pages/bills/store';

interface PaymentFormProps {
  store: TBillStore;
  form: PayBillsForm;
  setFormData: SetStoreFunction<PayBillsForm>;
}

export const PaymentForm = (props: PaymentFormProps) => {
  const { t } = useLocalization();
  const { companies } = useCompanies();

  const [paymentDate, setPaymentDate] = createSignal<string | null>(new Date().toISOString().split('T')[0]);
  const [collapsedVendorIds, setCollapsedVendorIds] = createSignal(new Set<string | undefined>());
  const billsGroupByVendor = createMemo(() => {
    const map = new Map<string, BillPayment[]>();

    props.form.billPayments.forEach((bill) => {
      if (map.get(bill.vendor.id)) {
        map.set(bill.vendor.id, [...(map.get(bill.vendor.id) as BillPayment[]), bill]);
      } else {
        map.set(bill.vendor.id, [bill]);
      }
    });

    return Array.from(map.values());
  });

  const onUpdateBills = (payload: { key: keyof BillPayment; value: any; billId?: string; vendorId?: string }) => {
    const { key, value, vendorId, billId } = payload;

    props.setFormData(
      produce((state) => {
        for (let i = 0; i < state.billPayments.length; i++) {
          if (vendorId && state.billPayments.at(i)?.vendor.id === vendorId) {
            state.billPayments[i][key] = value as never;
          } else if (billId && state.billPayments.at(i)?.id === billId) {
            state.billPayments[i][key] = value as never;
            break;
          }
        }
      })
    );
  };

  const onRemoveBills = (vendorId?: string) => {
    props.setFormData(
      'billPayments',
      props.form.billPayments.filter((item) => item.vendor.id !== vendorId)
    );
  };

  const columns = [
    {
      title: t('Vendor'),
      class: 'w-[25%]',
      render: (bills: BillPayment[]) => (
        <div class="flex items-center gap-5">
          <span>{bills.at(0)?.vendor.name}</span>
          <Show when={bills.length > 1}>
            <span class="whitespace-nowrap text-[#FF7448]">{`${bills.length} ${t('Payments')}`}</span>
          </Show>
        </div>
      ),
    },
    {
      title: t('Account'),
      class: 'w-[25%]',
      render: (bills: BillPayment[]) => (
        <DropdownMenu
          buttonClass="bg-white text-xs"
          overlayClass="text-xs"
          value={bills.at(0)?.bankAccountId}
          options={props.store.bankAccountOptions ?? []}
          onChange={(value) => onUpdateBills({ key: 'bankAccountId', value, vendorId: bills.at(0)?.vendor.id })}
        />
      ),
    },
    {
      title: t('Payment Type'),
      class: 'w-[20%]',
      render: (bills: BillPayment[]) => (
        <DropdownMenu
          buttonClass="bg-white text-xs"
          overlayClass="text-xs"
          value={bills.at(0)?.paymentType}
          options={paymentTypeOptions(t, companies()?.id)}
          onChange={(value) => onUpdateBills({ key: 'paymentType', value, vendorId: bills.at(0)?.vendor.id })}
        />
      ),
    },
    {
      title: t('Amount to pay'),
      class: 'w-[20%] text-right',
      render: (bills: BillPayment[]) => (
        <span class="font-semibold text-[#FF7448]">{currency(bills.reduce((acc, cur) => acc + (cur.paymentAmount ?? 0), 0))}</span>
      ),
    },
    {
      title: '',
      class: 'w-[10%]',
      render: (bills: BillPayment[]) => (
        <div class="flex items-center gap-3">
          <IconCircleArrow
            class={cn({
              'text-text-level02': collapsedVendorIds().has(bills.at(0)?.vendor.id),
              'text-primary rotate-180': !collapsedVendorIds().has(bills.at(0)?.vendor.id),
            })}
          />
          <IconDelete
            class="text-text-level02 hover:text-primary"
            onClick={(e) => {
              e.stopPropagation();
              onRemoveBills(bills.at(0)?.vendor.id);
            }}
          />
        </div>
      ),
    },
  ];

  const handleDateChange = (date: string | null) => {
    setPaymentDate(date);
    props.setFormData('paymentDate', date);
  };

  const onToggleExpanded = (vendorId?: string) => {
    setCollapsedVendorIds((prev) => {
      const next = new Set(prev);

      if (next.has(vendorId)) {
        next.delete(vendorId);
      } else {
        next.add(vendorId);
      }

      return next;
    });
  };

  return (
    <div class="thinscroll mx-auto flex w-10/12 min-w-[710px] max-w-[1200px] flex-col overflow-x-auto">
      <Show
        when={props.form.billPayments.length}
        fallback={<Empty description={t('The property and the vendor you selected do not have a bill')} />}>
        <h4 class="mt-3 text-base font-semibold text-text-level01">{t('Payment date')}</h4>
        <div class="mb-5 mt-4">
          <DueDateInputField value={paymentDate()} class="w-1/2" onInput={handleDateChange} placeholder={t('Select payment date')} />
        </div>
        <h4 class="mb-3 text-base font-semibold text-text-level01">{t('Payment Method')}</h4>
        <div class="flex items-center border-t border-input-border">
          <For each={columns}>{(column) => <div class={cn('p-4 text-xs text-text-level02', column.class)}>{column.title}</div>}</For>
        </div>
        <div class="flex flex-col gap-4">
          <For each={billsGroupByVendor()}>
            {(bills) => (
              <VendorGroup
                isExpanded={!collapsedVendorIds().has(bills.at(0)?.vendor.id)}
                bills={bills}
                columns={columns}
                onToggleExpanded={() => onToggleExpanded(bills.at(0)?.vendor.id)}
                onUpdateBills={onUpdateBills}
              />
            )}
          </For>
        </div>
      </Show>
    </div>
  );
};

interface VendorGroupProps {
  bills: BillPayment[];
  isExpanded: boolean;
  columns: { title: string; render: (bills: BillPayment[]) => JSX.Element; class?: string }[];
  onToggleExpanded: () => void;
  onUpdateBills: (payload: { key: keyof BillPayment; value: any; billId?: string; vendorId?: string }) => void;
}

const VendorGroup = (props: VendorGroupProps) => {
  const { t } = useLocalization();

  return (
    <div class="overflow-hidden rounded-lg border border-input-border bg-white" onClick={() => props.onToggleExpanded()}>
      <div class={cn('flex cursor-pointer items-center bg-input', { 'border-b border-input-border': props.isExpanded })}>
        <For each={props.columns}>
          {(column) => <div class={cn('px-4 py-2 text-xs text-text-level02', column.class)}>{column.render(props.bills)}</div>}
        </For>
      </div>
      <Show when={props.isExpanded}>
        <div class="p-5" onClick={(e) => e.stopPropagation()}>
          <p class="mb-2 text-xs font-semibold text-text-level01">{t('Payment Information')}</p>
          <PaymentInfoTable bills={props.bills} onUpdateBills={props.onUpdateBills} />
        </div>
      </Show>
    </div>
  );
};

interface PaymentInfoTableProps {
  bills: BillPayment[];
  onUpdateBills: (payload: { key: keyof BillPayment; value: any; billId?: string; vendorId?: string }) => void;
}

export const PaymentInfoTable = (props: PaymentInfoTableProps) => {
  const { t } = useLocalization();
  const columns = createMemo(() => {
    const isDisplayPaymentMethodColumn = props.bills.at(0)?.paymentType === 'manual';
    const propertyColumn = {
      title: t('Property'),
      headerClass: cn('w-1/4 bg-cell-purple-bg text-cell-purple-text', { 'w-[30%]': !isDisplayPaymentMethodColumn }),
      class: 'text-xs py-2',
      render: (bill: BillPayment) => (
        <div class="flex flex-col gap-1.5">
          <p>{bill.property?.name ?? emptyPlaceholder}</p>
          <p>{bill.property?.address?.streetAddress1}</p>
        </div>
      ),
    };
    const paymentMethodColumn = {
      title: t('Payment Method'),
      headerClass: 'w-1/4 bg-cell-purple-bg text-cell-purple-text',
      class: 'text-xs py-2',
      render: (bill: BillPayment) => (
        <DropdownMenu
          buttonClass="bg-light-gray text-xs"
          overlayClass="text-xs"
          value={bill.paymentMethod}
          options={paymentMethodOptions(t)}
          onChange={(value) => props.onUpdateBills({ key: 'paymentMethod', value, billId: bill.id })}
        />
      ),
    };
    const memoColumn = {
      title: t('Memo'),
      headerClass: cn('w-[30%] bg-cell-purple-bg text-cell-purple-text', { 'w-[35%]': !isDisplayPaymentMethodColumn }),
      class: 'text-xs py-2',
      render: (bill: BillPayment) => (
        <LabeledTextInput
          maxlength={150}
          inputClass="text-xs"
          inputContainerClass="bg-light-gray"
          value={bill.memo || bill.vendorMemo || ''}
          placeholder={t('Enter memo')}
          onInput={(value) => props.onUpdateBills({ key: 'memo', value, billId: bill.id })}
          validationFunction={(value) => {
            const str = value.toString();
            return str.length > 150 ? t('{name} must be {length} characters or less', { name: t('Memo'), length: '150' }) : undefined;
          }}
        />
      ),
    };
    const transactionIdColumn = {
      title: t('External transaction ID'),
      headerClass: cn('w-1/5 bg-cell-purple-bg text-cell-purple-text', { 'w-[40%]': !isDisplayPaymentMethodColumn }),
      class: 'text-xs py-2',
      render: (bill: BillPayment) => (
        <Show
          when={bill.paymentType === 'printCheck' || (bill.paymentType === 'manual' && bill.paymentMethod === 'check')}
          fallback={
            <div class="flex h-[38px] items-center">
              <LabeledTextInput
                inputContainerClass="bg-light-gray"
                inputClass="text-xs"
                class="w-full"
                value={bill.externalTransactionId ?? ''}
                placeholder={t('Enter transaction ID')}
                onInput={(value) => props.onUpdateBills({ key: 'externalTransactionId', value, billId: bill.id })}
              />
            </div>
          }>
          {t('It will be generated automatically')}
        </Show>
      ),
    };

    if (isDisplayPaymentMethodColumn) {
      return [propertyColumn, paymentMethodColumn, memoColumn, transactionIdColumn];
    }

    return [propertyColumn, memoColumn, transactionIdColumn];
  });

  return (
    <div class="overflow-hidden rounded-lg border border-input-border">
      <Table columns={columns()} data={props.bills} />
    </div>
  );
};
