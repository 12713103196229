import { createMemo } from 'solid-js';
import { UnitLeaseNavbar } from '~/components/units/UnitLeaseNavbar';
import { useLocalization } from '~/contexts/global';
import type { Component } from 'solid-js';
export const LeaseNavbar: Component = () => {
  const { t } = useLocalization();

  const leaseNavItems = createMemo(() => [
    { label: t('Current leases'), href: '' },
    { label: t('Communications'), href: 'communications' },
    { label: t('Rent charges'), href: 'rent-charges' },
    { label: t('Transactions'), href: 'transactions' },
    { label: t('Deposits'), href: 'deposits' },
    { label: t('Emails'), href: 'emails' },
    { label: t('Signings'), href: 'signings' },
    { label: t('Lease files'), href: 'files' },
    { label: t('Notes'), href: 'notes' },
  ]);

  return <UnitLeaseNavbar showLeaseSelect={true} leaseNavItems={leaseNavItems()} />;
};
