import { Show, For, createMemo, createSignal } from 'solid-js';
import CircleArrowIcon from '~/assets/images/common/circleArrow.svg?component-solid';
import IconInformation from '~/assets/images/common/info.svg?component-solid';
import IconGreenCheck from '~/assets/images/owner-funds/greenCheck.svg';
import IconPurpleInfo from '~/assets/images/owner-funds/purpleInfo.svg';
import { Section } from '~/components/common/FormSectionTitle';
import { Checkbox } from '~/components/common/Inputs/Checkbox';
import { RingLoader } from '~/components/common/Loaders';
import { PropertyTitle } from '~/components/properties';
import { useLocalization } from '~/contexts/global';
import { useAddOwnerDistributions } from '~/contexts/local';
import { cn } from '~/utils/classnames';
import type { PropertyReviewRecord } from '~/contexts/local';

export const PropertiesReviewListView = () => {
  const { t } = useLocalization();
  const { propertyValidationRecords, onChangePropertyValidationRecords } = useAddOwnerDistributions();
  const [isInvalidatedPropertiesExpanded, setIsInvalidatedPropertiesExpanded] = createSignal(false);
  const validatedProperties = createMemo(() => propertyValidationRecords()?.filter((item) => !item.isMissingOwnerInformation) ?? []);
  const invalidatedProperties = createMemo(() => propertyValidationRecords()?.filter((item) => item.isMissingOwnerInformation) ?? []);

  const onChangeChecked = (checked: boolean, propertyId?: string) => {
    const data = [...(propertyValidationRecords() ?? [])];
    const targetIndex = data?.findIndex((item) => item.property?.id === propertyId);

    if (!data || !targetIndex || targetIndex < -1) {
      return;
    }

    data[targetIndex].checked = checked;
    onChangePropertyValidationRecords(data);
  };

  const columns = [
    {
      class: 'w-[60%] p-4',
      render: (item: PropertyReviewRecord) => (
        <PropertyTitle feedbackIconReadonly property={item.property} class="h-9 [&>img]:size-9 [&_h4]:text-xs [&_h4]:font-medium" />
      ),
    },
    {
      class: 'w-[30%] p-4',
      render: (item: PropertyReviewRecord) => (
        <Show
          when={!item.isMissingOwnerInformation}
          fallback={
            <div class="flex max-w-fit items-center gap-1 overflow-hidden whitespace-nowrap rounded-full border border-essential-colour bg-[rgba(214,69,251,0.08)] px-3 py-1 text-xs font-normal text-essential-colour">
              <img src={IconPurpleInfo} />
              <Show
                when={(item.ownershipPercentage ?? 0) < 1}
                fallback={
                  <span>{t('{percentage}% Assigned', { percentage: `${((item.ownershipPercentage ?? 0) * 100).toFixed(0)}` })}</span>
                }>
                <span>{t('Only {percentage}% Assigned', { percentage: `${((item.ownershipPercentage ?? 0) * 100).toFixed(0)}` })}</span>
              </Show>
            </div>
          }>
          <div class="flex max-w-fit items-center gap-1 overflow-hidden whitespace-nowrap text-xs font-normal text-light-green">
            <img src={IconGreenCheck} />
            <span>{t('Owner Assigned')}</span>
          </div>
        </Show>
      ),
    },
  ];

  return (
    <Show
      when={!propertyValidationRecords.loading}
      fallback={
        <div class="flex items-center justify-center pt-6">
          <RingLoader color="#A126EC" text={`${t('Loading')}...`} />
        </div>
      }>
      <div class="flex flex-col gap-6">
        <Section title={t('Property review')} class="mb-0 [&>*]:mb-0">
          <div class="mt-4 flex flex-col gap-4">
            <For each={validatedProperties()}>
              {(record) => (
                <div class="flex items-center gap-3">
                  <Checkbox
                    checked={record.checked}
                    disabled={record.isMissingOwnerInformation}
                    onInput={(checked) => onChangeChecked(checked, record.property?.id)}
                  />
                  <div class="flex flex-1 items-center rounded-lg border border-input-border bg-input text-title-gray">
                    <For each={columns}>{(column) => <span class={cn('overflow-hidden', column.class)}>{column.render(record)}</span>}</For>
                  </div>
                </div>
              )}
            </For>
          </div>
        </Section>
        <Section
          titleJSX={
            <div class="flex w-full items-center justify-between">
              <div class="inline-flex items-center gap-1">
                <span class="text-base font-semibold text-text-level01">{t('Invalid Properties')}</span>
                <span class="rounded-full bg-[#FF7448] px-1.5 text-xs leading-[18px] text-white">{invalidatedProperties().length}</span>
              </div>
              <div class="inline-flex items-center gap-1.5">
                <div class="inline-flex items-center gap-1">
                  <IconInformation class="text-[#FF7448]" />
                  <span class="text-xs text-[#FF7448]">{t('Missing owner information,can not be selected')}</span>
                </div>
                <div class="w-fit cursor-pointer rounded bg-transparent p-1 transition-all hover:bg-input-border">
                  <CircleArrowIcon
                    onClick={() => setIsInvalidatedPropertiesExpanded((prev) => !prev)}
                    class={cn({
                      'text-text-level02': !isInvalidatedPropertiesExpanded(),
                      'text-primary rotate-180': isInvalidatedPropertiesExpanded(),
                    })}
                  />
                </div>
              </div>
            </div>
          }
          border
          class="mb-0 [&>*]:mb-0">
          <div class={cn('mt-4 flex flex-col gap-4 overflow-hidden', { 'h-0': !isInvalidatedPropertiesExpanded() })}>
            <For each={invalidatedProperties()}>
              {(record) => (
                <div class="flex flex-1 items-center rounded-lg border border-input-border bg-input text-title-gray">
                  <For each={columns}>{(column) => <span class={cn('overflow-hidden', column.class)}>{column.render(record)}</span>}</For>
                </div>
              )}
            </For>
          </div>
        </Section>
      </div>
    </Show>
  );
};
