import { MagicUseCase } from '~/pdfsigner/usecases/baseUseCases/magicUseCase';

export class ChangeFontSizeUseCase extends MagicUseCase {
  public async runLogic(strFontSize: string) {
    const fontSize = parseInt(strFontSize);
    if (!isNaN(fontSize) && fontSize >= 0 && fontSize < 999) {
      this.getState().selectedFontSize = fontSize;
      const selectedAnnotation = this.getState().selectedAnnotation;
      if (selectedAnnotation) {
        selectedAnnotation.fontSize = fontSize;
      }
    }
  }
}
