import { createContext, createSignal, Show, onCleanup, onMount } from 'solid-js';
import { useLocalization } from '~/contexts/global';
import { HttpError } from '~/errors';
import { eventEmitter } from '~/framework/eventEmitter';
import { DocumentCategoryRequiredError } from '~/pdfsigner/errors/categoryRequiredError';
import { LeaseTemplateIdsRequiredError } from '~/pdfsigner/errors/leaseTemplateIdsRequired';
import { DocumentSignersMissingError } from '~/pdfsigner/errors/signersMissingError';
import { SignersRequiredError } from '~/pdfsigner/errors/signersRequiredError';
import { DocumentTitleRequiredError } from '~/pdfsigner/errors/titleRequiredError';
import type { JSX, Component } from 'solid-js';

const ErrorContext = createContext();

interface ErrorHandlerProps {
  children: JSX.Element;
}

export const ErrorHandler: Component<ErrorHandlerProps> = (props) => {
  const [error, setError] = createSignal<Error | undefined>(undefined);
  const [shouldDisplay, setShouldDisplay] = createSignal<boolean>(true);
  const { t } = useLocalization();

  const reportError = (error: Error, shouldDisplay?: boolean) => {
    if (error instanceof HttpError) return;
    const display = shouldDisplay !== false;
    setShouldDisplay(display);
    setError(error);
  };

  const clearError = () => {
    setError(undefined);
  };

  const getMessageForError = (error?: Error): string => {
    if (error instanceof DocumentTitleRequiredError) {
      return 'Please provide a title for the document.';
    }
    if (error instanceof DocumentCategoryRequiredError) {
      return 'Please select a category for the document.';
    }
    if (error instanceof SignersRequiredError) {
      return 'Please add signers to the document.';
    }
    if (error instanceof DocumentSignersMissingError) {
      return 'Please make sure that all signers have their signatures placed.';
    }
    if (error instanceof LeaseTemplateIdsRequiredError) {
      return 'Please select at least one lease template.';
    }
    return 'Something went wrong. Please try again later.';
  };

  const errorHandler = (error: Error) => {
    reportError(error);
  };

  onMount(() => {
    eventEmitter.registerForErrors(errorHandler);
  });

  onCleanup(() => {
    eventEmitter.unregisterFromErrors(errorHandler);
  });

  return (
    <ErrorContext.Provider value={{ error, reportError, clearError }}>
      {props.children}
      <Show when={error() && shouldDisplay()}>
        <div class="fixed inset-0 z-[9999] flex items-center justify-center bg-black/10 p-4">
          <div class="w-[500px] max-w-full overflow-hidden rounded-lg bg-white shadow-lg">
            <div class="border-b border-gray-200 px-6 py-4">
              <h2 class="text-center text-lg font-semibold text-primary">{'Oops!'}</h2>
            </div>
            <div class="p-6 py-4">
              <p class="text-center text-sm">{t(getMessageForError(error()))}</p>
            </div>
            <div class="py-4 text-center">
              <button class="rounded bg-primary px-4 py-2 font-bold text-white hover:bg-fuchsia-700" onClick={clearError}>
                {t('Close')}
              </button>
            </div>
          </div>
        </div>
      </Show>
    </ErrorContext.Provider>
  );
};

export default ErrorContext;
