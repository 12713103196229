import { useNavigate, useParams } from '@solidjs/router';
import { onMount } from 'solid-js';
import IconPlus from '~/assets/images/common/plus.svg?component-solid';
import { Button } from '~/components/common/Buttons';
import { FileTitle } from '~/components/file-attachments';
import { IconDownload, IconTrash, ListView } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import usePresenter from '~/framework/hooks/usePresenter';
import useUseCase from '~/framework/hooks/useUseCase';
import { LeaseDraftsPresenter } from '~/pdfsigner/presenters/LeaseDraftsPresenter';
import { DeleteLeaseDraftUseCase } from '~/pdfsigner/usecases/pdfEditing/deleteLeaseDraftUseCase';
import { LoadLeaseDraftsForLeaseUseCase } from '~/pdfsigner/usecases/pdfEditing/loadLeaseDraftsForLeaseUseCase';
import { downloadFile } from '~/utils/file';
import type { Component } from 'solid-js';
import type { PresentableDocumentDescriptor } from '~/pdfsigner/ui/types/presentablePaginatedLeaseDrafts';

export const Signings: Component = () => {
  const params = useParams();
  const { t } = useLocalization();
  const navigate = useNavigate();
  const { execute: getDocuments, isLoading: isLoadingDocuments } = useUseCase(LoadLeaseDraftsForLeaseUseCase);
  const { execute: deleteDraft, isLoading: isDeletingDraft } = useUseCase(DeleteLeaseDraftUseCase);
  const { model: documents } = usePresenter(LeaseDraftsPresenter);

  const loadLeaseDrafts = async () => {
    const docs = documents();
    const page = docs ? docs.page : 1;
    getDocuments({ leaseId: params.leaseId, page });
  };

  onMount(() => {
    loadLeaseDrafts();
  });

  const onRowClick = (item: PresentableDocumentDescriptor) => {
    navigate(`/leasing/leases/${params.leaseId}/draft/${item.id}`);
  };

  const tableActions = (item: PresentableDocumentDescriptor) => {
    const actions = [
      {
        label: t('Download'),
        icon: IconDownload,
        onClick: () => downloadFile(item.signedUrl),
      },
      {
        label: t('Delete'),
        icon: IconTrash,
        onClick: async () => {
          await deleteDraft(item.id);
          await loadLeaseDrafts();
        },
      },
    ];
    return actions;
  };

  return (
    <div class="text-left">
      <ListView
        class="mt-4"
        title={t('Signings')}
        titleActions={
          <Button href={`/leasing/leases/create-lease-draft/${params.leaseId}`} class="font-medium capitalize">
            <IconPlus class="size-5 text-white" />
            {t('New document')}
          </Button>
        }
        onRowClick={onRowClick}
        loading={isLoadingDocuments() || isDeletingDraft()}
        data={documents()?.items}
        page={documents()?.page}
        totalPages={documents()?.totalPages}
        pageSize={documents()?.pageSize}
        columns={[
          {
            title: t('File'),
            render: (item) => <FileTitle file={{ fileName: item.name }} />,
          },
          {
            title: t('Status'),
            headerClass: 'w-40',
            key: 'status',
          },
        ]}
        actions={(item) => tableActions(item)}
      />
    </div>
  );
};
