import { For } from 'solid-js';
import { Checkbox } from '~/components/common/Inputs/Checkbox';
import { useLocalization } from '~/contexts/global';
import { signerColors } from '~/pdfsigner/ui/types/signerColors';
import type { PresentableSignerList } from '~/pdfsigner/ui/types/presentableSignerList';

export interface SignersSelectorProps {
  signerList?: PresentableSignerList;
  onSelectSigner: (index: number) => void;
  onSetSignerPm?: (data: { index: number; isPropertyManager: boolean }) => void;
  isPmDisabled?: boolean;
}

export const SignersSelector = (props: SignersSelectorProps) => {
  const { t } = useLocalization();

  return (
    <div class="flex flex-col rounded-lg border border-input-border px-4">
      <For each={props.signerList?.signers}>
        {(signer, index) => (
          <div class="flex items-center justify-between border-b border-input-border py-3 last-of-type:border-b-0">
            <Checkbox
              label={signer.name}
              showLabel
              htmlType="radio"
              labelPosition="right"
              labelClass="max-w-[90px] text-sm"
              checked={props.signerList?.selectedSigner.id === signer.id}
              labelStyle={{ color: signerColors[index()] }}
              onInput={() => props.onSelectSigner(index())}
            />
            <Checkbox
              label={t('PM')}
              showLabel
              disabled={props.isPmDisabled}
              htmlType="checkbox"
              labelPosition="right"
              labelClass="text-sm"
              checked={signer.isPropertyManager}
              onInput={(checked: boolean) => props.onSetSignerPm?.({ index: index(), isPropertyManager: checked })}
            />
          </div>
        )}
      </For>
    </div>
  );
};
