import { useParams } from '@solidjs/router';
import { createEffect, Show, createMemo, untrack } from 'solid-js';
import { AIScoreCell } from '~/components/common/AiScoreCell';
import { Avatar } from '~/components/common/Avatar';
import DisplayAddress from '~/components/common/DisplayAddress';
import { MaintenanceRequestTitle, MaintenanceRequestUrgency } from '~/components/maintenance-requests';
import { ListView } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useRunBooks } from '~/contexts/local';
import { RunbookTitle } from '~/pages/ai-runbooks/runbooks-details/components/RunbookTitle';
import { emptyPlaceholder } from '~/utils/constant';
import { formatAgo, dateFormat } from '~/utils/date';
import type { Component } from 'solid-js';
import type { TableColumns } from '~/components/ui';
import type { MaintenanceRequestListDto } from '~/swagger/Api';

export const RunbookMaintenanceRequestTab: Component = () => {
  const { t } = useLocalization();
  const params = useParams<{ runbookId: string }>();

  const { getRunBookMaintenanceRequest, runBookMaintenanceRequest: maintenanceRequests } = useRunBooks();

  createEffect(() => {
    getRunBookMaintenanceRequest({
      runBookId: params.runbookId,
    });
  });

  const columns = createMemo(() => {
    const col: TableColumns<MaintenanceRequestListDto> = [
      {
        title: t('Priority'),
        render: (row) => <MaintenanceRequestUrgency urgency={row.urgency} />,
      },
      {
        title: t('Title'),
        render: (row) => <MaintenanceRequestTitle maintenanceRequest={row} />,
      },
      {
        title: t('Unit'),
        render: (row) => (
          <div class="w-24">
            <div class="text-xs text-text-level01">{maintenanceRequests()?.units?.find((unit) => unit.id === row.unitId)?.name}</div>
            <DisplayAddress
              class="text-[12px] text-text-level03"
              address={maintenanceRequests()?.properties?.find((property) => property.id === row.propertyId)?.address}
            />
          </div>
        ),
      },
      {
        title: t('Assigned to'),
        render: (row) => {
          const propertyManager = maintenanceRequests()?.propertyManagers?.find(
            (propertyManager) => propertyManager.id === untrack(() => row.assignedPropertyManagerId)
          );
          return (
            <Show when={propertyManager} fallback={<div class="text-gray-400">{emptyPlaceholder}</div>}>
              <div class="flex">
                <Avatar name={propertyManager?.name || ''} />
                <div class="ml-2">
                  <div class="text-nowrap text-xs text-text-level01">{propertyManager?.name}</div>
                  <div class="text-xxs text-text-level03">{t('Manager')}</div>
                </div>
              </div>
            </Show>
          );
        },
      },
      {
        title: t('AI score'),
        render: (row) => <AIScoreCell score={row.aiUrgencyScore} />,
      },
      {
        title: t('Last updated'),
        render: (row) => (
          <div>
            <div class="text-xs font-medium text-text-level01">{dateFormat(t('MM/DD/YYYY hh:mm A'), row.lastUpdated)}</div>
            <div class="text-xxs text-[#68738D]">{formatAgo(row.lastUpdated, 2)}</div>
          </div>
        ),
      },
    ];

    return col.filter(Boolean);
  });

  return (
    <div class="flex flex-col-reverse gap-4 lg:flex-row">
      <div class="flex flex-1 flex-col gap-5">
        <RunbookTitle />
        <ListView
          onChange={({ page, pageSize }) => {
            getRunBookMaintenanceRequest({
              page,
              pageSize,
              runBookId: params.runbookId,
            });
          }}
          totalPages={maintenanceRequests()?.totalPages}
          page={maintenanceRequests()?.currentPage}
          pageSize={maintenanceRequests()?.pageSize}
          rowLink={(item) => `/maintenance/maintenance-request/${item.id}`}
          skeletonSize={10}
          loading={maintenanceRequests.loading}
          data={maintenanceRequests()?.items}
          columns={columns()}
        />
      </div>
      {/* TODO waiting for api update */}
      {/* <div class="relative h-fit w-full lg:w-[267px]">
        <LatestMaintenanceRequest />
      </div> */}
    </div>
  );
};
