import { useParams } from '@solidjs/router';
import { createEffect, createMemo, createSignal, Show } from 'solid-js';
import { RingLoader } from '~/components/common/Loaders';
import { MaintenanceRequestSearch } from '~/components/maintenance-requests';
import { Modal } from '~/components/modals/Modal';
import { toast } from '~/components/ui';
import { useLocalization, useWorkOrders } from '~/contexts/global';
import { useCreateModel } from '~/utils/hooks';
import type { Component, Setter } from 'solid-js';

type BindingMaintenanceRequestModalProps = {
  visible?: Setter<boolean>[];
  workOrderId?: string;
  onSuccess?: () => void;
};

export const BindingMaintenanceRequestModal: Component<BindingMaintenanceRequestModalProps> = (props) => {
  const { t } = useLocalization();
  const params = useParams();
  const [visible, setVisible] = useCreateModel(props, 'visible', false);
  const [newMaintenanceRequestId, setNewMaintenanceRequestId] = createSignal<string>('');
  const [loading, setLoading] = createSignal<boolean>(false);
  const { updateMaintenanceRequest, workOrder, getWorkOrder } = useWorkOrders();

  const title = createMemo<string>(() => (workOrder()?.maintenanceRequestId ? 'Change maintenance request' : 'Assign maintenance request'));

  const handleClose = () => {
    setVisible(false);
  };
  const handleDone = async () => {
    setLoading(true);
    const workOrderId = params?.workOrderId || props.workOrderId;
    try {
      await updateMaintenanceRequest(workOrderId as string, {
        newMaintenanceRequestId: newMaintenanceRequestId(),
      });
      toast.success(t('Operation successful'));
      props.onSuccess && props.onSuccess();
      handleClose();
    } finally {
      setLoading(false);
    }
  };

  createEffect(() => {
    if (!params?.workOrderId && props.workOrderId) {
      getWorkOrder(props.workOrderId);
    }
  });

  return (
    <Modal title={t(title())} loading={loading()} visible={visible()} confirmation={false} onCancel={handleClose} onDone={handleDone}>
      <Show
        when={!workOrder.loading}
        fallback={
          <div class="flex h-32 items-center justify-center">
            <RingLoader color="#a126ec" text={t('Loading')} />
          </div>
        }>
        <div class="p-6">
          <div class="mb-2 text-xs uppercase text-text-level02">{t('Select maintenance request')}</div>
          <MaintenanceRequestSearch
            selected={workOrder()?.maintenanceRequest ?? undefined}
            onSelect={(v) => setNewMaintenanceRequestId(v.id)}
          />
        </div>
      </Show>
    </Modal>
  );
};
