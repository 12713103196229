import { createMemo, onMount } from 'solid-js';
import IconGeneral from '~/assets/images/settings/rental/general.svg';
import { useForm } from '~/components/common/BetterForm';
import ThreeSectionBox from '~/components/common/ThreeSectionBox/ThreeSectionBox';
import { toast } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useRentalApplicationSettings } from '~/contexts/local';
import { SettingTitle } from '~/pages/settings/SettingTitle';
import { EditForm } from '~/pages/settings/components/edit-form/EditForm';
import type { ItemProps } from '~/pages/settings/components/edit-form/EditForm';
import type { RentalApplicationSettingsDto } from '~/swagger/Api';

export const GeneralSettings = () => {
  const { getRentalApplicationSettings, rentalApplicationSettings, updateRentalApplicationSettings } = useRentalApplicationSettings();
  const { t } = useLocalization();
  const form = useForm();
  const draftExpirationDaysOptions = [7, 15, 30, 60, 90, 100, 'Stet'];
  const draftReminderDaysOptions = (): (number | string)[] => {
    const numberOptions = Array.from({ length: 6 }, (_, i) => i + 1);
    return [...numberOptions, 'Not remind'];
  };
  const sections = createMemo<ItemProps[]>(() => {
    return [
      {
        field: 'rentalApplicationRecencyInDays',
        label: 'Rental application recency in days',
        type: 'number',
        class: 'col-span-2',
        labelClass: 'w-full',
        inputContainerClass: 'h-10',
        description: 'Applications within this time period will show new applications in the system.',
        rules: [
          {
            required: true,
            message: t(`${name} is required`, { name: 'Rental application recency in days' }),
          },
          {
            validator: (value: number) => value <= 100 && value >= 0,
            message: t('The value of rentalApplicationRecencyInDays is between 0 and 100'),
          },
        ],
      },
      {
        field: 'draftExpirationDays',
        label: 'Draft expiration days',
        type: 'select',
        options: draftExpirationDaysOptions.map((i) => ({ label: i + '', value: typeof i === 'string' ? undefined : i })),
        class: 'col-span-2',
        labelClass: 'w-full',
        description: 'Drafts will be automatically deleted after the specified number of days.',
        rules: [],
      },
      {
        field: 'draftReminderDays',
        label: 'Draft reminder days',
        type: 'select',
        options: draftReminderDaysOptions().map((i) => ({ label: i + '', value: typeof i === 'string' ? undefined : i })),
        class: 'col-span-2',
        labelClass: 'w-full',
        description: 'A reminder will be sent if a draft remains inactive for the specified number of days.',
        rules: [],
      },
      // todo: Wait for the back-end interface to update
      // {
      //   field: 'reminderIntervalDays',
      //   label: 'Rental application reminder interval days',
      //   type: 'select',
      //   options: [1, 2, 3].map((i) => ({ label: i + '', value: typeof i === 'string' ? undefined : i })),
      //   class: 'col-span-2',
      //   labelClass: 'w-full',
      //   description: 'Set the interval in days to remind applicants to complete and submit their rental application.',
      //   rules: [],
      // },
    ];
  });

  const handleSave = async (data: RentalApplicationSettingsDto) => {
    const { validateStatus } = form.validateForm();
    if (!validateStatus) return;
    try {
      await updateRentalApplicationSettings(data as RentalApplicationSettingsDto);
      form.setFormStore(data);
      toast(t('Standard questions settings saved successfully'), 'success');
    } catch (error) {
      toast(t('Failed to save standard questions settings'), 'error');
    }
  };

  onMount(async () => {
    await getRentalApplicationSettings();
  });

  return (
    <ThreeSectionBox
      class="h-fit"
      topTitle={<SettingTitle title={t('General settings')} icon={IconGeneral} />}
      middle={
        <div class="flex flex-col gap-5 p-4">
          <div class="flex flex-col gap-4 p-2 text-left">
            <EditForm
              sections={sections()}
              onOk={(data: RentalApplicationSettingsDto) => handleSave(data)}
              model={(rentalApplicationSettings() as RentalApplicationSettingsDto) || {}}
            />
          </div>
        </div>
      }
    />
  );
};
