import { useNavigate } from '@solidjs/router';
import { Switch, Match, Show } from 'solid-js';
import IconField from '~/components/common/IconField';
import { Panel } from '~/components/common/Panels';
import { SendEmail } from '~/components/common/SendEmail';
import { LeaseTitle } from '~/components/leases';
import { PropertyTitle } from '~/components/properties';
import { UnitTitle } from '~/components/units';
import { useLocalization } from '~/contexts/global';
import type { Component } from 'solid-js';

export const RelationDetails: Component<{
  class?: string;
  loading?: boolean;
  violation?: MagicDoor.Api.AssociationViolationDto;
  onSendLeaseNotification: () => void;
}> = (props) => {
  const navigate = useNavigate();

  const { t } = useLocalization();

  const goPropertyOrUnitOrLease = () => {
    if (props.violation?.leaseId) {
      navigate(`/leasing/leases/${props.violation.leaseId}`);
    } else if (props.violation?.unitId && props.violation?.portfolioId && props.violation?.propertyId) {
      navigate(`/portfolios/${props.violation.portfolioId}/properties/${props.violation.propertyId}/units/${props.violation.unitId}`);
    } else if (props.violation?.portfolioId && props.violation?.propertyId) {
      navigate(`/portfolios/${props.violation.portfolioId}/properties/${props.violation.propertyId}`);
    }
  };

  const renderRelationField = () => (
    <>
      <Switch fallback={<IconField name={t('Property')} loading={props.loading} class="w-full" />}>
        <Match when={props.violation?.lease}>
          <IconField
            name={t('Lease')}
            loading={props.loading}
            value={
              <div class="w-full overflow-hidden rounded-lg bg-input p-2">
                <LeaseTitle
                  class="truncate"
                  property={props.violation?.property}
                  feedbackIconReadonly
                  unit={props.violation?.unit ?? undefined}
                  lease={props.violation?.lease ?? undefined}
                  onClick={goPropertyOrUnitOrLease}
                />
              </div>
            }
          />
        </Match>
        <Match when={props.violation?.unit}>
          <IconField
            name={t('Unit')}
            loading={props.loading}
            class="w-full"
            value={
              <div class="w-full overflow-hidden rounded-lg bg-input p-2" onClick={goPropertyOrUnitOrLease}>
                <UnitTitle
                  feedbackIconReadonly
                  class="truncate"
                  property={props.violation?.property}
                  unit={props.violation?.unit as MagicDoor.Api.HydratedUnitDto}
                />
              </div>
            }
          />
        </Match>
        <Match when={props.violation?.property}>
          <IconField
            name={t('Property')}
            loading={props.loading}
            class="w-full"
            value={
              <div class="w-full overflow-hidden rounded-lg bg-input p-2">
                <PropertyTitle
                  feedbackIconReadonly
                  class="truncate"
                  property={props.violation?.property}
                  onClick={goPropertyOrUnitOrLease}
                />
              </div>
            }
          />
        </Match>
      </Switch>
      <Show when={props.violation?.lease && props.violation?.lease?.id}>
        <SendEmail
          class="mt-10 w-full justify-center"
          id={props.violation?.lease?.id}
          type="lease"
          isAssociationViolation
          text={t('Send lease email')}
          showModal
        />
      </Show>
    </>
  );

  return (
    <Panel title={t('Details')}>
      <div class="flex w-full flex-row flex-wrap gap-5 p-4 lg:flex-col">
        <div class="my-4 w-full cursor-pointer">{renderRelationField()}</div>
      </div>

      {/* <Show when={props.violation?.lease && props.violation?.lease?.id}>
        <div class="px-4 py-3">
          <Button class="w-full justify-center text-sm capitalize" onClick={props.onSendLeaseNotification}>
            <IconSend class="size-5 text-white" />
            {t('Send lease notification')}
          </Button>
        </div>
      </Show> */}
    </Panel>
  );
};
