import { runWithOwner } from 'solid-js';
import { isEmptyData } from '~/utils/tool';
import {
  getCityValidationError,
  getStateValidationError,
  getStreetAddress1ValidationError,
  getZipCodeValidationError,
  getCountryValidationError,
} from '~/utils/validations';
import type { Owner } from 'solid-js';
import type { BetterForm } from '~/components/common/BetterForm/type';

export function validateAddress(address: MagicDoor.Api.AddressDto, isRequired: boolean = true): boolean | string {
  const validationErrors: Partial<MagicDoor.Api.AddressDto> = {
    streetAddress1: getStreetAddress1ValidationError(address.streetAddress1, isRequired),
    city: getCityValidationError(address.city, isRequired),
    state: getStateValidationError(address.state, isRequired),
    zipCode: getZipCodeValidationError(address.zipCode, isRequired),
    country: getCountryValidationError(address.country, isRequired),
    // isValid: true,
  };

  if (isEmptyData(validationErrors)) {
    return true;
  }

  return JSON.stringify(validationErrors);
}

type getRules = (t: (str: string, options?: Record<string, string>) => string, ...opts: any[]) => BetterForm.Rule[];

export const email: getRules = (t) => [
  { message: t('Please input email'), required: true },
  { message: t('Email is not valid'), type: 'email' },
];

export const phone: getRules = (t) => [
  { message: t('Please input phone'), required: true },
  { type: 'phone', message: t('Phone is not valid') },
];

export const dateOfBirth: getRules = (t) => [
  {
    message: t('Please input date of birth'),
    required: true,
  },
  {
    message: t('Date of birth is not valid'),
    validator: (value) => {
      return /^\d{4}-\d{2}-\d{2}$/.test(value);
    },
  },
];

export const bankAccountName: getRules = (t, isRequired) => [
  { length: 100, message: t('{name} must be {length} characters or less', { name: t('Bank name'), length: '100' }) },
  { required: isRequired, message: t('Please input bank name') },
];

export const bankAccountNumber: getRules = (t, isRequired) => [
  { required: isRequired, message: t('Please input account number') },
  { regex: /^\d{4,17}$/, message: t('Account number must be between 4 and 17 digits') },
];

export const bankAccountRoutingNumber: getRules = (t, isRequired) => [
  { message: t('Routing number should have 9 numbers'), regex: /^\d{9}$/ },
  { required: isRequired, message: t('Please input routing number') },
  {
    validator: (value: string) => {
      if (!value || value.length !== 9) return false;

      const digits = value.split('').map(Number);

      const sum =
        3 * (digits[0] + digits[3] + digits[6]) + 7 * (digits[1] + digits[4] + digits[7]) + 1 * (digits[2] + digits[5] + digits[8]);

      return sum % 10 === 0;
    },
    message: t('Invalid routing number'),
  },
];

type getError = (value: string, t: Translate) => string | false;

export const bankAccountType: getRules = (t, isRequired) => [{ required: isRequired, message: t('Please select an account type') }];

export const getPropertyAddress1ValidError: getError = (address1: string, t: Translate): string | false => {
  if (!address1) {
    return t('Street address1 is required');
  } else if (
    address1.length > 50 ||
    /^[A-Za-z0-9\s.,'#-]+(?: [A-Za-z0-9\s.,'#-]+)*,\s*[A-Za-z ]+(?:\s[A-Za-z ]+)*,\s*[A-Z]{2}(?:\s\d{5})?$/.test(address1)
  ) {
    return t('Street address1 is not valid');
  }
  return false;
};

export const getRentalApplicationAddress1ValidError: getError = (address1: string, t: Translate): string | false => {
  if (!address1) {
    return t('Street address1 is required');
  } else if (address1.length > 50 || !/^(?!.*([&][#]))[A-Za-z0-9 #(),&.,'_+~/*-]*$/.test(address1)) {
    return t('Street address1 is not valid');
  }
  return false;
};

export const getPropertyAddress2ValidError: getError = (address2: string, t: Translate): string | false => {
  if (
    address2 &&
    (/^[A-Za-z0-9\s.,'#-]+(?: [A-Za-z0-9\s.,'#-]+)*,\s*[A-Za-z ]+(?:\s[A-Za-z ]+)*,\s*[A-Z]{2}(?:\s\d{5})?$/.test(address2) ||
      address2.length > 100)
  ) {
    return t('Street address2 is not valid');
  }
  return false;
};

export const getRentalApplicationAddress2ValidError: getError = (address2: string, t: Translate): string | false => {
  if (address2 && (!/^(?!.*([&][#]))[A-Za-z0-9 #(),&.,'_+~/*-]*$/.test(address2) || address2.length > 100)) {
    return t('Street address2 is not valid');
  }
  return false;
};

export const getCityValidError: getError = (city: string, t: Translate): string | false => {
  if (!city) {
    return t('City is required');
  } else if (!/^[^0-9><=$?|%"`]{2,27}$/.test(city)) {
    return t('City is not valid');
  }
  return false;
};
export const getStateValidError: getError = (state: string, t: Translate): string | false => {
  if (!state) {
    return t('State is required');
  } else if (!/^[A-Za-z]{2}$/.test(state)) {
    return t('State must be 2 characters');
  }
  return false;
};
export const getZipCodeValidError: getError = (zipCode: string, t: Translate): string | false => {
  if (!zipCode) {
    return t('Zip Code is required');
  } else if (!/^\d{5}$/.test(zipCode)) {
    return t('Zip Code must be 5 digits');
  }
  return false;
};

export const getCountryValidError: getError = (country: string, t: Translate): string | false => {
  if (!country || country.trim() === '') {
    return t('Country is required');
  } else if (!/^[a-zA-ZÀ-ÖØ-öø-ÿ' -]{2,50}$/.test(country)) {
    return t('Country is not valid');
  }
  return false;
};

export const addressRequired = (t: Translate, validators?: Record<string, getError>): BetterForm.Rule[] => {
  return [
    {
      validator: (value: any = {}) => {
        const errors: Record<string, string> = {};

        const combinedValidators: Record<string, getError> = {
          streetAddress1: getPropertyAddress1ValidError,
          streetAddress2: getPropertyAddress2ValidError,
          city: getCityValidError,
          state: getStateValidError,
          zipCode: getZipCodeValidError,
          country: getCountryValidError,
          ...validators,
        };

        for (const [key, validator] of Object.entries(combinedValidators)) {
          const error = validator(value[key], t);
          if (error) {
            errors[key] = error;
          }
        }

        if (Object.keys(errors).length > 0) {
          return JSON.stringify(errors);
        }
        return true;
      },
    },
  ];
};

type Translate = (s: string, params?: Record<string, string>) => string;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const addressFormat = (owner: Owner | null, t: Translate, isRequired?: boolean): BetterForm.Rule => {
  return {
    validator: (value) => {
      if (!value) {
        return true;
      }
      return runWithOwner(owner, () => validateAddress(value, isRequired)) as string | false;
    },
  };
};
