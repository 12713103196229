import { createEffect, createMemo, createSignal, on, untrack } from 'solid-js';
import { unwrap } from 'solid-js/store';
import { createMagicDoorContext, useLocalization } from '~/contexts/global';
import { SearchRepository } from '~/repositories/searchRepository';
import { LevelSearch } from '~/swagger/Api';
import { createTriggerResource } from '~/utils/resource';
import { cloneDeep, debounce } from '~/utils/tool';
import { CustomLevelSearch, searchLevels } from './LevelSearchComponents/SearchLevels';
import type { CustomLevelSearchType } from './LevelSearchComponents/SearchLevels';

const searchRepository = new SearchRepository();

export type Variants = {
  mutiTypeIds: Record<string, string[]>;
  singleTypeIds: string[];
  leveledIds: Record<string, string>[];
  singleType?: CustomLevelSearchType;
};

type InitialSelected = { id?: string; __type: CustomLevelSearchType; [key: string]: any };

export type SearchSelectProps = {
  multiple?: boolean;
  prefix?: string;
  onChange?: (selected: any[], varians: Variants) => void;
  enabledTypes?: CustomLevelSearchType[];
  singleTypeOnly?: boolean;
  initialSelected?: InitialSelected[];
  initialExpandedKeys?: string[];
  placeholder?: string;
  class?: string;
  exclude?: Partial<Record<CustomLevelSearchType, (item: any) => boolean | undefined>>;
  query?: {
    listedUnit?: boolean;
  };
};

export type SearchNode<T = Record<string, any>> = T & { __lazy?: boolean; __type?: LevelSearch; __keyPath?: string[]; __loaded?: boolean };

export const [SearchSelectProvider, useSearchSelectContext] = createMagicDoorContext('searchSelect', (props: SearchSelectProps) => {
  const { t } = useLocalization();

  const [selected, setSelected] = createSignal<any[]>([]);
  const [searchText, setSearchText] = createSignal('');
  const [hasSearched, setHasSearched] = createSignal(false);
  const [expandedKeys, setExpandedKeys] = createSignal<string[]>([]);
  const [portfolioLoading, setPortfolioLoading] = createSignal(false);
  const [loadingKeys, setLoadingKeys] = createSignal<string[]>([]);

  const [searchResults, fetch, { mutate: mutateSearchResults }] = createTriggerResource(
    async (query: Partial<MagicDoor.Api.SelectSearchRequestDto>) => {
      const res = await searchRepository.search(query);
      return processSearchResult(res);
    }
  );

  const globalLoading = createMemo(() => searchResults.loading || portfolioLoading());

  const mergedProps = createMemo(() => ({
    class: props.class,
    prefix: props.prefix,
    multiple: props.multiple,
    enabledTypes: props.enabledTypes ?? [],
    singleTypeOnly: props.singleTypeOnly ?? true,
  }));

  createEffect(
    on(
      () => props.initialSelected,
      async (initialSelected, prevInitialSelected) => {
        const currentSelected = processInitialSelected(initialSelected);
        const prevSelected = processInitialSelected(prevInitialSelected);
        if (currentSelected.length && !prevSelected.length) {
          const groupedByType = groupBy<InitialSelected, CustomLevelSearchType>(currentSelected, '__type');
          for (const [type, items] of Object.entries(groupedByType)) {
            const ids = items.map((item) => item.id).filter((id) => !!id) as string[];
            if (ids.length) {
              const res = await searchRepository.batchGetByIds(ids, type as CustomLevelSearchType);
              setSelected(res.map((item) => ({ ...item, __type: type })));
            }
          }
        }
      }
    )
  );

  createEffect(() => {
    setExpandedKeys((prev) => {
      return [...prev, ...(props.initialExpandedKeys || [])];
    });
  });

  const handleSelect = (item: any) => {
    if (isSelected(item)) {
      setSelected((prev) => prev.filter((i: any) => i.id !== item.id));
    } else {
      setSelected(props.multiple ? (prev: any[]) => [...prev, item] : [item]);
    }

    setSearchText('');

    props.onChange?.(unwrap(selected()), createVariantsFromSelected(unwrap(selected())));
  };

  const handleExpandClick = (item: any, childrens: any[]) => {
    if (item.__lazy || childrens.length) {
      setNodeExpandStatus(item, !isExpanded(item));
    }
    if (item.__type === LevelSearch.Property && !item.__loaded) {
      loadUnits(item);
    }
    if (item.__type === LevelSearch.Lease && !item.__loaded) {
      loadTenants(item);
    }
  };

  const handleSearch = (e: any) => {
    const _value = e.target?.value;
    setSearchText(_value);

    if (!_value) {
      setHasSearched(false);
      mutateSearchResults(undefined);
      loadPortfolios(true);
      resetExpandedKeys();
      return;
    }

    debouncedSearch(_value);
  };

  const resetExpandedKeys = () => {
    setExpandedKeys([]);
  };

  const setNodeExpandStatus = (node: SearchNode, expanded: boolean) => {
    setExpandedKeys((prev) => {
      if (expanded) {
        return [...prev, node.id];
      }
      return prev.filter((key) => key !== node.id);
    });
  };

  const loadPortfolios = async (hard = false) => {
    if (searchResults() && !hard) return;

    setPortfolioLoading(true);

    let portfolios = await searchRepository.getPortfolios();

    setPortfolioLoading(false);

    if (!mergedProps().enabledTypes?.includes(CustomLevelSearch.Portfolio)) {
      portfolios = portfolios.filter((portfolio) => portfolio.properties?.length).filter(filterByType(CustomLevelSearch.Portfolio));
    }

    mutateSearchResults({
      portfolios: portfolios.filter(filterByType(CustomLevelSearch.Portfolio)).map((portfolio) => {
        const properties = portfolio.properties?.filter((pro) => pro.active);
        return {
          portfolio: Object.assign(portfolio, {
            __type: LevelSearch.Portfolio,
            __keyPath: [portfolio.id],
            propertyCount: properties?.length,
          } as SearchNode),
          properties: properties.filter(filterByType(CustomLevelSearch.Property)).map((property) => ({
            property: Object.assign(property, {
              __lazy: true,
              __type: LevelSearch.Property,
              __keyPath: [portfolio.id, property.id],
            } as SearchNode),
          })),
        };
      }),
    });
  };

  const loadUnits = async (node: SearchNode) => {
    if (loadingKeys().includes(node.id)) return;
    setLoadingKeys((prev) => [...prev, node.id]);
    try {
      const children = await searchRepository.getUnits(node.id, props.query?.listedUnit);
      node.__loaded = true;
      mutateSearchResults(cloneDeep(updateNodeChildren(searchResults(), node, children.items)));
    } finally {
      setLoadingKeys((prev) => prev.filter((key) => key !== node.id));
    }
  };

  const loadTenants = async (node: SearchNode) => {
    if (loadingKeys().includes(node.id)) return;
    setLoadingKeys((prev) => [...prev, node.id]);
    try {
      const children = await searchRepository.getTenants(node.id);
      node.__loaded = true;
      mutateSearchResults(cloneDeep(updateNodeChildren(searchResults(), node, children)));
    } finally {
      setLoadingKeys((prev) => prev.filter((key) => key !== node.id));
    }
  };

  const debouncedSearch = debounce((search: string) => {
    const query = untrack(() => props.query);
    if (!search.length) {
      setHasSearched(false);
      return;
    }
    setHasSearched(true);
    fetch({
      search,
      excludeUnlistedUnit: query?.listedUnit,
    });
  }, 500);

  const processSearchResult = (result: MagicDoor.Api.SelectSearchResultDto) => {
    const deepEnabledType = props.enabledTypes?.reduce((prev, curr) => {
      const index = searchLevels.findIndex((level) => level.type === curr);
      return Math.max(index, prev);
    }, 0);

    if (!deepEnabledType) return;

    function recursiveData(items: any[], level: number, maxLevel: number) {
      const _items = [];
      for (const item of items) {
        const { childItems, type } = searchLevels[level];

        if (!filterByType(type)(item[type])) {
          continue;
        }

        if (!childItems) {
          _items.push(item);

          continue;
        }

        const nextLevelItems = item[childItems];
        item[childItems] = nextLevelItems?.length ? recursiveData(nextLevelItems, level + 1, maxLevel) : [];

        if (item[childItems]?.length && level < maxLevel) {
          setExpandedKeys((prev) => [...prev, item[type].id]);
        }

        if (item[childItems]?.length || props.enabledTypes?.includes(type)) {
          _items.push(item);
        }
      }

      return _items;
    }

    result.portfolios = recursiveData(result.portfolios, 0, deepEnabledType);
    return result;
  };

  const updateNodeChildren = (state: any, node: SearchNode, children: any[]) => {
    if (!node.__keyPath) return state;

    const [_nodeInState, _childrenKey] = (function findNode(index = 0, parent = state.portfolios) {
      const config = searchLevels[index];
      const currentId = node.__keyPath[index];
      const childrenKey = config.childItems;
      const currentNode = parent?.find((node: any) => node[config.type].id === currentId);

      if (index === node.__keyPath.length - 1) {
        return [currentNode, childrenKey];
      } else {
        return findNode(index + 1, currentNode[childrenKey!]);
      }
    })();

    if (!_childrenKey) return state;

    _nodeInState[_childrenKey] = (function updateNode(_node = node, _chilren: any[] = children): SearchNode[] {
      const level = searchLevels.findIndex((config) => config.type === _node.__type);
      const config = searchLevels[level];
      const nextConfig = searchLevels[level + 1];

      if (!config || !nextConfig || !_chilren.length) return [];

      return _chilren.filter(filterByType(nextConfig.type)).map((child) => {
        const childNode = Object.assign(child, {
          __type: nextConfig.type,
          __keyPath: [...(_node?.__keyPath || []), child.id],
        } as SearchNode);

        if (nextConfig.type === CustomLevelSearch.Tenant || !nextConfig.childItems) return childNode;

        const childNodeChildren = child[nextConfig.childItems];

        return {
          [nextConfig.type]: childNode,
          [nextConfig.childItems]: childNodeChildren.length ? updateNode(childNode, childNodeChildren) : [],
        };
      });
    })();

    return state;
  };

  const groupBy = <T, D extends string>(items: any[], key: string): Record<D, T[]> => {
    return items.reduce((prev, curr) => {
      prev[curr[key]] = prev[curr[key]] || [];
      prev[curr[key]].push(curr);
      return prev;
    }, {});
  };

  const isExpanded = (node?: SearchNode) => Boolean(node && expandedKeys().includes(node.id));
  const isLoading = (node?: SearchNode) => Boolean(node && loadingKeys().includes(node.id));
  const isSelected = (item: any) => selected().some((selectedItem: any) => selectedItem.id === item.id);
  const isDisabled = (type: CustomLevelSearchType) => {
    if (!type) {
      return true;
    }

    const normalizedType = type.toUpperCase();

    let _disabled = false;

    const enabledTypesList = mergedProps().enabledTypes;
    if (enabledTypesList?.length) {
      const normalizedEnabledTypes = enabledTypesList.map((t) => t.toUpperCase());
      _disabled = !normalizedEnabledTypes.includes(normalizedType);
    }

    if (mergedProps().singleTypeOnly && selected().length && mergedProps().multiple) {
      const selectedType = selected()[0].__type?.toUpperCase();
      _disabled = normalizedType !== selectedType;
    }

    return _disabled;
  };

  const getPlaceholder = () => {
    if (props.placeholder) return props.placeholder;

    const typeNames = mergedProps()
      .enabledTypes.map((type) => {
        switch (type) {
          case CustomLevelSearch.Portfolio:
            return t('Portfolios');
          case CustomLevelSearch.Property:
            return t('Properties');
          case CustomLevelSearch.Unit:
            return t('Units');
          case CustomLevelSearch.Lease:
            return t('Leases');
          case CustomLevelSearch.Tenant:
            return t('Tenants');
          default:
            return '';
        }
      })
      .filter(Boolean);

    if (typeNames.length === 0) return t('Please enter');

    if (typeNames.length === 1) {
      return t('Search {{type}}', { type: typeNames[0].toLowerCase() });
    }

    if (typeNames.length === 2) {
      return t('Search {{first}} and {{second}}', {
        first: typeNames[0].toLowerCase(),
        second: typeNames[1].toLowerCase(),
      });
    }

    const lastType = typeNames.pop()!.toLowerCase();
    return t('Search {{list}}, and {{last}}', {
      list: typeNames.map((name) => name.toLowerCase()).join(', '),
      last: lastType,
    });
  };

  // eslint-disable-next-line solid/reactivity
  const filterByType = (type: CustomLevelSearchType) => (item: any) => {
    return !(props.exclude?.[type] && props.exclude[type](item));
  };

  const createVariantsFromSelected = (selected: any[]) => {
    const _selected = selected ?? [];

    const variants: Variants = {
      mutiTypeIds: {},
      leveledIds: [],
      singleType: mergedProps().singleTypeOnly ? selected[0]?.__type : undefined,
      singleTypeIds: [],
    };

    for (const item of _selected) {
      const type = item.__type;

      if (!type) continue;
      const idsKey = `${type}Ids`;
      const typeIndex = searchLevels.findIndex((config) => config.type === type);

      if (item.__keyPath) {
        const tempIdMap: Record<string, string> = {};
        for (let i = 0; i <= typeIndex; i++) {
          const config = searchLevels[i];
          tempIdMap[`${config.type}Id`] = item.__keyPath[i];
        }
        variants.leveledIds.push(tempIdMap);
      }

      if (mergedProps().singleTypeOnly) {
        variants.singleTypeIds.push(item.id);
      } else {
        if (!variants.mutiTypeIds[idsKey]) {
          variants.mutiTypeIds[idsKey] = [];
        }
        variants.mutiTypeIds[idsKey].push(item.id);
      }
    }
    return variants;
  };

  const processInitialSelected = (initialSelected?: InitialSelected[]): InitialSelected[] => {
    if (!initialSelected?.length) return [];
    return initialSelected.filter((item) => item.id);
  };

  return {
    selected,
    handleSelect,
    isSelected,
    searchText,
    searchResults,
    onSearchInput: handleSearch,
    hasSearched,
    loadPortfolios,
    globalLoading,
    isExpanded,
    isLoading,
    handleExpandClick,
    loadUnits,
    isDisabled,
    getPlaceholder,
    mergedProps: mergedProps,
  };
});
