import { onMount, Show } from 'solid-js';
import { Elements, PaymentElement, useElements } from 'solid-stripe';
import { Button } from '~/components/common/Buttons';
import { Section } from '~/components/common/FormSectionTitle';
import { useLocalization, useRentalApplicationPayment } from '~/contexts/global';
import { PaymentLoading } from '~/pages/rental-applications/add-rental-application/PaymentStatus';

const PayButton = () => {
  const { t } = useLocalization();
  const { store, onPayment } = useRentalApplicationPayment();
  const elements = useElements();

  return (
    <Button
      block
      class="mt-5"
      loading={store.isSubmitting}
      onClick={() => {
        onPayment(elements());
      }}>
      {t('Pay')} ${store.intent.totalAmount}
    </Button>
  );
};

export const Payment = () => {
  const { t } = useLocalization();
  const { store, initPaymentByApplicationId, skipPayment } = useRentalApplicationPayment();

  onMount(() => {
    initPaymentByApplicationId();
  });

  return (
    <Show when={store.stripLoaded} fallback={<PaymentLoading />}>
      <div class="self-stretch overflow-y-auto p-6">
        <Elements stripe={store.stripe} clientSecret={store.intent.clientSecret}>
          <div class="flex flex-col gap-4">
            <Section required border title={t('Application Payment')}>
              <div class="flex items-start gap-4">
                <div class="flex-1 [&>*]:mb-6">
                  <PaymentElement />
                </div>
                <div class="basis-64 rounded bg-inputbox-bg px-4 py-5">
                  <div class="mb-5 flex items-center justify-between border-b border-partingline pb-5 text-base font-medium">
                    <span class="">{t('Total')}</span>
                    <span>$ {store.intent.totalAmount}</span>
                  </div>
                  <PayButton />
                  <Button
                    class="mt-5"
                    variant="white"
                    block
                    onClick={() => {
                      skipPayment();
                    }}
                    loading={store.skipPaymentLoading}>
                    {t(`Skip payment`)}
                  </Button>
                </div>
              </div>
            </Section>
          </div>
        </Elements>
      </div>
    </Show>
  );
};
