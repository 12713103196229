import { MagicUseCase } from '~/pdfsigner/usecases/baseUseCases/magicUseCase';
import { DeleteAnnotationUseCase } from './deleteAnnotationUseCase';
import { SelectAnnotationUseCase } from './selectAnnotationUseCase';
import type { TransformRequest } from '~/pdfsigner/usecases/types/transformRequest';

export class DragAnnotationUseCase extends MagicUseCase {
  public async runLogic(request: TransformRequest) {
    const selectAnnotationUseCase = new SelectAnnotationUseCase();
    const deleteAnnotationUseCase = new DeleteAnnotationUseCase();
    await selectAnnotationUseCase.execute({ id: request.id, page: request.startPage });
    if (!this.getState().selectedAnnotation || !request.relativePosition) {
      return;
    }
    if (!this.isTransformationStarted()) {
      await this.initializeTransformation(request);
    }
    const annotation = this.getState().selectedAnnotation;
    if (request.startPage !== request.endPage && annotation) {
      await deleteAnnotationUseCase.execute({ id: request.id });
      this.getState().selectedDocument?.pdfPages[request.endPage].annotations.push(annotation);
      await selectAnnotationUseCase.execute({
        id: request.id,
        page: request.endPage,
      });
    }
    const clickPosition = this.getState().clickPosition;
    const initialBounds = this.getState().initialBounds;
    if (!clickPosition || !initialBounds) {
      return;
    }
    let x = initialBounds.x;
    let y = initialBounds.y;
    if (request.relativePosition != undefined) {
      const position = request.relativePosition.position;
      const boundingBox = request.relativePosition.boundingBox;
      const newX = (position.x - boundingBox.x) / boundingBox.width;
      const newY = (position.y - boundingBox.y) / boundingBox.height;
      const deltaX = clickPosition.x - x;
      const deltaY = clickPosition.y - y;
      x = newX - deltaX;
      y = newY - deltaY;
    }
    const selectedAnnotation = this.getState().selectedAnnotation;
    if (selectedAnnotation) {
      selectedAnnotation.x = x;
      selectedAnnotation.y = y;
    }
  }
}
