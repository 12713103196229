import dayjs from 'dayjs';
import { createEffect, on } from 'solid-js';
import PlusIcon from '~/assets/images/common/plus.svg?component-solid';
import { FormItem, Form, FormList } from '~/components/common/BetterForm';
import { FormListRemoverWrapper } from '~/components/common/BetterForm/FormListRemover';
import { useForm, useFormContext } from '~/components/common/BetterForm/context';
import { Button } from '~/components/common/Buttons';
import DueDateInputField from '~/components/common/Inputs/DueDateInputField';
import LabeledNumberInput from '~/components/common/Inputs/LabeledNumberInput';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { BaseModal } from '~/components/modals/BaseModal';
import { Footer } from '~/components/modals/Footer';
import { useLocalization, useRentalApplication } from '~/contexts/global';
import { currentUpdateType, MAX_INPUT_AMOUNT } from '~/utils/constant';

export const EmploymentSubForm = (props: { index: number }) => {
  const { t } = useLocalization();
  const fromContext = useFormContext();

  return (
    <div class="flex flex-col gap-8">
      <div class="grid grid-cols-2 gap-2">
        <FormItem
          label={t('Company name')}
          rules={[
            { required: true, message: t('Please input company name') },
            {
              validator: (value: string) => value.length > 0 && value.length < 150,
              message: t('{name} must be between {min} and {max} characters', { name: t('Job title'), min: '1', max: '150' }),
            },
          ]}
          formFieldName="name"
          placeholder={t('Please input job title')}
          component={LabeledTextInput}
        />
        <FormItem
          label={t('Salary')}
          formFieldName="salary"
          placeholder={t('Please input salary')}
          prepend="$"
          component={LabeledNumberInput}
          min={0}
          max={MAX_INPUT_AMOUNT}
          rules={[
            { required: true, message: t('Please input salary') },
            { type: 'number', message: t('Salary must be a number') },
          ]}
        />
      </div>
      <div class="grid grid-cols-2 gap-2">
        <FormItem
          label={t('Position')}
          formFieldName="position"
          component={LabeledTextInput}
          rules={[
            {
              validator: (value: string | undefined) => !value || value.length < 150,
              message: t('{name} must be {length} characters or less', { name: t('Position'), length: '150' }),
            },
          ]}
        />
        <FormItem
          label={t('Employment contact')}
          placeholder={t('Please input employment contact')}
          formFieldName="phone"
          rules={[{ type: 'phone', message: t('Employment contact is invalid') }]}
          component={LabeledTextInput}
        />
      </div>
      <div class="grid grid-cols-2 gap-2">
        <FormItem label={t('Start date')} formFieldName="startDate" component={DueDateInputField} />
        <FormItem
          label={t('End date')}
          formFieldName="endDate"
          component={DueDateInputField}
          rules={[
            {
              validator: (value: string | undefined) => {
                const currentFormData = fromContext.formStore.employment[props.index];

                if (currentFormData && currentFormData.startDate && value) {
                  return dayjs(currentFormData.startDate).isSameOrBefore(dayjs(value));
                }

                return true;
              },
              message: t('{date1} cannot be earlier than {date2}', { date1: t('End date'), date2: t('Start date') }),
            },
          ]}
          showUntil
        />
      </div>
    </div>
  );
};

const WorkHistoryForm = () => {
  const { t } = useLocalization();

  return (
    <FormList
      formFieldName="employment"
      renderItemsGroup={({ index, remove }) => (
        <FormListRemoverWrapper class="mt-4" index={index} remove={remove} removeAll showRemove>
          <EmploymentSubForm index={index()} />
        </FormListRemoverWrapper>
      )}>
      {({ add }) => {
        return (
          <div class="mt-4 flex justify-end ">
            <Button
              variant="outlined"
              class="flex flex-1 justify-center"
              onClick={() => {
                add({});
              }}>
              <PlusIcon />
              {t('Add more')}
            </Button>
          </div>
        );
      }}
    </FormList>
  );
};

export const WorkHistoryModal = () => {
  const { applicationStore, setApplicationStore, baseUpdate } = useRentalApplication();
  const { t } = useLocalization();
  const form = useForm();

  createEffect(
    on([() => applicationStore.currentUpdateType], () => {
      if (applicationStore.currentUpdateType !== currentUpdateType.work) return;
      form.setFieldsValue({
        employment: applicationStore.application?.employment,
      });
    })
  );

  const onHide = () => {
    setApplicationStore('currentUpdateType', null);
    form.resetFields();
  };

  const onSubmit = () => {
    const validationResult = form.validateForm();

    if (!validationResult.validateStatus) {
      return;
    }

    baseUpdate(form.formStore).then(() => {
      onHide();
    });
  };

  return (
    <BaseModal
      size="md"
      title={t('Update work history')}
      visible={applicationStore.currentUpdateType === currentUpdateType.work}
      onClose={() => {
        setApplicationStore('currentUpdateType', null);
      }}>
      <div class="thinscroll flex max-h-[50vh] flex-col gap-2 overflow-y-auto px-4 pb-4">
        <Form defaultForm={form}>
          <WorkHistoryForm />
        </Form>
      </div>
      <Footer onOk={onSubmit} okProps={{ loading: applicationStore.updateLoading }} onCancel={onHide} />
    </BaseModal>
  );
};
