import { useNavigate } from '@solidjs/router';
import { loadStripe } from '@stripe/stripe-js';
import { createStore, produce } from 'solid-js/store';
import { toast } from '~/components/ui';
import { createMagicDoorContext } from '~/contexts/utils';
import { RentalApplicationRepository } from '~/repositories/rentalApplicationRepository';
import { ScreeningStatus } from '~/swagger/Api';
import { rentalApplicationPaymentStatus } from '~/utils/constant';
import { createLazyResource } from '~/utils/resource';
import { getStripeKey } from '~/utils/stripeKey';
import type { Stripe, StripeElements } from '@stripe/stripe-js';

const applicationRepo = new RentalApplicationRepository();

export const [RentalApplicationPaymentProvider, useRentalApplicationPayment] = createMagicDoorContext('RentalApplicationPayment', () => {
  const navigate = useNavigate();

  const [store, setStore] = createStore<{
    intent: Partial<MagicDoor.Api.RentalApplicationPaymentIntentDto>;
    application: Partial<MagicDoor.Api.RentalApplicationDto>;
    stripe: Stripe | null;
    stripLoaded: boolean;
    isSubmitting: boolean;
    isScreening: boolean;
    isCheckingScreeningStatus: boolean;
    isLoadingQuestions: boolean;
    isAnsweringQuestions: boolean;
    paymentStatus: Partial<MagicDoor.Api.RentalApplicationPaymentStatusDto>;
    transUnionScreeningStatus: Partial<MagicDoor.Api.CheckScreeningStatusResultDto>;
    transUnionScreeningQuestions: Partial<MagicDoor.Api.TransunionExamQuestionsDto>;
    selectedAnswers: Record<string, string>;
    skipPaymentLoading: boolean;
    isLoadingRentalStatus: boolean;
  }>({
    intent: {},
    application: {},
    stripe: null,
    stripLoaded: false,
    isSubmitting: false,
    paymentStatus: {},
    isScreening: false,
    transUnionScreeningStatus: {},
    transUnionScreeningQuestions: {},
    selectedAnswers: {},
    isCheckingScreeningStatus: false,
    isLoadingQuestions: false,
    isAnsweringQuestions: false,
    skipPaymentLoading: false,
    isLoadingRentalStatus: false,
  });

  const [settings, settingsActions] = createLazyResource(() => applicationRepo.getApplicationSettings());

  const gotoStatusPage = (status: rentalApplicationPaymentStatus) => {
    navigate(`/leasing/rental-applications/new/${store.application.id}/status/${status}`, { resolve: false });
  };

  async function getApplicationIntent() {
    const applicationId = store.application.id;
    if (!applicationId) return;

    if (!store.paymentStatus.paid) {
      const res = await applicationRepo.getRentalApplicationPaymentIntent(applicationId);
      if (res) {
        setStore(
          produce((draft) => {
            draft.intent = res;
          })
        );
      } else {
        navigate('/leasing/rental-applications', { replace: true });
      }
      return;
    }
  }

  const getPaymentStatus = async (applicationId = store.application.id) => {
    if (!applicationId) return;

    const status = await applicationRepo.getRentalApplicationPaymentStatus(applicationId);

    setStore(
      produce((draft) => {
        draft.paymentStatus = status;
      })
    );
  };

  const getRentalApplication = async () => {
    if (!store.application.id) return;
    const rental = await applicationRepo.getRentalApplication(store.application.id);
    setStore({
      application: rental,
    });
  };

  const _loadStripe = async () => {
    const stripKey = getStripeKey();
    if (!stripKey || !store.intent.stripeAccountId) return;
    const stripeInstance = await loadStripe(stripKey, {
      stripeAccount: store.intent.stripeAccountId,
    });
    setStore(
      produce((draft) => {
        draft.stripe = stripeInstance;
        draft.stripLoaded = true;
      })
    );
  };

  const setRentalApplicationId = (applicationId: string) => {
    if (!applicationId) return;
    setStore(
      produce((draft) => {
        draft.application.id = applicationId;
      })
    );
  };

  const initPaymentByApplicationId = async () => {
    await getApplicationIntent();
    await _loadStripe();
  };

  const onPayment = async (elements: StripeElements | null) => {
    if (!elements || !store.stripe) return;
    try {
      setStore('isSubmitting', true);
      const result = await store.stripe.confirmPayment({
        elements: elements,
        redirect: 'if_required',
      });

      if (!result.error) {
        gotoStatusPage(rentalApplicationPaymentStatus.processing);
      } else {
        toast(result.error.message, 'error');
      }
    } finally {
      setStore('isSubmitting', false);
    }
  };

  const handleScreening = async () => {
    if (!store.application.id) return;
    setStore('isScreening', true);
    try {
      await applicationRepo.startScreening(store.application.id);
      gotoStatusPage(rentalApplicationPaymentStatus.questionnaire);
    } finally {
      setStore('isScreening', false);
    }
  };

  const getScreeningStatus = async () => {
    if (!store.application.id) return;
    try {
      setStore('isCheckingScreeningStatus', true);
      const status = await applicationRepo.getScreeningStatus(store.application.id);
      setStore(produce((draft) => (draft.transUnionScreeningStatus = status)));
    } finally {
      setStore('isCheckingScreeningStatus', false);
    }
  };

  const getScreeningQuestions = async () => {
    if (!store.application.id) return;
    try {
      setStore('isLoadingQuestions', true);
      const questions = await applicationRepo.getScreeningQuestions(store.application.id);
      setStore(produce((draft) => (draft.transUnionScreeningQuestions = questions)));
    } finally {
      setStore('isLoadingQuestions', false);
    }
  };

  const handleSubmitAnswers = async () => {
    if (!store.application.id) return;

    try {
      setStore('isAnsweringQuestions', true);
      await applicationRepo.postScreeningAnswers(store.application.id, {
        examId: store.transUnionScreeningQuestions?.examId ?? 0,
        answers: Object.entries(store.selectedAnswers).map(([questionKeyName, selectedChoiceKeyName]) => ({
          questionKeyName,
          selectedChoiceKeyName,
        })),
      });
      autoNavigateByStatus();
    } catch {
      getScreeningQuestions();
    } finally {
      setStore('isAnsweringQuestions', false);
    }
  };

  const updateAnswer = (questionKey: string, choiceKey?: string) => {
    if (questionKey && choiceKey) {
      setStore(
        produce((draft) => {
          draft.selectedAnswers[questionKey] = choiceKey;
        })
      );
    }
  };

  async function skipPayment() {
    if (!store.application.id) return;

    setStore({
      skipPaymentLoading: true,
    });
    try {
      await applicationRepo.skipPayment(store.application.id);
      navigate('/leasing/rental-applications', { replace: true });
    } finally {
      setStore({
        skipPaymentLoading: false,
      });
    }
  }

  async function onSubmit(appId = store.application.id) {
    if (!appId) return;
    if (appId !== store.application.id) {
      setStore(produce((draft) => (draft.application.id = appId)));
    }
    await applicationRepo.lockRentalApplication(appId);

    autoNavigateByStatus();
  }

  async function autoNavigateByStatus() {
    await Promise.allSettled([getPaymentStatus(), getScreeningStatus(), settingsActions.fetch()]);

    if (store.paymentStatus.paid) {
      if (store.transUnionScreeningStatus.status === ScreeningStatus.ManualVerificationRequired) {
        return gotoStatusPage(rentalApplicationPaymentStatus.confirmIdentity);
      }

      if (store.transUnionScreeningStatus.status === ScreeningStatus.Verified) {
        return gotoStatusPage(rentalApplicationPaymentStatus.submitted);
      }

      if (store.transUnionScreeningStatus.status === ScreeningStatus.UnVerified) {
        return gotoStatusPage(rentalApplicationPaymentStatus.questionnaire);
      }

      if (settings()?.requireScreening) {
        return gotoStatusPage(rentalApplicationPaymentStatus.readyToConnect);
      }

      return gotoStatusPage(rentalApplicationPaymentStatus.submitted);
    }

    if (settings()?.requirePayment) {
      return navigate(`/leasing/rental-applications/new/${store.application.id}/payment`);
    }

    return gotoStatusPage(rentalApplicationPaymentStatus.submitted);
  }

  async function getRentalApplicationStatus(applicationId: string) {
    if (!applicationId) return;
    setRentalApplicationId(applicationId);
    try {
      setStore({
        isLoadingRentalStatus: true,
      });
      await getRentalApplication();
      if (!store.application.draft) {
        await autoNavigateByStatus();
      }
    } finally {
      setStore({
        isLoadingRentalStatus: false,
      });
    }
  }

  return {
    store,
    setStore,
    initPaymentByApplicationId,
    onPayment,
    getPaymentStatus,
    handleScreening,
    handleSubmitAnswers,
    updateAnswer,
    skipPayment,
    getRentalApplicationStatus,
    get settings() {
      settingsActions.fetch();
      return settings;
    },
    settingsActions,
    onSubmit,
    getScreeningQuestions,
    autoNavigateByStatus,
  };
});
