import { MagicUseCase } from '~/pdfsigner/usecases/baseUseCases/magicUseCase';
import { propertiesRepository } from '~/repositories/propertiesRepository';
import type { PropertyDto } from '~/swagger/Api';

export class BatchGetPropertiesUseCase extends MagicUseCase {
  public async runLogic(propertyIds?: string[]) {
    let properties: PropertyDto[] = [];
    if (propertyIds && propertyIds.length > 0) {
      properties = await propertiesRepository.batchGetPropertyByIds(propertyIds);
    }
    this.getState().properties = properties;
  }
}
