import { useNavigate } from '@solidjs/router';
import { children, createEffect, createMemo, createSignal, For, onCleanup, onMount, Show } from 'solid-js';
import ArchiveIcon from '~/assets/images/chat/archive.svg?component-solid';
import IconBacktrack from '~/assets/images/chat/backtrack.svg?component-solid';
import ImgCancelArchive from '~/assets/images/chat/cancelArchive.png';
import IconEdit from '~/assets/images/chat/chatEdit.svg';
import IconSave from '~/assets/images/chat/save.svg';
import IconTempPfp from '~/assets/images/chat/tempPfp.svg';
import IconDetail from '~/assets/images/common/detail.svg?component-solid';
import PrintIcon from '~/assets/images/common/print.svg?component-solid';
import IconCall from '~/assets/images/contact/call.svg?component-solid';
import { Avatar } from '~/components/common/Avatar';
import { LanguageSelect } from '~/components/languages';
import { DropdownActions, Popover, toast, confirm } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useChat, useChatsList } from '~/contexts/local';
import { ArchiveChatModal } from '~/pages/chats/delete-chat/ArchiveChatModal';
import { printDocument } from '~/utils/print';
import { ParticipantDetails } from './ChatHeaderParticipantDetails';
import type { JSX } from 'solid-js';
import type { DropdownAction } from '~/components/ui';

interface ChatHeaderProps {
  chats: MagicDoor.Api.HydratedMagicChatDto | undefined;
  chatId?: string | null;
  printContainer?: JSX.Element;
  printHeader?: JSX.Element;
  printWrapClass?: string;
  extraStyle?: string;
  source?: 'chat' | 'archive';
}

const ChatHeader = (props: ChatHeaderProps) => {
  const { t } = useLocalization();
  const navigate = useNavigate();

  const { editChat, archiveChat } = useChat();
  const { fetchChats, unarchiveChat } = useChatsList();

  const [showArchiveModal, setShowArchiveModal] = createSignal<boolean>(false);
  const [modalLoading, setModalLoading] = createSignal<boolean>(false);

  let inputRef: HTMLInputElement | undefined;
  const [isEditingTitle, setIsEditingTitle] = createSignal<boolean>(false);
  const [newTitle, setNewTitle] = createSignal<string | undefined>('');

  const [oldTitle, setOldTitle] = createSignal<string | undefined>('');

  const header = children(() => props.printHeader);
  const container = children(() => props.printContainer);

  onMount(() => {
    setOldTitle(props.chats?.subject || '');
  });

  const currentChatParticipants = () => {
    return (
      props.chats?.participants.filter(
        (participant) => participant.participantType === 'tenant' || participant.participantType === 'vendor'
      ) || []
    );
  };

  const matchingParticipant = createMemo(() => {
    return currentChatParticipants().find((p) => p.participantType === (props.chats?.type as any));
  });

  const navigateToDetail = () => {
    const chatType = props.chats?.type;

    if (!chatType) {
      console.error('Chat type is undefined or not valid.');
      return;
    }

    let path = '';

    if (chatType === 'lease' && props.chats?.lease) {
      path = `/leasing/leases/${props.chats?.typeKey}`;
    } else if (chatType === 'maintenanceRequest') {
      path = `/maintenance/maintenance-request/${props.chats?.typeKey}`;
    } else if (['workOrderGroup', 'workOrder'].includes(chatType)) {
      path = `/maintenance/work-orders/${props.chats?.typeKey}`;
    } else {
      if (matchingParticipant()) {
        switch (matchingParticipant()?.participantType) {
          case 'tenant':
            path = `/users/tenants/${props.chats?.typeKey}`;
            break;
          case 'vendor':
            path = `/maintenance/vendors/${props.chats?.typeKey}`;
            break;

          default:
            console.error(`Unsupported participant type: ${matchingParticipant()?.participantType}`);
            return;
        }
      }
    }

    if (path) {
      return path;
    } else {
      console.error('No valid navigation path found.');
    }
  };

  const archiveCurrentChat = async () => {
    if (!props.chatId) return;
    setModalLoading(true);

    try {
      await archiveChat(props.chatId);
      toast.success(t('Chat has been archived successfully'));

      setShowArchiveModal(false);
      fetchChats();
      navigate('/communications/chats', {
        replace: true,
      });
    } finally {
      setModalLoading(false);
    }
  };

  const viewParticipantDetails = (participantId: string) => {
    const participant = currentChatParticipants().find((p) => p.id === participantId);

    if (!participant) {
      console.error(`Participant with ID ${participantId} not found.`);
      return;
    }

    let path = '';

    switch (participant.participantType) {
      case 'tenant':
        path = `/users/tenants/${participant.accountId}`;
        break;
      case 'vendor':
        path = `/maintenance/vendors/${participant.accountId}`;
        break;
      default:
        console.error(`Unsupported participant type: ${participant.participantType}`);
        return;
    }

    return path;
  };

  const handleEditActive = () => {
    setIsEditingTitle(true);
    setNewTitle(oldTitle());
    setTimeout(() => inputRef?.focus(), 0);
  };

  const handleEditChatTitle = async (newTitle: string, eventType: string) => {
    if (!props.chatId) {
      return;
    }

    if (newTitle.trim() === oldTitle()?.trim()) {
      setIsEditingTitle(false);
      return;
    }

    if (!newTitle.trim()) {
      if (eventType === 'blur') {
        setNewTitle(oldTitle);
        setIsEditingTitle(false);
      }

      if (eventType === 'click') {
        toast.error(t('Operation failed, title is empty'));
      }

      return;
    }

    if (newTitle.length > 250) {
      toast.error(t('Operation failed, title cannot be longer than 250 characters'));
      return;
    }

    await editChat(props.chatId, { subject: newTitle });
    setIsEditingTitle(false);
    setOldTitle(newTitle);
  };

  const handleLanguageChange = async (selectedValue: string) => {
    if (props.chatId && selectedValue) {
      await editChat(props.chatId, {
        participantLanguage: selectedValue as MagicDoor.Api.UpdateChatDto['participantLanguage'],
        subject: props.chats?.subject || '',
      });
    }
  };

  const renderParticipantImages = () => {
    const participants = currentChatParticipants();
    const [windowWidth, setWindowWidth] = createSignal(window.innerWidth);

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    onCleanup(() => window.removeEventListener('resize', handleResize));

    const visibleCount = () => (windowWidth() < 1382 ? 1 : currentChatParticipants().length > 4 ? 4 : currentChatParticipants().length);
    const overflowCount = () => currentChatParticipants().length + 1 - visibleCount();

    return (
      <>
        <Show when={participants.length === 1}>
          <Popover>
            <Popover.Trigger>
              <Avatar name={participants[0].name ?? ''} size="large" />
            </Popover.Trigger>
            <Popover.Content class="relative">
              <div class="absolute top-0 z-10  rounded-md bg-white text-xs shadow-lg ring-1 ring-partingline ">
                <ParticipantDetails participant={participants[0]} viewParticipantDetails={viewParticipantDetails} />
              </div>
            </Popover.Content>
          </Popover>
        </Show>

        <Show when={participants.length > 1}>
          <DropdownActions
            class="text-auxiliary-text"
            align="start"
            // @ts-expect-error: supresssing click event workaround
            actions={currentChatParticipants().map((participant) => ({
              label: participant.name,
              onClick: navigateToDetail(),
              jsxIcon: <Avatar name={participant.name ?? ''} size="large" />,
              childrenPosition: 'right',
              childrenStyle: 'p-0',
              children: <ParticipantDetails participant={participant} viewParticipantDetails={viewParticipantDetails} />,
            }))}>
            <div class="relative flex -space-x-3 rounded-md p-1.5 hover:bg-input-border">
              <For each={currentChatParticipants()?.slice(0, visibleCount())}>
                {(participant, index) => (
                  <div class="relative shrink-0">
                    <Avatar name={participant.name ?? ''} size="large" solid />
                    <Show when={index() === visibleCount() - 1 && currentChatParticipants().length > visibleCount()}>
                      <div class="absolute inset-0 flex items-center justify-center rounded-full bg-black/50 text-white">
                        +{overflowCount()}
                      </div>
                    </Show>
                  </div>
                )}
              </For>
            </div>
          </DropdownActions>
        </Show>
      </>
    );
  };

  createEffect(() => {
    setNewTitle(props.chats?.subject);
  });

  const onPrint = () => {
    const headerElement = header();
    const containerElement = container();

    if (headerElement && containerElement) {
      printDocument(containerElement as HTMLDivElement, props.printWrapClass, headerElement as HTMLDivElement, props.extraStyle);
    }
  };

  const cancelArchive = () => {
    confirm({
      title: t('Unarchive'),
      class: 'px-0 w-96',
      titleClass: 'px-5',
      footerClass: 'px-5',
      doneText: t('Confirm'),
      content: (
        <div>
          <img src={ImgCancelArchive} alt="ImgCancelArchive" />
          <div class="mt-4 px-5">
            {t('After clicking confirm, chat records will be restored. After successful restoration, it will jump to the chat window.')}
          </div>
        </div>
      ),
      async onResolve(confirmed) {
        if (!confirmed || !props.chats?.id) return;
        await unarchiveChat(props.chats.id);
        navigate(`/communications/chats/${props.chats.id}`, { replace: true });
      },
    });
  };

  const chatActions = createMemo<DropdownAction[]>(() => {
    const baseActions = [
      {
        label: t('Details'),
        icon: IconDetail,
        href: navigateToDetail(),
      },
      {
        label: t('Print'),
        icon: PrintIcon,
        onClick: onPrint,
      },
    ];
    const archiveActions = [
      {
        label: t('Archive'),
        icon: ArchiveIcon,
        onClick: () => setShowArchiveModal(true),
      },
    ];
    const unarchiveActions = [
      {
        label: t('Unarchive'),
        icon: IconBacktrack,
        onClick: () => cancelArchive(),
      },
    ];

    return props.source === 'archive' ? baseActions.concat(unarchiveActions) : baseActions.concat(archiveActions);
  });

  return (
    <div class="flex w-full items-center justify-between">
      <div class="flex items-center">
        <Show
          when={currentChatParticipants().length > 0}
          fallback={<img src={IconTempPfp} alt="Temporary Profile Picture" class="size-10 rounded-full" />}>
          {renderParticipantImages()}
        </Show>

        <div class="ml-2flex flex-col items-start justify-center text-base font-semibold md:ml-4 md:text-xl">
          <div class="flex gap-2 ">
            <Show
              when={!isEditingTitle()}
              fallback={
                <div class="relative flex items-center justify-center gap-2">
                  <input
                    type="text"
                    ref={inputRef}
                    value={newTitle()}
                    onInput={(e) => setNewTitle(e.currentTarget.value)}
                    onBlur={(e) => handleEditChatTitle(newTitle() || '', e.type)}
                    class="w-40 truncate rounded-full border border-essential-colour bg-input px-2 py-0.5 pr-20 sm:w-40 md:w-fit"
                  />
                  <button
                    class="absolute right-1 top-1/2 -translate-y-1/2 rounded-full bg-essential-colour px-2 py-1"
                    onMouseDown={(e) => e.preventDefault()}
                    onClick={(e) => handleEditChatTitle(newTitle() || '', e.type)}>
                    <div class="flex items-center justify-center gap-1 text-xs font-normal text-white">
                      <img src={IconSave} alt="Save Chat Title" class="size-5" />
                      <span class="hidden md:flex">{t('Save')}</span>
                    </div>
                  </button>
                </div>
              }>
              <div class="max-w-32 truncate sm:w-40 md:ml-2 md:max-w-fit xl:w-fit">{props.chats?.subject}</div>
              <Show when={props.source !== 'archive'}>
                <button onClick={handleEditActive}>
                  <img src={IconEdit} alt="Edit Chat Title" class="size-5" />
                </button>
              </Show>
            </Show>
          </div>

          <div>
            <For each={props.chats?.participants.filter((participant) => participant.participantType === 'unknown')}>
              {(participant) => (
                <div class="mt-0.5 flex gap-2 text-xs font-normal text-text-level03 md:text-sm">
                  <Show when={participant.phoneNumber}>
                    <div class="flex gap-1 rounded-lg bg-slate-100 px-2 py-0.5">
                      <div class="hide-on-mobile flex">
                        <IconCall /> :
                      </div>
                      {participant.phoneNumber}
                    </div>
                  </Show>
                </div>
              )}
            </For>
          </div>
        </div>
      </div>

      <div class="flex items-center justify-center gap-2">
        <div class="flex items-center justify-center gap-2 text-text-level03">
          <div class="hide-on-mobile hidden xl:flex">{t('Your message is being sent out as')}</div>
          <Show when={props.chatId && props.source !== 'archive'}>
            <LanguageSelect
              class="w-28 md:w-44"
              value={props.chats?.participantLanguage}
              onChange={(e) => handleLanguageChange(e)}
              placeholder="Select language"
            />
          </Show>
        </div>

        <ArchiveChatModal
          visible={showArchiveModal()}
          onCancel={() => setShowArchiveModal(false)}
          onConfirm={archiveCurrentChat}
          chatSubject={props.chats?.subject}
          modalLoading={modalLoading()}
        />

        <DropdownActions class="text-auxiliary-text" actions={chatActions()} />
      </div>
    </div>
  );
};

export default ChatHeader;
