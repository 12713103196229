import { Match, Switch } from 'solid-js';
import { Empty } from '~/components/common/Empty';
import { Section } from '~/components/common/FormSectionTitle';
import { RingLoader } from '~/components/common/Loaders';
import { useLocalization } from '~/contexts/global';
import { useAddOwnerDistributions } from '~/contexts/local';
import { OwnerDistributionsEditListView } from './OwnerDistributionsListView';

export const OwnerDistributions = () => {
  const { t } = useLocalization();
  const { ownerDistributions } = useAddOwnerDistributions();

  return (
    <div class="px-[96px]">
      <p class="mb-2.5 text-xs font-thin normal-case text-text-level02">
        {t('Owner distribution fees will not be sent until you click confirm.')}
      </p>
      <Section title={t('Owner distributions')} border class="mb-0 [&>*]:mb-0 [&>*]:border-b-0">
        <Switch>
          <Match when={ownerDistributions.loading}>
            <div class="flex items-center justify-center pt-6">
              <RingLoader color="#A126EC" text={`${t('Loading')}...`} />
            </div>
          </Match>
          <Match when={!ownerDistributions.loading && !ownerDistributions()?.length}>
            <Empty />
          </Match>
          <Match when={!ownerDistributions.loading && ownerDistributions()?.length}>
            <OwnerDistributionsEditListView />
          </Match>
        </Switch>
      </Section>
    </div>
  );
};
