import { MagicUseCase } from '~/pdfsigner/usecases/baseUseCases/magicUseCase';
import { SelectAnnotationUseCase } from './selectAnnotationUseCase';
import type { TransformRequest } from '~/pdfsigner/usecases/types/transformRequest';

export class ResizeAnnotationUseCase extends MagicUseCase {
  public async runLogic(request: TransformRequest) {
    if (!request.relativePosition) {
      return;
    }
    const selectAnnotationUseCase = new SelectAnnotationUseCase();
    await selectAnnotationUseCase.execute({ id: request.id, page: request.startPage });
    if (!this.isTransformationStarted()) {
      this.initializeTransformation(request);
    }
    const selectedAnnotation = this.getState().selectedAnnotation;
    const clickPosition = this.getState().clickPosition;
    const initialBounds = this.getState().initialBounds;
    if (!selectedAnnotation || !clickPosition || !initialBounds) {
      return;
    }
    const position = request.relativePosition.position;
    const boundingBox = request.relativePosition.boundingBox;
    const x = (position.x - boundingBox.x) / boundingBox.width;
    const y = (position.y - boundingBox.y) / boundingBox.height;
    const deltaX = x - clickPosition.x;
    const deltaY = y - clickPosition.y;
    selectedAnnotation.width = initialBounds.width + deltaX;
    selectedAnnotation.height = initialBounds.height + deltaY;
  }
}
