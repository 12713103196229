import { MagicUseCase } from '~/pdfsigner/usecases/baseUseCases/magicUseCase';
import type { PMSelectionRequest } from '~/pdfsigner/usecases/types/pmSelectionChangeRequest';

export class PMSelectionChangedUseCase extends MagicUseCase {
  public async runLogic(request: PMSelectionRequest) {
    const selectedDocument = this.getState().selectedDocument;
    if (!request || !selectedDocument) {
      return;
    }
    selectedDocument.signers[request.index].isPropertyManager = request.isPropertyManager;
  }
}
