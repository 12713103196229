import { Route, Routes, useParams } from '@solidjs/router';
import { createEffect, For, Show, createSignal } from 'solid-js';
import { Button, LinkButton } from '~/components/common/Buttons';
import { NumberToggle } from '~/components/common/Buttons/NumberToggle';
import { Empty } from '~/components/common/Empty';
import { Panel } from '~/components/common/Panels';
import { DeleteModal } from '~/components/modals';
import { IconTrash } from '~/components/ui';
import { useLocalization, useOwners } from '~/contexts/global';
import { AddOwnerPaymentModal } from './AddOwnerPayments/AddOwnerPaymentModal';
import type { Component, ParentProps } from 'solid-js';

export const ListOwnerPayments: Component = () => {
  const { t } = useLocalization();
  const { ownerId } = useParams();
  const { paymentAccounts, getPaymentAccounts, deleteOwnerPaymentAccount } = useOwners();
  const [deleteAccountId, setDeleteAccountId] = createSignal<string>();

  createEffect(() => {
    if (ownerId) {
      getPaymentAccounts(ownerId);
    }
  });

  const handleDelete = async () => {
    const accountId = deleteAccountId();
    if (ownerId && accountId) {
      await deleteOwnerPaymentAccount(ownerId, accountId);
      setDeleteAccountId(undefined);
    }
  };

  return (
    <>
      <Panel title={t('Payment accounts')} actions={<LinkButton href="add">{t('Add payment account')}</LinkButton>}>
        <Show
          when={paymentAccounts()?.paymentAccounts?.length ?? 0 > 0}
          fallback={<Empty description={t('There are no payment accounts for this owner')} />}>
          <div class="space-y-4 p-4">
            <For each={paymentAccounts()?.paymentAccounts ?? []}>
              {(account) => (
                <div class="mb-4 rounded-lg border p-4 shadow-sm">
                  <div class="mb-4 flex items-center justify-between">
                    <div class="flex items-center gap-2">
                      <h3 class="text-lg font-semibold">{account.name}</h3>
                      <span class="rounded-full bg-gray-100 px-3 py-1 text-sm">{account.accountProvider}</span>
                    </div>
                    <Button variant="outlined" onClick={() => setDeleteAccountId(account.id)}>
                      <IconTrash class="size-4" /> {t('Delete')}
                    </Button>
                  </div>

                  <Show when={account.bankInformation}>
                    <AccountSection cols="md:grid-cols-2">
                      <InfoField label={t('Bank name')} value={account.bankInformation?.bankName} />
                      <InfoField label={t('Account type')} value={account.bankInformation?.accountType} />
                      <div class="space-y-1">
                        <p class="text-sm text-gray-500">{t('Account number')}</p>
                        <NumberToggle
                          fullNumber={account.bankInformation?.accountNumber ?? ''}
                          maskedNumber={`•••• ${account.accountMask}`}
                        />
                      </div>
                      <InfoField label={t('Routing number')} value={account.bankInformation?.routingNumber} />
                    </AccountSection>
                  </Show>

                  <Show when={account.stripe}>
                    <AccountSection cols="md:grid-cols-3" withBorder>
                      <InfoField label={t('Card type')} value={`${account.stripe?.brand} ${account.stripe?.cardType}`} />
                      <InfoField label={t('Last 4 digits')} value={account.stripe?.last4} />
                      <InfoField label={t('Expiration')} value={`${account.stripe?.expMonth}/${account.stripe?.expYear}`} />
                    </AccountSection>
                  </Show>

                  <Show when={account.plaid}>
                    <AccountSection cols="md:grid-cols-2" withBorder>
                      <InfoField label={t('Plaid account name')} value={account.plaid?.plaidAccountName} />
                      <InfoField label={t('Official name')} value={account.plaid?.plaidAccountOfficialName} />
                      <InfoField label={t('Account type')} value={account.plaid?.plaidAccountType} />
                    </AccountSection>
                  </Show>
                </div>
              )}
            </For>
          </div>
        </Show>
      </Panel>

      <DeleteModal visible={!!deleteAccountId()} onCancel={() => setDeleteAccountId(undefined)} onConfirm={handleDelete}>
        {t('Are you sure you want to delete this payment account?')}
      </DeleteModal>

      <Routes>
        <Route path="/add" component={AddOwnerPaymentModal} />
      </Routes>
    </>
  );
};

interface InfoFieldProps {
  label: string;
  value?: string | null;
}

const InfoField: Component<InfoFieldProps> = (props) => {
  return (
    <div class="space-y-1">
      <p class="text-sm text-gray-500">{props.label}</p>
      <p class="font-medium">{props.value}</p>
    </div>
  );
};

interface AccountSectionProps extends ParentProps {
  cols: string;
  withBorder?: boolean;
}

const AccountSection: Component<AccountSectionProps> = (props) => {
  return <div class={`${props.withBorder ? 'mt-4 border-t pt-4' : 'mb-4'} grid grid-cols-1 gap-4 ${props.cols}`}>{props.children}</div>;
};
