import { MagicUseCase } from '~/pdfsigner/usecases/baseUseCases/magicUseCase';
import type { AnnotatedPdfPage } from '~/pdfsigner/usecases/types/annotatedPdfPage';
import type { DeleteRequest } from '~/pdfsigner/usecases/types/deleteRequest';

export class DeleteAnnotationUseCase extends MagicUseCase {
  public async runLogic(request: DeleteRequest) {
    if (!this.getState().selectedDocument) {
      return;
    }
    const pdfPages: AnnotatedPdfPage[] = [...(this.getState().selectedDocument?.pdfPages || [])];
    for (const page of pdfPages) {
      if (page.annotations.some((annotation) => annotation.id === request.id)) {
        page.annotations = page.annotations.filter((annotation) => annotation.id !== request.id);
        break;
      }
    }
    if (request.id === this.getState().selectedAnnotation?.id) {
      this.getState().selectedAnnotation = undefined;
    }
  }
}
