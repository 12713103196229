import { useLocation, useNavigate } from '@solidjs/router';
import { createMemo } from 'solid-js';
import { leasePeriodString } from '~/components/leases/utils';
import { DeleteModal } from '~/components/modals';
import { toast } from '~/components/ui';
import { useLocalization, useUnits } from '~/contexts/global';
import { useLease, useLeasesList } from '~/contexts/local';

export const DeleteLeaseModal = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useLocalization();
  const { unit } = useUnits();
  const { lease, deleteLease, setLease } = useLease();
  const { refetchLeases } = useLeasesList();

  const leaseName = createMemo(() => {
    const currentUnit = unit();
    if (currentUnit == null) return t('Loading');
    const currentLease = lease();
    if (currentLease == null) return t('Loading');
    return `${t('Unit')} ${currentUnit.name} ${leasePeriodString(currentLease)}`;
  });

  const handleConfirm = async () => {
    const currentLease = lease();
    if (currentLease == null) return;

    await deleteLease(currentLease.id);
    setLease(unit()?.activeLeases?.[0] as any);
    refetchLeases();
    toast.success(t('{name} has been deleted successfully', { name: t('Lease') }));
    if (!location.pathname.startsWith('/leasing')) {
      return navigate(-1);
    }
    const { portfolioId, propertyId, unitId } = currentLease;
    navigate(`/portfolios/${portfolioId}/properties/${propertyId}/units/${unitId}`, { replace: true });
  };

  return (
    <DeleteModal
      onConfirm={handleConfirm}
      onCancel={() => navigate(-1)}
      confirm={t('By deleting “<b>{name}</b>”, You will also <i>Delete all related records</i>. Are you sure you want to delete it?', {
        name: leaseName(),
      })}>
      {t(
        'If you delete “<b>{name}</b>”, It cannot be retrieved. All the transactions, tenants, files, requests under the lease will be gone. Please confirm to continue deleting it',
        { name: leaseName() }
      )}
    </DeleteModal>
  );
};
