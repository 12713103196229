import { A } from '@solidjs/router';
import { createMemo, Show } from 'solid-js';
import IconAirplane from '~/assets/images/common/airplane.svg?component-solid';
import IconEdit from '~/assets/images/common/edit.svg?component-solid';
import IconViewArrow from '~/assets/images/common/viewArrow.svg?component-solid';
import IconNoData from '~/assets/images/empty/noMaintenance.svg';
import IconHeader from '~/assets/images/vendors/headerImg.png';
import { Avatar } from '~/components/common/Avatar';
import { LinkButton } from '~/components/common/Buttons';
import { Empty } from '~/components/common/Empty';
import IconField from '~/components/common/IconField';
import { MaintenanceRequestStatus } from '~/components/maintenance-requests/MaintenanceRequestStatus';
import { IconClock, IconHash, IconMail, IconPhone } from '~/components/ui';
import { Skeleton } from '~/components/ui/Skeleton';
import { useLocalization } from '~/contexts/global';
import { useRunBooks } from '~/contexts/local';
import { emptyPlaceholder } from '~/utils/constant';
import { dateFormat } from '~/utils/date';
import { getImageUrl } from '~/utils/file';
import aiRunbook from './aiRunbook.png';

export const Learn = () => {
  const { t } = useLocalization();
  return (
    <a href="https://youtu.be/6Ub85mwQb90" rel="noopener noreferrer" target="_blank">
      <div class="flex flex-col rounded-lg bg-[#1A68DC] p-6 capitalize text-white">
        <img src={aiRunbook} alt="ai-runbook" class="mb-5 w-44 self-center" />
        <div class="mb-2 text-lg font-medium">{t('Learn to master MagicDoor quickly')}</div>
        <div class="text-xs text-white/70">{t('AI-Enabled property management software that honors your time')}</div>

        <div class="mt-6 text-sm">{t('Watch video')}</div>
      </div>
    </a>
  );
};

export const LatestMaintenanceRequest = () => {
  const { t } = useLocalization();
  const { runBook } = useRunBooks();
  return (
    <Show
      when={runBook()?.lastUseMaintenanceRequestId}
      fallback={
        <div class="rounded-lg bg-white">
          <Empty description={t('No maintenance request yet')} />
        </div>
      }>
      <div class="rounded-lg bg-white">
        <div class="px-4 py-5">
          <div class="text-xs font-medium capitalize text-text-level03">{t('Latest maintenance request')}</div>

          <div class="my-2 text-base font-semibold text-text-level01">
            <Show when={!runBook.loading} fallback={<Skeleton class="h-6 w-full" />}>
              {runBook()?.lastUseMaintenanceRequest?.title ?? emptyPlaceholder}
            </Show>
          </div>

          <div class="mb-1">
            <Show when={!runBook.loading} fallback={<Skeleton class="h-4 w-32" />}>
              <Show when={runBook()?.lastUseMaintenanceRequest?.status} fallback={emptyPlaceholder}>
                <MaintenanceRequestStatus status={runBook()?.lastUseMaintenanceRequest?.status as MagicDoor.Api.MaintenanceRequestStatus} />
              </Show>
            </Show>
          </div>
          <div class="flex items-center gap-1 text-xs text-text-level03">
            {t('Last update')}:
            <Show when={!runBook.loading} fallback={<Skeleton class="h-4 w-32" />}>
              <span>
                {runBook()?.lastUseMaintenanceRequest?.lastUpdated
                  ? dateFormat(t('MM/DD/YYYY hh:mm A'), runBook()?.lastUseMaintenanceRequest?.lastUpdated)
                  : emptyPlaceholder}
              </span>
            </Show>
          </div>
          <IconField
            class="mt-5"
            name={t('Request number')}
            loading={runBook.loading}
            value={runBook()?.lastUseMaintenanceRequest?.tenantId}
            src={IconHash}
          />
          <IconField
            class="mt-5"
            name={t('Created at')}
            loading={runBook.loading}
            value={
              runBook()?.lastUseMaintenanceRequest?.created
                ? dateFormat(t('MM/DD/YYYY hh:mm A'), runBook()?.lastUseMaintenanceRequest?.created)
                : emptyPlaceholder
            }
            src={IconClock}
          />
        </div>
        <Show when={runBook()?.lastUseMaintenanceRequestId}>
          <div class="border-t p-4">
            <LinkButton variant="outlined" href={`/maintenance/maintenance-request/${runBook()?.lastUseMaintenanceRequestId}`}>
              <span class="text-nowrap"> {t('Maintenance request')}</span>
              <IconViewArrow class="size-5" />
            </LinkButton>
          </div>
        </Show>
      </div>
    </Show>
  );
};

export const VendorAndPropertyManager = () => {
  const { t } = useLocalization();
  const { runBook } = useRunBooks();

  const defaultPropertyManagerImgUrl = createMemo(() => {
    const propertyManager = runBook()?.defaultPropertyManager;
    return propertyManager && getImageUrl('avatar', propertyManager?.id, propertyManager?.imageId);
  });

  return (
    <div class="flex flex-col gap-4">
      <div class="w-full rounded-lg bg-white">
        <Show
          when={runBook()?.defaultVendorId || runBook.loading}
          fallback={
            <div class="p-5">
              <div class="text-sm font-medium text-text-level01">{t('Vendor')}</div>
              <Empty descriptionClass="text-xs" size="92px" description={t('No default vendor yet')} imgSrc={IconNoData} />
              <LinkButton class="text-sm" variant="outlined" href="default-vendor">
                {t('Add a default vendor')}
              </LinkButton>
            </div>
          }>
          <div
            class="flex h-20 w-full items-start justify-between bg-cover bg-right px-4 pt-5"
            style={{
              'background-image': `url(${IconHeader})`,
            }}>
            <div>
              <Show when={!runBook.loading} fallback={<Skeleton class="w-36" />}>
                <A
                  href={`/maintenance/vendors/${runBook()?.defaultVendor?.id}`}
                  class="text-sm font-medium text-text-level01 hover:underline">
                  {runBook()?.defaultVendor?.name}
                </A>
              </Show>

              <div class="mt-0.5 text-xs text-text-level03">{t('Vendor')}</div>
            </div>
            <Show when={!runBook.loading}>
              <A href="default-vendor">
                <IconEdit class="text-link" />
              </A>
            </Show>
          </div>
          <div class="flex flex-col gap-4 p-4">
            <div class="flex items-center gap-2">
              <IconPhone class="size-5 text-text-level03" />
              <Show when={!runBook.loading} fallback={<Skeleton class="w-36" />}>
                <div class="text-sm text-text-level01">{runBook()?.defaultVendor?.contact.phone || emptyPlaceholder}</div>
              </Show>
            </div>
            <div class="flex items-center gap-2">
              <IconMail class="size-5 text-text-level03" />
              <Show when={!runBook.loading} fallback={<Skeleton class="w-44" />}>
                <div class="text-sm text-text-level01">{runBook()?.defaultVendor?.contact.email || emptyPlaceholder}</div>
              </Show>
            </div>
            <div class="flex items-center gap-2.5">
              <LinkButton
                class="flex size-10 items-center justify-center p-0"
                disabled={runBook.loading}
                variant="outlined"
                href={`/communications/chats/${runBook()?.defaultVendor?.chatId}`}>
                <IconAirplane class="size-5 text-essential-colour" />
              </LinkButton>

              <LinkButton class="px-4" disabled={runBook.loading} variant="outlined" href="vendor-overrides">
                {t('Add new overrides')}
              </LinkButton>
            </div>
          </div>
        </Show>
      </div>

      <div class="w-full rounded-lg bg-white">
        <Show
          when={runBook()?.defaultPropertyManagerId || runBook.loading}
          fallback={
            <div class="p-5">
              <div class="text-sm font-medium capitalize text-text-level01">{t('Property manager')}</div>
              <Empty descriptionClass="text-xs" size="92px" description={t('No default property manager yet')} imgSrc={IconNoData} />
              <LinkButton class="text-sm" variant="outlined" href="default-property-manager">
                {t('Add a default manager')}
              </LinkButton>
            </div>
          }>
          <div class="flex h-20 w-full items-start justify-between bg-warning-light px-4 pt-5">
            <div class="flex items-center gap-2">
              <Avatar size="large" imgUrl={defaultPropertyManagerImgUrl()} name={runBook()?.defaultPropertyManager?.name || ''} />
              <div>
                <Show when={!runBook.loading} fallback={<Skeleton class="w-36" />}>
                  <div class="text-sm font-medium text-text-level01">{runBook()?.defaultPropertyManager?.name}</div>
                </Show>
                <div class="mt-0.5 text-xs capitalize text-text-level03">{t('Property manager')}</div>
              </div>
            </div>
            <Show when={!runBook.loading}>
              <A href="default-property-manager">
                <IconEdit class="text-link" />
              </A>
            </Show>
          </div>
          <div class="flex flex-col gap-4 p-4">
            <div class="flex items-center gap-2">
              <IconMail class="size-5 text-text-level03" />
              <Show when={!runBook.loading} fallback={<Skeleton class="w-44" />}>
                <div class="text-sm text-text-level01">{runBook()?.defaultPropertyManager?.email || emptyPlaceholder}</div>
              </Show>
            </div>
            <div class="flex items-center gap-2.5">
              <LinkButton disabled={runBook.loading} variant="outlined" href="property-manager-overrides">
                {t('Add new overrides')}
              </LinkButton>
            </div>
          </div>
        </Show>
      </div>
    </div>
  );
};
