import { A } from '@solidjs/router';
import { Show, createEffect } from 'solid-js';
import IconTempPfp from '~/assets/images/chat/tempPfp.svg';
import { MessageBody } from './chat-message/MessageBody';
import { MessageStatus } from './chat-message/MessageStatus';
import { ParticipantInfo } from './chat-message/ParticipantInfo';
import { useMessageUtils } from './hooks/UseMessageUtils';
import type { ChatMessageProps } from './hooks/UseMessageUtils';

export const ChatContent = (props: ChatMessageProps) => {
  const {
    showOriginal,
    setShowOriginal,
    participantName,
    setParticipantName,
    toggleMessageView,
    updateParticipantName,
    messageIcon,
    messageStyle,
    participantInSystem,
    linkToDetails,
  } = useMessageUtils(props);

  createEffect(() => {
    const isPropertyManager = props.message.messageType !== 'participant';
    setShowOriginal(isPropertyManager);
  });

  createEffect(() => {
    setParticipantName(updateParticipantName());
  });

  return (
    <div class="w-full py-4">
      <div
        class={`flex w-full gap-3.5 ${
          props.message.messageType !== 'participant' ? 'items-start justify-end' : 'flex-row-reverse items-start justify-end'
        }`}>
        <div class={`flex flex-col gap-1.5 text-xs ${props.message.messageType !== 'participant' ? 'items-end' : 'items-start'}`}>
          <ParticipantInfo
            href={linkToDetails() || ''}
            participantName={participantName()}
            participantInSystem={participantInSystem()}
            message={props.message}
          />
          <MessageBody
            toggleMessageView={toggleMessageView}
            message={props.message}
            showOriginal={showOriginal()}
            allMessage={props.allMessage}
            chatId={props.chatDetails?.id ?? ''}
          />
          <MessageStatus textStatus={props.message.textStatus ?? ''} />
        </div>

        <A
          href={linkToDetails() ?? ''}
          classList={{
            'cursor-default': !participantInSystem(),
          }}
          class={`flex size-10 min-h-10 min-w-10 items-center justify-center overflow-hidden rounded-full ${messageStyle()}`}>
          <Show when={props.chatDetails?.type !== 'unknown'} fallback={<img src={IconTempPfp} alt="Message Icon" class="size-5" />}>
            {messageIcon()}
          </Show>
        </A>
      </div>
    </div>
  );
};
