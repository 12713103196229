import { createResource, createSignal } from 'solid-js';
import { createMagicDoorContext } from '~/contexts/utils';
import { OwnersRepository } from '~/repositories/ownersRepository';
import { createTriggerResource } from '~/utils/resource';
import type { OwnersFilter } from '~/repositories/ownersRepository';

const repo = new OwnersRepository();
export const [OwnersProvider, useOwners] = createMagicDoorContext('Owners', () => {
  const [filtered, setFilter, actions] = createTriggerResource((filter: OwnersFilter) => repo.getOwners(filter));

  const [currentId, setCurrentId] = createSignal<string>();
  const [current, currentActions] = createResource(currentId, (id) => repo.getOwner(id));

  const [paymentAccounts, getPaymentAccounts, paymentAccountsActions] = createTriggerResource((ownerId: string) =>
    repo.getOwnerPaymentAccounts(ownerId)
  );

  const addOwner = async (payload: MagicDoor.Api.UpdateOwnerDto) => {
    const response = await repo.addOwner(payload);
    return response;
  };

  const updateOwner = async (id: string, payload: MagicDoor.Api.UpdateOwnerDto) => {
    const res = await repo.updateOwner(id, payload);
    currentActions.mutate(res);
  };

  const addOwnerPaymentAccount = async (ownerId: string, payload: MagicDoor.Api.CreateOwnerPaymentAccountDto) => {
    const res = await repo.addOwnerPaymentAccount(ownerId, payload);
    return res;
  };

  const deleteOwnerPaymentAccount = async (ownerId: string, paymentAccountId: string) => {
    await repo.deleteOwnerPaymentAccount(ownerId, paymentAccountId);
    paymentAccountsActions.refetch();
  };

  return {
    filtered: filtered,
    setFilter: (filter: OwnersFilter) => setFilter(filter),
    current,
    setCurrentId,
    refetch: actions.refetch,
    addOwner,
    updateOwner,
    paymentAccounts,
    paymentAccountsActions,
    getPaymentAccounts,
    addOwnerPaymentAccount,
    deleteOwnerPaymentAccount,
  };
});
