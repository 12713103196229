import { ValidationError } from '~/errors';
import { urlWithQuery } from '~/utils/url';
import { BaseRestRepository } from './baseRestRepository';
import type { PropertyType } from '~/swagger/Api';

const PROPERTIES_URL = '/api/properties';

export function validateProperties(properties: MagicDoor.Api.CreatePropertyDto | MagicDoor.Api.UpdatePropertyDto): void {
  if (!properties) throw new ValidationError('Properties object must be provided');
}

export function validateId(id: string, entityName = 'Entity'): void {
  if (!id) throw new ValidationError(`${entityName} ID must be provided`);
}

export type PropertiesFilter = MagicDoor.Filter<{
  portfolioId?: string;
  ownerId?: string;
  type?: `${PropertyType}`;
}>;

export type PropertyImagePayload = {
  file: File;
  isDefault?: boolean;
  description?: string;
};

export class PropertiesRepository extends BaseRestRepository {
  public async getProperties(filter?: PropertiesFilter): Promise<MagicDoor.Api.HydratedPropertyDtoPaginationDto> {
    const url = urlWithQuery(PROPERTIES_URL, filter);
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async getProperty(propertyId: string): Promise<MagicDoor.Api.HydratedPropertyDto> {
    validateId(propertyId);
    const url = `${PROPERTIES_URL}/${propertyId}`;
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  // 数量限制100个
  public async batchGetPropertyByIds(propertyIds: string[]): Promise<MagicDoor.Api.HydratedPropertyDto[]> {
    const url = urlWithQuery(`${PROPERTIES_URL}/batch`, { propertyIds });
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async createProperty(property: MagicDoor.Api.CreatePropertyDto): Promise<MagicDoor.Api.HydratedPropertyDto> {
    validateProperties(property);
    const url = PROPERTIES_URL;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(property),
    });
    return this.getJsonResponse(response);
  }

  public async updateProperty(propertyId: string, property: MagicDoor.Api.UpdatePropertyDto): Promise<MagicDoor.Api.HydratedPropertyDto> {
    validateId(propertyId);
    validateProperties(property);
    const url = `${PROPERTIES_URL}/${propertyId}`;
    const response = await this.fetchWithAuth(url, {
      method: 'PUT',
      body: JSON.stringify(property),
    });
    return this.getJsonResponse(response);
  }

  /** Not preferred. use deactivateProperty instead in most cases */
  public async deleteProperty(propertyId: string): Promise<void> {
    validateId(propertyId);
    const url = `${PROPERTIES_URL}/${propertyId}`;
    await this.fetchWithAuth(url, { method: 'DELETE' });
  }

  public async deactivateProperty(propertyId: string, forceDeactivateChildren?: boolean): Promise<void> {
    validateId(propertyId);
    const url = urlWithQuery(`${PROPERTIES_URL}/${propertyId}/deactivate`, { forceDeactivateChildren });
    await this.fetchWithAuth(url, { method: 'DELETE' });
  }

  public async removePropertyOwner(propertyId: string, ownerId: string): Promise<void> {
    const url = `${PROPERTIES_URL}/${propertyId}/owners/${ownerId}`;
    await this.fetchWithAuth(url, { method: 'DELETE' });
  }

  public async addPropertyOwner(
    propertyId: string,
    ownerId: string,
    payload: MagicDoor.Api.PropertyOwnershipDto
  ): Promise<MagicDoor.Api.HydratedOwnerPropertyDto> {
    const url = `${PROPERTIES_URL}/${propertyId}/owners/${ownerId}`;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
    return this.getJsonResponse(response);
  }

  public async updatePropertyOwner(
    propertyId: string,
    ownerId: string,
    payload: MagicDoor.Api.PropertyOwnershipDto
  ): Promise<MagicDoor.Api.HydratedOwnerPropertyDto> {
    const url = `${PROPERTIES_URL}/${propertyId}/owners/${ownerId}`;
    const response = await this.fetchWithAuth(url, {
      method: 'PUT',
      body: JSON.stringify(payload),
    });
    return this.getJsonResponse(response);
  }

  public async getPropertyOverview(propertyId: string, useUpdatedAt?: boolean): Promise<MagicDoor.Api.PropertyOverviewDto> {
    let url = `${PROPERTIES_URL}/${propertyId}/overview`;
    if (useUpdatedAt) {
      url += `?updatedAt=` + new Date().getTime();
    }
    const response = await this.fetchWithAuth(url, { method: 'GET' });
    return this.getJsonResponse(response);
  }

  public async updatePropertyMagicTags(
    propertyId: string,
    magicTags: MagicDoor.Api.UpdateUnitMagicTagDto
  ): Promise<MagicDoor.Api.HydratedPropertyDto> {
    const url = `${PROPERTIES_URL}/${propertyId}/magic-tags`;
    const response = await this.fetchWithAuth(url, {
      method: 'PUT',
      body: JSON.stringify(magicTags),
    });
    return this.getJsonResponse(response);
  }

  public async moveProperty(propertyId: string, portfolioId: string): Promise<void> {
    const url = `${PROPERTIES_URL}/${propertyId}/move`;
    await this.fetchWithAuth(url, {
      method: 'PUT',
      body: JSON.stringify({ portfolioId }),
    });
  }
}

export const propertiesRepository = new PropertiesRepository();
