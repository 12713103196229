import { useNavigate } from '@solidjs/router';
import IconEdit from '~/assets/images/common/edit.svg?component-solid';
import IconHeader from '~/assets/images/vendors/headerImg.png';
import { useLocalization, useVendors } from '~/contexts/global';
import { emptyPlaceholder } from '~/utils/constant';
import VendorContactInformation from './VendorContactInformation';
import VendorInsurance from './VendorInsurance';
import VendorPaymentInformation from './VendorPaymentInformation';
// import VendorActive from './VendorActive';

const VendorInformation = () => {
  const { current } = useVendors();
  const navigate = useNavigate();
  const { t } = useLocalization();

  const handleEditClick = () => {
    navigate(`/maintenance/vendors/${current()?.id}/edit`);
  };

  return (
    <div class="flex w-full  flex-col rounded-lg border border-solid bg-white ">
      <div class="relative flex h-24 flex-col justify-center p-4 text-left">
        <h2 class="max-w-[90%] truncate text-xl font-semibold">{current()?.name}</h2>
        <div class="text-xs uppercase text-text-level03 ">{current()?.category?.name || emptyPlaceholder}</div>
        <img class="absolute left-0 top-0 size-full" src={IconHeader} alt="" />
        <button class="absolute right-4" type="button" onClick={handleEditClick}>
          <IconEdit class="text-link" />
        </button>
      </div>
      <div class="border-b">
        <VendorContactInformation />
      </div>
      <div class="border-b">
        <VendorPaymentInformation />
      </div>
      <div class="border-b">
        <VendorInsurance />
      </div>
      <div class="flex items-center justify-between border-b p-4 text-sm">
        <span class="font-medium ">{t('One payment per bill')}</span>
        <span
          class="flex items-center gap-2 rounded-xl  px-4 py-0.5 "
          classList={{
            'bg-success-light text-success': current()?.paymentSettings?.onePaymentPerBill,
            'text-danger bg-danger-light': !current()?.paymentSettings?.onePaymentPerBill,
          }}>
          <span
            class="size-1.5 rounded-full"
            classList={{
              'bg-success': current()?.paymentSettings?.onePaymentPerBill,
              'bg-danger': !current()?.paymentSettings?.onePaymentPerBill,
            }}
          />
          {current()?.paymentSettings?.onePaymentPerBill ? t('On') : t('Off')}
        </span>
      </div>

      {/* <VendorActive /> */}
    </div>
  );
};

export default VendorInformation;
