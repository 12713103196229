import { toast } from '~/components/ui';
import { HttpError, NotFoundError, UnauthorizedError } from '~/errors';
import { getErrorMessage } from '~/errors/getErrorMessage';
import { SpecialHttpStatusCode } from '~/errors/httpError';
import { tokenStorage } from '~/utils/storage';
import type { HttpErrorJson } from '~/errors/httpError';

export abstract class BaseRestRepository {
  protected async fetchWithAuth(url: string, options: RequestInit = {}, showErrorMessage = true): Promise<Response> {
    const init = this.getRequestInit(true, options);

    let response = await fetch(url, init).catch(() => new HttpError(SpecialHttpStatusCode.FailedToConnect));

    if (response instanceof Response) {
      if (response.ok) return response;
      const responseText = await response.clone().text();
      const error: HttpErrorJson | string = await response.json().catch(() => responseText);
      response = this.createError(response.status, error);
    }

    if (showErrorMessage) toast.error(getErrorMessage(response));

    throw response;
  }

  protected async fetchWithoutAuth(url: string, options: RequestInit = {}): Promise<Response> {
    const init = this.getRequestInit(false, options);
    const response = await fetch(url, init);
    if (response.ok) return response;
    const error = await response
      .clone()
      .json()
      .catch(() => response.text());
    throw this.createError(response.status, error);
  }

  protected async getJsonResponse(response: Response, swallow = false) {
    try {
      return await response.json();
    } catch (e: any) {
      if (response.status === 200) {
        console.error('Error parsing json:', e);
        if (swallow) {
          console.warn('Swallowing error');
          return {};
        }
        throw new Error('JSON parsing failed');
      }
    }
  }

  private getRequestInit(withAuth: boolean, init: RequestInit = {}): RequestInit {
    init.headers = init.headers || {};

    if (init.headers instanceof Headers) {
      init.headers = { ...init.headers };
    }

    const headers = init.headers as Record<string, string | undefined>;

    const contentType = headers['content-type'] || headers['Content-Type'];

    if (contentType === 'multipart/form-data') {
      // delete custom content-type, browser will add correct one
      delete headers['content-type'];
      delete headers['Content-Type'];
    } else if (contentType == null) {
      headers['content-type'] = 'application/json';
    }

    if (withAuth) {
      const token = tokenStorage.get();
      if (token == null) throw new UnauthorizedError();
      headers['authorization'] = `Bearer ${token}`;
    }

    return init;
  }

  private createError(status: number, json: any): HttpError {
    if (status === 401) return new UnauthorizedError();
    if (status === 404) {
      return new NotFoundError(status, json);
    }
    return new HttpError(status, json);
  }
}
