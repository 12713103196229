import { MagicUseCase } from '~/pdfsigner/usecases/baseUseCases/magicUseCase';
import type { LeaseTemplateUpdateRequest } from '~/pdfsigner/usecases/types/leaseTemplateUpdateRequest';

export class UpdateLeaseTemplateUseCase extends MagicUseCase {
  public async runLogic(request: LeaseTemplateUpdateRequest) {
    if (!request) {
      return;
    }

    if (
      this.getState().dataPathManager.doesDataPathWithKeyExist(request.dataPathKey) &&
      this.getState().dataPathManager.getSelectedNodeKey() !== request.dataPathKey
    ) {
      this.getState().dataPathManager.selectNodeWithKey(request.dataPathKey);
    }
    const selectedDocument = this.getState().selectedDocument;
    if (selectedDocument) {
      if (request.leaseCategoryId) {
        const desiredCategory = this.getState().leaseTemplateCategories.find((item) => item.id === request.leaseCategoryId);
        selectedDocument.category = desiredCategory;
      }
      selectedDocument.title = request.title ? request.title : this.getState().selectedDocument?.title;
    }
  }
}
