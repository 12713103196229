import { createSignal, onMount, Show } from 'solid-js';
import { LabeledBankAccountSearch } from '~/components/bank-accounts/BankAccountSearch';
import { Form, FormItem, useForm } from '~/components/common/BetterForm';
import { DropdownMenu } from '~/components/common/DropdownMenu';
import DueDateInputField from '~/components/common/Inputs/DueDateInputField';
import LabeledNumberInput from '~/components/common/Inputs/LabeledNumberInput';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { LeaseOverviewBanner } from '~/components/leases';
import { Modal } from '~/components/modals/Modal';
import { toast } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useLeaseRentTransactions } from '~/contexts/local';
import { TransactionStatus } from '~/swagger/Api';
import { cn } from '~/utils/classnames';
import { MAX_INPUT_AMOUNT, paymentMethodOptions, transactionStatusOptions } from '~/utils/constant';

export interface LeaseTransactionUpdateModalProps {
  lease: MagicDoor.Api.HydratedLeaseDto;
  visible: boolean;
  transactionDetail?: MagicDoor.Api.HydratedLeaseTransactionDto;
  onCancel: () => void;
  onSuccess: () => void;
}

export const LeaseDepositTransactionUpdateModal = (props: LeaseTransactionUpdateModalProps) => {
  const { t } = useLocalization();
  const { updateTransaction } = useLeaseRentTransactions();
  const form = useForm();
  const [isLoading, setIsLoading] = createSignal(false);

  const onSubmit = async () => {
    try {
      const { amount, transactionDate, bankAccountId, status, memo, paymentMethod, transactionFinalizedDate } = form.formStore;

      if (!props.transactionDetail?.id) {
        return;
      }

      const { validateStatus } = form.validateForm();

      if (!validateStatus) {
        return;
      }

      setIsLoading(true);
      await updateTransaction(props.transactionDetail.id, {
        amount,
        transactionDate,
        bankAccountId,
        status,
        memo,
        paymentMethod,
        transactionFinalizedDate,
      });
      toast.success(t('Transaction has been updated successfully'));
      props.onCancel();
      props.onSuccess();
    } finally {
      setIsLoading(false);
    }
  };

  onMount(() => {
    if (!props.transactionDetail) {
      return;
    }

    const { transactionDate, transaction } = props.transactionDetail;
    const { bankAccountId, status, memo, transactionPaymentMethod, transactionFinalizedDate, amount } = transaction ?? {};

    form.setFieldsValue({
      amount,
      transactionDate,
      bankAccountId,
      status,
      memo,
      paymentMethod: transactionPaymentMethod,
      transactionFinalizedDate,
    });
  });

  return (
    <Modal loading={isLoading()} title={t('Update Transaction')} visible={props.visible} onCancel={props.onCancel} onDone={onSubmit}>
      <div class="px-8 py-6">
        <LeaseOverviewBanner lease={props.lease} />
        <Form class="grid grid-cols-2 gap-4 py-6" defaultForm={form}>
          <FormItem
            class="col-span-full !mb-0"
            label={t('Bank account')}
            formFieldName="bankAccountId"
            rules={[{ message: t('Please select bank account'), required: true }]}
            placeholder={t('Please select bank account')}
            component={LabeledBankAccountSearch}
          />
          <FormItem
            class="col-span-1 !mb-0"
            label={t('Amount')}
            formFieldName="amount"
            rules={[
              { required: true, message: t('Please input the amount') },
              {
                type: 'number',
                range: [1, MAX_INPUT_AMOUNT],
                message: t('{name} must be between {min} and {max}', { name: t('Amount'), min: '1', max: MAX_INPUT_AMOUNT }),
              },
            ]}
            placeholder={t('Please input the amount')}
            component={LabeledNumberInput}
          />
          <FormItem
            class="col-span-1"
            label={t('Payment Method')}
            formFieldName="paymentMethod"
            placeholder={t('Please select the payment method')}
            onChangeMethodName="onChange"
            options={paymentMethodOptions(t)}
            component={DropdownMenu}
          />
          <FormItem
            class="col-span-1"
            label={t('Status')}
            formFieldName="status"
            placeholder={t('Please select the transaction status')}
            onChangeMethodName="onChange"
            options={transactionStatusOptions(t)}
            component={DropdownMenu}
          />
          <FormItem
            class="col-span-1"
            label={t('Transaction Date')}
            formFieldName="transactionDate"
            placeholder={t('Please select the transaction status')}
            component={DueDateInputField}
          />
          <Show when={form.formStore.status !== TransactionStatus.Pending}>
            <FormItem
              class="col-span-1"
              label={t('Transaction Finalized Date')}
              required
              rules={[{ message: t('Please select the transaction finalized date'), required: true }]}
              formFieldName="transactionFinalizedDate"
              placeholder={t('Please select the transaction finalized date')}
              component={DueDateInputField}
            />
          </Show>
          <FormItem
            class={cn('col-span-full', { 'col-span-1': form.formStore.status !== TransactionStatus.Pending })}
            label={t('Memo')}
            formFieldName="memo"
            maxlength={1000}
            placeholder={t('Please input the memo')}
            component={LabeledTextInput}
          />
        </Form>
      </div>
    </Modal>
  );
};
