import { useLocalization } from '~/contexts/global';
import { useLeaseRentTransactions } from '~/contexts/local';
import { LeaseAddDepositTransactionModal } from '~/pages/leases/lease-details/components/lease-deposit-transaction/LeaseAddDepositTransactionModal';
import type { Component } from 'solid-js';
import type { ModalProps } from '~/components/modals/Modal';
import type { AddDepositTransactionType } from '~/pages/leases/lease-details/components/lease-deposit-transaction/LeaseAddDepositTransactionModal';

type LeaseSetupChargeModalProps = Omit<ModalProps, 'title'> & {
  currentLease: MagicDoor.Api.HydratedLeaseDto;
};

export const LeaseSetupChargeModal: Component<LeaseSetupChargeModalProps> = (props) => {
  const { t } = useLocalization();
  const { setupCharge } = useLeaseRentTransactions();

  const handleSubmit = async (data: AddDepositTransactionType) => {
    await setupCharge(props.currentLease.id, {
      dueDate: data.dueDate as string,
      chartOfAccountId: data.chartOfAccountId as string,
      amount: Number(data.amount),
      description: data.description || null,
      companyCharge: data.companyCharge ?? false,
    });
  };

  return (
    <LeaseAddDepositTransactionModal
      {...props}
      title={t('Add a charge')}
      submitText={t('Save')}
      onSubmit={handleSubmit}
      chartOfAccountType="revenue"
      showAllChartOfAccountsSelector={true}
      formDefaultValues={{
        description: 'Rent',
      }}
    />
  );
};
