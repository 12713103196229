import { useLocalization } from '~/contexts/global';

export const Empty = (props: { type?: string }) => {
  const { t } = useLocalization();
  return (
    <div class="flex justify-center">
      <div class="relative mx-auto px-4 text-center">
        <div class="absolute inset-x-0 top-1/2 translate-y-1/2 border-t" />
        <span class="relative bg-white px-3">{t('No {type} yet', { type: t(`${props.type ?? 'data'}`) })}</span>
      </div>
    </div>
  );
};
