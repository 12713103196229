import { urlWithQuery } from '~/utils/url';
import { BaseRestRepository } from './baseRestRepository';

export type WorkOrderStatus = 'closed' | 'pending' | 'inProgress';

export type WorkOrdersFilter = {
  page?: number;
  pageSize?: number;
  portfolioId?: string[];
  propertyIds?: string[];
  unitIds?: string[];
  leaseIds?: string[];
  vendorIds?: string[];
  status?: WorkOrderStatus;
};

export const WORK_ORDER_BASE_URL = '/api/work-orders';
export class WorkOrdersRepository extends BaseRestRepository {
  public async getWorkOrders(filter: WorkOrdersFilter): Promise<MagicDoor.Api.WorkOrdersPaginationDto> {
    const url = urlWithQuery(WORK_ORDER_BASE_URL, filter);
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async getWorkOrder(workOrderId: string): Promise<MagicDoor.Api.HydratedWorkOrderDto> {
    const url = `${WORK_ORDER_BASE_URL}/${workOrderId}`;
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async addWorkOrder(payload: MagicDoor.Api.CreateWorkOrderDto): Promise<MagicDoor.Api.HydratedWorkOrderDto> {
    const url = WORK_ORDER_BASE_URL;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
    return this.getJsonResponse(response);
  }

  public async updateMaintenanceRequest(
    workOrderId: string,
    payload: MagicDoor.Api.UpdateWorkOrderMaintenanceRequestDto
  ): Promise<MagicDoor.Api.HydratedWorkOrderDto> {
    const url = `${WORK_ORDER_BASE_URL}/${workOrderId}/maintenance-request`;
    const response = await this.fetchWithAuth(url, {
      method: 'PUT',
      body: JSON.stringify(payload),
    });
    return this.getJsonResponse(response);
  }

  public async deleteWorkOrder(workOrderId: string): Promise<void> {
    const url = `${WORK_ORDER_BASE_URL}/${workOrderId}`;
    await this.fetchWithAuth(url, {
      method: 'DELETE',
    });
  }

  public async updateWorkOrder(
    workOrderId: string,
    payload: MagicDoor.Api.UpdateWorkOrderDto
  ): Promise<MagicDoor.Api.HydratedWorkOrderDto> {
    const url = `${WORK_ORDER_BASE_URL}/${workOrderId}`;
    const response = await this.fetchWithAuth(url, {
      method: 'PUT',
      body: JSON.stringify(payload),
    });
    return this.getJsonResponse(response);
  }

  public async updateWorkOrderStatus(
    workOrderId: string,
    payload: MagicDoor.Api.UpdateWorkOrderStatusDto
  ): Promise<MagicDoor.Api.HydratedWorkOrderDto> {
    const url = `${WORK_ORDER_BASE_URL}/${workOrderId}/status`;
    const response = await this.fetchWithAuth(url, {
      method: 'PUT',
      body: JSON.stringify(payload),
    });
    return this.getJsonResponse(response);
  }

  public async updateVendor(
    workOrderId: string,
    payload: MagicDoor.Api.UpdateWorkOrderVendorDto
  ): Promise<MagicDoor.Api.HydratedWorkOrderDto> {
    const url = `${WORK_ORDER_BASE_URL}/${workOrderId}/vendor`;
    const response = await this.fetchWithAuth(url, {
      method: 'PUT',
      body: JSON.stringify(payload),
    });
    return this.getJsonResponse(response);
  }

  public async updateLease(
    workOrderId: string,
    payload: MagicDoor.Api.UpdateWorkOrderLeaseDto
  ): Promise<MagicDoor.Api.HydratedWorkOrderDto> {
    const url = `${WORK_ORDER_BASE_URL}/${workOrderId}/lease`;
    const response = await this.fetchWithAuth(url, {
      method: 'PUT',
      body: JSON.stringify(payload),
    });
    return this.getJsonResponse(response);
  }

  public async addDraftBill(workOrderId: string, payload: MagicDoor.Api.AddDraftWorkOrderBillDto): Promise<MagicDoor.Api.WorkOrderBillDto> {
    const url = `${WORK_ORDER_BASE_URL}/${workOrderId}/draft-bills`;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
    return this.getJsonResponse(response);
  }

  public async updateDraftBill(
    workOrderId: string,
    billId: string,
    payload: MagicDoor.Api.AddDraftWorkOrderBillDto
  ): Promise<MagicDoor.Api.WorkOrderBillDto> {
    const url = `${WORK_ORDER_BASE_URL}/${workOrderId}/draft-bills/${billId}`;
    const response = await this.fetchWithAuth(url, {
      method: 'PUT',
      body: JSON.stringify(payload),
    });
    return this.getJsonResponse(response);
  }

  public async deleteDraftBill(workOrderId: string, billId: string): Promise<void> {
    const url = `${WORK_ORDER_BASE_URL}/${workOrderId}/draft-bills/${billId}`;
    await this.fetchWithAuth(url, {
      method: 'DELETE',
    });
  }

  public async convertDraftBill(workOrderId: string, billId: string): Promise<void> {
    const url = `${WORK_ORDER_BASE_URL}/${workOrderId}/draft-bills/${billId}/convert`;
    await this.fetchWithAuth(url, {
      method: 'POST',
    });
  }

  public async addBillFile(workOrderId: string, billId: string, file: File, description?: string): Promise<MagicDoor.Api.WorkOrderBillDto> {
    const url = `${WORK_ORDER_BASE_URL}/${workOrderId}/draft-bills/${billId}/files`;
    const formData = new FormData();
    formData.append('File', file);
    formData.append('Description', description || '');

    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return this.getJsonResponse(response);
  }

  public async deleteBillFile(workOrderId: string, billId: string, fieldId: string): Promise<void> {
    const url = `${WORK_ORDER_BASE_URL}/${workOrderId}/draft-bills/${billId}/files/${fieldId}`;
    await this.fetchWithAuth(url, { method: 'DELETE' });
  }

  public async createWorkOrderNotification(workOrderId: string, formData: FormData): Promise<void> {
    const url = `${WORK_ORDER_BASE_URL}/${workOrderId}/notification/vendor`;
    await this.fetchWithAuth(url, {
      method: 'POST',
      body: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  public async getUserWorkOrders(filter: WorkOrdersFilter): Promise<MagicDoor.Api.WorkOrdersPaginationDto> {
    const url = urlWithQuery(`${WORK_ORDER_BASE_URL}/mine`, filter);
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }
}
