import { For, Show, createEffect, createSignal } from 'solid-js';
import IconChevron from '~/assets/images/common/lineArrow.svg?component-solid';
import { useForm } from '~/components/common/BetterForm';
import { FormWrapper } from '~/components/common/BetterForm/Form';
import CallToActionButton from '~/components/common/Buttons/CallToActionButton';
import {
  CategoryComponentSection,
  CategoryComponentWrapperProvider,
  CategoryContentSection,
  getErrorAndProgressProps,
} from '~/components/common/Category';
import CancelWithConfirmation from '~/components/modals/CancelWithConfirmation';
import { toast } from '~/components/ui';
import { useLocalization, useVendors } from '~/contexts/global';
import ContactsSection from '~/pages/vendors/components/form-sections/ContactsSection';
import InsuranceSection from '~/pages/vendors/components/form-sections/InsuranceSection';
import PaymentInformationSection from '~/pages/vendors/components/form-sections/PaymentInformationSection';
import VendorInformationSection from '~/pages/vendors/components/form-sections/VendorInformationSection';
import { isEmptyData } from '~/utils/tool';
import PaymentSettingsSection from './form-sections/PaymentSettingsSection';
import type { JSX, Setter } from 'solid-js';
import type { Section } from '~/components/common/Category';

interface Props {
  onClose: () => void;
  setShowCloseConfirmation?: Setter<boolean>;
  showCloseConfirmation?: boolean;
}

type AddSection = {
  key: string;
  component: (props: any) => JSX.Element;
  name: string;
  ref?: HTMLElement | null;
  required?: boolean;
} & Omit<Section, 'component'>;

const AddNewVendor = (props: Props) => {
  const { addVendor } = useVendors();

  const [activeCategory, setActiveCategory] = createSignal<string>('vendorInformation');
  const [expandedAdditional, setExpandedAdditional] = createSignal<boolean>(false);

  const { t } = useLocalization();

  const form = useForm();

  const sections: AddSection[] = [
    {
      key: 'vendorInformation',
      component: VendorInformationSection,
      name: t('Vendor information'),
      required: true,
      fields: ['name', 'language', 'memo'],
    },
    {
      key: 'contacts',
      component: ContactsSection,
      name: t('Contacts'),
      fields: ['contact.phone', 'contact.email', 'address'],
    },
    {
      key: 'paymentInformation',
      component: PaymentInformationSection,
      name: t('Payment information'),
      fields: ['irs1099Form.taxId', 'irs1099Form.taxIdType'],
    },
    {
      key: 'insurance',
      component: InsuranceSection,
      name: t('Insurance'),
      fields: ['insurancePolicy.policyNumber', 'insurancePolicy.expirationDate', 'insurancePolicy.provider'],
    },
    {
      key: 'paymentSettings',
      component: PaymentSettingsSection,
      name: t('Payment settings'),
      fields: ['paymentSettings.onePaymentPerBill'],
    },
  ];

  const handleToggleExpandedAdditional = () => {
    const expanded = setExpandedAdditional((prev) => !prev);
    if (!expanded) {
      setActiveCategory('vendorInformation');
    }
  };

  let scrollContainer!: HTMLDivElement;

  const performSave = async () => {
    try {
      const { validateStatus } = form.validateForm();

      if (!validateStatus) {
        return;
      }

      const created = await addVendor(form.formStore as MagicDoor.Api.CreateOrUpdateVendorDto);
      toast.success(t('{name} has been added successfully', { name: created.name }));
      props.onClose();
    } catch (err: any) {
      toast.error(t('Operation failed, please try again later') + ' ' + err?.json?.detail);
    }
  };

  createEffect(() => {
    const store = { ...form.formStore };
    delete store.language;
    props.setShowCloseConfirmation?.(!isEmptyData(store));
  });

  return (
    <FormWrapper
      class="relative overflow-hidden text-sm"
      initialValues={{
        paymentSettings: {
          onePaymentPerBill: false,
        },
      }}
      defaultForm={form}
      onFormSubmit={performSave}>
      <CategoryComponentWrapperProvider
        onActiveCategoryChange={(key, evt) => {
          if (key !== 'vendorInformation' && evt === 'click') setExpandedAdditional(true);
          if (evt === 'scroll' && !expandedAdditional()) return setActiveCategory('vendorInformation');
          setActiveCategory(key);
        }}
        sections={sections}
        getScrollElement={() => scrollContainer}
        activeCategory={activeCategory()}>
        <div class="relative flex h-full overflow-auto pb-[91px]">
          <div class="thinscroll sticky top-0 hidden  w-1/3 overflow-auto bg-inputbox-bg text-text-level03 lg:block">
            <div class="flex gap-2">
              <CategoryComponentSection
                headerTitle={<div class="py-3 pl-5 text-lg font-semibold text-text-level01">{t('Add vendor')}</div>}
                categoryFooterTitle={t('Vendor')}
                class="min-w-0 bg-transparent"
                {...getErrorAndProgressProps(form)}
              />
            </div>
          </div>

          <CategoryContentSection
            renderMethod={({ store, setContentRef }) => {
              return (
                <div ref={scrollContainer} class="thinscroll flex h-full flex-1 flex-col overflow-auto px-8 pb-5">
                  <div
                    ref={(ref) => {
                      setContentRef('vendorInformation', ref);
                    }}>
                    <VendorInformationSection />
                  </div>
                  <button
                    type="button"
                    class="text-normal my-4 flex items-center justify-center gap-3 px-8 py-4 text-text-level02"
                    onClick={handleToggleExpandedAdditional}>
                    <hr class="grow" /> {t('Add additional information')}{' '}
                    <IconChevron class="transition" classList={{ 'rotate-180': !expandedAdditional() }} /> <hr class="grow" />
                  </button>
                  <Show when={expandedAdditional()}>
                    <For each={(store.sections as AddSection[]).slice(1)}>
                      {({ key, component: SectionComponent }) => (
                        <div
                          ref={(ref) => {
                            setContentRef(key, ref);
                          }}>
                          <SectionComponent />
                        </div>
                      )}
                    </For>
                  </Show>
                </div>
              );
            }}
          />
        </div>

        <div class="absolute bottom-0 z-20 flex w-full items-center justify-between border-t bg-white p-6">
          <div class="w-2/3 text-left text-xs text-text-level03">
            {t(
              'After filling in the basic information, you can create a supplier, and complete information is more convenient for cooperation.'
            )}
          </div>
          <div class="flex w-1/3 justify-end gap-2">
            <CancelWithConfirmation
              enabled={props.showCloseConfirmation}
              onConfirm={props.onClose}
              buttonsDisabled={addVendor.pending}
              message={t('If you cancel the addition, the information already filled in cannot be saved')}
            />
            <CallToActionButton
              type="submit"
              loading={addVendor.pending}
              tooltipMessage={t('Please fill out all the required fields that are in red')}>
              {t('Create vendor')}
            </CallToActionButton>
          </div>
        </div>
      </CategoryComponentWrapperProvider>
    </FormWrapper>
  );
};

export default AddNewVendor;
