import { useNavigate, useSearchParams } from '@solidjs/router';
import { createEffect, createMemo, createSignal, onMount } from 'solid-js';
import { createStore } from 'solid-js/store';
import Breadcrumb from '~/components/common/Breadcrumb';
import { MultiStepsPanel } from '~/components/common/Panels/MultiStepsPanel';
import { Stepper } from '~/components/common/Stepper';
import { toast } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useBills } from '~/contexts/local';
import BillStore from '~/pages/bills/store';
import { paymentMethodOptions } from '~/utils/constant';
import { cloneDeep, isEmptyData } from '~/utils/tool';
import { AllocationsForm } from './components/AllocationsForm';
import { ChooseBillsForm } from './components/ChooseBillsForm';
import { PaymentForm } from './components/PaymentForm';
import { ReviewAndPrintForm } from './components/ReviewAndPrintForm';
import type { BillPayment, PayBillsForm } from './types';

const initPayBillsForm = {
  isAllProperties: true,
  isAllVendors: true,
  properties: [],
  vendors: [],
  consolidatePayments: true,
  billPayments: [],
  paymentDate: new Date().toISOString().split('T')[0],
};

export const PayBillView = () => {
  const { store, getVendorBillsPay, getChartOfAccounts, getBankAccounts } = BillStore;
  const { payBill } = useBills();
  const navigate = useNavigate();
  const [query] = useSearchParams<{ propertyId?: string; vendorId?: string }>();
  const { t } = useLocalization();
  const [formData, setFormData] = createStore<PayBillsForm>(cloneDeep(initPayBillsForm));
  const breadcrumbItems = createMemo(() => [{ label: t('Bills'), link: '/accounting/bills' }, { label: t('Pay bills') }]);
  const steps = createMemo(() => [t('Choose bills'), t('Allocations'), t('Payment'), t('Review & print')]);
  const [currentStep, setCurrentStep] = createSignal<number>(0);
  const [isValidate, setIsValidate] = createSignal<boolean>(false);
  const [reviewData, setReviewData] = createSignal<MagicDoor.Api.PayBillsResultsDto>();
  const [isNeedToUpdate, setIsNeedToUpdate] = createSignal<boolean>(true);

  const stepValidator = (step: number) => {
    switch (step) {
      case 0: {
        const isNoProperties = !formData.isAllProperties && isEmptyData(formData.properties);
        const isNoVendors = !formData.isAllVendors && isEmptyData(formData.vendors);

        setIsValidate(!isNoProperties && !isNoVendors);
        break;
      }

      case 1: {
        const hasBills = Boolean(formData.billPayments.length);
        const isAllAmountAreRight = formData.billPayments.every((bill) => {
          return !isNaN(bill.paymentAmount as number) && Number(bill.paymentAmount) >= 0 && Number(bill.paymentAmount) <= bill.dueAmount;
        });

        setIsValidate(hasBills && isAllAmountAreRight);
        break;
      }

      case 2: {
        const hasBills = Boolean(formData.billPayments.length);

        setIsValidate(hasBills);
        break;
      }
    }
  };

  const onStepChange = (step: number, isBack?: boolean) => {
    if (step === 1 && !isBack) {
      if (isNeedToUpdate()) {
        getVendorBillsPay({
          propertyIdOr: formData.properties.filter((item) => item),
          vendorIdOr: formData.vendors.filter((item) => item),
        });
      }

      setIsNeedToUpdate(false);
    }

    setCurrentStep(step);
  };

  const onPayBills = async () => {
    const bills = formData.billPayments
      .filter((bill) => Boolean(bill.paymentAmount))
      .map((bill) => ({
        billId: bill.id,
        bankAccountId: bill.bankAccountId,
        amount: bill.paymentAmount,
        paymentType: bill.paymentType,
        memo: bill.memo,
        paymentMethod: bill.paymentMethod,
        externalTransactionId:
          bill.paymentType === 'printCheck' || (bill.paymentType === 'manual' && bill.paymentMethod === 'check')
            ? ''
            : bill.externalTransactionId,
      })) as MagicDoor.Api.PayVendorBillDto[];
    const payload = { paymentDate: formData.paymentDate, bills };
    const result = await payBill(payload);

    setReviewData(result);
    toast.success(t('Bills have been paid successfully'));
  };

  createEffect(() => {
    const defaultBankAccountId = store.bankAccountOptions?.at(0)?.value;
    const defaultPaymentMethod = paymentMethodOptions(t).at(0)?.value;
    const defaultPaymentType = 'printCheck';
    const billPayments: BillPayment[] =
      store.bills?.map((bill) => ({
        id: bill.id,
        property: bill.property,
        bankAccountId: defaultBankAccountId,
        paymentMethod: defaultPaymentMethod,
        paymentAmount: bill.due,
        dueAmount: bill.due,
        vendor: bill.vendor,
        paymentType: defaultPaymentType,
        chartOfAccountName: bill.lineItems.map((item) => store.chartOfAccountMap?.get(item.chartOfAccountId)).filter(Boolean) as string[],
        memo: bill.memo ?? '',
        externalTransactionId: '',
        dueDate: bill.dueDate,
        totalAmount: bill.totalAmount,
        vendorMemo: bill.vendor.defaultMemo,
      })) ?? [];

    setFormData('billPayments', billPayments);
  });

  createEffect(() => {
    stepValidator(currentStep());
  });

  onMount(() => {
    getChartOfAccounts();
    getBankAccounts();

    if (query.propertyId) {
      setFormData('isAllProperties', false);
      setFormData('properties', [query.propertyId]);
    }

    if (query.vendorId) {
      setFormData('isAllVendors', false);
      setFormData('vendors', [query.vendorId]);
    }

    if (query.propertyId || query.vendorId) {
      setCurrentStep(1);
      getVendorBillsPay({
        propertyIdOr: formData.properties.filter((item) => item),
        vendorIdOr: formData.vendors.filter((item) => item),
      });
      setIsNeedToUpdate(false);
    }
  });

  return (
    <>
      <Breadcrumb backLink={() => navigate(-1)} items={breadcrumbItems()} />
      <MultiStepsPanel
        title={t('Pay bills')}
        submitText={t('Pay bills')}
        stepper={
          <div class="flex items-center justify-center pb-10">
            <Stepper class="p-9" steps={steps()} step={currentStep()} />
          </div>
        }
        currentStep={currentStep()}
        showPrevious
        disableNext={!isValidate()}
        successPage={<ReviewAndPrintForm reviewData={reviewData()} />}
        onClose={() => navigate(-1)}
        onStepChange={(step, isBack) => onStepChange(step, isBack)}
        onSubmit={onPayBills}>
        <ChooseBillsForm store={store} form={formData} setFormData={setFormData} setNeedToUpdate={setIsNeedToUpdate} />
        <AllocationsForm store={store} form={formData} setFormData={setFormData} />
        <PaymentForm store={store} form={formData} setFormData={setFormData} />
      </MultiStepsPanel>
    </>
  );
};
