import { useParams } from '@solidjs/router';
import { Show, onMount } from 'solid-js';
import { RingLoader } from '~/components/common/Loaders';
import useUseCase from '~/framework/hooks/useUseCase';
import DocumentUpload from '~/pdfsigner/ui/components/DocumentUpload';
import { LeaseTemplateHeader } from '~/pdfsigner/ui/components/header/LeaseTemplateHeader';
import { LeaseTemplateToolbar } from '~/pdfsigner/ui/components/toolbar/LeaseTemplateToolbar';
import { LoadLeaseTemplateUseCase } from '~/pdfsigner/usecases/pdfEditing/loadLeaseTemplateUseCase';
import { SaveLeaseTemplateUseCase } from '~/pdfsigner/usecases/pdfEditing/saveLeaseTemplateUseCase';
import type { JSX } from 'solid-js';

const Composer = (): JSX.Element => {
  const { templateId } = useParams();
  const { execute: loadTemplate, isLoading } = useUseCase(LoadLeaseTemplateUseCase);
  const { execute: save, isLoading: isSaving } = useUseCase(SaveLeaseTemplateUseCase);

  onMount(() => {
    loadTemplate(templateId);
  });

  return (
    <Show
      when={!isLoading() && !isSaving()}
      fallback={
        <div class="flex h-[calc(100vh_-_125px)] flex-wrap items-center justify-center">
          <RingLoader color="#a126ec" size={125} borderWidth={5} showIcon />
        </div>
      }>
      <div class="flex h-[calc(100vh_-_125px)] flex-col overflow-hidden px-8 pt-5">
        <LeaseTemplateHeader />
        <div class="flex flex-1 items-start gap-3 overflow-hidden">
          <DocumentUpload />
          <LeaseTemplateToolbar onSave={save} />
        </div>
      </div>
    </Show>
  );
};

export default Composer;
