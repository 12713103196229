import { ValidationError } from '~/errors';
import { urlWithQuery } from '~/utils/url';
import { BaseRestRepository } from './baseRestRepository';

const UNIT_LISTINGS_URL = '/api/unit-listings';

export type UnitListingFilter = {
  page: number;
  pageSize: number;
  portfolioId?: string;
  propertyId?: string;
  listed?: boolean;
  pinned?: boolean;
  availableFrom?: string | undefined;
  minRentalPrice?: number;
  maxRentalPrice?: number;
  minBeds?: number;
  minBaths?: number;
  propertyType?: MagicDoor.Api.PropertyType;
  search?: string | undefined;
};
export type UnitListingImagePayload = {
  file: File;
  description?: string;
};
export type addUnitListingPayload = {
  portfolioIds?: string[] | undefined;
  propertyIds?: string[] | undefined;
  unitIds?: string[] | undefined;
};

export function validateId(id: string, entityName = 'Entity'): void {
  if (!id) throw new ValidationError(`${entityName} ID must be provided`);
}
export class UnitListingsRepository extends BaseRestRepository {
  public async getUnitListings(filter: UnitListingFilter): Promise<MagicDoor.Api.PaginatedUnitListingDto> {
    const url = urlWithQuery(UNIT_LISTINGS_URL, filter);
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }
  public async getUnitListingDetail(unitListingId: string): Promise<MagicDoor.Api.HydratedUnitListingDto> {
    const url = `${UNIT_LISTINGS_URL}/${unitListingId}`;
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }
  public async addUnitListingImage(unitId: string, images: File[]): Promise<MagicDoor.Api.UploadResultDto> {
    validateId(unitId);
    const formData = new FormData();
    images?.forEach((img) => {
      formData.append(img.name, img, img.name);
    });
    const url = `/api/files`;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      headers: { 'Content-Type': 'multipart/form-data' },
      body: formData,
    });
    return this.getJsonResponse(response);
  }
  public async deleteUnitListingImage(unitId: string, imageId: string): Promise<MagicDoor.Api.UnitListingDto> {
    validateId(unitId);
    validateId(imageId);
    const url = `${UNIT_LISTINGS_URL}/${unitId}/images/${imageId}`;
    const response = await this.fetchWithAuth(url, { method: 'DELETE' });
    return this.getJsonResponse(response);
  }
  public async addUnitListing(payload: addUnitListingPayload): Promise<MagicDoor.Api.ImportUnitListingDto> {
    const url = `${UNIT_LISTINGS_URL}/import`;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
    return this.getJsonResponse(response);
  }
  public async getnerateAiListing(
    unitId: string,
    prompt: MagicDoor.Api.UnitMasterListingRequestDto
  ): Promise<MagicDoor.Api.UnitMasterListingDto> {
    const url = `${UNIT_LISTINGS_URL}/${unitId}/generate-ai-masterlisting`;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(prompt),
    });
    return this.getJsonResponse(response);
  }
  public async updateUnitListing(model: MagicDoor.Api.UpdateUnitListingDto, unitId: string): Promise<MagicDoor.Api.UpdateUnitListingDto> {
    const url = `${UNIT_LISTINGS_URL}/${unitId}`;
    const response = await this.fetchWithAuth(url, {
      method: 'PUT',
      body: JSON.stringify(model),
    });
    return this.getJsonResponse(response);
  }
}

export const unitListingsRepository = new UnitListingsRepository();
