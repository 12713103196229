import { createMemo, createSignal } from 'solid-js';
import { TabNav } from '~/components/common/TabNav';
import { LeaseSearch } from '~/components/leases';
import { PortfolioSearch } from '~/components/portfolios';
import { PropertySearch } from '~/components/properties';
import { CustomLevelSearch } from '~/components/search-select/LevelSearchComponents/SearchLevels';
import { LeveledSearchSelect } from '~/components/search-select/LevelSearchSelect';
import { TenantSearch } from '~/components/tenants';
import { UnitSearch } from '~/components/units';
import { useLocalization } from '~/contexts/global';
import type { Component } from 'solid-js';
import type { CustomLevelSearchType } from '~/components/search-select/LevelSearchComponents/SearchLevels';
import type { EntrySearchProps } from '~/components/ui';

const globalSearchComponent: Record<CustomLevelSearchType, Component<EntrySearchProps<any, any, any>>> = {
  [CustomLevelSearch.Tenant]: TenantSearch,
  [CustomLevelSearch.Portfolio]: PortfolioSearch,
  [CustomLevelSearch.Property]: PropertySearch,
  [CustomLevelSearch.Unit]: UnitSearch,
  [CustomLevelSearch.Lease]: LeaseSearch,
};

enum SearchType {
  Level = 'level-search',
  Global = 'global-search',
}

export const SearchWithTab = <T extends { id: string }>(props: {
  selectType: CustomLevelSearchType;
  onChange: (selected: T[]) => void;
  placeholder: string;
}) => {
  const [activeTab, setActiveTab] = createSignal(SearchType.Level);
  const [selected, setSelected] = createSignal<(T & { selectFrom: SearchType })[]>([]);

  const tabs = createMemo(() => {
    const Component = globalSearchComponent[props.selectType] || (() => <div>No component found</div>);
    const { t } = useLocalization();

    return [
      {
        label: t('Level search'),
        key: SearchType.Level,
        component: () => (
          <LeveledSearchSelect
            onChange={(selected) => {
              handleSelect(selected, SearchType.Level);
            }}
            placeholder={props.placeholder}
            enabledTypes={[props.selectType]}
            initialSelected={selected()
              .filter((item) => item.selectFrom === SearchType.Level)
              .map((item) => ({ ...item, __type: props.selectType }))}
          />
        ),
      },
      {
        label: t('Global search'),
        key: SearchType.Global,
        component: () => (
          <Component
            onSelect={(selected) => {
              handleSelect(toArray(selected), SearchType.Global);
            }}
            placeholder={props.placeholder}
            defaultSelectedIds={selected()
              .filter((item) => item.selectFrom === SearchType.Global)
              .map((item) => item.id)}
          />
        ),
      },
    ];
  });

  function toArray<T>(value: T | T[]) {
    return value ? (Array.isArray(value) ? value : [value]) : [];
  }

  function handleSelect(selected: T[], selectFrom: SearchType) {
    setSelected(selected.map((item) => ({ ...item, selectFrom })));
    props.onChange(selected);
  }

  function handleTabChange(key: SearchType) {
    setActiveTab(key);
  }

  return <TabNav tabs={tabs()} activeTab={activeTab()} setActiveTab={(key) => handleTabChange(key as SearchType)} />;
};
