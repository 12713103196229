import { Switch, Match, For, createSignal, createMemo } from 'solid-js';
import { produce } from 'solid-js/store';
import CircleArrowIcon from '~/assets/images/common/circleArrow.svg?component-solid';
import { Empty } from '~/components/common/Empty';
import { unicodeToEscapeSequence } from '~/components/common/FeedbackIcon/utils';
import LabeledNumberInput from '~/components/common/Inputs/LabeledNumberInput';
import { RingLoader } from '~/components/common/Loaders';
import { PropertyTitle } from '~/components/properties';
import { useLocalization } from '~/contexts/global';
import { cn } from '~/utils/classnames';
import { emptyPlaceholder } from '~/utils/constant';
import { dateFormat } from '~/utils/date';
import { currency } from '~/utils/number';
import type { Component } from 'solid-js';
import type { BillPayment, PayBillsSubForm } from '~/pages/bills/pay-bill/types';

interface VendorGroupProps {
  bills: BillPayment[];
  onChange: (billId: string, value?: number) => void;
}

const VendorGroup = (props: VendorGroupProps) => {
  const { t } = useLocalization();
  const vendor = createMemo(() => props.bills.at(0)?.vendor);
  const property = createMemo(() => props.bills.at(0)?.property);
  const column = [
    {
      title: t('Due Date'),
      render: (bill: BillPayment) => dateFormat('', bill.dueDate),
    },
    {
      title: t('Memo'),
      render: (bill: BillPayment) => bill.memo || emptyPlaceholder,
    },
    {
      title: t('Chart of accounts'),
      render: (bill: BillPayment) => bill.chartOfAccountName.join(','),
    },
    {
      title: t('Total'),
      class: 'text-right',
      render: (bill: BillPayment) => currency(bill.totalAmount),
    },
    {
      title: t('Unpaid balance'),
      class: 'text-right',
      render: (bill: BillPayment) => currency(bill.paymentAmount),
    },
    {
      title: t('Amount to pay'),
      class: 'text-right',
      render: (bill: BillPayment) => (
        <div class="flex w-full justify-end">
          <LabeledNumberInput
            class="!mb-0 w-44"
            inputContainerClass="bg-white"
            inputClass=" text-right"
            prepend="$"
            onInput={(value) => props.onChange(bill.id, value)}
            value={bill.paymentAmount}
            placeholder={t('Please enter')}
            error={
              (Number(bill.paymentAmount) > Number(bill.dueAmount) && t('Amount exceeds due')) ||
              (Number(bill.paymentAmount) < 0 && t("Amount can't be less than 0")) ||
              ''
            }
          />
        </div>
      ),
    },
  ];

  return (
    <>
      <div class="px-5">
        <div class="my-3 text-xs font-semibold text-text-level01">{t('Vendor')}</div>
        <div class="mb-5 flex items-center text-sm font-medium text-text-level01">
          <span class="mr-2">{vendor()?.name}</span>
          <For each={vendor()?.icons}>
            {(icon) => (
              <div class="flex cursor-pointer items-center justify-center rounded-full border-essential-colour">
                <emoji-regular class="text-xl">{unicodeToEscapeSequence(icon)}</emoji-regular>
              </div>
            )}
          </For>
        </div>
        <div class="mb-3 text-xs font-semibold text-text-level01">{t('Bills')}</div>
        <div class="not-prose relative bg-white">
          <div
            class="bg-grid-slate-100 absolute inset-0 [mask-image:linear-gradient(0deg,#fff,rgba(255,255,255,0.6))] "
            style={{ 'background-position': '10px 10px' }}
          />
          <div class="relative">
            <div class="my-1 overflow-hidden rounded-lg shadow-sm">
              <table class="w-full table-auto border-collapse bg-cell-purple-bg text-xs text-cell-purple-text">
                <thead>
                  <tr>
                    <For each={column}>
                      {(column) => <th class={cn('border-b p-4 text-left font-normal', column.class)}>{column.title}</th>}
                    </For>
                  </tr>
                </thead>
                <tbody class="bg-white text-text-level01">
                  <For each={props.bills}>
                    {(bill) => (
                      <tr>
                        <For each={column}>
                          {(column) => <td class={cn('border-b border-slate-100 px-4 py-2', column.class)}>{column.render(bill)}</td>}
                        </For>
                      </tr>
                    )}
                  </For>
                </tbody>
              </table>
            </div>
          </div>
          <div class="pointer-events-none absolute inset-0 rounded-lg border border-black/5 " />
        </div>
        <BillSummary bills={props.bills} availableBalance={property()?.availableBalance ?? 0} />
      </div>
      <div class="my-6 h-px w-full bg-partingline last-of-type:hidden" />
    </>
  );
};

interface PropertyGroupProps {
  bills: BillPayment[];
  onToggleExpand: () => void;
  isExpand: boolean;
  onChange: (billId: string, value?: number) => void;
}

const PropertyGroup = (props: PropertyGroupProps) => {
  const { t } = useLocalization();
  const totalPaymentAmount = createMemo(() =>
    props.bills.reduce((acc, cur) => {
      return acc + (cur.paymentAmount ?? 0);
    }, 0)
  );
  const billsGroupByVendor = createMemo(() => {
    const map = new Map<string | undefined, BillPayment[]>();

    props.bills.forEach((bill) => {
      if (map.get(bill.vendor?.id)) {
        map.set(bill.vendor?.id, [...(map.get(bill.vendor?.id) as BillPayment[]), bill]);
      } else {
        map.set(bill.vendor?.id, [bill]);
      }
    });

    return Array.from(map.values());
  });

  return (
    <>
      <div class="mb-4 w-full overflow-hidden rounded-lg border">
        <div
          onClick={() => props.onToggleExpand()}
          class={cn('flex cursor-pointer items-center justify-between bg-input px-3 py-4', { 'border-b rounded-lg': props.isExpand })}>
          <PropertyTitle property={props.bills.at(0)?.property} feedbackIconReadonly />
          <div class="flex shrink-0 items-center justify-start">
            <span class="text-xs uppercase text-text-level02">{t('Payment total')}</span>：
            <span class="text-xs font-semibold text-text-level01">{currency(totalPaymentAmount())}</span>
            <CircleArrowIcon class={cn('ml-5 mr-1', { 'text-text-level02': !props.isExpand, 'text-primary rotate-180': props.isExpand })} />
          </div>
        </div>
        <div class={cn('w-full pb-6 transition-all', { hidden: !props.isExpand, block: props.isExpand })}>
          <For each={billsGroupByVendor()}>{(bills) => <VendorGroup bills={bills} onChange={props.onChange} />}</For>
        </div>
      </div>
    </>
  );
};

const BillSummary = (props: { bills: BillPayment[]; availableBalance: number }) => {
  const { t } = useLocalization();

  const enteredPaymentAmount = createMemo(() => {
    return props.bills.reduce((total, bill) => total + Number(bill.paymentAmount ?? 0), 0);
  });

  const remainingBalance = createMemo(() => {
    return (props.availableBalance as number) - enteredPaymentAmount();
  });

  return (
    <div class="flex flex-col items-end pt-2.5 text-right text-xs text-text-level02">
      <div class="mb-1 normal-case">{`${t('Available balance')}: ${currency(props.availableBalance)}`}</div>
      <div class="mb-2.5 w-[203px] border-b border-input-border pb-2">{`${t('Amount to pay')}: ${currency(enteredPaymentAmount())}`}</div>
      <div
        class={cn('text-sm', {
          'text-danger': remainingBalance() < 0,
          'text-warning': remainingBalance() > 0 && remainingBalance() < Number(props.availableBalance),
          'text-green': remainingBalance() === props.availableBalance,
        })}>
        {`${t('Remaining balance')}: ${currency(remainingBalance())}`}
      </div>
    </div>
  );
};

export const AllocationsForm: Component<PayBillsSubForm> = (props) => {
  const { t } = useLocalization();
  const [collapsedGroups, setCollapsedGroups] = createSignal<Set<string | undefined>>(new Set());
  const totalPaymentAmount = createMemo(() =>
    props.form.billPayments.reduce((acc, cur) => {
      return acc + (cur.paymentAmount ?? 0);
    }, 0)
  );
  const billsGroupByProperty = createMemo(() => {
    const map = new Map<string | undefined, BillPayment[]>();

    props.form.billPayments.forEach((item) => {
      if (map.get(item.property?.id)) {
        map.set(item.property?.id, [...(map.get(item.property?.id) as BillPayment[]), item]);
      } else {
        map.set(item.property?.id, [item]);
      }
    });

    return Array.from(map.values());
  });

  const onToggleExpand = (propertyId?: string) => {
    setCollapsedGroups((prev) => {
      const next = new Set(prev);

      if (next.has(propertyId)) {
        next.delete(propertyId);
      } else {
        next.add(propertyId);
      }

      return next;
    });
  };

  const onPaymentAmountChange = (billId: string, value?: number) => {
    props.setFormData(
      produce((state) => {
        const realValue = isNaN(Number(value)) ? 0 : Number(value);
        const index = state.billPayments.findIndex((item) => item.id === billId);

        if (state.billPayments[index]) {
          state.billPayments[index].paymentAmount = realValue;
        }
      })
    );
  };

  return (
    <div class="thinscroll mx-auto flex w-10/12 min-w-[710px] flex-col gap-5 overflow-x-auto">
      <Switch>
        <Match when={!props.store.billsLoading && props.store.bills?.length !== 0}>
          <div>
            <h4 class="py-3 text-base font-semibold">{t('Allocations')}</h4>
            <For each={billsGroupByProperty()}>
              {(bills) => (
                <PropertyGroup
                  bills={bills}
                  isExpand={!collapsedGroups().has(bills.at(0)?.property?.id)}
                  onToggleExpand={() => onToggleExpand(bills.at(0)?.property?.id)}
                  onChange={onPaymentAmountChange}
                />
              )}
            </For>
            <div class="mt-4 flex justify-end">
              <span class="text-sm font-semibold">
                {t('Total Payment Amount')}: {currency(totalPaymentAmount())}
              </span>
            </div>
          </div>
        </Match>
        <Match when={props.store.billsLoading}>
          <div class="flex items-center justify-center">
            <RingLoader color="#A126EC" text={`${t('Loading')}...`} />
          </div>
        </Match>
        <Match when={props.store.bills?.length == 0 && !props.store.billsLoading}>
          <Empty description={t('The property and the vendor you selected do not have a bill')} />
        </Match>
      </Switch>
    </div>
  );
};
