import { createEffect, createSignal, on, Show } from 'solid-js';
import ConvertToLeaseBg from '~/assets/images/rental-application/convert-to-lease.png';
import { LabeledInputSearch } from '~/components/common/Inputs/LabeledInputSearch';
import { CustomLevelSearch } from '~/components/search-select/LevelSearchComponents/SearchLevels';
import { LeveledSearchSelect } from '~/components/search-select/LevelSearchSelect';
import { Tabs } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import UnitItem from '~/pages/bills/components/UnitItem';

interface ConvertToLeaseModalProps {
  preferredUnitOptions: MagicDoor.Api.UnitDto[];
  onUnitSelect: (item: MagicDoor.Api.UnitDto | undefined) => void;
}

export const ConvertToLeaseModal = (props: ConvertToLeaseModalProps) => {
  const { t } = useLocalization();
  const [unitType, setUnitType] = createSignal<'preferred' | 'other'>('other');
  const [unitId, setUnitId] = createSignal<string>();

  createEffect(
    on(unitType, () => {
      setUnitId('');
    })
  );

  createEffect(() => {
    if (props.preferredUnitOptions.length) {
      setUnitType('preferred');
    }
  });

  const handleUnitSelect = (item: MagicDoor.Api.UnitDto | undefined) => {
    setUnitId(item?.id);
    props.onUnitSelect(item);
  };

  return (
    <>
      <img class="-mx-5 block h-32 max-w-none bg-light-pink object-cover" src={ConvertToLeaseBg} alt="ConvertToLeaseBg" />
      <Tabs defaultSelected={unitType()} onChange={setUnitType}>
        <Tabs.List class="my-3 flex gap-4">
          <Tabs.Trigger
            class="flex-1 overflow-hidden rounded-lg border px-3 py-2 text-center transition-colors aria-selected:border-primary aria-selected:bg-primary/10 aria-selected:font-medium aria-selected:text-primary"
            value="preferred">
            {t('Preferred unit')}
            <p class="mt-1 truncate text-xs font-normal text-text-level03">{t("Select the applicant's preferred unit")}</p>
          </Tabs.Trigger>
          <Tabs.Trigger
            class="flex-1 overflow-hidden rounded-lg border px-3 py-2 text-center transition-colors aria-selected:border-primary aria-selected:bg-primary/10 aria-selected:font-medium aria-selected:text-primary"
            value="other">
            {t('Other unit')}
            <p class="mt-1 truncate text-xs font-normal text-text-level03">{t('Select other unit')}</p>
          </Tabs.Trigger>
        </Tabs.List>
        <Tabs.Content value="preferred">
          <Show
            when={props.preferredUnitOptions.length}
            fallback={
              <div class="rounded-lg border border-input-border bg-light-gray p-2 px-3 text-text-level03">
                {t('The applicant has not added units, Please select other unit.')}
              </div>
            }>
            <LabeledInputSearch
              value={unitId()}
              options={props.preferredUnitOptions}
              renderItem={(item) => <UnitItem unit={item} />}
              placeholder={t('Select unit')}
              onSelect={(_, item) => handleUnitSelect(item)}
              valuePropName="id"
            />
          </Show>
        </Tabs.Content>
        <Tabs.Content value="other">
          <LeveledSearchSelect
            onChange={(selected: MagicDoor.Api.UnitDto[]) => {
              handleUnitSelect(selected[0]);
            }}
            placeholder={t('Select unit')}
            enabledTypes={[CustomLevelSearch.Unit]}
          />
        </Tabs.Content>
      </Tabs>
    </>
  );
};
