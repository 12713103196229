import { getErrorMessage } from '~/errors/getErrorMessage';

export type HttpErrorJson = {
  type?: string;
  title?: string;
  status?: number; // <= http status code
  detail?: string;
  instance?: string;
  errors?: Record<string, string[]>;
};

export enum SpecialHttpStatusCode {
  FailedToConnect = -1,
}

export class HttpError extends Error {
  private httpStatusCode: number;
  private json?: HttpErrorJson | string;

  constructor(httpStatusCode: number, json?: HttpErrorJson | string) {
    super();
    this.httpStatusCode = httpStatusCode;
    this.json = json;
    this.message = `HTTP Status Code: ${httpStatusCode}, Details: ${getErrorMessage(this)}`;
    Object.setPrototypeOf(this, HttpError.prototype);
  }

  getStatusCode(): number {
    return this.httpStatusCode;
  }

  getJson<T>(): T | undefined {
    if (typeof this.json === 'string') {
      return undefined;
    }
    return this.json as T;
  }

  getErrors(): Record<string, string | string[]> {
    if (this.httpStatusCode === SpecialHttpStatusCode.FailedToConnect) {
      return { unknown: 'We are having trouble connecting. Please check your internet connection and try again.' };
    }

    if (this.json && this.httpStatusCode < 500) {
      if (typeof this.json === 'string') {
        return { unknown: this.json };
      }

      if (this.json.detail) {
        return { unknown: this.json.detail };
      }

      if (this.json.errors) {
        return this.json.errors;
      }
    }

    return { unknown: 'There was an issue processing your request. Please try again later or contact support if the problem persists.' };
  }
}
