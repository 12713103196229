import { createEffect } from 'solid-js';
import { useLocalization } from '~/contexts/global';
import { useAddOwnerDistributions } from '~/contexts/local';
import { PropertiesReviewListView } from './PropertiesReviewListView';

export const PropertiesReview = () => {
  const { t } = useLocalization();
  const { propertyValidationRecordsActions } = useAddOwnerDistributions();

  createEffect(() => {
    propertyValidationRecordsActions.refetch();
  });

  return (
    <div class="px-[96px]">
      <p class="mb-2.5 text-xs font-thin normal-case text-text-level02">
        {t('All properties require assigned owners set in their respective accounts.')}
      </p>
      <PropertiesReviewListView />
    </div>
  );
};
