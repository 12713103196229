import { useParams } from '@solidjs/router';
import { FileListView } from '~/components/file-attachments';
import { useLocalization } from '~/contexts/global';
import { AttachmentsProvider, useLease } from '~/contexts/local';
import type { Component } from 'solid-js';

export const LeaseFiles: Component = () => {
  const params = useParams();
  const { lease } = useLease();
  const { t } = useLocalization();

  return (
    <div class="text-left">
      <AttachmentsProvider>
        <FileListView entryType={'lease' as MagicDoor.Api.EntityType} entryId={lease()?.id ?? params.leaseId} />
      </AttachmentsProvider>
    </div>
  );
};
