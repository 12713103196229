import { createSignal, createEffect } from 'solid-js';
import ModalBgIcon from '~/assets/images/leases/transactions/modalBgIcon.svg';
import { Form, useForm } from '~/components/common/BetterForm';
import { FormItem } from '~/components/common/BetterForm/Form';
import LabeledNumberInput from '~/components/common/Inputs/LabeledNumberInput';
import { LeaseStatus, LeaseTitle } from '~/components/leases';
import { Modal } from '~/components/modals/Modal';
import { toast } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useLease, useLeaseDeposits } from '~/contexts/local';
import { formConfirmationContent } from '~/utils/constant';
import { currency } from '~/utils/number';
import type { Component } from 'solid-js';
import type { ModalProps } from '~/components/modals/Modal';

type LeaseReleaseDepositModalProps = Omit<ModalProps, 'title'> & {
  currentLease: MagicDoor.Api.HydratedLeaseDto;
};

export const LeaseReleaseDepositModal: Component<LeaseReleaseDepositModalProps> = (props) => {
  const { t } = useLocalization();
  const { releaseDeposit } = useLeaseDeposits();
  const { lease, getLease } = useLease();

  const form = useForm();

  const [loading, setLoading] = createSignal<boolean>(false);
  const [showCloseConfirmation, setShowCloseConfirmation] = createSignal<boolean>(false);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const { validateStatus } = form.validateForm();
      if (!validateStatus) {
        setLoading(false);
        return;
      }
      await releaseDeposit(props.currentLease.id, { amount: form.formStore.amount });
      props.onCancel && props.onCancel();
      getLease(lease()?.id);
      toast.success(t('Deposit has been released successfully'));
    } catch (error) {
      toast.error(t('Failed to release deposit') + `: ${error}`);
    } finally {
      setLoading(false);
    }
  };

  createEffect(() => {
    setShowCloseConfirmation(true);
  });

  createEffect(() => {
    form.validateForm();
  });

  return (
    <Modal
      {...props}
      title={t('Release deposit')}
      doneText={t('Release')}
      loading={loading()}
      onDone={handleSubmit}
      confirmation={showCloseConfirmation() ? formConfirmationContent(t) : false}>
      <Form defaultForm={form} initialValues={{ amount: lease()?.securityDepositPaid || 0 }}>
        <div class="thinscroll flex flex-col gap-5 overflow-auto px-8 pb-16 pt-6">
          <div class="flex h-16 w-full items-center justify-between rounded-lg bg-slate-100 p-2">
            <LeaseTitle feedbackIconReadonly showTenants lease={props.currentLease} />
            <LeaseStatus lease={props.currentLease} />
          </div>
          <div class="grid grid-cols-1 gap-y-4 pb-4">
            <div>
              <div class="flex gap-1 rounded-lg bg-slate-100 p-2 text-sm text-text-level02">
                <span>{t('Paid security deposit')}:</span>
                <span class="font-semibold text-essential-colour">{currency(lease()?.securityDepositPaid || 0)}</span>
              </div>
            </div>
            <FormItem
              component={LabeledNumberInput}
              formFieldName="amount"
              label={t('Amount')}
              prepend="$"
              rules={[
                { required: true, message: t('{name} is required', { name: t('Amount') }) },
                { validator: (value) => value > 0, message: t('Amount must be greater than 0') },
                {
                  validator: (value) => value <= (lease()?.securityDepositPaid ?? 0),
                  message: t('Amount cannot exceed the paid security deposit'),
                },
              ]}
              onChangeMethodName="onInput"
              valuePropName="value"
            />
          </div>
        </div>
      </Form>
      <div class="relative flex w-full items-center justify-end bg-pink-100 py-5">
        <img src={ModalBgIcon} class="absolute bottom-0 left-4 hidden h-20 w-52 sm:block" />
        <div class="mr-4 flex items-center">
          <span class="mr-2 text-xs uppercase lg:text-sm">{t('Total amount')}:</span>
          <span class="text-sm font-bold text-essential-colour lg:text-2xl">{currency(form.formStore.amount || 0)}</span>
        </div>
      </div>
    </Modal>
  );
};
