import { useNavigate } from '@solidjs/router';
import { createSignal, Show, Switch, Match } from 'solid-js';
import DisplayAddress from '~/components/common/DisplayAddress';
import IconField from '~/components/common/IconField';
import { SendEmail } from '~/components/common/SendEmail';
import { LeaseTitle } from '~/components/leases';
import { MaintenanceWorkOrderSummary } from '~/components/maintenance-requests/MaintenanceWorkOrderSummary';
import { OwnerDropdownSummary } from '~/components/owner/OwnerDropdownSummary';
import { PropertyTitle } from '~/components/properties';
import { IconClock, IconHash, IconMapPin } from '~/components/ui';
import { UnitTitle } from '~/components/units';
import { VendorSummary } from '~/components/vendors/VendorSummary';
import { useLocalization } from '~/contexts/global';
import { BindingMaintenanceRequestModal } from '~/pages/work-orders/list-work-orders/components/BindingMaintenanceRequestModal';
import { BindingVendorModal } from '~/pages/work-orders/list-work-orders/components/BindingVendorModal';
import { cn } from '~/utils/classnames';
import { emptyPlaceholder } from '~/utils/constant';
import { dateFormat } from '~/utils/date';
import { currency } from '~/utils/number';
import { WorkOrderQuickActions } from './WorkOrderQuickActions';
import type { Component } from 'solid-js';

const WorkOrderDetail: Component<{
  class?: string;
  loading?: boolean;
  workOrder?: MagicDoor.Api.HydratedWorkOrderDto;
}> = (props) => {
  const { t } = useLocalization();
  const navigate = useNavigate();

  const goPropertyOrUnitOrLease = () => {
    if (props.workOrder?.leaseId) {
      navigate(`/leasing/leases/${props.workOrder.leaseId}`);
    } else if (props.workOrder?.unitId && props.workOrder?.portfolioId && props.workOrder?.propertyId) {
      navigate(`/portfolios/${props.workOrder.portfolioId}/properties/${props.workOrder.propertyId}/units/${props.workOrder.unitId}`);
    } else if (props.workOrder?.portfolioId && props.workOrder?.propertyId) {
      navigate(`/portfolios/${props.workOrder.portfolioId}/properties/${props.workOrder.propertyId}`);
    }
  };

  const renderRelationField = () => (
    <>
      <Switch
        fallback={<IconField name={t('Property')} loading={props.loading} value={emptyPlaceholder} src={IconMapPin} class="w-full" />}>
        <Match when={props.workOrder?.lease}>
          <IconField
            name={t('Lease')}
            loading={props.loading}
            value={
              <div class="w-full overflow-hidden rounded-lg bg-input p-2">
                <LeaseTitle
                  class="truncate"
                  property={props.workOrder?.property}
                  feedbackIconReadonly
                  unit={props.workOrder?.unit ?? undefined}
                  lease={props.workOrder?.lease ?? undefined}
                  onClick={goPropertyOrUnitOrLease}
                />
              </div>
            }
          />
        </Match>
        <Match when={props.workOrder?.unit}>
          <IconField
            name={t('Unit')}
            loading={props.loading}
            class="w-full"
            value={
              <div class="w-full overflow-hidden rounded-lg bg-input p-2" onClick={goPropertyOrUnitOrLease}>
                <UnitTitle
                  feedbackIconReadonly
                  class="truncate"
                  property={props.workOrder?.property}
                  unit={props.workOrder?.unit as MagicDoor.Api.HydratedUnitDto}
                />
              </div>
            }
          />
        </Match>
        <Match when={props.workOrder?.property}>
          <IconField
            name={t('Property')}
            loading={props.loading}
            class="w-full"
            value={
              <div class="w-full overflow-hidden rounded-lg bg-input p-2">
                <PropertyTitle
                  feedbackIconReadonly
                  class="truncate"
                  property={props.workOrder?.property}
                  onClick={goPropertyOrUnitOrLease}
                />
              </div>
            }
          />
        </Match>
      </Switch>
    </>
  );

  return (
    <div class="divide-y divide-partingline overflow-hidden rounded-lg border border-partingline bg-white">
      <div class="px-5 py-4">
        <div class="flex items-center gap-1 text-lg font-semibold text-title-gray">{t('Detail')}</div>
      </div>
      <div class="flex w-full flex-row flex-wrap gap-5 p-4 lg:flex-col">
        <div class="my-4 w-full">{renderRelationField()}</div>
        <IconField
          name={t('Address')}
          loading={props.loading}
          value={
            <div onClick={goPropertyOrUnitOrLease} class="cursor-pointer">
              <DisplayAddress address={props.workOrder?.property?.address} />
            </div>
          }
          src={IconMapPin}
        />
        <IconField name={t('Request number')} loading={props.loading} value={props.workOrder?.id || emptyPlaceholder} src={IconHash} />
        <IconField
          name={t('Created at')}
          loading={props.loading}
          value={dateFormat(t('MM/DD/YYYY hh:mm A'), props.workOrder?.created)}
          src={IconClock}
        />
        <Show when={props.workOrder?.leaseId}>
          <SendEmail class="w-full justify-center" id={props.workOrder?.leaseId} type="lease" text={t('Send lease email')} showModal />
        </Show>
      </div>
    </div>
  );
};

export const WorkOrderSummary: Component<{
  class?: string;
  loading?: boolean;
  workOrder?: MagicDoor.Api.HydratedWorkOrderDto;
}> = (props) => {
  const { t } = useLocalization();
  const [maintenanceRequestVisible, setMaintenanceRequestVisible] = createSignal<boolean>(false);
  const [vendorVisible, setVendorVisible] = createSignal<boolean>(false);

  return (
    <div class={cn('flex flex-col gap-4 text-sm', props.class)}>
      <WorkOrderDetail loading={props.loading} workOrder={props.workOrder} />
      <WorkOrderQuickActions
        workOrder={props.workOrder}
        onBindMaintenanceRequest={() => setMaintenanceRequestVisible(true)}
        onBindVendor={() => setVendorVisible(true)}
      />
      <Show when={props.workOrder?.maintenanceRequest}>
        <MaintenanceWorkOrderSummary
          loading={props.loading}
          currentPage="workOrder"
          maintenanceRequest={props.workOrder?.maintenanceRequest ?? undefined}
        />
      </Show>
      <VendorSummary loading={props.loading} vendor={props.workOrder?.vendor ?? undefined} />
      <OwnerDropdownSummary
        loading={props.loading}
        owners={props.workOrder?.owners}
        bottomContent={
          <Show when={props.workOrder?.owners && props.workOrder.owners.length > 0}>
            <div class="flex w-full px-3 pb-3 text-sm normal-case ">
              <div class="flex w-full items-center justify-center gap-1.5 rounded-lg border-input-border bg-input py-3">
                <span class="text-text-level03">{t('Owner approval limit')}:</span>
                <span class="font-semibold text-text-level02">{currency(props.workOrder?.ownerApprovalLimit || undefined)}</span>
              </div>
            </div>
          </Show>
        }
      />
      <BindingMaintenanceRequestModal
        workOrderId={props.workOrder?.id}
        visible={[maintenanceRequestVisible, setMaintenanceRequestVisible]}
      />
      <BindingVendorModal workOrderId={props.workOrder?.id} visible={[vendorVisible, setVendorVisible]} />
    </div>
  );
};
