import { useParams } from '@solidjs/router';
import { createMemo, createSignal, Show, Switch, Match, createEffect, For } from 'solid-js';
import { ChatWindow } from '~/components/common/ChatWindow';
import { Panel } from '~/components/common/Panels';
import Tab from '~/components/layouts/Tabs';
import { useLocalization, useWorkOrders } from '~/contexts/global';
import type { TabItem } from '~/components/layouts/Tabs';
export const WorkOrderCommunicationsTab = () => {
  const { t } = useLocalization();
  const { workOrder } = useWorkOrders();
  const params = useParams();
  const showChat = createMemo(() => workOrder()?.vendorId);
  const showGroupChat = createMemo(() => workOrder()?.vendorId && workOrder()?.maintenanceRequestId);
  const showMaintenanceChat = createMemo(() => workOrder()?.maintenanceRequestId);

  const [activeTab, setActiveTab] = createSignal<string>('workOrderChat');
  const [tabs, setTabs] = createSignal<TabItem[]>([]);

  createEffect(() => {
    const tempTabs: TabItem[] = [
      {
        key: 'workOrderChat',
        title: t('Work order chat'),
      },
      {
        key: 'maintenanceChat',
        title: t('Maintenance chat'),
      },
      {
        key: 'groupChat',
        title: t('Group chat'),
      },
    ];
    workOrder()?.owners.forEach((owner) => {
      tempTabs.push({
        key: owner.id,
        title: owner.firstName ? owner.firstName + (owner.lastName ? ' ' + owner.lastName : '') : '',
      });
    });
    setTabs(tempTabs);
  });

  return (
    <div>
      <Tab tabs={tabs()} activeTab={activeTab()} onTabChange={setActiveTab} class="rounded-b-none" noTranslate />
      <Panel
        header={
          <div class="border-b px-5 py-2 normal-case text-warning">
            <Switch>
              <Match when={activeTab() === 'workOrderChat'}>
                {t('This is a chat between you and the vendor assigned to this work order')}
              </Match>
              <Match when={activeTab() === 'maintenanceChat'}>
                {t('This is a chat between you and the tenants that submitted the maintenance request')}
              </Match>
              <Match when={activeTab() === 'groupChat'}>
                {t('This is a chat between you, the vendor assigned and the tenants that submitted the maintenance request')}
              </Match>
              <Match when={!['workOrderChat', 'maintenanceChat', 'groupChat'].includes(activeTab())}>
                {t('This is a chat between you and the owners')}
              </Match>
            </Switch>
          </div>
        }
        class="flex flex-col rounded-t-none">
        <Switch>
          <Match when={activeTab() === 'workOrderChat'}>
            <Show
              when={showChat()}
              fallback={
                <div class="my-32 flex w-full justify-center normal-case text-warning">{t('Please assigned a vendor to chat')}</div>
              }>
              <ChatWindow
                participantId={workOrder()?.id || params.workOrderId}
                chatId={workOrder()?.chatId}
                participantType="workOrder"
                class="h-section overflow-hidden rounded-lg border-none"
              />
            </Show>
          </Match>
          <Match when={activeTab() === 'maintenanceChat'}>
            <Show
              when={showMaintenanceChat()}
              fallback={
                <div class="my-32 flex w-full justify-center text-warning">{t('Please assigned a maintenance request to chat')}</div>
              }>
              <ChatWindow
                participantId={workOrder()?.maintenanceRequestId}
                chatId={workOrder()?.maintenanceRequest?.chatId}
                participantType="maintenanceRequest"
                class="h-section2 overflow-hidden rounded-lg border-none"
              />
            </Show>
          </Match>
          <Match when={activeTab() === 'groupChat'}>
            <Show
              when={showGroupChat()}
              fallback={
                <div class="my-32 flex w-full justify-center text-warning">
                  {t('Please add a maintenance request and vendor to access the group chat feature.')}
                </div>
              }>
              <ChatWindow
                participantId={workOrder()?.id || params.workOrderId}
                chatId={workOrder()?.groupChatId}
                participantType="workOrderGroup"
                class="h-section overflow-hidden rounded-lg border-none"
              />
            </Show>
          </Match>
          <For each={workOrder()?.owners}>
            {(item) => (
              <Match when={item.id === activeTab()}>
                <ChatWindow participantId={item.id} chatId={item.chatId} participantType="owner" class="h-section rounded-lg border-none" />
              </Match>
            )}
          </For>
        </Switch>
      </Panel>
    </div>
  );
};
