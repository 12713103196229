import { Show, createSignal } from 'solid-js';
import IconHide from '~/assets/images/common/hide.svg';
import IconShow from '~/assets/images/common/show.svg';

interface NumberToggleProps {
  fullNumber: string;
  maskedNumber: string;
}

export function NumberToggle(props: NumberToggleProps) {
  const [isHidden, setIsHidden] = createSignal<boolean>(true);

  return (
    <div class="flex items-center space-x-2">
      <span class="font-mono">{isHidden() ? props.maskedNumber : props.fullNumber}</span>
      <button class="px-1" onClick={() => setIsHidden(!isHidden())}>
        <Show when={isHidden()} fallback={<img src={IconShow} />}>
          <img src={IconHide} />
        </Show>
      </button>
    </div>
  );
}
