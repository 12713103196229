import { createMemo } from 'solid-js';
import { Avatar } from '~/components/common/Avatar';
import { getTenantFullName, getTenantShortName } from '~/components/tenants/utils';
import { useLocalization } from '~/contexts/global';
import { cn } from '~/utils/classnames';
import { dateFormat } from '~/utils/date';
import type { Component, ComponentProps } from 'solid-js';

export const ShortCut: Component<{ tenant: MagicDoor.Api.HydratedTenantDto }> = (props) => (
  <div class="flex items-center">
    <Avatar name={getTenantShortName(props.tenant)} />
    <div class="ml-2 overflow-hidden text-ellipsis text-sm font-semibold">{getTenantFullName(props.tenant)}</div>
  </div>
);

export const TenantItem: Component<{ tenant?: MagicDoor.Api.HydratedTenantDto } & ComponentProps<'div'>> = (props) => {
  const { t } = useLocalization();
  const extraInfo = createMemo(() => {
    const currentLease = props.tenant?.currentLeases?.[0];
    if (currentLease) {
      return (
        <div class="flex flex-col items-end text-xs">
          <div class="mt-1 flex items-center gap-1 text-green">
            <div class="size-1 rounded-full bg-green" />
            {dateFormat(undefined, currentLease.start)} - {dateFormat(undefined, currentLease.end)}
          </div>
        </div>
      );
    }
    return (
      <div class="text-xs text-text-level03">
        {t('Created on')}: {dateFormat(t('MM/DD/YYYY'), props.tenant?.created)}
      </div>
    );
  });

  return (
    <div class={cn(props.class, 'flex cursor-pointer items-center justify-between gap-4 border-b border-b-stone-200 last:border-none')}>
      <ShortCut tenant={props.tenant as MagicDoor.Api.HydratedTenantDto} />
      {extraInfo()}
    </div>
  );
};
