import { useNavigate } from '@solidjs/router';
import { createEffect } from 'solid-js';
import { eventEmitter } from '~/framework/eventEmitter';

const Navigator = () => {
  const navigate = useNavigate();

  const handleNavigation = (url: string) => {
    if (url) {
      navigate(url);
    }
  };

  createEffect(() => {
    eventEmitter.registerForNavigation(handleNavigation);
    return () => {
      eventEmitter.unregisterFromNavigation(handleNavigation);
    };
  });
  return null;
};

export default Navigator;
