import { createEffect } from 'solid-js';
import { createReconciledStore } from './ReconciledStore';
import { eventEmitter } from './eventEmitter';
import type { AppState } from './appState';

interface ModelType {
  model: any;
}

export abstract class Presenter<T extends object> {
  private presentableModel?: any;
  private setPresentableModel: (model: any) => void;

  constructor() {
    const [presentableModel, setPresentableModel] = createReconciledStore<ModelType>({ model: undefined });
    this.presentableModel = presentableModel;
    this.setPresentableModel = setPresentableModel;

    const stateChangeHandler = (newState: AppState<any>) => {
      const newModel: ModelType = { model: this.createModel(newState.getState()) };
      this.setPresentableModel(newModel);
    };

    createEffect(() => {
      eventEmitter.registerForStateChange(stateChangeHandler);
      return () => {
        eventEmitter.unregisterFromStateChange(stateChangeHandler);
      };
    });
  }

  protected abstract createModel(state: any): T | undefined;

  public getPresentableModel(): T | undefined {
    return this.presentableModel?.model;
  }
}
