import { For } from 'solid-js';
import { Checkbox } from '~/components/common/Inputs/Checkbox';
import { useLocalization } from '~/contexts/global';
import { cloneDeep } from '~/utils/tool';
import { LabeledGroup } from './LabeledGroup';
import type { LabeledGroupProps } from './LabeledGroup';
import type { Component } from 'solid-js';

type Option = {
  label: string;
  value: boolean;
};
export interface CheckboxProps extends LabeledGroupProps {
  options: Option[];
  value?: string[];
  onInput?: (e: string[]) => void;
}
export const LabeledCheckbox: Component<CheckboxProps> = (props) => {
  const { t } = useLocalization();

  const handleCheckboxInput = (checked: boolean | string[], label: string) => {
    let newCheckList = props.value ? cloneDeep(props.value) : [];
    newCheckList = checked ? [...newCheckList, label] : newCheckList.filter((v) => v !== label);
    props?.onInput && props.onInput(newCheckList);
  };
  return (
    <LabeledGroup {...props}>
      <div class="grid grid-cols-2">
        <For each={props.options}>
          {(option) => (
            <Checkbox
              showLabel
              htmlType="checkbox"
              class="hover:before:opacity-0"
              checkBoxClass="py-2"
              label={t(option.label)}
              checked={props.value?.length ? props.value?.includes(option?.label) : false}
              onInput={(checked) => {
                handleCheckboxInput(checked, option.label);
              }}
            />
          )}
        </For>
      </div>
    </LabeledGroup>
  );
};
