import { createResource, createSignal } from 'solid-js';
import { createMagicDoorContext } from '~/contexts/utils';
import { VendorRepository } from '~/repositories/vendorRepository';
import { createMutation, createTriggerResource } from '~/utils/resource';
import type { VendorFilter } from '~/repositories/vendorRepository';

const repo = new VendorRepository();

export const [VendorsProvider, useVendors] = createMagicDoorContext('Vendors', () => {
  const [filter, setFilter] = createSignal<VendorFilter | undefined>(undefined);
  const [filtered, filteredActions] = createResource(filter, (filter) => repo.getVendors(filter));

  const [currentId, setCurrentId] = createSignal<string | undefined>(undefined);
  const [current, currentActions] = createResource(currentId, (id) => repo.getVendor(id));
  const [vendorOverview, setVendorOverview] = createSignal<MagicDoor.Api.VendorOverviewDto | undefined>(undefined);

  const [paymentAccounts, getPaymentAccounts, paymentAccountsActions] = createTriggerResource((vendorId: string) =>
    repo.getVendorPaymentAccounts(vendorId)
  );
  const getVendorOverview = createMutation(async (vendorId: string) => {
    const overview = await repo.getVendorOverview(vendorId, true);
    setVendorOverview(overview);
    return overview;
  });

  const addVendor = createMutation(async (model: MagicDoor.Api.CreateOrUpdateVendorDto) => {
    const created = await repo.createVendor(model);
    currentActions.mutate(created);
    filteredActions.refetch();
    return created;
  });

  const updateVendor = createMutation(async (vendorId: string, model: MagicDoor.Api.CreateOrUpdateVendorDto) => {
    const updated = await repo.updateVendor(vendorId, model);
    if (vendorId === currentId()) currentActions.mutate(updated);
    currentActions.refetch();
    return updated;
  });

  const deleteVendor = createMutation(async (vendorId: string) => {
    await repo.deleteVendor(vendorId);
    if (vendorId === currentId()) currentActions.mutate(undefined);
    filteredActions.refetch();
  });

  const addVendorPaymentAccount = createMutation(async (vendorId: string, payload: MagicDoor.Api.CreateVendorPaymentAccountDto) => {
    const created = await repo.addVendorPaymentAccount(vendorId, payload);
    return created;
  });

  const deleteVendorPaymentAccount = createMutation(async (vendorId: string, paymentAccountId: string) => {
    await repo.deleteVendorPaymentAccount(vendorId, paymentAccountId);
    paymentAccountsActions.refetch();
  });

  return {
    filtered,
    setFilter: (filter: VendorFilter) => setFilter(filter),
    current,
    setCurrentId,
    vendorOverview,
    getVendorOverview,
    addVendor,
    updateVendor,
    deleteVendor,
    paymentAccounts,
    getPaymentAccounts,
    paymentAccountsActions,
    addVendorPaymentAccount,
    deleteVendorPaymentAccount,
  };
});
