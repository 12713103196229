import { useParams } from '@solidjs/router';
import { Show, createSignal, createEffect, For, createMemo } from 'solid-js';
import IconPencil from '~/assets/images/units/lease/editLease.svg?component-solid';
import { LinkButton } from '~/components/common/Buttons';
import CnMoney from '~/components/common/CnMoney';
import { LeaseItemWithTenants } from '~/components/leases';
import { IconBan, IconCirclePlus, ListView, TableActions } from '~/components/ui';
import { UnitTitle } from '~/components/units';
import { useLocalization, useUnits } from '~/contexts/global';
import { useLocalPagination } from '~/hooks';
import { DeactivateUnit } from '~/pages/units/components/DeactivateUnit';
import { emptyPlaceholder } from '~/utils/constant';
import { currency } from '~/utils/number';

export const UnitsListView = () => {
  const { t } = useLocalization();
  const { units, getUnits } = useUnits();
  const params = useParams<{ propertyId: string; portfolioId: string }>();
  const { localPageSize, setPage, setPageSize } = useLocalPagination('UnitsListView');
  const [deactivating, setDeactivating] = createSignal<MagicDoor.Api.HydratedUnitDto>();

  const depositContent = (lease: MagicDoor.Api.HydratedLeaseDto | MagicDoor.Api.LeaseDto) => {
    const paid = createMemo(() => currency(lease.securityDepositPaid));
    const requested = createMemo(() => currency(lease.securityDepositRequested));

    return (
      <>
        <Show when={paid() !== requested()} fallback={<span class="text-sm font-medium text-text-level01">{currency(requested())}</span>}>
          <span class="text-sm font-medium text-text-level01">{paid()}</span>
          <span class="text-xs font-light text-text-level04">/{requested()}</span>
        </Show>
      </>
    );
  };

  createEffect(() => getUnits({ pageSize: localPageSize, propertyId: params.propertyId, portfolioId: params.portfolioId }));

  return (
    <>
      <ListView
        class="mx-8"
        title={t('Units')}
        data={units()?.items}
        page={units()?.currentPage}
        pageSize={units()?.pageSize}
        totalPages={units()?.totalPages}
        viewAllSuffix="leases"
        loading={units.loading}
        searchPlaceholder={t('Search units')}
        getTotalRowCount={(data) => {
          return data?.activeLeases?.length ?? 0;
        }}
        onChange={({ page, pageSize, search }) => {
          setPage(page);
          setPageSize(pageSize);
          getUnits({ ...params, page, pageSize, search });
        }}
        rowLink={(item) => item.id}
        titleActions={
          <LinkButton href="add" noScroll>
            <IconCirclePlus class="size-5" />
            <span class="hidden lg:block">{t('Add unit')}</span>
          </LinkButton>
        }
        columns={[
          {
            title: t('Units'),
            render: (unit: MagicDoor.Api.HydratedUnitDto) => <UnitTitle class="min-w-40" unit={unit} />,
          },
          {
            title: t('Lease'),
            key: 'activeLeases',
            render: (_, leases) => (
              <div class="my-2 flex flex-col divide-y">
                <Show when={leases?.length} fallback={<div class="text-xs text-danger">{t('Not leased')} </div>}>
                  <For each={leases}>
                    {(lease) => (
                      <div class="py-2">
                        <LeaseItemWithTenants lease={lease} />
                      </div>
                    )}
                  </For>
                </Show>
              </div>
            ),
          },
          {
            title: t('Rent'),
            headerClass: 'text-right',
            class: 'text-right',
            key: 'activeLeases',
            render: (_, leases) => (
              <div class="flex flex-col gap-6">
                <Show when={leases.length} fallback={emptyPlaceholder}>
                  <For each={leases}>{(lease) => <span class="text-sm">{currency(lease.currentRent)}</span>}</For>
                </Show>
              </div>
            ),
          },
          {
            title: t('Deposit'),
            headerClass: 'text-right',
            class: 'text-right',
            key: 'activeLeases',
            render: (_, leases) => (
              <div class="flex flex-col gap-6">
                <Show when={leases.length} fallback={emptyPlaceholder}>
                  <For each={leases}>{(lease) => <span class="text-sm">{depositContent(lease)}</span>}</For>
                </Show>
              </div>
            ),
          },
          {
            title: t('Balances'),
            class: 'text-right',
            headerClass: 'text-right',
            key: 'activeLeases',
            render: (_, leases) => (
              <div class="flex flex-col gap-6">
                <Show when={leases.length} fallback={emptyPlaceholder}>
                  <For each={leases}>{(lease) => <CnMoney money={lease.balance} showIcon />}</For>
                </Show>
              </div>
            ),
          },
          {
            title: t('Posted balances'),
            class: 'text-right',
            headerClass: 'text-right',
            key: 'activeLeases',
            render: (_, leases) => (
              <div class="flex flex-col gap-6">
                <Show when={leases.length} fallback={emptyPlaceholder}>
                  <For each={leases}>{(lease) => <CnMoney money={lease.postedBalance} showIcon />}</For>
                </Show>
              </div>
            ),
          },
          {
            title: t('Operation'),
            headerClass: 'text-right',
            class: 'relative',
            render: (unit) => (
              <div class="flex justify-end">
                <TableActions
                  actions={[
                    {
                      label: t('Edit'),
                      icon: IconPencil,
                      href: `edit/${unit.id}`,
                      noScroll: true,
                    },
                    {
                      label: t('Deactivate'),
                      icon: IconBan,
                      onClick: () => setDeactivating(unit),
                    },
                    {
                      label: t('Add service bill'),
                      icon: IconCirclePlus,
                      href: `addServiceBill/${unit.id}`,
                      noScroll: true,
                    },
                  ]}
                />
              </div>
            ),
          },
        ]}
      />
      <DeactivateUnit unit={deactivating()} onClose={setDeactivating} />
    </>
  );
};
