import { useBeforeLeave } from '@solidjs/router';
import { createSignal, Show } from 'solid-js';
import { Button } from '~/components/common/Buttons';
import { IconAlert, IconPencilLine, IconSave } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { cn } from '~/utils/classnames';
import type { Component } from 'solid-js';

export const SaveBtn: Component<{
  class?: string;
  dirty?: boolean;
  saveText?: string;
  onSave: () => Promise<void> | void;
  onCancel: () => void;
}> = (props) => {
  const { t } = useLocalization();

  const [saving, setSaving] = createSignal<boolean>(false);
  const [saved, setSaved] = createSignal<boolean>(false);
  const [confirm, setConfirm] = createSignal<boolean>(false);

  useBeforeLeave((e) => {
    if (saved()) return;
    if (!props.dirty) return;
    e.preventDefault();
    setConfirm(true);
  });

  const handleSave = async () => {
    setSaving(true);
    try {
      await props.onSave();
      setSaved(true);
    } finally {
      setSaving(false);
    }
  };

  const handleCancel = () => {
    if (confirm()) {
      setConfirm(false);
    } else if (!saved() && props.dirty) {
      setConfirm(true);
      return;
    }
    props.onCancel();
  };

  return (
    <div
      class={cn(
        'flex items-center gap-2 border-y px-6 py-4 transition-colors ease-[cubic-bezier(0,0,0.9,2)]',
        confirm() && 'bg-[#eec9ff]',
        props.class
      )}>
      <Show when={props.dirty && !confirm()}>
        <IconPencilLine class="size-5 text-text-level02" />
        <p class="text-sm font-semibold text-title-gray">{t('The settings has been updated, please save it in time')}</p>
      </Show>
      <Show when={confirm()}>
        <IconAlert class="size-5 text-warning" />
        <p class="text-sm font-semibold text-warning">{t('Note that there are no updates yet saved.')}</p>
      </Show>
      <Button variant="outlined" class="ml-auto mr-2" onClick={handleCancel}>
        {t('Cancel')}
      </Button>
      <Button onClick={handleSave} loading={saving()} disabled={!props.dirty}>
        <IconSave class="size-5" />
        {props.saveText ?? t('Save')}
      </Button>
    </div>
  );
};
