import { ListView } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import type { KeyValuePair } from './EditMagicTagsModal';
import type { Component } from 'solid-js';

export const MagicTagsList: Component<{
  defaultData?: KeyValuePair;
  class?: string;
}> = (props) => {
  const { t } = useLocalization();

  const Cell = (value: string) => {
    return <div class="break-normal text-xs capitalize text-text-level01">{value}</div>;
  };

  return (
    <ListView
      class={`w-full rounded ${props.class ?? ''}`}
      skeletonSize={10}
      hideFooter
      data={Object.entries(props?.defaultData ?? {}).map(([key, value]) => ({ key, value }))}
      columns={[
        {
          title: t('Magic tags'),
          render: (row) => Cell(row.key),
        },
        {
          title: t('Value'),
          render: (row) => Cell(row.value),
        },
      ]}
    />
  );
};
