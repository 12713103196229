import { leaseDraftRepository } from '~/pdfsigner/repositories/leaseDraftRepository';
import { MagicUseCase } from '~/pdfsigner/usecases/baseUseCases/magicUseCase';
import { ValidateDocumentUseCase } from './validateDocumentUseCase';

export class SaveLeaseDraftUseCase extends MagicUseCase {
  public async runLogic() {
    await new ValidateDocumentUseCase().execute();
    const selectedDocument = this.getState().selectedDocument;
    if (selectedDocument) {
      await leaseDraftRepository.saveDraft(selectedDocument);
    }
  }
}
