import { useNavigate, useSearchParams } from '@solidjs/router';
import { createMemo, createEffect, Show } from 'solid-js';
import PrintIcon from '~/assets/images/common/print.svg?component-solid';
import Breadcrumb from '~/components/common/Breadcrumb';
import { Button } from '~/components/common/Buttons';
import DisplayAddress from '~/components/common/DisplayAddress';
import { leaseStatusString } from '~/components/leases/utils';
import { formatTenantNames } from '~/components/tenants';
import { IconDownload, IconLoader } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import usePresenter from '~/framework/hooks/usePresenter';
import useUseCase from '~/framework/hooks/useUseCase';
import { useReportPrinter } from '~/pdfsigner/hooks/useReportPrinter';
import { RentRollPresenter } from '~/pdfsigner/presenters/reports/RentRollPresenter';
import { GetRentRollReportUseCase } from '~/pdfsigner/usecases/reports/getRentRollReportUseCase';
import { RentStatus } from '~/swagger/Api';
import { emptyPlaceholder } from '~/utils/constant';
import { PropertySelect } from './components/PropertySelect';
import { parseColumns, ReportTable } from './components/Report';
import type { LineItem } from './components/Report';
import type { Component } from 'solid-js';
import type { RentRollReportFilter } from '~/repositories/reportRepository';
import type { RentRollReportGroupDto } from '~/swagger/Api';

const RentRollReport: Component<{ class?: string; report?: MagicDoor.Api.RentRollReportDto }> = (props) => {
  const { t } = useLocalization();

  const columns = () =>
    parseColumns([
      {
        name: 'BD/BA',
        type: 'string',
        id: 'bedroomsAndBathrooms',
      },
      {
        name: 'Tenant',
        type: 'string',
        id: 'tenantName',
      },
      {
        name: 'Status',
        type: 'string',
        id: 'status',
      },
      {
        name: 'Sqft',
        type: 'number',
        id: 'squareFeet',
      },
      { name: 'Rent', id: 'rent' },
      { name: 'Deposit', type: 'string', id: 'deposit' },
      { name: 'Lease from', type: 'date', id: 'leaseFrom' },
      { name: 'Move in', type: 'date', id: 'moveIn' },
    ]);

  const getOccupancyRate = (group: RentRollReportGroupDto): string => {
    let occupancy = emptyPlaceholder;
    let rentedUnits = 0;
    group.items?.forEach((item) => {
      if (item.status === RentStatus.Rented) {
        rentedUnits++;
      }
    });
    if (group.items && group.property?.unitCount) {
      occupancy = `${Math.round((rentedUnits / group.property?.unitCount) * 100)}%`;
    }
    return occupancy;
  };

  const lines = createMemo(() => {
    if (!props.report || !props.report.groups) return [];
    const res = props.report.groups?.flatMap((group) => {
      const leases = group.items ?? [];
      let totalRent = 0;
      let totalDeposit = 0;
      let totalSquareFeet = 0;
      const occupiedPercentage = getOccupancyRate(group);
      return [
        {
          type: 'item',
          level: 0,
          label: <DisplayAddress address={group.property?.address} />,
        },
        ...leases.flatMap((lease) => {
          totalRent += lease.lease?.currentRent ?? 0;
          totalDeposit += lease.lease?.securityDepositPaid ?? 0;
          totalSquareFeet += lease.unit?.unitSizeSqft ?? 0;
          return [
            {
              type: 'item',
              label: lease.unit?.name,
              level: 0,
              values: {
                bedroomsAndBathrooms: `${lease.unit?.beds ?? emptyPlaceholder} / ${lease.unit?.baths ?? emptyPlaceholder}`,
                tenantName: formatTenantNames(lease.tenants?.map((item) => ({ tenant: item }))),
                status: leaseStatusString(lease.lease?.status),
                squareFeet: lease.unit?.unitSizeSqft,
                rent: lease.lease?.currentRent,
                deposit: lease.lease?.securityDepositPaid,
                leaseFrom: lease.lease?.start,
                moveIn: lease.lease?.tenants?.[0].moveIn,
              },
            },
          ];
        }),
        {
          type: 'total',
          level: 0,
          label: t('Total {count} units', { count: group.property?.unitCount + '' }),
          values: {
            status: t(`{percentage} occupied`, { percentage: occupiedPercentage }),
            squareFeet: totalSquareFeet,
            rent: totalRent,
            deposit: totalDeposit,
          },
        },
      ];
    }) as LineItem[];
    return res;
  });
  return <ReportTable class="[&_[data-level='1']]:!font-normal" label={t(`Unit`)} lines={lines() as any} columns={columns() as any} />;
};

export const RentRollReportPage = () => {
  const { t } = useLocalization();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams<
    Omit<RentRollReportFilter, 'portfolioIds' | 'propertyIds'> & { portfolioIds?: string; propertyIds?: string }
  >();

  const { execute: getRentRollReport, isLoading: isLoadingRentRoll } = useUseCase(GetRentRollReportUseCase);
  const { model: rentRollReport } = usePresenter(RentRollPresenter);
  const { print, saveAsCSV } = useReportPrinter();

  const breadcrumbItems = createMemo(() => [{ label: t('Reports'), link: '/reports' }, { label: t('Rent roll') }]);

  createEffect(() => {
    const params: any = {};
    if (searchParams.propertyIds) {
      params.propertyIds = searchParams.propertyIds.split(',');
    } else if (searchParams.portfolioIds) {
      params.portfolioIds = searchParams.portfolioIds.split(',');
    }
    getRentRollReport(params);
  });

  return (
    <>
      <Breadcrumb backLink={() => navigate(-1)} items={breadcrumbItems()} />
      <div class="m-8 rounded-lg border border-partingline bg-white p-8">
        <div class="flex items-center justify-between">
          <h1 class="text-3xl font-semibold text-title-gray">{t('Rent roll report')}</h1>
          <div class="flex space-x-2">
            <Button
              onClick={() => print(rentRollReport()?.rentRollReport)}
              disabled={isLoadingRentRoll()}
              color="primary"
              variant="outlined">
              <div class="flex items-center">
                <PrintIcon class="mr-1 size-5" />
                {t('Print')}
              </div>
            </Button>
            <Button
              onClick={() => saveAsCSV(rentRollReport()?.rentRollReport)}
              disabled={isLoadingRentRoll()}
              color="primary"
              variant="outlined">
              <div class="flex items-center">
                <IconDownload class="mr-1 size-5" />
                {t('CSV')}
              </div>
            </Button>
          </div>
        </div>

        <div class="my-6 flex flex-wrap items-center gap-3">
          <PropertySelect />
        </div>

        <Show when={isLoadingRentRoll()} fallback={<RentRollReport report={rentRollReport()?.rentRollData} />}>
          <IconLoader class="mx-auto my-56 animate-spin" />
        </Show>
      </div>
    </>
  );
};
