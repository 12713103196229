import { ReportStatus } from '~/swagger/Api';

export interface PaymentStatusIndicatorProps {
  label: string | undefined;
  color: string;
}
export interface StatusIndicatorProps {
  label: ReportStatus;
  color: string;
}

export interface RentalApplicationProps {
  application?: MagicDoor.Api.RentalApplicationDto;
}

export const paymentStatusIndicatorsArray: Array<PaymentStatusIndicatorProps> = [
  {
    label: 'paid',
    color: '#36CBAC',
  },
  {
    label: 'unpaid',
    color: '#BEC6DB',
  },
  {
    label: 'processing',
    color: '#00C0FF',
  },
  {
    label: 'failed',
    color: '#FF0000',
  },
  {
    label: 'refunded',
    color: '#999999',
  },
];

export const REPORT_STATUS_MAP = {
  [ReportStatus.NotRequested]: 'Not requested',
  [ReportStatus.Requested]: 'Requested',
  [ReportStatus.Available]: 'Available',
} as const;

export const statusIndicatorsArray: Array<StatusIndicatorProps> = [
  {
    label: ReportStatus.Available,
    color: '#36CBAC',
  },
  {
    label: ReportStatus.NotRequested,
    color: '#BEC6DB',
  },
  {
    label: ReportStatus.Requested,
    color: '#3C35F2',
  },
];
