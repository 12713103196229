import { useNavigate } from '@solidjs/router';
import { createEffect, createResource, on } from 'solid-js';
import { createStore } from 'solid-js/store';
import { useFormContext } from '~/components/common/BetterForm/context';
import { useRentalApplicationPayment } from '~/contexts/applications/PaymentContext';
import { useLocalization } from '~/contexts/localization';
import { createMagicDoorContext } from '~/contexts/utils';
import { RentalApplicationRepository } from '~/repositories/rentalApplicationRepository';
import { emptyPlaceholder } from '~/utils/constant';

const applicationRepo = new RentalApplicationRepository();

export const NEW_RENTAL_APPLICATION_REVIEW_STEP = Infinity;

const initStep = 0;

export const [NewRentalApplicationProvider, useNewRentalApplication] = createMagicDoorContext('NewRentalApplication', () => {
  const { t } = useLocalization();
  const navigate = useNavigate();
  const [store, setStore] = createStore<{
    applicationId: string;
    currentStep: number;
    stepChangeLoading: boolean;
    disableNext: boolean;
    //NOTE - 这个字段有啥作用？
    currentTenant: Partial<MagicDoor.Api.HydratedTenantDto>;
    activeTab: string;
    steps: { key: string; label: string }[];
    selectedUnits: MagicDoor.Api.UnitListingDto[];
    selectedApplyWith: MagicDoor.Api.HydratedTenantDto[];
  }>({
    applicationId: '',
    currentStep: initStep,
    stepChangeLoading: false,
    disableNext: false,
    currentTenant: {},
    activeTab: 'new-applicant',
    steps: [
      { key: 'tenant-profile', label: t('Tenant profile') },
      { key: 'rental-history', label: t('Rental history') },
      { key: 'employment', label: t('Employment') },
      { key: 'identity', label: t('Identity') },
      { key: 'questions-and-terms', label: t('Questions & terms') },
    ],
    selectedUnits: [],
    selectedApplyWith: [],
  });
  const [regions] = createResource(() => applicationRepo.getRentalApplicationRegions());
  const { onSubmit } = useRentalApplicationPayment();

  const form = useFormContext();

  createEffect(
    on(
      () => store.activeTab,
      () => {
        form.setFieldsValue({ tenantId: undefined, lastName: '', firstName: '' });
        if (store.activeTab === 'new-applicant') {
          form.resetFields(['tenantId', 'phone', 'email', 'dateOfBirth']);
        }
      }
    )
  );

  async function fetchApplication(applicationId: string) {
    if (!applicationId) return;
    setStore('applicationId', applicationId);
    const res = await applicationRepo.getApplication(applicationId);
    form.setFieldsValue(res);
  }

  function getValueFromStore() {
    const baseData = { ...form.formStore } as any;
    if (store.currentTenant.id === baseData.tenantId && store.currentTenant.id) {
      baseData.lastName = store.currentTenant.lastName || emptyPlaceholder;
      baseData.firstName = store.currentTenant.firstName || emptyPlaceholder;
    }
    return baseData;
  }

  async function onCreate() {
    const res = await applicationRepo.createApplication(getValueFromStore());

    if (res.id) {
      setStore('applicationId', res.id);
    }
  }

  async function onEdit() {
    await applicationRepo.updateApplication(store.applicationId, getValueFromStore());
  }

  async function onStepChange(step: number) {
    try {
      setStore('stepChangeLoading', true);
      if (step > store.currentStep) {
        const { validateStatus } = form.validateForm();
        if (!validateStatus) return;
      }

      if (step > store.currentStep || step === store.currentStep) {
        if (!store.applicationId) {
          await onCreate();
        } else if (store.applicationId) {
          await onEdit();
        }
      }

      if (step === NEW_RENTAL_APPLICATION_REVIEW_STEP) {
        return onSubmit(store.applicationId);
      }

      navigate(`/leasing/rental-applications/new/${store.applicationId}/${store.steps[step].key}`);
    } finally {
      setStore('stepChangeLoading', false);
    }
  }

  return {
    onStepChange,
    store,
    regions,
    setStore,
    form,
    fetchApplication,
  };
});
