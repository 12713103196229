import { useNavigate, useParams } from '@solidjs/router';
import { createSignal, Show } from 'solid-js';
import { Form, FormItem, useForm } from '~/components/common/BetterForm';
import { Modal } from '~/components/modals/Modal';
import { LabeledPropertyManagerSearch } from '~/components/property-managers/PropertyManagerSearch';
import { LabeledLeveledSearchSelect } from '~/components/search-select/LevelSearchSelect';
import { toast } from '~/components/ui';
import { LabeledVendorSearch } from '~/components/vendors';
import { useLocalization } from '~/contexts/global';
import { useRunBooks } from '~/contexts/local';
import { LevelSearch } from '~/swagger/Api';
import type { Component } from 'solid-js';

interface AddDefaultPmAndVendorOverridesModalProps {
  source: 'vendor' | 'propertyManager';
}

export const AddDefaultPmAndVendorOverridesModal: Component<AddDefaultPmAndVendorOverridesModalProps> = (props) => {
  const form = useForm();
  const { t } = useLocalization();
  const navigate = useNavigate();

  const params = useParams<{ runbookId: string }>();
  const [loading, setLoading] = createSignal<boolean>(false);
  const { runBook, addVendorOverride, addPropertyManagersOverride } = useRunBooks();

  const handleClose = () => {
    navigate(-1);
  };

  const handleSubmit = async () => {
    const { validateStatus } = form.validateForm();
    if (!validateStatus) return;
    setLoading(true);
    const apiFunc = props.source === 'vendor' ? addVendorOverride : addPropertyManagersOverride;
    const runbookId = runBook()?.id || params.runbookId;
    try {
      await apiFunc(runbookId, {
        ...(form.formStore as any),
      });
      toast.success(t('{name} has been edited successfully', { name: t('AI runbook') }));
      handleClose();
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      visible
      class="animate-zoom-in capitalize"
      loading={loading()}
      title={t('Add new override')}
      doneText={t('Confirm')}
      onDone={handleSubmit}
      onCancel={handleClose}
      confirmation={false}>
      <Form defaultForm={form} class="mb-6 p-8" onFormSubmit={handleSubmit}>
        <div class="flex flex-col gap-3">
          <FormItem
            class="w-full"
            label={t('Portfolio or property')}
            formFieldName="portfolioOrPropertyId"
            component={LabeledLeveledSearchSelect}
            rules={[
              {
                validator: () => !!form.formStore.propertyId || !!form.formStore.portfolioId,
                message: t(`Please select portfolio, property`),
              },
            ]}
            placeholder={t('Please select portfolio, property')}
            enabledTypes={[LevelSearch.Portfolio, LevelSearch.Property]}
            onChange={(_, variants) => {
              form.setFormStore({
                propertyId: undefined,
                portfolioId: undefined,
              });
              if (variants.singleType) {
                form.setFormStore({
                  [`${variants.singleType}Id`]: variants.singleTypeIds.at(0),
                });
              }
            }}
          />
          <Show
            when={props.source === 'vendor'}
            fallback={
              <FormItem
                class="col-span-full"
                label={t('Property manager')}
                formFieldName="propertyManagerId"
                component={LabeledPropertyManagerSearch<undefined>}
                placeholder={t('Please select property manager')}
                rules={[{ required: true, message: t('Please select property manager') }]}
              />
            }>
            <FormItem
              class="col-span-full"
              label={t('Vendor')}
              formFieldName="vendorId"
              component={LabeledVendorSearch<undefined>}
              placeholder={t('Please select vendor')}
              rules={[{ required: true, message: t('Please select vendor') }]}
            />
          </Show>
        </div>
      </Form>
    </Modal>
  );
};
