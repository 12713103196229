import { useParams, useNavigate } from '@solidjs/router';
import { useForm } from '~/components/common/BetterForm';
import { useOwners } from '~/contexts/global';
import { AddOwnerPayment } from './AddOwnerPayment';
import type { Component } from 'solid-js';

export const AddOwnerPaymentModal: Component = () => {
  const params = useParams<{ ownerId: `${number}` }>();
  const navigate = useNavigate();

  const form = useForm();
  const { addOwnerPaymentAccount, paymentAccountsActions } = useOwners();

  const handleClose = () => {
    navigate(-1);
    return;
  };

  const handleSubmit = async () => {
    await addOwnerPaymentAccount(params.ownerId, form.formStore as MagicDoor.Api.CreateOwnerPaymentAccountDto);
    navigate(-1);
    paymentAccountsActions.refetch();
  };

  return (
    <>
      <div class="fixed inset-0 z-50 flex items-center justify-center bg-black/50">
        <div class="w-fit rounded-lg bg-white">
          <AddOwnerPayment onClose={handleClose} form={form} onSubmit={handleSubmit} />
        </div>
      </div>
    </>
  );
};
