import { Presenter } from '~/framework/Presenter';
import { AnnotationType } from '~/pdfsigner/usecases/types/annotation';
import type { PdfState } from '~/pdfsigner/state/pdfAppState';
import type { PresentableToolbar } from '~/pdfsigner/ui/types/presentableToolbar';
import type { Signer } from '~/pdfsigner/usecases/types/signer';

export class ToolbarPresenter extends Presenter<PresentableToolbar> {
  createModel(state: PdfState): PresentableToolbar {
    return {
      categoryId: state.selectedDocument?.category?.id ?? '',
      selectedAnnotationType: state.selectedAnnotationType || undefined,
      title: state.selectedDocument?.title ? state.selectedDocument.title : '',
      numberOfSignerOptions: this.createSignerOptions(state),
      numberOfSelectedSigners: state.selectedDocument?.signers ? state.selectedDocument?.signers.length : 2,
      dataPaths: state.dataPathManager.getDataPaths(),
      selectedNode: state.dataPathManager.getSelectedNode(),
      isAutofillEnabled: state.selectedAnnotationType === AnnotationType.AUTOFILL,
      templateCategoryOptions: this.createTemplateCategoryOptions(state),
      fontSizeOptions: this.createFontSizeOptions(state),
      receivedSignaturesText: this.createReceivedSignatureText(state.selectedDocument?.signers),
      selectedFontSize: state.selectedAnnotation ? state.selectedAnnotation.fontSize : state.selectedFontSize,
      signedUrl: state.selectedDocument?.signedUrl || '',
      signers: state.selectedDocument?.signers,
    };
  }

  private createReceivedSignatureText(signers?: Signer[]): string {
    const totalSignature = signers ? signers.length : 0;
    const receivedSignature = signers ? signers.filter((signer) => signer.hasSigned).length : 0;
    return `${receivedSignature}/${totalSignature}`;
  }

  private createSignerOptions(state: PdfState) {
    const options = [];

    for (let i = 0; i < state.maxNumberOfSigners; i++) {
      options.push({ value: i + 1, label: `${i + 1}` });
    }

    return options;
  }

  private createFontSizeOptions(state: PdfState) {
    const options = [];

    for (let i = 0; i < state.fontSizes.length; i++) {
      options.push({ value: state.fontSizes[i], label: `${state.fontSizes[i]}` });
    }

    return options;
  }

  private createTemplateCategoryOptions(state: PdfState) {
    const options = state.leaseTemplateCategories.map((item) => ({
      value: item.id,
      label: item.name,
    }));

    return options;
  }
}
