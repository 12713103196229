import type { Accessor } from 'solid-js';
import type { Presenter } from '~/framework/Presenter';

function usePresenter<T extends object>(PresenterClass: new () => Presenter<T>): { model: Accessor<T | undefined> } {
  const presenter = new PresenterClass();
  const modelAccessor = () => presenter.getPresentableModel();

  return { model: modelAccessor };
}

export default usePresenter;
