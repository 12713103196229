import { useParams } from '@solidjs/router';
import { createSignal, Show } from 'solid-js';
import starIcon from '~/assets/images/chat/aiGenerateStar.png';
import magicPerson from '~/assets/images/identity/magic_person_lamplight.png';
import magicAI from '~/assets/images/units/listing/magic_ai.gif';
import MagicIcon from '~/assets/images/units/listing/magic_person.svg?component-solid';
import magicStarPerson from '~/assets/images/units/listing/magic_star_person.png';
import ToggleIcon from '~/assets/images/units/listing/toggle.svg?component-solid';
import { FormItem } from '~/components/common/BetterForm';
import { useFormContext } from '~/components/common/BetterForm/context';
import { Button } from '~/components/common/Buttons';
import { AiButton } from '~/components/common/Buttons/AiButton';
import LabeledTextarea from '~/components/common/Inputs/LabeledTextArea';
import { toast } from '~/components/ui';
import { IconRefresh } from '~/components/ui/Icons';
import { useLocalization, useUnitListings } from '~/contexts/global';
import { OperateTags } from './OperateTags';
import type { Component } from 'solid-js';

type AiEditProps = {
  tags: string[] | null | undefined;
  toggle: (type: string) => void;
  description: string | null | undefined;
  onSave: (back: boolean) => void;
  isChange: boolean;
};
export const AiEdit: Component<AiEditProps> = (props) => {
  const { t } = useLocalization();
  const params = useParams<{ portfolioId?: `${number}`; listingId: `${number}` }>();
  const [loading, setLoading] = createSignal<boolean>(false);
  const [tags, setTags] = createSignal<string[] | undefined | null>();
  const [textAreaVal, setTextAreaVal] = createSignal<string>('');
  const [btnIsClick, setBtnIsClick] = createSignal<boolean>(false);
  const { generateAiListing } = useUnitListings();
  const form = useFormContext();

  const handleToggle = () => {
    props.toggle('default');
  };
  const handleGenerate = async () => {
    if (!textAreaVal().trim()) {
      toast(t('Please enter'));
      return;
    }
    setBtnIsClick(true);
    setLoading(true);
    try {
      const res = await generateAiListing(params.listingId, { prompt: textAreaVal() });
      setTags(res.keywords);
      handleFormSet(res.keywords, res.description);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const handleTags = (tags: string[]) => {
    handleFormSet(tags);
  };
  const handleFormSet = (tags: string[] | undefined, description?: string | undefined) => {
    form.setFieldsValue({
      tags,
      description,
    });
  };
  const handleSave = () => {
    props.onSave(false);
  };
  const PageTitle = (props: { title: string }) => {
    return (
      <div class="mb-4 flex items-center">
        <img src={starIcon} alt="starIcon" />
        <h3 class="text-base font-semibold capitalize text-white">{props.title}</h3>
      </div>
    );
  };
  return (
    <div>
      <Show when={props.isChange}>
        <div class="flex w-full flex-col items-center text-center">
          <img src={magicPerson} width={250} />
          <div class="mb-8 mt-4 text-sm text-title-gray">
            {t(
              'To generate listings using AI, please save your changes first so content can be created based on the completed information.'
            )}
          </div>
          <div class="flex gap-2">
            <Button class="ml-auto" variant="outlined" onClick={() => props.toggle('default')}>
              {t('Cancel')}
            </Button>
            <Button onClick={handleSave}>{t('Save changes')}</Button>
          </div>
        </div>
      </Show>
      <Show when={!props.isChange}>
        <div class="rounded-2xl bg-gradient-to-r from-[#23B6FF] to-[#FB54B8] p-0.5">
          <div class="rounded-2xl bg-white p-3">
            <div class="mb-3 flex w-fit items-center rounded-full bg-[#F3F3FD] px-4 py-2">
              <MagicIcon />
              <span class="text-base font-medium capitalize text-primary">{t('Message magic-ai')}</span>
            </div>
            <textarea
              class="w-full bg-transparent text-sm text-text-level03 focus:outline-none focus:ring-0"
              value={textAreaVal()}
              rows={5}
              onInput={(e) => setTextAreaVal(e.target.value)}
              placeholder={t("Is there any specific information you'd like to provide before Magic - AI enhances your listing ?")}
            />
            <div class="flex items-center justify-end">
              <Show when={btnIsClick()}>
                <div class="mr-3 text-xs text-text-level03">{t('Not satisfied with the content?')}</div>
                <div
                  class="flex w-fit cursor-pointer items-center rounded-full border border-essential-colour px-2 py-1 text-primary hover:bg-essential-colour/10"
                  onClick={handleGenerate}>
                  <IconRefresh class="size-4" />
                  <span class="ml-1 text-xs uppercase">{t('Re-generate')}</span>
                </div>
              </Show>
              <Show when={!btnIsClick()}>
                <AiButton text={t('Generate')} onClick={handleGenerate} disabled={loading()} class="rounded-full capitalize" />
              </Show>
            </div>
          </div>
        </div>
        <Show when={btnIsClick()}>
          <div
            class="mt-5 rounded-2xl px-5 pb-9 pt-4"
            style={{
              background: 'linear-gradient(220deg, #4113A4, #7443C5)',
            }}>
            <div class="flex">
              <img src={magicStarPerson} alt="img" class="mr-3 h-12" />
              <div>
                <div class="text-xl font-medium text-white">{t("Here is the data we've analyzed for your property!")}</div>
                <div class="text-sm font-normal text-white/70">{t('Unleash the magic power of AI: customized your listing!')}</div>
              </div>
            </div>
            <div class="mb-6 mt-4 h-px w-full bg-white/15" />
            <Show
              when={!loading()}
              fallback={
                <div class="flex flex-col items-center">
                  <img src={magicAI} alt="loadingImg" class="h-40" />
                  <div class="mt-2 text-xs text-white">
                    {t('MagicDoor AI is being generated')}
                    <span class="animate-pulse">...</span>
                  </div>
                </div>
              }>
              <PageTitle title={t('AI Features')} />
              <div class="mb-5 rounded-r-xl rounded-bl-xl bg-white p-3">
                <OperateTags tags={tags()} onChange={handleTags} />
              </div>
              <PageTitle title={t('AI generated listing')} />
              <div class="rounded-r-xl rounded-bl-xl bg-white p-3">
                <FormItem
                  formFieldName="description"
                  component={LabeledTextarea}
                  rows={5}
                  placeholder={t('Please enter a listing description')}
                />
              </div>
            </Show>
          </div>
        </Show>

        <div class="flex justify-center">
          <div
            class="mt-3 flex w-fit cursor-pointer items-center justify-center gap-2 rounded-full border border-essential-colour px-4 py-2 text-sm capitalize text-primary hover:bg-essential-colour/10"
            onClick={handleToggle}>
            <ToggleIcon />
            {t('Write manually without AI')}
          </div>
        </div>
      </Show>
    </div>
  );
};
