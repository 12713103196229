import { DocumentIdRequiredError } from '~/pdfsigner/errors/idRequiredError';
import { DocumentSignersMissingError } from '~/pdfsigner/errors/signersMissingError';
import { DocumentTitleRequiredError } from '~/pdfsigner/errors/titleRequiredError';
import { MagicUseCase } from '~/pdfsigner/usecases/baseUseCases/magicUseCase';
import { AnnotationType } from '~/pdfsigner/usecases/types/annotation';

export class ValidateDocumentUseCase extends MagicUseCase {
  public async runLogic() {
    if (!this.getState().selectedDocument?.title || this.getState().selectedDocument?.title === '') {
      throw new DocumentTitleRequiredError();
    }
    if (!this.getState().selectedDocument?.id) {
      throw new DocumentIdRequiredError();
    }
    if (!this.haveAllSignersBeenPlaced()) {
      throw new DocumentSignersMissingError();
    }
  }

  private haveAllSignersBeenPlaced(): boolean {
    const placedSigners = new Set();
    this.getState().selectedDocument?.pdfPages.forEach((page) => {
      page.annotations.forEach((annotation) => {
        if (annotation.type === AnnotationType.SIGNATURE && !placedSigners.has(annotation.signerId)) {
          placedSigners.add(annotation.signerId);
        }
      });
    });
    return placedSigners.size === this.getState().selectedDocument?.signers.length;
  }
}
