import { Show } from 'solid-js';
import NoSearchData from '~/assets/images/empty/noSearchData.svg';
import { Empty } from '~/components/common/Empty';
import { PrintPageHeader } from '~/components/print';
import { useLocalization } from '~/contexts/global';
import { dateFormat } from '~/utils/date';
import { upperFirst } from '~/utils/tool';
import type { ChatMessageDto, CompanyDto, HydratedMagicChatDto } from '~/swagger/Api';

interface PrintHeaderProps {
  chat: HydratedMagicChatDto | undefined;
  companies: CompanyDto | undefined;
  memberContent: string;
  chatMessages: ChatMessageDto[];
}

export const PrintHeader = (props: PrintHeaderProps) => {
  const { t } = useLocalization();

  return (
    <div>
      <PrintPageHeader companies={props.companies} />
      <div class="flex flex-col gap-1 pb-3 pt-6">
        <h1 class="text-center text-xl font-semibold text-[#3F4156]">{`${t('Chat with', { name: props.chat?.subject ?? '' })}`}</h1>
      </div>
      <div class="grid grid-cols-2 gap-2 pb-3 text-left text-xxs font-normal text-title-gray">
        <Show when={props.chat?.subject}>
          <div class="pb-1 text-sm text-gray-600">
            <span class="text-grid-label">{t('Chat name')} :</span>
            <span class="pl-2 text-grid-content">{props.chat?.subject}</span>
          </div>
        </Show>
        <Show when={props.chat?.type === 'lease' && props.chat?.lease}>
          <div class="pb-1 text-sm text-gray-600">
            <span class="text-grid-label">{t('Related lease')} :</span>
            <span class="pl-2 text-grid-content">
              {`${upperFirst(props.chat?.lease?.status as string)} (${props.chat?.lease?.start} ~ ${
                props.chat?.lease?.end ? props.chat?.lease?.end : t('Until now')
              })`}
            </span>
          </div>
        </Show>
        <Show when={props.chat?.subject}>
          <div class="pb-1 text-sm text-gray-600">
            <span class="text-grid-label">{t('Member')} :</span>
            <span class="pl-2 text-grid-content">{props.memberContent}</span>
          </div>
        </Show>
        <div class="pb-1 text-sm text-gray-600">
          <span class="text-grid-label">{t('Timestamp')} :</span>
          <span class="pl-2 text-grid-content">
            {dateFormat('', props.chat?.created as string)} ~{' '}
            {props.chat?.lastMessageSentAt
              ? dateFormat('', props.chat?.lastMessageSentAt as string)
              : dateFormat('', props.chat?.created as string)}
          </span>
        </div>
        <div class="pb-1 text-sm text-gray-600">
          <span class="text-grid-label">{t('Date')} :</span>
          <span class="pl-2 text-grid-content">{dateFormat('', new Date().toISOString())}</span>
        </div>
      </div>
      <Show when={!props.chatMessages || !props.chatMessages?.length}>
        <div class="flex w-full items-center justify-center">
          <Empty imgSrc={NoSearchData} description={t('No data yet')} />
        </div>
      </Show>
    </div>
  );
};
