import { HttpError } from '~/errors/httpError';

export class UnauthorizedError extends HttpError {
  constructor() {
    super(401);

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, UnauthorizedError.prototype);
  }
}
