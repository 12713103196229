import { Show } from 'solid-js';
import { FormItem } from '~/components/common/BetterForm';
import { Checkbox } from '~/components/common/Inputs/Checkbox';
import { LabeledInputSearch } from '~/components/common/Inputs/LabeledInputSearch';
import { useLocalization } from '~/contexts/global';
import { useAddOrEditMaintenanceModalContext } from '~/pages/maintenance-request/add-maintenance-request/Context';

export const AssignedTo = (props: { isCreate?: boolean }) => {
  const { t } = useLocalization();
  const { store } = useAddOrEditMaintenanceModalContext();

  return (
    <div class="flex flex-col gap-8">
      <div class="flex gap-7">
        <FormItem
          label={t(`Assigned to`)}
          formFieldName="assignedPropertyManagerId"
          options={store.managers}
          onChangeMethodName="onSelect"
          component={LabeledInputSearch}
          placeholder={t('Please select')}
        />
      </div>
      <Show when={props.isCreate}>
        <FormItem showLabel label={t('Disable AI')} formFieldName="disableAi" onChangeMethodName="onInput" component={Checkbox} />
      </Show>
    </div>
  );
};
