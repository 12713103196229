import { FormItem } from '~/components/common/BetterForm';
import DueDateInputField from '~/components/common/Inputs/DueDateInputField';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { YearRange } from '~/components/ui/Calendar/yearRange';
import { useLocalization } from '~/contexts/global';
import { whenThisDayIsNotBirthDay } from '~/utils/date';
import { email, phone } from '~/utils/formRules';

export const RentalBasicInfo = () => {
  const { t } = useLocalization();

  return (
    <div class="grid grid-cols-3 gap-2 gap-y-6">
      <FormItem
        required
        placeholder={t('MM/DD/YYYY')}
        label={t(`Date of birth`)}
        rules={[
          { type: 'dateOfBirth', message: t('Date of birth is invalid') },
          { required: true, message: t('Date of birth is required') },
          {
            message: t('Age cannot be less than 18 years'),
            validator: (value) => {
              const today = new Date();
              const eighteenYearsAgo = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());

              return new Date(value) < eighteenYearsAgo;
            },
          },
          {
            message: t('Age cannot be greater than 125 years'),
            validator: (value) => {
              const today = new Date();
              const target = new Date(today.getFullYear() - 125, today.getMonth(), today.getDate());
              return new Date(value) > target;
            },
          },
        ]}
        formFieldName={['dateOfBirth']}
        component={DueDateInputField}
        yearRange={YearRange.Birthday}
        disabledDate={whenThisDayIsNotBirthDay}
      />
      <FormItem placeholder="Phone" rules={phone(t)} label={t(`Phone number`)} formFieldName={['phone']} component={LabeledTextInput} />
      <FormItem
        placeholder={t('Email')}
        rules={email(t)}
        label={t(`Email address`)}
        formFieldName={['email']}
        component={LabeledTextInput}
      />
    </div>
  );
};
