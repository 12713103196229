import { Button } from '~/components/common/Buttons';
import { useLocalization } from '~/contexts/global';
import type { Component } from 'solid-js';

type BtnProps = {
  onCancel: () => void;
  onSave: () => void;
};
export const BottomBtns: Component<BtnProps> = (props) => {
  const { t } = useLocalization();
  return (
    <div class="flex items-center justify-end gap-2 border-t border-input-border bg-white px-6 py-5">
      <Button class="ml-auto" variant="outlined" onClick={props.onCancel}>
        {t('Cancel')}
      </Button>
      <Button onClick={props.onSave}>{t('Save changes')}</Button>
    </div>
  );
};
