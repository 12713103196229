import { Route, Routes, useMatch, useNavigate } from '@solidjs/router';
import { onMount, Show } from 'solid-js';
import { FormWrapper } from '~/components/common/BetterForm/Form';
import Breadcrumb from '~/components/common/Breadcrumb';
import { RingLoader } from '~/components/common/Loaders';
import { Panel } from '~/components/common/Panels';
import { useLocalization } from '~/contexts/global';
import { NewRentalApplicationProvider, useRentalApplicationPayment } from '~/contexts/local';
import { Payment } from '~/pages/rental-applications/add-rental-application/Payment';
import { PaymentStatus } from '~/pages/rental-applications/add-rental-application/PaymentStatus';
import { StepsBeforePayment } from '~/pages/rental-applications/add-rental-application/StepsBeforePayment';

export const NewRentalApplication = () => {
  return (
    <FormWrapper
      validateTriggers={['onChange']}
      initialValues={{
        unit: [{}],
        residentialHistory: [
          {
            address: {
              city: '',
            },
          },
        ],
        employment: [
          {
            position: '',
          },
        ],
      }}>
      <NewRentalApplicationProvider>
        <NewRentalApplicationView />
      </NewRentalApplicationProvider>
    </FormWrapper>
  );
};

const NewRentalApplicationView = () => {
  const { t } = useLocalization();
  const navigate = useNavigate();
  const { getRentalApplicationStatus, store, settingsActions } = useRentalApplicationPayment();

  const match = useMatch(() => `/leasing/rental-applications/new/:applicationId/*`);

  onMount(() => {
    const params = match()?.params;
    if (params?.applicationId) {
      getRentalApplicationStatus(params.applicationId);
    }

    settingsActions.fetch();
  });

  return (
    <>
      <Breadcrumb
        backLink={() => navigate(-1)}
        items={[{ label: t('Rental applications'), link: '/leasing/rental-applications' }, { label: t('New rental application') }]}
      />
      <Panel class="relative m-8 flex h-section1 flex-col" title={t('New rental application')}>
        <Show
          when={!store.isLoadingRentalStatus}
          fallback={<RingLoader color={'#A126EC'} class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" />}>
          <Routes>
            <Route path="/:applicationId/status/:paymentStatus" component={PaymentStatus} />
            <Route path="/:applicationId/payment" component={Payment} />
            <Route path={['/:applicationId/:step', '*']} component={StepsBeforePayment} />
          </Routes>
        </Show>
      </Panel>
    </>
  );
};
