import { DocumentCategoryRequiredError } from '~/pdfsigner/errors/categoryRequiredError';
import { leaseTemplateRepository } from '~/pdfsigner/repositories/leaseTemplateRepository';
import { MagicUseCase } from '~/pdfsigner/usecases/baseUseCases/magicUseCase';
import { ValidateDocumentUseCase } from './validateDocumentUseCase';

export class SaveLeaseTemplateUseCase extends MagicUseCase {
  public async runLogic() {
    await new ValidateDocumentUseCase().execute();
    const selectedDocument = this.getState().selectedDocument;
    if (!selectedDocument?.category) {
      throw new DocumentCategoryRequiredError();
    }
    const id = await leaseTemplateRepository.saveTemplate(selectedDocument);
    const shouldNavigate = !selectedDocument.id;
    selectedDocument.id = id;
    if (shouldNavigate) {
      this.navigate(`/lease-templates/${id}`);
    }
  }
}
