import { createMemo, Show } from 'solid-js';
import IconFilledCheck from '~/assets/images/common/filledCheck.svg?component-solid';
import { useSearchSelectContext } from '~/components/search-select/Context';
import { cn } from '~/utils/classnames';
import type { Accessor, Component } from 'solid-js';
import type { CustomLevelSearchType } from '~/components/search-select/LevelSearchComponents/SearchLevels';

export const SelectArea: Component<{
  item: Accessor<any>;
  type: CustomLevelSearchType;
  onClick?: (e: MouseEvent) => void;
}> = (props) => {
  const { isSelected, mergedProps } = useSearchSelectContext();

  const _isSelected = createMemo(() => isSelected(props.item()));

  return (
    <div
      class={cn(
        'border-blue-gray-200 flex size-5 items-center justify-center rounded-md border text-white group-aria-checked:border-essential-colour',
        {
          'bg-purple': _isSelected(),
          'rounded-full': !mergedProps().multiple,
        }
      )}>
      <Show when={_isSelected()}>
        <IconFilledCheck />
      </Show>
    </div>
  );
};
