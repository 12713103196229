import { HttpError } from '~/errors';

export const getErrorMessage = (error: unknown) => {
  if (error instanceof HttpError) {
    const errors = error.getErrors();
    return Object.values(errors).flat() as string[];
  }

  if (error instanceof Error) {
    return error.message;
  }
  return 'Operation failed, please try again later';
};
