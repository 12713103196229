import { useParams } from '@solidjs/router';
import { createEffect, createSignal, For, Show } from 'solid-js';
import IconDocument from '~/assets/images/units/listing/document.svg?component-solid';
import { FormItem, SegmentFormItem } from '~/components/common/BetterForm';
import { useFormContext } from '~/components/common/BetterForm/context';
import { Button } from '~/components/common/Buttons';
// import { DropdownMenu } from '~/components/common/DropdownMenu';
import { Checkbox } from '~/components/common/Inputs/Checkbox';
import DueDateInputField from '~/components/common/Inputs/DueDateInputField';
import { LabeledCheckbox } from '~/components/common/Inputs/LabeledCheckbox';
import LabeledNumberInput from '~/components/common/Inputs/LabeledNumberInput';
import { LabeledRadio } from '~/components/common/Inputs/LabeledRadio';
import LabeledSelect from '~/components/common/Inputs/LabeledSelect';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
// import LabeledAddressInput from '~/components/common/Inputs/form-inputs/LabeledAddressInput';
// import LabeledAddressInput from '~/components/common/Inputs/form-inputs/LabeledAddressInput';
import { Radio } from '~/components/common/Inputs/Radio';
import ExpandableSection from '~/components/common/expandables/ExpandableSection';
// import { propertyTypes } from '~/components/properties/PropertyType';
import { useLocalization, useUnitListings } from '~/contexts/global';
// import { useMaintenanceRequests } from '~/contexts/local';
import { NO_LIMIT } from '~/utils/constant';
import { AiEdit } from './AiEdit';
import { DefaultEdit } from './DefaultEdit';
import type { Component } from 'solid-js';

type leaseOption = {
  label: string;
  value: string;
};
type CollapseProps = {
  key?: string;
  title: string;
  options: {
    label: string;
    value: boolean;
  }[];
  isOpen: boolean;
};
type ListingProps = {
  onSave: (back: boolean) => void;
  isChange: boolean;
};
export const UnitListingsSection: Component<ListingProps> = (props) => {
  const { t } = useLocalization();
  const form = useFormContext();
  // const { maintenanceRequestCategory } = useMaintenanceRequests();
  const { unitListingDetail, getUnitListingsDetail } = useUnitListings();
  const params = useParams<{ listingId: `${number}` }>();
  const [collapseItems, setCollapseItems] = createSignal<CollapseProps[]>();
  const [isOpen, setIsOpen] = createSignal<boolean>(false);
  const [operateType, setOperateType] = createSignal<string>('default');
  const [petSize, setPetSize] = createSignal<string>();
  const [petIsAll, setPetIsAll] = createSignal<boolean>();
  const petTypeEnum = ['Cat', 'Dog', 'Fish', 'Bird', 'Reptile', 'Other'];

  const petTypes = petTypeEnum.map((item) => ({ label: t(item), value: false }));
  const petSizeOpts = [
    {
      label: t('Small (under 10kg)'),
      value: 'small',
    },
    {
      label: t('Medium (10 - 25kg)'),
      value: 'medium',
    },
    {
      label: t('Large (25 - 50kg)'),
      value: 'large',
    },
    {
      label: t('No limit'),
      value: NO_LIMIT,
    },
  ];
  const leaseTypeOptions: leaseOption[] = [
    {
      label: t('Month-to-month'),
      value: 'monthToMonth',
    },
    {
      label: t('Six months'),
      value: 'halfYear',
    },
    {
      label: t('One year'),
      value: 'oneYear',
    },
    {
      label: t('Two years'),
      value: 'twoYears',
    },
    {
      label: t('Three years'),
      value: 'threeYears',
    },
    {
      label: t('Other'),
      value: 'other',
    },
  ];
  let expandableRef: any;
  const progress = () => {
    let fieldsFilled = 0;
    if (form.formStore['Included utilities']) fieldsFilled++;
    if (form.formStore['Interior amenities']) fieldsFilled++;
    if (form.formStore['Outdoor amenities']) fieldsFilled++;
    if (form.formStore['Community amenities']) fieldsFilled++;
    return fieldsFilled / 4;
  };
  const unitProgress = () => {
    let fieldsFilled = 0;
    if (form.formStore.address?.streetAddress1) fieldsFilled++;
    if (form.formStore.address?.city) fieldsFilled++;
    if (form.formStore.address?.zipCode) fieldsFilled++;
    if (form.formStore.address?.state) fieldsFilled++;
    if (form.formStore.beds) fieldsFilled++;
    if (form.formStore.baths) fieldsFilled++;
    if (form.formStore.unitSizeSqft) fieldsFilled++;
    if (form.formStore.type) fieldsFilled++;
    return fieldsFilled / 8;
  };
  const termsProgress = () => {
    let fieldsFilled = 0;
    if (form.formStore.rentalPrice) fieldsFilled++;
    if (form.formStore.securityDeposit) fieldsFilled++;
    if (form.formStore.availableFrom) fieldsFilled++;
    if (form.formStore.leaseTerm) fieldsFilled++;
    return fieldsFilled / 4;
  };
  const magicAiProgress = () => {
    let fieldsFilled = 0;
    if (form.formStore.tags) fieldsFilled++;
    if (form.formStore.description) fieldsFilled++;
    return fieldsFilled / 2;
  };
  const petPolicyProgress = () => {
    const formStore = form.formStore;
    if (!formStore?.allowed) {
      return 1;
    }
    const fields = ['policies', 'size', 'fee', 'deposit', 'description'];
    const fieldsFilled = fields.filter((field) => formStore[field]).length;
    return fieldsFilled / fields.length;
  };
  const checkboxMap = {
    includedUtilities: [
      {
        label: 'Water & sewer',
        value: false,
      },
      {
        label: 'Electricity',
        value: false,
      },
      {
        label: 'Gas',
        value: false,
      },
      {
        label: 'Trash',
        value: false,
      },
      {
        label: 'Internet/cable',
        value: false,
      },
      {
        label: 'Heating/air conditioning',
        value: false,
      },
    ],
    interiorAmenities: [
      {
        label: 'In-unit washer/dryer',
        value: false,
      },
      {
        label: 'Dishwasher',
        value: false,
      },
      {
        label: 'Microwave',
        value: false,
      },
      {
        label: 'Stainless steel appliances',
        value: false,
      },
      {
        label: 'Hardwood flooring',
        value: false,
      },
      {
        label: 'Central AC/heating',
        value: false,
      },
      {
        label: 'Walk-in closets',
        value: false,
      },
      {
        label: 'High-speed internet',
        value: false,
      },
      {
        label: 'Fireplace',
        value: false,
      },
    ],
    outdoorAmenities: [
      {
        label: 'Patio',
        value: false,
      },
      {
        label: 'BBQ grills or picnic area',
        value: false,
      },
      {
        label: 'Rooftop deck',
        value: false,
      },
      {
        label: 'Parking space',
        value: false,
      },
    ],
    communityAmenities: [
      {
        label: 'Gym',
        value: false,
      },
      {
        label: 'Swimming pool',
        value: false,
      },
      {
        label: 'Community clubhouse',
        value: false,
      },
      {
        label: 'Playground or dog park',
        value: false,
      },
      {
        label: 'Laundry facilities',
        value: false,
      },
      {
        label: 'Parking or garage',
        value: false,
      },
      {
        label: '24/7 security or controlled access',
        value: false,
      },
    ],
  };

  const tempCollapseItems = [
    {
      key: '2',
      title: 'Included utilities',
      options: checkboxMap.includedUtilities,
      isOpen: false,
    },
    {
      key: '3',
      title: 'Interior amenities',
      options: checkboxMap.interiorAmenities,
      isOpen: false,
    },
    {
      key: '4',
      title: 'Outdoor amenities',
      options: checkboxMap.outdoorAmenities,
      isOpen: false,
    },
    {
      key: '5',
      title: 'Community amenities',
      options: checkboxMap.communityAmenities,
      isOpen: false,
    },
  ];
  const toggleCollapse = () => {
    setIsOpen(!isOpen());
    const items = collapseItems()?.map((item) => {
      return { ...item, isOpen: isOpen() };
    });
    setCollapseItems(items);
  };
  const getFieldGroup = (name: string | undefined) => {
    const groupMap = {
      'Included utilities': checkboxMap.includedUtilities,
      'Interior amenities': checkboxMap.interiorAmenities,
      'Outdoor amenities': checkboxMap.outdoorAmenities,
      'Community amenities': checkboxMap.communityAmenities,
    };
    for (const [key, value] of Object.entries(groupMap)) {
      const checkItem = value.find((item) => item.label === name);
      if (checkItem) return key;
    }
  };
  const handlePolicies = (obj: Record<string, boolean | null> | undefined) => {
    if (!obj) return [];
    if (Object.keys(obj).length === petTypeEnum.length) {
      setPetIsAll(true);
    } else {
      setPetIsAll(false);
    }
    return Object.keys(obj)
      .filter((key) => obj[key])
      .map((key) => key.charAt(0).toUpperCase() + key.slice(1));
  };
  const handleCheckAll = () => {
    if (petIsAll()) {
      form.setFieldsValue({
        policies: petTypeEnum,
      });
    } else {
      form.setFieldsValue({
        // todo: The assignment fails after changing to an empty array
        policies: false,
      });
    }
  };
  const handleFormSetFields = () => {
    // Facts and features
    const obj = {
      'Included utilities': [] as string[],
      'Interior amenities': [] as string[],
      'Outdoor amenities': [] as string[],
      'Community amenities': [] as string[],
    };
    unitListingDetail()?.amenities?.forEach((item) => {
      if (item.avalability === 'available') {
        const key = getFieldGroup(item.name);
        if (key) obj[key as keyof typeof obj].push(item.name as string);
      }
    });

    form.setFieldsValue({
      address: unitListingDetail()?.property?.address,
      beds: unitListingDetail()?.unit?.beds,
      baths: unitListingDetail()?.unit?.baths,
      unitSizeSqft: unitListingDetail()?.unit?.unitSizeSqft,
      type: unitListingDetail()?.property?.type,
      rentalPrice: unitListingDetail()?.rentalPrice,
      securityDeposit: unitListingDetail()?.securityDeposit,
      availableFrom: unitListingDetail()?.availableFrom,
      leaseTerm: unitListingDetail()?.leaseTerm,
      allowed: unitListingDetail()?.petPolicy?.allowed,
      policies: handlePolicies(unitListingDetail()?.petPolicy?.policies ?? undefined),
      size: unitListingDetail()?.petPolicy?.size || NO_LIMIT,
      fee: unitListingDetail()?.petPolicy?.fee,
      deposit: unitListingDetail()?.petPolicy?.deposit,
      'Included utilities': obj['Included utilities'] || [],
      'Interior amenities': obj['Interior amenities'] || [],
      'Outdoor amenities': obj['Outdoor amenities'] || [],
      'Community amenities': obj['Community amenities'] || [],
      petPolicyDesc: unitListingDetail()?.petPolicy?.description,
      description: unitListingDetail()?.description,
      tags: unitListingDetail()?.tags,
    });
    setPetSize(unitListingDetail()?.petPolicy?.size || NO_LIMIT);
  };
  const handleToggle = (type: string) => {
    setOperateType(type);
  };
  createEffect(() => {
    setCollapseItems(tempCollapseItems as CollapseProps[]);
    getUnitListingsDetail(params.listingId);
    handleFormSetFields();
  });

  return (
    <>
      {/* <SegmentFormItem title={t('Unit information')} progress={unitProgress()} error={!form.isValidate} required>
        <div class="grid grid-cols-1 gap-7 gap-y-8 p-8">
          <FormItem
            class="col-span-full lg:col-span-1"
            label={t('Category')}
            rules={[{ required: true, message: t('Please select category') }]}
            options={maintenanceRequestCategory()?.map((item) => ({ value: item.id!, label: item.name! }))}
            formFieldName="categoryId"
            onChangeMethodName="onChange"
            component={DropdownMenu}
            placeholder={t('Please select')}
          />
        </div>
        <div class="grid grid-cols-1 gap-7 gap-y-8 p-8">
          <FormItem
            class="col-span-full lg:col-span-1"
            component={LabeledAddressInput}
            formFieldName="address"
            required
            disabled={true}
            rules={[{ required: true, message: t('Please enter') }]}
          />
        </div>
        <div class="grid grid-cols-2 gap-7 gap-y-8 px-8 py-0">
          <FormItem
            component={LabeledNumberInput}
            label={t('Bedroom')}
            disabled={true}
            rules={[
              {
                range: [0, 100],
                type: 'number',
                message: t('Bedroom must be between 0 and 100'),
              },
            ]}
            placeholder={t('Please enter')}
            formFieldName="beds"
            getValueFromEvent={(e) => +e}
            step={0.5}
          />
          <FormItem
            component={LabeledNumberInput}
            disabled
            rules={[
              {
                range: [0, 100],
                type: 'number',
                message: t('Bathroom must be between 0 and 100'),
              },
            ]}
            label={t('Bathroom')}
            placeholder={t('Please enter')}
            formFieldName="baths"
            getValueFromEvent={(e) => +e}
            step={0.5}
          />
        </div>
        <div class="grid grid-cols-2 gap-7 gap-y-8 p-8">
          <FormItem
            label={t('Unit size(sqft)')}
            placeholder={t('Please enter')}
            component={LabeledNumberInput}
            formFieldName="unitSizeSqft"
            disabled={true}
            rules={[
              {
                range: [0, 2147483647],
                type: 'int',
                message: t('Unit size must be between 0 and 10000'),
              },
            ]}
            getValueFromEvent={(e) => +e}
          />
          <FormItem
            class="col-span-full lg:col-span-1"
            label={t('Unit type')}
            disabled={true}
            rules={[{ required: true, message: t('Please select') }]}
            options={Object.keys(propertyTypes).map((item) => ({ value: item!, label: item! }))}
            formFieldName="type"
            onChangeMethodName="onChange"
            component={DropdownMenu}
            placeholder={t('Please select')}
          />
        </div>
      </SegmentFormItem> */}
      <SegmentFormItem title={t('Terms')} id="terms" progress={termsProgress()} error={!form.isValidate} autoScroll={false}>
        <div class="grid grid-cols-2 gap-7 gap-y-8 p-8">
          <FormItem
            class="col-span-full lg:col-span-1"
            label={t('Rent amount($)')}
            placeholder={t('Please enter')}
            component={LabeledNumberInput}
            formFieldName="rentalPrice"
            rules={[{ required: true, message: t('Please enter') }]}
          />
          <FormItem
            class="col-span-full lg:col-span-1"
            label={t('Security deposit($)')}
            placeholder={t('Please enter')}
            component={LabeledNumberInput}
            formFieldName="securityDeposit"
          />
        </div>
        <div class="grid grid-cols-2 gap-7 gap-y-8 px-8 py-0">
          <FormItem
            class="col-span-full mb-1 lg:col-span-1"
            formFieldName="availableFrom"
            label={t('Date available')}
            component={DueDateInputField}
          />
          <FormItem
            class="col-span-full lg:col-span-1"
            formFieldName="leaseTerm"
            label={t('Lease type')}
            options={leaseTypeOptions}
            component={LabeledSelect}
          />
        </div>
      </SegmentFormItem>
      <SegmentFormItem
        title={t('Facts and features')}
        id="facts-and-features"
        progress={progress()}
        error={!form.isValidate}
        autoScroll={false}>
        <div class="p-8">
          <Button class="mb-2 w-fit px-2 text-sm" color="link" variant="text" onClick={toggleCollapse}>
            {isOpen() ? t('Close all options') : t('Expand to choose all options')}
          </Button>
          <For each={collapseItems()}>
            {(item) => (
              <ExpandableSection
                ref={expandableRef}
                headerBorder
                btnClass="mb-0"
                defaultOpen={item.isOpen}
                class="mb-2 rounded-lg border border-input-border px-5 text-base"
                title={
                  <div class="flex w-full items-center justify-between">
                    <span class="capitalize text-title-gray">{t(item.title)}</span>
                    <span class="flex cursor-default gap-2 rounded-full border border-essential-colour px-2 py-1 text-xs text-primary">
                      <IconDocument />
                      {form.formStore?.[item?.title]?.length || 0}
                    </span>
                  </div>
                }>
                <div class="p-2">
                  <FormItem formFieldName={item.title} component={LabeledCheckbox} options={item.options} />
                </div>
              </ExpandableSection>
            )}
          </For>
        </div>
      </SegmentFormItem>
      <SegmentFormItem title={t('Pet policy')} id="pet-policy" error={!form.isValidate} autoScroll={false} progress={petPolicyProgress()}>
        <div class="p-8">
          <div class="flex rounded-lg border border-input-border px-5 py-4">
            <FormItem
              formFieldName="allowed"
              label={t('Are pets allowed?')}
              component={LabeledRadio}
              labelContainerClass="flex-row items-center justify-center"
              labelClass="mb-0"
              class="flex"
              items={[
                { label: t('Yes'), value: true },
                { label: t('No'), value: false },
              ]}
              direction="row"
            />
          </div>
          <Show when={form.formStore.allowed}>
            <ExpandableSection
              headerBorder
              btnClass="mb-0 items-center"
              defaultOpen={true}
              class="my-4 rounded-lg border border-input-border px-5 text-base"
              title={<div class="capitalize text-title-gray">{t('Allowed pet types')}</div>}
              noncollapsible={
                <Checkbox
                  checkBoxClass="ml-2 h-9 text-xs"
                  label={t('Select all')}
                  showLabel
                  checked={petIsAll()}
                  onInput={(checked) => {
                    setPetIsAll(checked);
                    handleCheckAll();
                  }}
                />
              }>
              <div class="p-2">
                <FormItem formFieldName="policies" required component={LabeledCheckbox} options={petTypes} />
              </div>
            </ExpandableSection>
            <ExpandableSection
              headerBorder
              btnClass="mb-0 capitalize"
              defaultOpen={true}
              class="mb-2 rounded-lg border border-input-border px-5 text-base"
              title={t('Pet size limit')}>
              <div class="p-2">
                <Radio
                  class="grid grid-cols-2"
                  items={petSizeOpts}
                  value={petSize()}
                  onInput={(value) => {
                    setPetSize(value as string);
                    form.setFieldsValue({
                      size: value,
                    });
                  }}
                />
              </div>
            </ExpandableSection>
            <div class="my-6 grid grid-cols-2 gap-8">
              <FormItem
                component={LabeledNumberInput}
                label={t('Additional pet fee(per month/$)')}
                placeholder={t('Please enter')}
                formFieldName="fee"
                getValueFromEvent={(e) => +e}
                step={0.5}
              />
              <FormItem
                component={LabeledNumberInput}
                label={t('Pet deposit($)')}
                placeholder={t('Please enter')}
                formFieldName="deposit"
                getValueFromEvent={(e) => +e}
                step={0.5}
              />
            </div>
            <div>
              <FormItem
                formFieldName="petPolicyDesc"
                label={t('Special requirements or notes')}
                component={LabeledTextInput}
                placeholder={t('e.g., must provide proof of vaccinations')}
              />
            </div>
          </Show>
        </div>
      </SegmentFormItem>
      <SegmentFormItem title={t('Magic AI')} id="magic-ai" progress={magicAiProgress()} error={!form.isValidate} autoScroll={false}>
        <div class="p-8">
          <Show when={operateType() === 'default'}>
            <DefaultEdit tags={unitListingDetail()?.tags} toggle={handleToggle} />
          </Show>
          <Show when={operateType() === 'ai'}>
            <AiEdit
              tags={unitListingDetail()?.tags}
              toggle={handleToggle}
              description={unitListingDetail()?.description}
              onSave={props.onSave}
              isChange={props.isChange}
            />
          </Show>
        </div>
      </SegmentFormItem>
    </>
  );
};
