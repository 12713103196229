import { createSignal, Show } from 'solid-js';
import IconAnnouncement from '~/assets/images/chat/annoucementMessage.svg';
import IconBot from '~/assets/images/chat/botMessage.png';
import { Avatar } from '~/components/common/Avatar';
import { toast } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useChat } from '~/contexts/local';
import { getImageUrl } from '~/utils/file';

export interface ChatMessageProps {
  message: MagicDoor.Api.ChatMessageDto;
  allMessage: MagicDoor.Api.ChatMessageDto[];
  chatDetails: MagicDoor.Api.HydratedMagicChatDto | undefined;
}

export const useMessageUtils = (props: ChatMessageProps) => {
  const { t } = useLocalization();
  const { getParticipantName } = useChat();

  const [showOriginal, setShowOriginal] = createSignal<boolean>(true);
  const [participantName, setParticipantName] = createSignal<string | null | undefined>('');

  const toggleMessageView = () => {
    if (props.message.translatedMessage) {
      setShowOriginal((prev) => !prev);
    }
  };

  const updateParticipantName = () => {
    switch (props.message.messageType) {
      case 'bot':
        return 'AI';
      case 'announcement':
        return 'Announcement';
      default:
        return props.chatDetails ? getParticipantName(props.message, props.chatDetails) : 'Unknown';
    }
  };

  const messageIcon = () => (
    <>
      <Show when={props.message.messageType === 'bot'}>
        <img src={IconBot} alt="Bot Icon" class="size-fit rounded-full" />
      </Show>

      <Show when={props.message.messageType === 'announcement'}>
        <img src={IconAnnouncement} alt="Announcement Icon" class="size-fit" />
      </Show>

      <Show when={props.message.messageType === 'participant'}>
        <Avatar name={participantName() || ''} size="large" />
      </Show>

      <Show
        when={
          props.message.messageType !== 'bot' && props.message.messageType !== 'announcement' && props.message.messageType !== 'participant'
        }>
        <Avatar size="large" imgUrl={getImageUrl('avatar', props.message.participantId as string)} name={participantName() ?? ''} />
      </Show>
    </>
  );

  const messageStyle = () => {
    switch (props.message.messageType) {
      case 'bot':
        return 'rounded-full';
      case 'announcement':
        return 'border border-warning bg-[#FFCB97]';
      case 'participant':
        return '';
      default:
        return 'border border-[#7F0DC5] bg-essential-colour';
    }
  };

  const participantInSystem = () => {
    return props.chatDetails?.type !== 'unknown' && props.message.messageType === 'participant';
  };

  const findParticipant = () => {
    return props.chatDetails?.participants?.find((p) => p.id === props.message.participantId);
  };

  const linkToDetails = () => {
    if (!participantInSystem()) return;

    const participant = findParticipant();
    if (!participant) return;

    let path = '';
    switch (participant.participantType) {
      case 'tenant':
        path = `/users/tenants/${participant.accountId}`;
        break;
      case 'vendor':
        path = `/maintenance/vendors/${participant.accountId}`;
        break;
      default:
        console.error(`Unsupported participant type: ${participant}`);
        return;
    }

    if (path) {
      return path;
    } else {
      toast(t('No valid navigation path found'), 'error');
    }
  };

  return {
    showOriginal,
    setShowOriginal,
    participantName,
    setParticipantName,
    toggleMessageView,
    updateParticipantName,
    messageIcon,
    messageStyle,
    participantInSystem,
    linkToDetails,
  };
};
