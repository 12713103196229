import { useParams } from '@solidjs/router';
import { Show, For, createSignal, onCleanup, createMemo } from 'solid-js';
import { template } from 'solid-js/web';
import { Button } from '~/components/common/Buttons';
import { RingLoader } from '~/components/common/Loaders';
import { Modal } from '~/components/modals/Modal';
import { ReportStatusIndicator } from '~/components/rental-application/components/StatusIndicators';
import { REPORT_STATUS_MAP, statusIndicatorsArray } from '~/components/rental-application/components/constants';
import { toast } from '~/components/ui';
import { useLocalization, useRentalApplication } from '~/contexts/global';
import { ReportStatus } from '~/swagger/Api';
import { cn } from '~/utils/classnames';

export const TransunionReports = () => {
  const { t } = useLocalization();
  const { applicationId } = useParams();
  const { applicationStore, getReport, setApplicationStore, requestReport, getReportStatus, fetchApplication } = useRentalApplication();

  const [timer, setTimer] = createSignal<number>(0);
  const [showContent, setShowContent] = createSignal<boolean>(false);

  const reportStatus = createMemo(() => applicationStore.application?.reportStatus ?? 'notRequested');

  const reports: { title: string; type: `${MagicDoor.Api.TransunionReportType}` }[] = [
    {
      title: t('Credit report'),
      type: 'credit',
    },
    {
      title: t('Eviction relation proceedings'),
      type: 'eviction',
    },
    {
      title: t('Criminal background report'),
      type: 'criminal',
    },
  ];

  const html = createMemo(() => {
    if (applicationStore.currentPreviewReportType) {
      const str = applicationStore.reports[applicationStore.currentPreviewReportType];
      if (str) {
        setTimeout(() => {
          const links = document.querySelector('#report_container')?.querySelectorAll('link') as any;
          Promise.all(
            Array.from(links).map((link: any) => {
              return new Promise((resolve, reject) => {
                link.addEventListener('load', resolve);
                link.addEventListener('error', reject);
              });
            })
          ).finally(() => {
            setShowContent(true);
          });
        }, 100);
        return template(`<div>${str}</div>`);
      }
    }

    return () => <span>{t('loading')}</span>;
  });

  const onRequestReport = async () => {
    await requestReport();
    toast.success(t('Operation successful, please waiting for report.'));
    const interval = setInterval(async () => {
      if ((await getReportStatus()) === 'available') {
        clearInterval(interval);
        fetchApplication(applicationId);
      }
    }, 5000);
    setTimer(interval as unknown as number);
  };

  onCleanup(() => {
    clearInterval(timer());
  });

  return (
    <>
      <div class="flex flex-wrap justify-between gap-4">
        <Show
          when={applicationStore.application?.reportStatus !== 'notRequested'}
          fallback={
            <div class="flex h-[132px] w-full grow flex-col items-stretch justify-between gap-5 rounded-xl border border-partingline bg-white p-4">
              <div class="flex flex-col items-start justify-between gap-1.5">
                <h2 class="flex-1 text-sm font-medium capitalize text-text-level01">{t('Request report')}</h2>
                <ReportStatusIndicator label={ReportStatus.NotRequested} color="#BEC6DB" />
              </div>
              <Button
                loading={applicationStore?.isReportLoading}
                onClick={onRequestReport}
                variant="outlined"
                class="flex w-full justify-center text-sm capitalize">
                {t('Request report')}
              </Button>
            </div>
          }>
          <For each={reports}>
            {(section) => (
              <div class="lex h-[132px] w-full flex-1 flex-col  items-stretch justify-between gap-5 rounded-xl border border-partingline bg-white p-4">
                <div class="flex flex-col items-start justify-between gap-1.5">
                  <h2 class="flex-1 text-sm font-medium capitalize text-text-level01">{section.title}</h2>
                  <ReportStatusIndicator
                    {...(statusIndicatorsArray.find(
                      (item) => item.label.toLowerCase() === REPORT_STATUS_MAP[reportStatus()].toLowerCase()
                    ) as (typeof statusIndicatorsArray)[0])}
                  />
                </div>
                <Button
                  onClick={() => {
                    setShowContent(false);
                    getReport(section.type);
                  }}
                  disabled={reportStatus() !== 'available'}
                  variant="outlined"
                  class="mt-3 flex w-full justify-center text-sm capitalize">
                  {t('View report')}
                </Button>
              </div>
            )}
          </For>
        </Show>
      </div>

      <Modal
        class="!w-4/5"
        visible={!!applicationStore.currentPreviewReportType}
        onCancel={() => {
          setApplicationStore('currentPreviewReportType', undefined);
        }}
        confirmation={false}
        onDone={() => {
          setApplicationStore('currentPreviewReportType', undefined);
        }}
        title={reports.find((item) => item.type === applicationStore.currentPreviewReportType)?.title}>
        <div class="thinscroll flex h-[calc(100vh-400px)] w-full items-center justify-center overflow-auto">
          <Show when={applicationStore.isReportLoading && !showContent()}>
            <RingLoader color="#A126EC" />
          </Show>
          <div
            class={cn('h-full grow', {
              'invisible w-0 grow-0': applicationStore.isReportLoading,
            })}>
            <div id="report_container" class={`${showContent() ? 'block' : 'hidden'}`}>
              {html() as any}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
