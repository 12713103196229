import { onMount, Show } from 'solid-js';
import { Form, FormItem } from '~/components/common/BetterForm';
import DueDateInputField from '~/components/common/Inputs/DueDateInputField';
import LabeledNumberInput from '~/components/common/Inputs/LabeledNumberInput';
import { LabeledSelect } from '~/components/common/Inputs/LabeledSelect';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { LabeledOwnerSearch } from '~/components/owner';
import { CustomLevelSearch } from '~/components/search-select/LevelSearchComponents/SearchLevels';
import { LabeledLeveledSearchSelect } from '~/components/search-select/LevelSearchSelect';
import { useLocalization, useOwners } from '~/contexts/global';
import { cn } from '~/utils/classnames';
import { currency } from '~/utils/number';
import type { BetterForm } from '~/components/common/BetterForm/type';
import type { Option } from '~/components/common/DropdownMenu';
import type { TBillStore } from '~/pages/bills/store';

interface AddContributionFormProps {
  form?: BetterForm.ContextValue;
  store: TBillStore;
  class?: string;
  ref?: any;
  paymentMethodOptions: Option[];
}

export const AddContributionForm = (props: AddContributionFormProps) => {
  const { t } = useLocalization();
  const { setFilter } = useOwners();

  onMount(() => {
    setFilter({});
  });

  return (
    <div class="mx-auto flex h-full w-10/12 flex-col gap-5">
      <Form defaultForm={props.form} class={cn('text-left', props.class)}>
        <div class="mt-4 grid grid-cols-3 gap-5">
          <div class="col-span-2 lg:col-start-1">
            <FormItem
              component={LabeledLeveledSearchSelect}
              enabledTypes={[CustomLevelSearch.Property]}
              label={t('Property')}
              placeholder={t('Select property')}
              formFieldName="propertyId"
              exclude={{
                [CustomLevelSearch.Property]: (item: any) => {
                  if (props.form?.getFieldValue('ownerId')) {
                    const ownerId = props.form?.getFieldValue('ownerId');
                    // TODO select 接口没有返回 owners，所以无法实现过滤
                    return item.owners?.some((owner: any) => owner.owner.id !== ownerId);
                  }
                },
              }}
              rules={[{ required: true, message: t('Property is required') }]}
              onChange={(seleted: any[]) => {
                props.form?.setValueToStore('propertyId', seleted[0]?.id);
              }}
            />
          </div>
        </div>
        <Show when={props.form?.formStore.propertyId}>
          <div class="grid grid-cols-3 gap-5">
            <div class="col-span-2">
              <FormItem
                filter={{
                  propertyIds: [props.form?.formStore.propertyId],
                }}
                component={LabeledOwnerSearch}
                label={t('Select owner')}
                placeholder={t('Please select owner')}
                formFieldName="ownerId"
                rules={[{ required: true, message: t('Owner is required') }]}
              />
            </div>
          </div>
        </Show>

        <div class="mt-4 grid grid-cols-3 gap-5">
          <FormItem
            options={props.store.bankAccountOptions || []}
            removeValueOnClean
            class="col-span-1"
            placeholder={t('Please select bank account')}
            label={t(`Bank account`)}
            formFieldName="bankAccountId"
            component={LabeledSelect}
            rules={[{ message: t(`Please select bank account`), required: true }]}
          />
          <FormItem
            removeValueOnClean
            class="col-span-1"
            placeholder={t('Please select contributionDate')}
            label={t(`Contribution date`)}
            formFieldName="contributionDate"
            component={DueDateInputField}
            rules={[{ message: t(`Please select contributionDate`), required: true }]}
          />
        </div>

        <div class="mt-4 grid grid-cols-3 gap-5">
          <FormItem
            options={props.paymentMethodOptions}
            removeValueOnClean
            class="col-span-1"
            placeholder={t('Please select payment method')}
            label={t(`Payment method`)}
            formFieldName="paymentMethod"
            component={LabeledSelect}
            rules={[{ message: t(`Please select payment method`), required: true }]}
          />
          <FormItem
            removeValueOnClean
            class="col-span-1"
            placeholder={t('Please input the contribution amount')}
            label={t(`Contribution amount`)}
            formFieldName="amount"
            prepend="$"
            component={LabeledNumberInput}
            rules={[{ message: t(`Please input the contribution amount`), required: true }]}
          />
        </div>
        <div class="mt-4 grid grid-cols-3 gap-5">
          <FormItem
            class="col-span-2"
            formFieldName="externalTransactionId"
            label={t('External transaction ID')}
            component={LabeledTextInput}
            placeholder={t('Please enter')}
          />
          <FormItem
            class="col-span-2"
            formFieldName="memo"
            label={t('Memo')}
            component={LabeledTextInput}
            placeholder={t('Please enter')}
          />
        </div>
      </Form>
      <div class="flex flex-1 items-end justify-end pb-4 text-right">
        <Show when={props?.form?.formStore.amount}>
          <div>
            <span class="mr-2 text-sm uppercase text-text-level03 lg:text-xl">{t('Owner contribution')}: </span>
            <span class="text-base font-bold text-essential-colour lg:text-2xl">{currency(props?.form?.formStore.amount)}</span>
          </div>
        </Show>
      </div>
    </div>
  );
};
