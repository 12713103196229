import { leaseDraftRepository } from '~/pdfsigner/repositories/leaseDraftRepository';
import { MagicUseCase } from '~/pdfsigner/usecases/baseUseCases/magicUseCase';
import { LoadLeaseDraftUseCase } from './loadLeaseDraftUseCase';
import { ValidateDocumentUseCase } from './validateDocumentUseCase';

export class SendDraftForSigningUseCase extends MagicUseCase {
  public async runLogic() {
    new ValidateDocumentUseCase().execute();
    const selectedDocument = this.getState().selectedDocument;
    if (selectedDocument?.id) {
      const status = await leaseDraftRepository.sendForSigning(selectedDocument.id);
      selectedDocument.status = status;
      await new LoadLeaseDraftUseCase().execute(selectedDocument.id);
    }
  }
}
