import { createResource, createSignal, untrack } from 'solid-js';
import type {
  InitializedResource,
  InitializedResourceOptions,
  Resource,
  ResourceActions,
  ResourceFetcher,
  ResourceOptions,
  ResourceSource,
} from 'solid-js';

type Mutation<T extends (...args: any[]) => Promise<any>> = T & {
  pending: boolean;
  loading: boolean;
  error: any | null;
};

export const createMutation = <T extends (...args: any[]) => Promise<any>>(fn: T) => {
  // const [state, setState] = createSignal<'idle' | 'pending' | 'error' | 'success'>('idle');
  const [pending, setPending] = createSignal<boolean>(false);
  const [error, setError] = createSignal<any | null>(null);

  const dispatch = ((...args) => {
    setError(null);
    setPending(true);
    return fn(...args)
      .catch((err) => {
        setError(err);
        throw err;
      })
      .finally(() => setPending(false));
  }) as T;

  return Object.defineProperties(dispatch, {
    pending: { get: pending },
    loading: { get: pending },
    error: { get: error },
  }) as Mutation<T>;
};

type LazyResourceActions<T, R = unknown> = ResourceActions<T, R> & { fetch: () => void };

type LazyInitializedResourceReturn<T, R = unknown> = [InitializedResource<T>, LazyResourceActions<T, R>];

type LazyResourceReturn<T, R = unknown> = [Resource<T>, LazyResourceActions<T | undefined, R>];

export function createLazyResource<T, R = unknown>(
  fetcher: ResourceFetcher<true, T, R>,
  options: InitializedResourceOptions<NoInfer<T>, true>
): LazyInitializedResourceReturn<T, R>;
export function createLazyResource<T, R = unknown>(
  fetcher: ResourceFetcher<true, T, R>,
  options?: ResourceOptions<NoInfer<T>, true>
): LazyResourceReturn<T, R>;
export function createLazyResource<T, S, R = unknown>(
  source: ResourceSource<S>,
  fetcher: ResourceFetcher<S, T, R>,
  options: InitializedResourceOptions<NoInfer<T>, S>
): LazyInitializedResourceReturn<T, R>;
export function createLazyResource<T, S, R = unknown>(
  source: ResourceSource<S>,
  fetcher: ResourceFetcher<S, T, R>,
  options?: ResourceOptions<NoInfer<T>, S>
): LazyResourceReturn<T, R>;
export function createLazyResource<T, S, R>(
  pSource: ResourceSource<S> | ResourceFetcher<S, T, R>,
  pFetcher?: ResourceFetcher<S, T, R> | ResourceOptions<T, S>,
  pOptions?: ResourceOptions<T, S> | undefined
): LazyResourceReturn<T, R> {
  const [trigger, setTrigger] = createSignal<boolean>();

  let source: ResourceSource<S>;
  let fetcher: ResourceFetcher<S, T, R>;
  let options: ResourceOptions<T, S>;

  if ((arguments.length === 2 && typeof pFetcher === 'object') || arguments.length === 1) {
    source = trigger as ResourceSource<S>;
    fetcher = pSource as ResourceFetcher<S, T, R>;
    options = (pFetcher || {}) as ResourceOptions<T, S>;
  } else {
    source = pSource as ResourceSource<S>;
    fetcher = pFetcher as ResourceFetcher<S, T, R>;
    options = pOptions || ({} as ResourceOptions<T, S>);
  }

  const [resource, { refetch, mutate }] = createResource(source, fetcher, options);

  const fetch = () => trigger() || setTrigger(true);

  return [resource, { fetch, refetch, mutate }];
}

export const createTriggerResource = <S, T, R = unknown>(fetcher: ResourceFetcher<S, T, R>) => {
  const [trigger, setTrigger] = createSignal<S>();
  const [resource, actions] = createResource(trigger, fetcher);
  const get = (trigger: S) => setTrigger(() => trigger);
  const forceGet = (pTrigger: S) => {
    const triggerValue = untrack(trigger);
    if (pTrigger === triggerValue) {
      actions.refetch();
      return;
    }
    get(pTrigger);
  };
  return [resource, get, Object.assign(actions, { forceGet })] as const;
};

export const search = <T, K extends keyof T>(items: T[], query: string, keys: K[]): T[] => {
  if (!query) return items;
  const q = query.toLowerCase();
  return items.filter((item) =>
    keys.some((key) => {
      const value = item[key];
      if (value == null) return false;
      if (typeof value === 'string') {
        return value.toLowerCase().includes(q);
      }
      if (typeof value === 'number') {
        return String(value).includes(q);
      }
      if (typeof value === 'object') {
        return JSON.stringify(value).toLowerCase().includes(q);
      }
      return false;
    })
  );
};

export type Pagination<T> = {
  page: number;
  pageSize: number;
  totalCount: number;
  totalPages: number;
  items: T[];
};

export const pagianate = <T>(items: T[], page: number, pageSize: number): Pagination<T> => {
  return {
    page,
    pageSize,
    totalCount: items.length,
    totalPages: Math.ceil(items.length / pageSize),
    items: items.slice((page - 1) * pageSize, page * pageSize),
  };
};

export type Filter<T> = {
  page?: number;
  pageSize?: number;
  searchText?: string;
  searchKeys?: (keyof T)[];
  filterFn?: (item: T) => boolean;
};

export const filterResource = <T>(resource: () => T[] | undefined, filter: Filter<T>): Pagination<T> | undefined => {
  let items = resource();
  if (items == null) return;

  const { page = 1, pageSize = 10, searchText, searchKeys = [], filterFn } = filter;

  if (typeof filterFn === 'function') {
    items = items.filter(filterFn);
  }

  if (searchText) {
    items = search(items, searchText, searchKeys);
  }

  return pagianate(items, page, pageSize);
};
