import { createEffect, For, Show } from 'solid-js';
import { createStore, produce } from 'solid-js/store';
import { Dynamic } from 'solid-js/web';
import PlusIcon from '~/assets/images/common/plus.svg?component-solid';
import IconRemove from '~/assets/images/common/remove.svg?component-solid';
import { Button } from '~/components/common/Buttons';
import type { Component, JSX } from 'solid-js';
import type { LabeledSearchSelectProps } from '~/components/ui/Select/SearchSelect';

type MutiSelectItems = (MagicDoor.Api.HydratedPropertyDto | undefined)[];

export const MutiSelect = <D extends { id: unknown }, U extends boolean | undefined = undefined>(props: {
  items: string[];
  onMutiSelectInput: (propertyIds: string[], newProperties: MutiSelectItems) => void;
  as?: Component<LabeledSearchSelectProps<D, U>>;
  addButtonText: string;
  renderSelectedItem?: (item: D) => JSX.Element;
  renderSelector?: (opts: { exclude: (item: D) => boolean; onSelect: (selected: any) => void }) => JSX.Element;
  initialSelectedItems?: MutiSelectItems;
}) => {
  const [selectedItems, setSelectedItems] = createStore<MutiSelectItems>(props.initialSelectedItems || []);

  function setItemData(index: number = selectedItems.length, selected?: MagicDoor.Api.HydratedPropertyDto) {
    if (index !== undefined) {
      setSelectedItems(
        produce((prev) => {
          prev.splice(index, 1, selected);
        })
      );
    }
  }

  const removeLine = (index: number) => {
    setSelectedItems(
      produce((prev) => {
        prev.splice(index, 1);
      })
    );
  };

  createEffect(() => {
    props.onMutiSelectInput(
      selectedItems.map((p) => p?.id ?? ''),
      selectedItems
    );
  });

  const fallback = (index: number) => {
    const exclude = (item: D) => selectedItems.findIndex((_item) => _item?.id === item.id) >= 0;
    return (
      <>
        {props.renderSelector ? (
          props.renderSelector({
            exclude,
            onSelect: (selected: any) => setItemData(index, selected),
          })
        ) : (
          <Dynamic
            component={props.as}
            exclude={exclude}
            groupClass="flex-1"
            onInput={(_: any, selected: any) => setItemData(index, selected)}
          />
        )}
      </>
    );
  };

  return (
    <For each={props.items}>
      {(_, index) => (
        <div class="col-span-2 flex items-center justify-between gap-4">
          <Show when={selectedItems[index()]} fallback={fallback(index())}>
            {props.renderSelectedItem?.(selectedItems[index()] as unknown as D)}
          </Show>
          <Show
            when={index() === props.items.length - 1}
            fallback={
              <div class="ml-5 flex items-center pt-2">
                <IconRemove class="cursor-pointer text-link" onClick={() => removeLine(index())} />
              </div>
            }>
            <div class="flex shrink-0 items-center">
              <Button color="link" variant="outlined" onClick={() => setItemData()}>
                <PlusIcon class="hidden sm:block" /> {props.addButtonText}
              </Button>
            </div>
          </Show>
        </div>
      )}
    </For>
  );
};
