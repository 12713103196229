import { Show, createEffect, createMemo, createSignal, mergeProps } from 'solid-js';
import { cn } from '~/utils/classnames';
import { getDecimalPlaces } from '~/utils/tool';
import { LabeledGroup } from './LabeledGroup';
import type { LabeledGroupProps } from './LabeledGroup';
import type { Component } from 'solid-js';

export interface InputProps extends LabeledGroupProps {
  placeholder?: string;
  value?: string | number | null | undefined;
  onInput?: (value: string, event: Event) => void;
  onClick?: (isMouseDown: boolean) => void;
  validationFunction?: (value: string | number) => string | undefined;
  labelClass?: string;
  suffix?: string;
  inputClass?: string;
  prepend?: string;
  percent?: boolean;
  disabled?: boolean;
  inputContainerClass?: string;
  max?: number;
  maxlength?: number;
  eventTriggerName?: 'onChange' | 'onInput';
}

const defaultTriggerName = 'onChange';

export const LabeledTextInput: Component<InputProps> = (rawProps) => {
  const props = mergeProps({ max: rawProps.type === 'number' ? (rawProps.max ? 1000000000 : undefined) : undefined }, rawProps);
  const [value, setValue] = createSignal<string | number>();
  const [isTouched, setTouched] = createSignal<boolean>(false);
  const [setError] = createSignal<string | undefined>(undefined);
  let inputRef!: HTMLInputElement;

  const isInvalid = createMemo(() => !!props.error);
  const errorMessage = createMemo(() => {
    if (!isTouched() || !props.validationFunction) return undefined;
    return handleInputValidation(props.value, props.validationFunction, setError) || props.error;
  });
  const onChangeMethodName = () => props?.eventTriggerName ?? defaultTriggerName;

  const handleInput = (value: string, event: Event) => {
    const trimmedValue = value.trim();
    props.onInput && props.onInput(props.percent ? (+trimmedValue / 100).toFixed(getDecimalPlaces(trimmedValue) + 2) : trimmedValue, event);
    setTouched(true);
  };

  const handleBlur = (): boolean => setTouched(true);

  createEffect(() => {
    if (props.percent && props.value) {
      const dpFixed = getDecimalPlaces(props.value) < 2 ? 0 : getDecimalPlaces(props.value) - 2;
      setValue((100 * (props.value as number)).toFixed(dpFixed));
    } else {
      setValue(props.value ?? '');
      if (!props.value && inputRef) {
        inputRef.value = '';
      }
    }
  });
  return (
    <LabeledGroup {...props} error={errorMessage()} labelClass={props.labelClass}>
      <div
        class={cn(
          'flex w-full flex-1 overflow-hidden rounded-md border bg-inputbox-bg transition focus-within:ring-1 focus-within:ring-essential-colour',
          props.inputContainerClass
        )}
        classList={{
          'border-red-300': isInvalid(),
          'ring-0': isInvalid(),
        }}>
        <Show when={props.prepend}>
          <span class="flex items-center border-r border-[#5551] px-3 text-text-level03">{props.prepend}</span>
        </Show>
        <input
          ref={inputRef}
          max={props.max}
          maxlength={props.maxlength}
          class={cn(
            'w-full flex-1 bg-transparent px-3 py-2 text-sm text-black outline-none placeholder:text-auxiliary-text',
            { 'cursor-not-allowed': props.disabled },
            props.inputClass
          )}
          value={value()}
          required={props.required}
          {...{
            [onChangeMethodName()]: (e: Event) => {
              handleInput((e.target as HTMLInputElement).value, e);
            },
          }}
          step="any"
          disabled={props.disabled}
          onBlur={handleBlur}
          type={props.type || 'text'}
          placeholder={props.placeholder}
          onMouseDown={() => props.onClick && props.onClick(true)}
          onMouseUp={() => props.onClick && props.onClick(false)}
        />
        <Show when={props.suffix}>
          <span class="flex items-center border-l border-[#5551] px-3 text-text-level03">{props.suffix}</span>
        </Show>
      </div>
    </LabeledGroup>
  );
};

const handleInputValidation = (
  value: string | number | null | undefined,
  validationFunction: (value: string | number) => string | undefined,
  setErrorFunction: (value: string | number | undefined) => void
) => {
  const errorMessage = validationFunction(value || '');
  setErrorFunction(errorMessage);
  return errorMessage;
};
