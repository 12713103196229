import { A } from '@solidjs/router';
import { Show } from 'solid-js';
import { TinToggle } from '~/components/TinToggle';
import DisplayAddress from '~/components/common/DisplayAddress';
import IconField from '~/components/common/IconField';
import ExpandableSection from '~/components/common/expandables/ExpandableSection';
import { OwnerHeader } from '~/components/owner';
import { IconHash, IconMapPin, IconUser, IconBuilding, IconMail, IconPhone, IconSquarePen } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { cn } from '~/utils/classnames';
import type { Component } from 'solid-js';

const InfoSection: Component<{ class?: string; loading?: boolean; owner?: MagicDoor.Api.OwnerDto }> = (props) => {
  const { t } = useLocalization();
  return (
    <div class="divide-y divide-partingline overflow-hidden rounded-lg border border-partingline bg-white">
      <div class="bg-#A51CD6 relative flex h-20 items-center space-y-1 bg-purple px-5 py-4">
        <div class="flex w-full items-center gap-2 ">
          <OwnerHeader feedbackIconMode="row" loading={props.loading} owner={props.owner} />
          <A href={`/users/owners/${props.owner?.id}/edit`} class="ml-auto">
            <IconSquarePen class="size-5 text-white" />
          </A>
        </div>
        <svg
          class="top-18 size-18 absolute right-10"
          width="87"
          height="87"
          viewBox="0 0 87 87"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M40.7937 50.0166V56.1633C34.0714 56.3591 27.3407 57.413 20.5911 59.3299C17.3963 60.2372 15.1818 63.1375 15.1378 66.4538L15.1371 66.5545V72.8558H40.7937V79H11.7276C10.2212 79 9 77.7774 9 76.2692V66.5545C9 60.4487 13.0491 55.0851 18.9162 53.4189C26.2087 51.3478 33.5013 50.2138 40.7937 50.0166ZM58.0021 44.1829C59.4996 42.9767 61.6337 42.9767 63.1313 44.1829L76.4732 54.9288C76.9498 55.3127 77.3344 55.7987 77.5986 56.351C77.8629 56.9033 78 57.5079 78 58.1204V74.6478C78 76.9101 76.1682 78.744 73.9086 78.744H47.2248C44.9651 78.744 43.1334 76.9101 43.1334 74.6478V58.1204C43.1334 57.5079 43.2705 56.9033 43.5348 56.351C43.799 55.7987 44.1836 55.3127 44.6602 54.9288L58.0021 44.1829ZM60.5667 50.0029L49.2705 59.1011V72.5998H57.566C57.5209 72.462 57.498 72.318 57.4981 72.1731V65.5806C57.4981 64.8265 58.1087 64.2152 58.8619 64.2152H62.2714C63.0247 64.2152 63.6352 64.8265 63.6352 65.5806V72.1731C63.6352 72.3221 63.6113 72.4655 63.5674 72.5998H71.8629V59.1011L60.5667 50.0029ZM43.0951 8C53.6401 8 62.1884 16.5582 62.1884 27.1154C62.1884 37.6725 53.6401 46.2308 43.0951 46.2308C32.5502 46.2308 24.0019 37.6725 24.0019 27.1154C24.0019 16.5582 32.5502 8 43.0951 8ZM43.0951 14.1442C35.9397 14.1442 30.139 19.9516 30.139 27.1154C30.139 34.2791 35.9397 40.0865 43.0951 40.0865C50.2506 40.0865 56.0513 34.2791 56.0513 27.1154C56.0513 19.9516 50.2506 14.1442 43.0951 14.1442Z"
            fill="url(#paint0_linear_11067_17153)"
          />
          <defs>
            <linearGradient id="paint0_linear_11067_17153" x1="43.5" y1="8" x2="43.5" y2="79" gradientUnits="userSpaceOnUse">
              <stop stop-color="white" stop-opacity="0.3" />
              <stop offset="1" stop-color="white" stop-opacity="0" />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <div class="flex flex-wrap gap-5 p-4 *:flex-1 *:basis-56">
        <IconField class="text-base" loading={props.loading} name={t('Phone')} value={props.owner?.phone} src={IconPhone} />
        <IconField class="text-base" loading={props.loading} name={t('Email')} value={props.owner?.email} src={IconMail} />
        <IconField
          class="text-base"
          loading={props.loading}
          name={t('Is company')}
          value={props.owner?.isCompany === true ? t('Yes') : t('No')}
          src={IconBuilding}
        />
        <IconField name={t('Address')} value={<DisplayAddress class="text-wrap" address={props.owner?.address} />} src={IconMapPin} />
      </div>

      <ExpandableSection title={t('Payment information')}>
        <div>
          <div class="flex w-full flex-row flex-wrap gap-y-4 border-t py-4 xl:flex-col">
            <IconField
              class="basis-1/3"
              name={t(`Taxpayer id (tin)`)}
              value={
                <Show when={props.owner?.federalTaxInformation?.taxId}>
                  <TinToggle
                    number={props.owner?.federalTaxInformation?.taxId || ''}
                    type={props.owner?.federalTaxInformation?.taxIdType === 'ssn' ? 'ssn' : 'ein'}
                  />
                </Show>
              }
              src={IconHash}
            />
            <IconField
              class="basis-1/3"
              name={t('Name')}
              value={props.owner?.federalTaxInformation?.overwriteName || 'Same as above'}
              src={IconUser}
            />
            <IconField
              class="basis-1/3"
              name={t('Address')}
              value={
                <Show when={props.owner?.federalTaxInformation?.overwriteAddress} fallback={t('Same as above')}>
                  <DisplayAddress address={props.owner?.federalTaxInformation?.overwriteAddress} />
                </Show>
              }
              src={IconMapPin}
            />
          </div>
        </div>
      </ExpandableSection>
    </div>
  );
};

// TODO - Implement OwnerPortalSection + Activity section when backend ready

export const OwnerSummary: Component<{ class?: string; loading?: boolean; owner?: MagicDoor.Api.OwnerDto }> = (props) => (
  <div class={cn('flex flex-col gap-4', props.class)}>
    <InfoSection loading={props.loading} owner={props.owner} />
  </div>
);
