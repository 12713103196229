import { Show, Switch, Match, createMemo } from 'solid-js';
import IconCheck from '~/assets/images/units/listing/check.svg?component-solid';
import NoAllowImg from '~/assets/images/units/listing/no_allow.png';
import NotSetImg from '~/assets/images/units/listing/not_set.svg';
import { useLocalization, useUnitListings } from '~/contexts/global';

type petSizeType = 'small' | 'medium' | 'large';

export const PetPolicy = () => {
  const { t } = useLocalization();
  const { unitListingDetail } = useUnitListings();
  const petPolicies = createMemo(() => Object.keys(unitListingDetail()?.petPolicy?.policies ?? {}));
  const petSizeMap = {
    small: t('Small (under 10kg)'),
    medium: t('Medium (10 - 25kg)'),
    large: t('Large (25 - 50kg)'),
  };
  return (
    <Switch>
      <Match when={unitListingDetail()?.petPolicy?.allowed}>
        <div class="w-full py-1">
          <div class="mb-3 flex w-full items-center justify-center rounded-lg bg-green-level01 py-2 text-light-green">
            <IconCheck class="mr-1" />
            {t('Allowed')}
          </div>
          <ul class="px-3 text-sm text-text-level02">
            <Show when={petPolicies().length > 0}>
              <li class="mb-2 list-disc">{petPolicies().join(', ')}</li>
            </Show>
            <Show when={unitListingDetail()?.petPolicy?.size}>
              <li class="mb-2 list-disc">{petSizeMap[unitListingDetail()?.petPolicy?.size as petSizeType]}</li>
            </Show>
            <Show when={unitListingDetail()?.petPolicy?.fee}>
              <li class="mb-2 list-disc">
                {unitListingDetail()?.petPolicy?.fee} {t('Monthly pet fee')}
              </li>
            </Show>
            <Show when={unitListingDetail()?.petPolicy?.deposit}>
              <li class="mb-2 list-disc">
                ${unitListingDetail()?.petPolicy?.deposit} {t('pet deposit')}
              </li>
            </Show>
            <Show when={unitListingDetail()?.petPolicy?.description}>
              <li class="list-disc">{unitListingDetail()?.petPolicy?.description}</li>
            </Show>
          </ul>
        </div>
      </Match>
      <Match when={unitListingDetail()?.petPolicy?.allowed === false}>
        <div class="absolute left-1/2 top-1/2 flex w-full -translate-x-1/2 -translate-y-1/2 flex-col items-center">
          <img src={NoAllowImg} alt="pet icon" />
          <p class="mt-2 text-center text-sm capitalize text-light-danger">{t('Not allow')}</p>
        </div>
      </Match>
      <Match when={unitListingDetail()?.petPolicy?.allowed === undefined}>
        <div class="absolute left-1/2 top-1/2 flex w-full -translate-x-1/2 -translate-y-1/2 flex-col items-center">
          <img src={NotSetImg} alt="pet icon" />
          <p class="mt-2 text-center text-sm capitalize text-text-level03">{t('Not set')}</p>
        </div>
      </Match>
    </Switch>
  );
};
