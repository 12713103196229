import { useNavigate } from '@solidjs/router';
import { createSignal } from 'solid-js';
import { FormWrapper } from '~/components/common/BetterForm/Form';
import { useForm } from '~/components/common/BetterForm/context';
import Breadcrumb from '~/components/common/Breadcrumb';
import { Button } from '~/components/common/Buttons';
import {
  CategoryContentSection,
  CategoryComponentSection,
  getErrorAndProgressProps,
  CategoryComponentWrapper,
} from '~/components/common/Category';
import { FileRepository } from '~/components/common/Upload/request';
import { toast } from '~/components/ui';
import { useLocalization, useProperties, useUnits, useWorkOrders } from '~/contexts/global';
import { transformKeyValuePairs } from '~/pages/maintenance-request/tools';
import { WORK_ORDER_BASE_URL } from '~/repositories/workOrderRepository';
import { AssignedTo } from './AssignTo';
import { EssentialInfomation } from './EssentialInfomation';
import { MagicTags } from './MagicTags';

const fileRepository = new FileRepository();

export const AddWorkOrderView = () => {
  const navigate = useNavigate();

  const { t } = useLocalization();
  const { addWorkOrder, newFiles } = useWorkOrders();
  const { updateUnitMagicTags } = useUnits();
  const { updatePropertyMagicTags } = useProperties();

  const form = useForm();

  const [isSubmiting, setIsSubmiting] = createSignal<boolean>(false);

  async function onSubmit(
    formData: MagicDoor.Api.CreateWorkOrderDto & {
      unitId: string;
      propertyId: string;
      unitIdForConfidentialDetail: string;
      assignedPropertyManagerId?: string;
    }
  ) {
    try {
      setIsSubmiting(true);

      const magicTags = transformKeyValuePairs(formData as never as Record<string, string>);
      const workOrder = await addWorkOrder({
        ...formData,
        permissionToEnter: true,
      });
      for (const file of newFiles()) {
        await fileRepository.uploadFile(`${WORK_ORDER_BASE_URL}/${workOrder.id}/files`, file);
      }
      if (formData.unitId || formData.unitIdForConfidentialDetail) {
        await updateUnitMagicTags(formData.unitId || formData.unitIdForConfidentialDetail, { magicTags });
      } else {
        await updatePropertyMagicTags(formData.propertyId, { magicTags });
      }
      form?.resetFields();
      toast.success(t('{name} has been added successfully', { name: 'Work order' }));
      navigate(`/maintenance/work-orders/${workOrder.id}`, { replace: true });
    } finally {
      setIsSubmiting(false);
    }
  }

  return (
    <FormWrapper
      initialValues={{
        status: 'pending',
        urgency: 'low',
      }}
      defaultForm={form}
      onFormSubmit={(form) =>
        onSubmit(form as MagicDoor.Api.CreateWorkOrderDto & { unitId: string; propertyId: string; unitIdForConfidentialDetail: string })
      }>
      <CategoryComponentWrapper
        sections={[
          {
            key: 'essential-information',
            name: t('Essential information'),
            component: EssentialInfomation,
            fields: [
              'description',
              'title',
              'unitId',
              'propertyIdOrUnitIdOrLeaseId',
              'urgency',
              'propertyId',
              'leaseId',
              'vendorId',
              'status',
              'dueDate',
            ],
          },
          {
            key: 'magic-tags',
            name: t('Magic tags'),
            component: MagicTags,
          },
          {
            key: 'assigned-to',
            name: t('Assigned to'),
            component: () => <AssignedTo isCreate />,
            fields: ['assignedPropertyManagerId', 'disableAi'],
          },
        ]}>
        <Breadcrumb backLink={() => navigate(-1)} items={[{ label: t(`Work orders`), link: '../' }, { label: t(`Add work order`) }]} />
        <div class="m-8 flex h-section1 flex-col overflow-hidden rounded-lg bg-white text-sm">
          <div class="flex flex-1 overflow-hidden">
            <div class="hidden w-[330px] shrink-0 border-r border-r-partingline lg:block">
              <h2 class="p-6 text-lg font-medium text-text-level01 ">{t('Work order')}</h2>
              <CategoryComponentSection {...getErrorAndProgressProps(form)} />
            </div>
            <div class="thinscroll flex grow flex-col items-stretch overflow-auto pb-8 text-left">
              <CategoryContentSection mode="padding" />
            </div>
          </div>
          <div class="flex h-24 items-center justify-end gap-2 border-t border-partingline px-5">
            <Button
              onClick={() => {
                navigate(-1);
              }}
              variant="outlined">
              {t('Cancel')}
            </Button>
            <Button loading={isSubmiting()} type="submit">
              {t('Create work order')}
            </Button>
          </div>
        </div>
      </CategoryComponentWrapper>
    </FormWrapper>
  );
};
