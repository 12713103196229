import { createSignal } from 'solid-js';
import { Modal } from '~/components/modals/Modal';
import { CustomLevelSearch } from '~/components/search-select/LevelSearchComponents/SearchLevels';
import { LeveledSearchSelect } from '~/components/search-select/LevelSearchSelect';
import { useLocalization } from '~/contexts/global';
import ImgAddOverride from './add-override-modal.svg';

export interface AddOverridesProps {
  onClose: () => void;
  onDone: (level: string, levelId: string) => void;
  loading?: boolean;
  overrides: any[];
}

export const AddOverridesModal = (props: AddOverridesProps) => {
  const { t } = useLocalization();
  const [selectLevel, setLevel] = createSignal('');
  const [selectLevelId, setSelectLevelId] = createSignal<string>();

  return (
    <Modal
      class="min-w-[766px]"
      visible
      title={t('Add new override')}
      doneText={t('Save')}
      onCancel={props.onClose}
      onDone={() => props.onDone(selectLevel(), selectLevelId() || '')}
      loading={props.loading}>
      <img src={ImgAddOverride} />
      <div class="mt-6 px-8 pb-8">
        <div class="mt-5 uppercase text-text-level03">Select portfolio, property, unit or lease</div>

        <LeveledSearchSelect
          enabledTypes={[CustomLevelSearch.Portfolio, CustomLevelSearch.Property, CustomLevelSearch.Unit, CustomLevelSearch.Lease]}
          placeholder="Search by name"
          onChange={(v, varians) => {
            if (varians.leveledIds.length) {
              setSelectLevelId(varians.leveledIds[0][`${varians.singleType}Id`]);
              setLevel(varians.singleType as string);
            } else {
              setSelectLevelId('');
              setLevel('');
            }
          }}
          singleTypeOnly
        />
      </div>
    </Modal>
  );
};
