import { MagicUseCase } from '~/pdfsigner/usecases/baseUseCases/magicUseCase';

export class SelectSignerUseCase extends MagicUseCase {
  public async runLogic(index: number) {
    const selectedDocument = this.getState().selectedDocument;
    if (selectedDocument) {
      this.getState().selectedSigner = selectedDocument.signers[index];
    }
  }
}
