import { createSignal, onMount, Show } from 'solid-js';
import IconPlus from '~/assets/images/common/plus.svg?component-solid';
import IconRentPayment from '~/assets/images/settings/property/propertyRentPayment.svg';
import { Button } from '~/components/common/Buttons';
import ThreeSectionBox from '~/components/common/ThreeSectionBox/ThreeSectionBox';
import { confirm, toast, IconLoader } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useBankAccountsList, useRentPaymentSettings } from '~/contexts/local';
import { SettingTitle } from '~/pages/settings/SettingTitle';
import { getMinimumPaymentSettings } from '~/pages/settings/components/CommonSettings';
import Overrides from '~/pages/settings/components/Overrides';
import { AddOverridesModal } from '~/pages/settings/components/add-overrides-modal';
import { EditForm } from '~/pages/settings/components/edit-form/EditForm';
import type { ItemProps } from '~/pages/settings/components/edit-form/EditForm';
import type { RentPaymentSettingsDto } from '~/swagger/Api';

export const PropertyRentPaymentSettings = () => {
  const { t } = useLocalization();
  const {
    deleteRentPaymentSettingsByLevel,
    isLoading,
    getAllRentPaymentSettings,
    addRentPaymentSettings,
    allRentPaymentSettings,
    addRentPaymentSettingsByLevel,
  } = useRentPaymentSettings();
  const { bankAccounts } = useBankAccountsList();

  const [showModal, setShowModal] = createSignal<boolean>(false);

  const sections = (values: RentPaymentSettingsDto) => {
    return [
      {
        field: 'allowOnlinePayments',
        label: 'Allow online payments',
        type: 'boolean',
        class: 'col-span-2',
        labelContainerClass: 'flex-row justify-between gap-5 flex-wrap',
        description: 'This will allow rent payment to be made via the tenant portal.',
      },
      {
        field: 'allowAchPayments',
        label: 'Allow ACH payments',
        type: 'boolean',
        class: 'col-span-2',
        labelContainerClass: 'flex-row justify-between',
      },
      {
        field: 'allowCardPayments',
        label: 'Allow card payments',
        type: 'boolean',
        class: 'col-span-2',
        labelContainerClass: 'flex-row justify-between',
      },

      {
        field: 'bankAccountId',
        label: 'Bank account to receive payments',
        type: 'select',
        options: bankAccounts().map((account) => ({ label: account.name, value: account.id })),
        class: 'col-span-2',
        labelClass: 'w-1/2',
        labelContainerClass: 'flex-row justify-between gap-5 flex-wrap',
        description:
          'The bank account that rent payment will be sent to, changing this value will only affect future payments. Bank account has to be linked with Plaid and/or Stripe',
      },
      {
        field: 'postBillsDaysBeforeDue',
        label: 'Post bills days before due',
        type: 'number',
        class: 'col-span-2',
        labelClass: 'w-1/2',
        labelContainerClass: 'flex-row justify-between gap-5 flex-wrap',
        description: 'This should be a number input, positive numbers only, and the number needs to be 1 or above.',
        rules: [
          {
            validator: (value: number) => value <= 365 && value >= 1,
            message: t('The value of postBillsDaysBeforeDue is between 1 and 365'),
          },
        ],
      },
      {
        field: 'allowPartialPayments',
        label: 'Allow partial payments',
        type: 'boolean',
        class: 'col-span-2',
        labelContainerClass: 'flex-row justify-between gap-5 flex-wrap',
      },
      ...getMinimumPaymentSettings(values),
    ].filter(Boolean) as ItemProps[];
  };

  onMount(async () => {
    await getAllRentPaymentSettings();
  });

  const onDone = async (level: string, levelId: string) => {
    await addRentPaymentSettingsByLevel(level, levelId, {
      ...(allRentPaymentSettings()?.settings as MagicDoor.Api.RentPaymentSettingsDto),
    });
    setShowModal(false);
    getAllRentPaymentSettings();
    toast(t('Rent payment settings saved successfully'), 'success');
  };

  const handleSave = async (data: MagicDoor.Api.RentPaymentSettingsDto, level?: string, levelId?: string) => {
    if (!level || !levelId) {
      await addRentPaymentSettings(data);
      toast(t('Rent payment settings saved successfully'), 'success');
      getAllRentPaymentSettings();
    } else {
      await addRentPaymentSettingsByLevel(level, levelId, data);
      toast(t('Rent payment settings saved successfully'), 'success');
      getAllRentPaymentSettings();
    }
  };

  const onDelete = async (level: string, levelId: string) => {
    confirm({
      content: t('Are you sure you want to delete this override?'),
      title: t(`Delete override`),
      async onResolve(confirmed) {
        if (!confirmed) return;
        await deleteRentPaymentSettingsByLevel(level, levelId);
        toast(t('Rent payment settings deleted successfully'), 'success');
        getAllRentPaymentSettings();
      },
    });
  };

  const onClose = () => {
    setShowModal(false);
  };

  return (
    <>
      <ThreeSectionBox
        class="h-fit"
        topAction={
          <Button variant="outlined" class="rounded-3xl px-3 py-1 text-sm" onClick={() => setShowModal(true)}>
            <IconPlus /> <div class="hidden font-medium md:block">{t('Add new overrides')}</div>
          </Button>
        }
        topTitle={<SettingTitle title={t('Rent payment')} icon={IconRentPayment} />}
        middle={
          <div class="flex flex-col gap-5 p-4">
            <Show when={!isLoading()} fallback={<IconLoader class="mx-auto my-20 animate-spin" />}>
              <div class="flex flex-col gap-4 p-2 text-left">
                <EditForm
                  sections={(values) => sections(values)}
                  onOk={(data) => handleSave(data)}
                  model={allRentPaymentSettings()?.settings || ({} as MagicDoor.Api.RentPaymentSettingsDto)}
                />
              </div>
            </Show>
            <Overrides overrides={allRentPaymentSettings()?.overrides || []} onDelete={onDelete} title={t('Rent payment overrides')}>
              {(override) => {
                return (
                  <div class="flex flex-col gap-4 p-2 text-left">
                    <EditForm
                      sections={(values) => sections(values)}
                      onOk={(data) => handleSave(data, override.level, override.levelId)}
                      model={override.settings || ({} as MagicDoor.Api.RentPaymentSettingsDto)}
                    />
                  </div>
                );
              }}
            </Overrides>
          </div>
        }
      />
      <Show when={showModal()}>
        <AddOverridesModal overrides={allRentPaymentSettings()?.overrides || []} onClose={onClose} onDone={onDone} loading={isLoading()} />
      </Show>
    </>
  );
};
