import { urlWithQuery } from '~/utils/url';
import { BaseRestRepository } from './baseRestRepository';

const UNITS_URL = '/api/units';

export type UnitFilter = MagicDoor.Filter<{
  propertyId?: string;
  portfolioId?: string;
  searchText?: string;
}>;

export type UnitPaymentFilter = MagicDoor.Filter<{
  unitId?: string;
}>;

export type UnitImagePayload = {
  file: File;
  isDefault?: boolean;
  description?: string;
};

export class UnitRepository extends BaseRestRepository {
  public async getUnits(filter?: UnitFilter): Promise<MagicDoor.Api.HydratedUnitDtoPaginationDto> {
    const url = urlWithQuery(UNITS_URL, filter);
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async addUnit(unit: MagicDoor.Api.CreateUnitDto): Promise<MagicDoor.Api.UnitDto> {
    const url = UNITS_URL;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(unit),
    });
    return this.getJsonResponse(response);
  }

  public async getUnit(unitId: string, showErrorMessage = true): Promise<MagicDoor.Api.HydratedUnitDto> {
    const url = `${UNITS_URL}/${unitId}`;
    const response = await this.fetchWithAuth(
      url,
      {
        method: 'GET',
      },
      showErrorMessage
    );
    return this.getJsonResponse(response);
  }

  public async batchGetUnitsByIds(unitIds: string[]): Promise<MagicDoor.Api.HydratedUnitDto[]> {
    const url = urlWithQuery(`${UNITS_URL}/batch`, { unitIds });
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  /** Not preferred. use deactivateUnit instead in most cases */
  public async deleteUnit(unitId: string) {
    const url = `${UNITS_URL}/${unitId}`;
    const response = await this.fetchWithAuth(url, {
      method: 'DELETE',
    });
    return this.getJsonResponse(response);
  }

  public async deactivateUnit(unitId: string, forceEndLease?: boolean): Promise<void> {
    const url = urlWithQuery(`${UNITS_URL}/${unitId}/deactivate`, { forceEndLease });
    await this.fetchWithAuth(url, { method: 'DELETE' });
  }

  public async updateUnit(unitId: string, unit: MagicDoor.Api.UpdateUnitDto): Promise<MagicDoor.Api.UnitDto> {
    const url = `${UNITS_URL}/${unitId}`;
    const response = await this.fetchWithAuth(url, {
      method: 'PUT',
      body: JSON.stringify(unit),
    });
    return this.getJsonResponse(response);
  }

  public async addUnitImage(unitId: string, image: UnitImagePayload): Promise<MagicDoor.Api.UnitDto> {
    const formData = new FormData();
    formData.append('imageFile', image.file, image.file.name);
    formData.append('isDefault', String(image.isDefault ?? false));
    image.description && formData.append('description', image.description);
    const url = `${UNITS_URL}/${unitId}/images`;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      headers: { 'Content-Type': 'multipart/form-data' },
      body: formData,
    });
    return this.getJsonResponse(response);
  }

  public async deleteUnitImage(unitId: string, imageId: string): Promise<MagicDoor.Api.UnitDto> {
    const url = `${UNITS_URL}/${unitId}/images/${imageId}`;
    const response = await this.fetchWithAuth(url, { method: 'DELETE' });
    return this.getJsonResponse(response);
  }

  public async updateUnitMagicTags(unitId: string, magicTags: MagicDoor.Api.UpdateUnitMagicTagDto): Promise<MagicDoor.Api.UnitDto> {
    const url = `${UNITS_URL}/${unitId}/magic-tags`;
    const response = await this.fetchWithAuth(url, {
      method: 'PUT',
      body: JSON.stringify(magicTags),
    });
    return this.getJsonResponse(response);
  }

  public async getUnitPayments(filter?: UnitPaymentFilter): Promise<MagicDoor.Api.HydratedTransactionDtoPaginationDto> {
    const url = urlWithQuery(`${UNITS_URL}/${filter?.unitId}/payments`, filter);

    const response = await this.fetchWithAuth(url);

    return this.getJsonResponse(response);
  }
}

export const unitRepository = new UnitRepository();
