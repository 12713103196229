import { createEffect, createSignal, Show } from 'solid-js';
import IconEdit from '~/assets/images/common/edit.svg?component-solid';
import IconWorkOrder from '~/assets/images/vendors/workOrders.svg?component-solid';
import IconBook from '~/assets/images/work-order/book.svg?component-solid';
import IconNote from '~/assets/images/work-order/note.svg?component-solid';
import IconPropertyManager from '~/assets/images/work-order/propertyManager.svg?component-solid';
import IconVendorHand from '~/assets/images/work-order/vendorHand.svg?component-solid';
import { Button } from '~/components/common/Buttons';
import { DropdownMenu } from '~/components/common/DropdownMenu';
import IconField from '~/components/common/IconField';
import { Panel } from '~/components/common/Panels/Panel';
import { ListFileThumbnailWithPreview } from '~/components/file-attachments/ListFileThumbnailWithPreview';
import { EditMagicTagsModal, MagicTagsList } from '~/components/magic-tags';
import { MaintenanceRequestUrgency } from '~/components/maintenance-requests';
import { IconCalendar, IconFlag, IconLockKeyhole, IconLockKeyholeOpen, Skeleton, toast } from '~/components/ui';
import { useLocalization, useProperties, useUnits, useWorkOrders } from '~/contexts/global';
import { emptyPlaceholder } from '~/utils/constant';
import { dateFormat } from '~/utils/date';
import type { Component } from 'solid-js';

const PermissionToEnter: Component<{ permissionToEnter?: boolean }> = (props) => {
  const { t } = useLocalization();
  return (
    <Show when={props.permissionToEnter != null} fallback={<Skeleton class="h-9" />}>
      <Show
        when={props.permissionToEnter}
        fallback={
          <div class="flex items-center gap-2 rounded-md bg-current-alpha px-4 py-2 text-sm font-medium uppercase text-light-warning">
            <IconLockKeyhole class="size-5" />
            {t('Entry not granted')}
          </div>
        }>
        <div class="flex items-center gap-2 rounded-md bg-current-alpha px-4 py-2 text-base font-medium uppercase text-light-green">
          <IconLockKeyholeOpen class="size-5" />
          {t('Entry granted')}
        </div>
      </Show>
    </Show>
  );
};

export const WorkOrderOverviewTab: Component = () => {
  const { t } = useLocalization();

  const { workOrder, updateWorkOrderStatus, refetchWorkOrders } = useWorkOrders();
  const { updateUnitMagicTags } = useUnits();
  const { updatePropertyMagicTags } = useProperties();

  const [magicTags, setMagicTags] = createSignal<Record<string, string>>({});
  const [isMagicTagsEditModalVisible, setIsMagicTagsEditModalVisible] = createSignal(false);

  const handleStatusChange = async (value: string) => {
    if (!value) return;
    await updateWorkOrderStatus(
      workOrder()?.id as string,
      {
        status: value,
      } as MagicDoor.Api.UpdateWorkOrderStatusDto
    );
    toast.success(t('Operation successful'));
  };

  createEffect(() => {
    if (workOrder()?.unitId) {
      setMagicTags(workOrder()?.unit?.magicTags || {});
    } else {
      setMagicTags(workOrder()?.property?.magicTags || {});
    }
  });

  return (
    <div class="flex flex-col gap-5">
      <Panel
        title={
          <Show
            when={!workOrder.loading}
            fallback={
              <div>
                <Skeleton class="h-7 w-64" />
                <Skeleton class="h-4 w-72 border-t-2 border-white" />
              </div>
            }>
            <div class="flex gap-2">
              <div class="hidden size-fit rounded-lg bg-safe-green p-4 xl:flex">
                <IconWorkOrder class="size-fit text-green" />
              </div>
              <div class="flex flex-col lg:min-w-64">
                <div>{workOrder()?.title}</div>
                <div class="text-xs font-normal normal-case text-text-level03">{`${workOrder()?.property?.name} - ${
                  workOrder()?.unit?.name || ''
                }`}</div>
              </div>
            </div>
          </Show>
        }
        actions={
          <div class="flex flex-wrap items-center gap-2">
            <DropdownMenu
              onChange={handleStatusChange}
              value={['pending', 'inProgress', 'closed'].includes(workOrder()?.status as string) ? workOrder()?.status : 'pending'}
              options={[
                { label: t('Pending'), value: 'pending' },
                { label: t('In progress'), value: 'inProgress' },
                { label: t('Closed'), value: 'closed' },
              ]}
              class="shrink-0"
            />
            <Show when={workOrder()?.maintenanceRequest}>
              <Button
                class="hidden shrink-0 whitespace-nowrap font-medium capitalize xl:flex"
                variant="outlined"
                href={`/maintenance/maintenance-request/${workOrder()?.maintenanceRequestId}`}>
                {t('Maintenance request')}
              </Button>
            </Show>
            <Button
              class="shrink-0 whitespace-nowrap font-medium capitalize"
              variant="solid"
              href={`/maintenance/work-orders/${workOrder()?.id}/edit`}>
              <IconEdit class="text-white" />
              {t('Edit')}
            </Button>
          </div>
        }
        actionClass="flex gap-2">
        <div class="flex flex-col px-5  py-6">
          <div class="rounded-lg bg-light-gray p-5">
            <Show when={workOrder.loading} fallback={<div class="font-medium normal-case text-title-gray">{workOrder()?.description}</div>}>
              <Skeleton />
              <Skeleton class="mt-1 w-2/3" />
            </Show>
            <ListFileThumbnailWithPreview loading={workOrder.loading} files={workOrder()?.files} />
          </div>
          <div class="mb-3 mt-6 flex items-center gap-1 text-sm text-text-level03">
            <IconFlag class="size-4" />
            {t('Priority')}
            <Show when={!workOrder.loading} fallback={<Skeleton class="ml-2 w-16 rounded-full" />}>
              <MaintenanceRequestUrgency class="ml-2" urgency={workOrder()?.urgency} />
            </Show>
          </div>

          <div class="mb-3 flex items-center gap-1 text-sm text-text-level03">
            <IconBook class="size-4" />
            {t('Reference')}:
            <Show when={!workOrder.loading} fallback={<Skeleton class="ml-2 w-16 rounded-full" />}>
              <div class="text-title-gray">#{workOrder()?.reference}</div>
            </Show>
          </div>
          <Show when={workOrder()?.internalDescription || workOrder.loading}>
            <div class="mb-3 flex items-center gap-1 text-sm text-text-level03">
              <IconBook class="size-4" />
              {t('Internal description')}:
              <Show when={!workOrder.loading} fallback={<Skeleton class="w-48" />}>
                <span class="normal-case text-title-gray">{workOrder()?.internalDescription}</span>
              </Show>
            </div>
          </Show>
          <div class="mb-3 flex items-center gap-1 text-sm text-text-level03">
            <IconPropertyManager class="size-4" />
            {t('Property manager')}:
            <Show when={!workOrder.loading} fallback={<Skeleton class="ml-2 w-16 rounded-full" />}>
              <div class="text-title-gray">{workOrder()?.assignedPropertyManager?.name ?? emptyPlaceholder}</div>
            </Show>
          </div>
          <div class="flex flex-col gap-1 pb-4 text-sm text-text-level03 sm:flex-row">
            <div class="flex shrink-0 items-start gap-1">
              <IconNote class="-ml-px size-5" />
              {t('Magic tags')}:
            </div>
            <Show when={!workOrder.loading} fallback={<Skeleton class="w-48" />}>
              <div class="flex flex-1 flex-row flex-wrap items-center gap-2 lg:items-start">
                <div class="flex flex-1 flex-wrap items-center">
                  <MagicTagsList class="mx-2" defaultData={magicTags()} />
                </div>
                <Button size="xs" variant="outlined" onClick={() => setIsMagicTagsEditModalVisible(true)}>
                  <IconEdit class="size-5" />
                  {t('Edit magic tags')}
                </Button>
              </div>
            </Show>
          </div>
          <PermissionToEnter permissionToEnter={workOrder()?.permissionToEnter} />

          <div class="my-5 h-px bg-partingline" />
          <div class="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
            <Show when={workOrder()?.vendor || workOrder.loading}>
              <div class="flex w-full gap-2 rounded-lg border border-partingline px-4 py-3">
                <IconField
                  iconRounded={true}
                  name={t('Vendor')}
                  loading={workOrder.loading}
                  value={workOrder()?.vendor?.name}
                  src={IconVendorHand}
                />
              </div>
            </Show>
            <Show when={workOrder()?.dueDate || workOrder.loading}>
              <div class="flex w-full gap-2 rounded-lg border border-partingline px-4 py-3">
                <IconField
                  iconRounded={true}
                  name={t('Due date')}
                  loading={workOrder.loading}
                  value={dateFormat('', workOrder()?.dueDate ?? '')}
                  src={IconCalendar}
                />
              </div>
            </Show>
          </div>
        </div>
      </Panel>
      <Show when={isMagicTagsEditModalVisible()}>
        <EditMagicTagsModal
          type={workOrder()?.unitId ? 'unit' : 'property'}
          visible={isMagicTagsEditModalVisible()}
          defaultData={magicTags()}
          onSubmit={(magicTags) => {
            let result;
            if (workOrder()?.unitId) {
              result = updateUnitMagicTags(workOrder()!.unitId as string, { magicTags });
            } else {
              result = updatePropertyMagicTags(workOrder()!.propertyId, { magicTags });
            }
            refetchWorkOrders();
            return result;
          }}
          onClose={() => {
            setIsMagicTagsEditModalVisible(false);
          }}
        />
      </Show>
    </div>
  );
};
