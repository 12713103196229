import * as Sentry from '@sentry/browser';
import { DEV } from 'solid-js';
import { UnauthorizedError } from '~/errors';

type Mechanism = {
  handled?: boolean;
};

export const initSentry = () => {
  if (DEV) return;

  Sentry.init({
    dsn: 'https://753a14b2b69bc21a4d0577fb441f57df@sentry.magicdoor.io/5',
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });

  registerErrorHandler();
};

export const registerErrorHandler = () => {
  window.onunhandledrejection = (event) => {
    sentryCapture(event.reason as Error);
  };
};

export const sentryCapture = (error?: Error) => {
  if (DEV) return;
  const mechanism: Mechanism = {};

  if (error) {
    if (error instanceof UnauthorizedError) return;
    Sentry.captureException(error, { mechanism });
  }
};
