import { useNavigate, useParams } from '@solidjs/router';
import { createMemo } from 'solid-js';
import { SegmentForm, useForm, SegmentFormScrollSpy } from '~/components/common/BetterForm';
import Breadcrumb from '~/components/common/Breadcrumb';
import { toast } from '~/components/ui';
import { useLocalization, useUnitListings } from '~/contexts/global';
import { MaintenanceRequestsProvider } from '~/contexts/local';
import { NO_LIMIT } from '~/utils/constant';
import { isNumber } from '~/utils/number';
import { filterUndefined, deepEqual } from '~/utils/tool';
import { BottomBtns } from './components/BottomBtns';
import { UnitListingsSection } from './components/UnitListingsSection';

export const EditListing = () => {
  const { t } = useLocalization();
  const navigate = useNavigate();
  const { unitListingDetail, updateUnitListing } = useUnitListings();
  const params = useParams<{ portfolioId?: `${number}`; listingId: `${number}` }>();
  const form = useForm();

  const breadcrumbItems = createMemo(() => [
    { label: t('Listing'), link: `/listings/${params.portfolioId}` },
    {
      label: unitListingDetail()?.title || t('Listing details'),
      link: `/listings/${params.portfolioId}/details/${params.listingId}`,
    },
    { label: t('Edit listing') },
  ]);

  const formatPetPolicy = (values: any) => {
    if (values.allowed) {
      return {
        allowed: true,
        size: values.size === NO_LIMIT ? undefined : values.size,
        fee: values.fee,
        policies: values.policies
          ? Object.fromEntries(values.policies?.map((item: string) => [item.toLocaleLowerCase(), true]) ?? [])
          : undefined,
        deposit: values.deposit,
        description: values.petPolicyDesc,
      };
    } else {
      return {
        allowed: values.allowed,
      };
    }
  };
  const formatAmenities = (values: any) => {
    let amenityArr: any = [];
    const newAmenity: MagicDoor.Api.AmenityDto[] = [];
    if (values['Included utilities']) amenityArr = [...amenityArr, ...values['Included utilities']];
    if (values['Interior amenities']) amenityArr = [...amenityArr, ...values['Interior amenities']];
    if (values['Outdoor amenities']) amenityArr = [...amenityArr, ...values['Outdoor amenities']];
    if (values['Community amenities']) amenityArr = [...amenityArr, ...values['Community amenities']];

    amenityArr.forEach((item: MagicDoor.Api.AmenityDto) => {
      newAmenity.push({
        name: item + '',
        icon: 'test',
        avalability: 'available' as MagicDoor.Api.AmenityAvalability.Available,
      });
    });
    return newAmenity;
  };

  const hydrateParam = () => {
    const formValues = form.formStore;
    const param = {} as MagicDoor.Api.UpdateUnitListingDto;
    param.rentalPrice = formValues.rentalPrice;
    param.securityDeposit = formValues.securityDeposit;
    param.availableFrom = formValues.availableFrom;
    param.leaseTerm = formValues.leaseTerm;
    param.petPolicy = formatPetPolicy(formValues);
    param.amenities = formatAmenities(formValues);
    param.description = formValues.description;
    param.tags = formValues.tags;
    return param;
  };

  const handleEqualPetPolicy = (current: MagicDoor.Api.PetPolicyDto | undefined, newPetPolicy: MagicDoor.Api.PetPolicyDto | undefined) => {
    if (!current || !newPetPolicy) return current === newPetPolicy;
    const filteredCurrent = filterUndefined(current);
    const filteredNewPetPolicy = filterUndefined(newPetPolicy);
    return deepEqual(filteredCurrent, filteredNewPetPolicy);
  };

  const isChange = () => {
    const formValues = hydrateParam();
    const current = unitListingDetail();
    const flag =
      current?.rentalPrice !== formValues.rentalPrice ||
      current?.securityDeposit !== formValues.securityDeposit ||
      current?.availableFrom !== formValues.availableFrom ||
      current?.leaseTerm !== formValues.leaseTerm ||
      !handleEqualPetPolicy(current?.petPolicy ?? undefined, formValues?.petPolicy ?? undefined) ||
      JSON.stringify(current?.amenities) !== JSON.stringify(formValues.amenities);
    return flag;
  };
  const handleBack = (back = true) => {
    back && navigate(`/listings/${params.portfolioId}/details/${params.listingId}`);
  };
  const handleSubmit = () => {
    if (vailidate()) {
      submit();
      handleBack(true);
    }
  };
  const handleSave = (back?: boolean) => {
    if (vailidate()) {
      submit();
      handleBack(back);
    }
  };

  const vailidate = () => {
    const param = hydrateParam();
    if (!isNumber(param.rentalPrice)) {
      return false;
    } else {
      return true;
    }
  };

  const submit = async () => {
    try {
      const param = hydrateParam();
      await updateUnitListing(param, params.listingId);
      toast.success(t('Listing updated successfully'));
    } catch (e) {
      toast.error(t('Failed to update listing'));
      console.log(e);
    }
  };

  return (
    <div class="relative">
      <Breadcrumb backLink={() => navigate(-1)} items={breadcrumbItems()} />
      <div class="my-5  px-8">
        <SegmentForm defaultForm={form} onFormSubmit={handleSubmit} class="flex h-section1 flex-1 flex-col overflow-hidden">
          <div class="flex flex-1 overflow-hidden">
            <div class="thinscroll relative hidden w-72 border-r border-input-border bg-white p-6 text-text-level03 xs:block">
              <div class="text-lg font-semibold capitalize text-text-level01">{t('Edit unit listing')}</div>
              <SegmentFormScrollSpy />
            </div>
            <div class="thinscroll h-full flex-1 overflow-auto bg-white">
              <MaintenanceRequestsProvider>
                <UnitListingsSection onSave={handleSave} isChange={isChange()} />
              </MaintenanceRequestsProvider>
            </div>
          </div>
          <BottomBtns onCancel={() => navigate(-1)} onSave={handleSubmit} />
        </SegmentForm>
      </div>
    </div>
  );
};
