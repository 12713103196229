import { useLocalization } from '~/contexts/global';
import type { Component } from 'solid-js';

const statusMap: Record<`${MagicDoor.Api.TransactionStatus}`, { label: string; color: string }> = {
  pending: { label: 'Pending', color: 'text-text-level03' },
  failed: { label: 'Failed', color: 'text-error' },
  success: { label: 'Success', color: 'text-success' },
  cancelled: { label: 'Cancelled', color: 'text-warning' },
  returned: { label: 'Returned', color: 'text-text-level03' },
  refunded: { label: 'Refunded', color: 'text-text-level03' },
};

export const TransactionStatus: Component<{ class?: string; status: `${MagicDoor.Api.TransactionStatus}` }> = (props) => {
  const { t } = useLocalization();
  return (
    <span class={`rounded-full bg-current-alpha px-2 py-1 text-xs ${statusMap[props.status].color}`}>
      {t(statusMap[props.status].label)}
    </span>
  );
};
