import { useNavigate, useParams } from '@solidjs/router';
import { createMemo, Show } from 'solid-js';
import { BankAccountMask } from '~/components/common/BankAccountMask';
import { Modal } from '~/components/modals/Modal';
import { TransactionLeaseName } from '~/components/transactions';
import { Table } from '~/components/ui';
import { UnitImage } from '~/components/units';
import { useLocalization, useTenants } from '~/contexts/global';
import { FieldItem, IconCards, IconCalendar } from '~/pages/tenants/tenant-details/components/TenantPaymentDetailsModal';
import { emptyPlaceholder } from '~/utils/constant';
import type { Component } from 'solid-js';
import type { TenantAutoPayType } from '~/swagger/Api';

const translate = (str?: TenantAutoPayType) =>
  str ? str.replace(/([A-Z])/g, ' $1').replace(/^./, (s) => s.toUpperCase()) : emptyPlaceholder;

export const AutoTenantPaymentDetailsModal: Component<{ visible: boolean }> = (props) => {
  const { t } = useLocalization();
  const navigate = useNavigate();

  const { autoPayments } = useTenants();
  const params = useParams<{ paymentId?: string; autoPaymentId?: string }>();

  const currentAutoPayment = createMemo(() => {
    const _autoPayments = autoPayments();
    if (params.autoPaymentId && _autoPayments) {
      return _autoPayments.find((p) => p.id === params.autoPaymentId);
    }
    return undefined;
  });

  const handleClose = () => {
    if (currentAutoPayment()) navigate(`/users/tenants/${currentAutoPayment()?.tenant?.id}/payments`, { scroll: false, replace: true });
    else navigate(-1);
  };

  return (
    <Modal
      size="md"
      title={t('Auto-payment detail')}
      visible={props.visible}
      confirmation={false}
      cancelText={t('Close')}
      onCancel={handleClose}
      onDone={handleClose}>
      <div class="space-y-6 px-8 py-6">
        <div class="flex items-center gap-2 rounded-lg bg-input p-2">
          <UnitImage class="size-12 rounded" unit={currentAutoPayment()?.unit as { id: string; imageId?: string }} />
          <div class="space-y-0.5">
            <h4 class="text-sm font-medium text-title-gray">
              <TransactionLeaseName property={currentAutoPayment()?.property} unit={currentAutoPayment()?.unit} />
            </h4>
          </div>
        </div>
        <div class="grid grid-cols-2 gap-3.5 text-title-gray">
          <FieldItem label={t('Payment start date')} icon={IconCalendar} value={currentAutoPayment()?.start} />
          <FieldItem label={t('Payment end date')} icon={IconCalendar} value={currentAutoPayment()?.end} />
          <FieldItem
            class="capitalize"
            label={t('Payment method')}
            icon={IconCards}
            value={currentAutoPayment()?.paymentAccount?.accountProvider}
          />
          <FieldItem
            label={t('Bank account')}
            icon={IconCards}
            value={<BankAccountMask accountMask={currentAutoPayment()?.paymentAccount?.accountMask} />}
          />
        </div>
        <div class="flex flex-col gap-2">
          <h3 class="text-xs text-text-level02">{t('Auto-payment detail')}</h3>
          <Show when={currentAutoPayment()}>
            <Table
              data={[currentAutoPayment()!]}
              columns={[
                {
                  title: t('Payment type'),
                  render: (item) => <span>{translate(item.type)}</span>,
                  headerClass: 'w-[150px]',
                },
                {
                  title: t('Pay day'),
                  render: (item) => <span>{item.frequency}</span>,
                  headerClass: 'w-[100px]',
                },
                {
                  title: t('Max limit'),
                  render: (item) => (
                    <div class="flex gap-8">
                      <Show when={item.maximumAmount}>
                        <span>{item.maximumAmount}</span>
                      </Show>
                      <span>
                        {t('Next payment')}: {item.nextPayment}
                      </span>
                    </div>
                  ),
                },
              ]}
            />
          </Show>
        </div>
      </div>
    </Modal>
  );
};
