import dayjs, { extend } from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import { emptyPlaceholder } from './constant';
import { padStart } from './tool';
import type { TFunction } from '~/contexts/global';

extend(relativeTime);
extend(utc);
extend(isSameOrBefore);
extend(isSameOrAfter);

type DateType = string | Date | number | dayjs.Dayjs | null | undefined;

export function getHours(hours: number, num: number): string {
  return padStart(hours % 12 || 12, num, '0');
}
export function meridiemFunc(hours: number) {
  return hours < 12 ? 'AM' : 'PM';
}

export function isValidDate(date: Date) {
  return date instanceof Date && !isNaN(date.getTime());
}

export function dateFormat(formatStr?: string, date?: Date | string | number | null | dayjs.Dayjs) {
  const currentLanguage = localStorage.getItem('locale') as 'zh' | 'en';
  const defaultPattern = 'MM/DD/YYYY';
  let str = formatStr || defaultPattern;

  str =
    currentLanguage === 'zh'
      ? str
          .split('/')
          .reduce((acc, cur) => {
            !cur.includes('Y') ? acc.push(cur) : acc.unshift(cur);
            return acc;
          }, [] as string[])
          .join('/')
      : str;

  if (typeof date === 'string' && date.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/) && !formatStr) {
    str = `${defaultPattern} h:mm A`;
  }

  return dayjs(date).format(str);
}

export function formatAgo(time: DateType, num = 1) {
  const units = [
    { ms: 365 * 24 * 60 * 60 * 1000, singular: 'year', plural: 'years' },
    { ms: 30 * 24 * 60 * 60 * 1000, singular: 'month', plural: 'months' },
    { ms: 24 * 60 * 60 * 1000, singular: 'day', plural: 'days' },
    { ms: 60 * 60 * 1000, singular: 'hour', plural: 'hours' },
    { ms: 60 * 1000, singular: 'minute', plural: 'minutes' },
  ];

  const now = dayjs();
  const timeDayjs = dayjs(time);
  let timeDiff = now.diff(timeDayjs);

  if (timeDiff < 60 * 1000) {
    return 'just now';
  }

  const parts = [];
  for (let i = 0; i < units.length; i++) {
    if (timeDiff < units[i].ms) continue;
    const count = Math.floor(timeDiff / units[i].ms);
    timeDiff -= count * units[i].ms;
    parts.push(`${count} ${count > 1 ? units[i].plural : units[i].singular}`);
    if (parts.length >= num) break;
  }

  return parts.join(' ') + ' ago';
}

export function getDateRange(dataNow: dayjs.Dayjs, intervalDays: number, bolPastTime: boolean, formatStr = ''): Array<string> {
  const oneDayTime = 24 * 60 * 60 * 1000;
  const list = [];
  let lastDay;

  if (bolPastTime === true) {
    lastDay = dayjs(dataNow.valueOf() - intervalDays * oneDayTime);
    list.push(dateFormat(formatStr, lastDay));
    list.push(dateFormat(formatStr, dataNow));
  } else {
    lastDay = dayjs(dataNow.valueOf() + intervalDays * oneDayTime);
    list.push(dateFormat(formatStr, dataNow));
    list.push(dateFormat(formatStr, lastDay));
  }

  return list;
}

export function checkAuditTime(startTime: Date | string, endTime: Date | string, nowTime?: Date | string) {
  const date = nowTime ? dayjs(nowTime) : dayjs();

  let startDate = dayjs(startTime).valueOf();

  let endDate = dayjs(endTime).valueOf();
  const nowDate = date.valueOf();
  const s = startDate > endDate;

  if (s) [startDate, endDate] = [endDate, startDate];

  if (nowDate > startDate && nowDate < endDate) {
    return s ? false : true;
  } else {
    return s ? true : false;
  }
}

export function diffDates(endDate: string | null | undefined, startDate: string | null | undefined, opts?: { withoutSuffix?: boolean }) {
  if (!endDate && !startDate) {
    return emptyPlaceholder;
  }
  return dayjs(startDate).from(endDate, opts?.withoutSuffix);
}

export function isYesterday(date: DateType) {
  const targetDate = dayjs(date);
  const yesterdayStart = dayjs().subtract(1, 'day').startOf('day');
  const yesterdayEnd = dayjs().subtract(1, 'day').endOf('day');

  return targetDate.isSameOrAfter(yesterdayStart) && targetDate.isSameOrBefore(yesterdayEnd);
}

export function isToday(date: DateType) {
  const targetDate = dayjs(date);
  const today = dayjs();

  return targetDate.isSame(today, 'day');
}

export function dateDiffInDays(timestamp: DateType) {
  const targetDate = dayjs(timestamp);
  const today = dayjs();
  return targetDate.diff(today, 'day');
}

export function formatChatDate(isoDateString: string, t: TFunction) {
  const dateStr = dateFormat('MM/DD/YYYY', isoDateString);
  const timeStr = dateFormat('h:mm A', isoDateString);

  if (isToday(isoDateString)) {
    return timeStr;
  }

  if (isYesterday(isoDateString)) {
    return `${t('yesterday at')} ${timeStr}`;
  }

  return `${dateStr} at ${timeStr}`;
}

export function isLaterDate(date: string) {
  return dayjs().diff(dayjs(date)) < 0;
}
export function dateDiffOfDay(startstamp: DateType, endstamp: DateType) {
  return dayjs(startstamp).diff(dayjs(endstamp), 'day');
}

export function whenThisDayIsNotBirthDay(date: Date) {
  return dayjs(date).isAfter(dayjs().subtract(18, 'year').subtract(2, 'day'));
}

export const convertTo12HourFormat = (time24: string) => {
  const [hours, minutes] = time24.split(':');
  let hour = parseInt(hours, 10);
  const ampm = hour >= 12 ? 'PM' : 'AM';
  hour = hour % 12 || 12;
  return `${hour}${minutes !== '00' ? `:${minutes}` : ''} ${ampm}`;
};
