import { useNavigate } from '@solidjs/router';
import dayjs from 'dayjs';
import { createMemo, createSignal } from 'solid-js';
import { LabeledChartOfAccountSelect } from '~/components/chart-of-accounts/ChartOfAccountSelect';
import { Form, FormItem, useForm } from '~/components/common/BetterForm';
import DueDateInputField from '~/components/common/Inputs/DueDateInputField';
import LabeledNumberInput from '~/components/common/Inputs/LabeledNumberInput';
import LabeledSelect from '~/components/common/Inputs/LabeledSelect';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { Modal } from '~/components/modals/Modal';
import { CustomLevelSearch } from '~/components/search-select/LevelSearchComponents/SearchLevels';
import { LabeledLeveledSearchSelect } from '~/components/search-select/LevelSearchSelect';
import { toast } from '~/components/ui';
import { useLocalization, usePropertyCredit } from '~/contexts/global';
import { useBankAccountsList } from '~/contexts/local';

export const AddPropertyCreditModal = () => {
  const { t } = useLocalization();
  const form = useForm();
  const { bankAccounts } = useBankAccountsList();
  const navigate = useNavigate();
  const { addPropertyCredit } = usePropertyCredit();
  const [loading, setLoading] = createSignal<boolean>(false);

  const bankAccountOptions = createMemo(() =>
    (bankAccounts() ?? []).map((account: any) => ({
      label: account.name,
      value: account.id,
    }))
  );

  const submit = async () => {
    try {
      const { validateStatus } = form.validateForm();
      if (validateStatus) {
        setLoading(true);
        await addPropertyCredit(form.formStore.propertyId, form.formStore as unknown as MagicDoor.Api.GivePropertyCreditDto);
        toast.success(t('{name} has been added successfully', { name: t('Property credit') }));
        navigate(-1);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      visible
      loading={loading()}
      onDone={submit}
      doneText={t('Confirm')}
      class="lg:w-modalSm"
      title={t('Add property credit')}
      onCancel={() => {
        navigate(-1);
      }}>
      <Form
        initialValues={{
          memo: 'Credit',
          creditDate: dayjs().format('YYYY-MM-DD'),
        }}
        class="grid grid-cols-1 gap-6 p-5 lg:grid-cols-2"
        defaultForm={form}>
        <FormItem
          component={LabeledLeveledSearchSelect}
          enabledTypes={[CustomLevelSearch.Property]}
          label={t('Property')}
          placeholder={t('Please select')}
          formFieldName="propertyId"
          rules={[{ required: true, message: t('Property is required') }]}
          class="col-span-full lg:col-span-1"
          onChange={(seleted: any[]) => {
            form.setValueToStore('propertyId', seleted[0].id);
          }}
        />
        <FormItem
          options={bankAccountOptions()}
          class="col-span-full lg:col-span-1"
          placeholder={t('Please select bank account')}
          label={t(`Bank account`)}
          formFieldName="bankAccountId"
          component={LabeledSelect}
          rules={[{ message: t(`Please select bank account`), required: true }]}
        />
        <FormItem class="col-span-full lg:col-span-1" formFieldName="creditDate" label={t('Credit date')} component={DueDateInputField} />
        <FormItem
          class="col-span-full lg:col-span-1"
          component={LabeledChartOfAccountSelect}
          formFieldName="companyChartOfAccountsId"
          rules={[{ required: true, message: t('Please select chart of account') }]}
          label={t('Chart of account')}
          placeholder={t('Please enter')}
        />
        <FormItem
          class="col-span-full lg:col-span-1"
          placeholder={t('Please enter')}
          label={t('Amount')}
          formFieldName="amount"
          prepend="$"
          component={LabeledNumberInput}
          min={1}
          rules={[{ message: t(`Please enter`), required: true }]}
        />

        <FormItem
          class="col-span-full lg:col-span-1"
          formFieldName="memo"
          label={t('Memo')}
          component={LabeledTextInput}
          placeholder={t('Please enter')}
          rules={[{ length: 500, message: t('{name} must be {length} characters or less', { name: t('Memo'), length: '500' }) }]}
        />
      </Form>
    </Modal>
  );
};
