import { useNavigate } from '@solidjs/router';
import { Match, Show, Switch } from 'solid-js';
import { LinkButton } from '~/components/common/Buttons/LinkButton';
import DisplayAddress from '~/components/common/DisplayAddress';
import IconField from '~/components/common/IconField';
import { LeaseTitle } from '~/components/leases';
import { MaintenanceWorkOrderSummary } from '~/components/maintenance-requests/MaintenanceWorkOrderSummary';
import { OwnerDropdownSummary } from '~/components/owner/OwnerDropdownSummary';
import { PropertyTitle } from '~/components/properties';
import { TenantSummary } from '~/components/tenants/TenantSummary';
import { IconClock, IconHash, IconMapPin, IconSquarePen, Skeleton } from '~/components/ui';
import { UnitTitle } from '~/components/units';
import { useLocalization } from '~/contexts/global';
import { cn } from '~/utils/classnames';
import { dateFormat } from '~/utils/date';
import { currency } from '~/utils/number';
import type { Component } from 'solid-js';

const MaintenanceRequestDetails: Component<{
  class?: string;
  loading?: boolean;
  maintenanceRequest?: MagicDoor.Api.MaintenanceRequestDto;
}> = (props) => {
  const { t } = useLocalization();
  const navigate = useNavigate();

  const goPropertyOrUnitOrLease = () => {
    if (props.maintenanceRequest?.leaseId) {
      navigate(`/leasing/leases/${props.maintenanceRequest.leaseId}`);
    } else if (props.maintenanceRequest?.unitId && props.maintenanceRequest?.portfolioId && props.maintenanceRequest?.propertyId) {
      navigate(
        `/portfolios/${props.maintenanceRequest.portfolioId}/properties/${props.maintenanceRequest.propertyId}/units/${props.maintenanceRequest.unitId}`
      );
    } else if (props.maintenanceRequest?.portfolioId && props.maintenanceRequest?.propertyId) {
      navigate(`/portfolios/${props.maintenanceRequest.portfolioId}/properties/${props.maintenanceRequest.propertyId}`);
    }
  };

  const renderRelationField = () => (
    <Switch fallback={<IconField name={t('Property')} loading={props.loading} class="w-full" />}>
      <Match when={props.maintenanceRequest?.lease}>
        <IconField
          name={t('Lease')}
          loading={props.loading}
          value={
            <div class="w-full overflow-hidden rounded-lg bg-input p-2">
              <LeaseTitle
                class="truncate"
                property={props.maintenanceRequest?.property}
                feedbackIconReadonly
                unit={props.maintenanceRequest?.unit}
                lease={props.maintenanceRequest?.lease}
                onClick={goPropertyOrUnitOrLease}
              />
            </div>
          }
        />
      </Match>
      <Match when={props.maintenanceRequest?.unit}>
        <IconField
          name={t('Unit')}
          loading={props.loading}
          class="w-full"
          value={
            <div class="w-full overflow-hidden rounded-lg bg-input p-2" onClick={goPropertyOrUnitOrLease}>
              <UnitTitle
                feedbackIconReadonly
                class="truncate"
                property={props.maintenanceRequest?.property}
                unit={props.maintenanceRequest?.unit as MagicDoor.Api.HydratedUnitDto}
              />
            </div>
          }
        />
      </Match>
      <Match when={props.maintenanceRequest?.property}>
        <IconField
          name={t('Property')}
          loading={props.loading}
          class="w-full"
          value={
            <div class="w-full overflow-hidden rounded-lg bg-input p-2">
              <PropertyTitle
                feedbackIconReadonly
                class="truncate"
                property={props.maintenanceRequest?.property}
                onClick={goPropertyOrUnitOrLease}
              />
            </div>
          }
        />
      </Match>
    </Switch>
  );

  return (
    <div class="divide-y divide-partingline overflow-hidden rounded-lg border border-partingline bg-white">
      <div class="px-5 py-4">
        <div class="flex items-center gap-1 text-lg font-semibold text-title-gray">
          {t('Details')}
          <LinkButton href="edit" variant="white" class="ml-auto border-none border-partingline p-2.5 text-sm">
            <IconSquarePen class="size-5 text-link" />
          </LinkButton>
        </div>
        <div class="flex items-center gap-1 text-xs text-text-level03">
          {t('Last update')}:
          <Show when={!props.loading} fallback={<Skeleton class="h-4 w-32" />}>
            <span>{dateFormat(t('MM/DD/YYYY hh:mm A'), props.maintenanceRequest?.lastUpdated)}</span>
          </Show>
        </div>
      </div>
      <div class="flex w-full flex-row flex-wrap gap-5 p-4 lg:flex-col">
        <div class="my-4 w-full cursor-pointer">{renderRelationField()}</div>
        <IconField
          name={t('Address')}
          loading={props.loading}
          value={<DisplayAddress address={props.maintenanceRequest?.property?.address} />}
          src={IconMapPin}
        />
        <IconField name={t('Request number')} loading={props.loading} value={props.maintenanceRequest?.tenant.id} src={IconHash} />
        <IconField
          name={t('Created at')}
          loading={props.loading}
          value={dateFormat(t('MM/DD/YYYY hh:mm A'), props.maintenanceRequest?.created)}
          src={IconClock}
        />
      </div>
    </div>
  );
};

export const MaintenanceRequestSummary: Component<{
  class?: string;
  loading?: boolean;
  maintenanceRequest?: MagicDoor.Api.MaintenanceRequestDto;
}> = (props) => {
  const { t } = useLocalization();

  return (
    <div class={cn('flex flex-col gap-4 text-sm', props.class)}>
      <MaintenanceRequestDetails loading={props.loading} maintenanceRequest={props.maintenanceRequest} />
      <MaintenanceWorkOrderSummary
        loading={props.loading}
        currentPage="maintenanceRequest"
        workOrder={props.maintenanceRequest?.workOrder ?? undefined}
      />
      <TenantSummary loading={props.loading} tenants={props.maintenanceRequest?.tenant || []} />
      <OwnerDropdownSummary
        loading={props.loading}
        owners={props.maintenanceRequest?.owners}
        bottomContent={
          <Show when={props.maintenanceRequest?.owners && props.maintenanceRequest.owners.length > 0}>
            <div class="flex w-full px-3 pb-3 text-sm normal-case ">
              <div class="flex w-full items-center justify-center gap-1.5 rounded-lg border-input-border bg-input py-3">
                <span class="text-text-level03">{t('Owner approval limit')}:</span>
                <span class="font-semibold text-text-level02">{currency(props.maintenanceRequest?.ownerApprovalLimit || undefined)}</span>
              </div>
            </div>
          </Show>
        }
      />
    </div>
  );
};
