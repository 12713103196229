import { useNavigate, useParams } from '@solidjs/router';
import { createSignal, For, createEffect, Show, onMount } from 'solid-js';
import { createStore } from 'solid-js/store';
import IconFile from '~/assets/images/chat/chatFile.png';
import IconDrag from '~/assets/images/common/dragDots.svg?component-solid';
import IconRemove from '~/assets/images/common/remove.svg?component-solid';
import { Button } from '~/components/common/Buttons';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { RingLoader } from '~/components/common/Loaders';
import { Panel } from '~/components/common/Panels';
import { useLocalization } from '~/contexts/global';
import { useLeaseTemplate, useLeaseTemplateCategory } from '~/contexts/local';
import usePresenter from '~/framework/hooks/usePresenter';
import useUseCase from '~/framework/hooks/useUseCase';
import { LeaseDocumentSignersPresenter } from '~/pdfsigner/presenters/LeaseDocumentSignersPresenters';
import { CreateLeaseDocumentDraftUseCase } from '~/pdfsigner/usecases/pdfEditing/createLeaseDocumentDraftUseCase';
import { GetSignersUseCase } from '~/pdfsigner/usecases/pdfEditing/getSignersUseCase';
import { MultiSelectSection } from './MultiSelectSection';
import type { Component } from 'solid-js';

type Template = {
  id: string;
  leaseTemplateCategoryId: string;
  name: string;
};

type SelectedTemplate = Template & { order: number };

export const LeaseTemplateForm: Component = () => {
  const { t } = useLocalization();
  const navigate = useNavigate();
  const { leaseId } = useParams();

  const { leasetemplatecategories } = useLeaseTemplateCategory();
  const { leasetemplates } = useLeaseTemplate();

  const [title, setTitle] = createSignal('');
  const [selectedTemplates, setSelectedTemplates] = createSignal<SelectedTemplate[]>([]);
  const [checkedTemplates, setCheckedTemplates] = createStore<Record<string, boolean>>({});
  const [dragOverIndex, setDragOverIndex] = createSignal<number | null>(null);
  const [selectedSigners, setSelectedSigners] = createSignal<string[]>([]);
  const [draggedId, setDraggedId] = createSignal<string | null>(null);
  const [isDragging, setIsDragging] = createSignal(false);

  const { execute: getSigners, isLoading: isLoadingSigners } = useUseCase(GetSignersUseCase);
  const { execute: createDocument, isLoading: isCreatingDocument } = useUseCase(CreateLeaseDocumentDraftUseCase);
  const { model: presentableSigners } = usePresenter(LeaseDocumentSignersPresenter);

  const toggleSigner = (signerId: string) => {
    if (selectedSigners().includes(signerId)) {
      setSelectedSigners(selectedSigners().filter((s) => s !== signerId));
      return;
    }
    setSelectedSigners([...selectedSigners(), signerId]);
  };

  onMount(() => {
    getSigners(leaseId);
  });

  createEffect(() => {
    const newSelectedTemplates =
      leasetemplates()
        ?.filter((t) => checkedTemplates[t.id])
        .map((t, index) => ({ ...t, order: index + 1 })) || [];
    setSelectedTemplates(newSelectedTemplates);
  });

  const handleDragStart = (e: DragEvent, template: SelectedTemplate) => {
    setIsDragging(true);
    setDraggedId(template.id);
    e.dataTransfer?.setData('text/plain', template.id);
    const component = e.target;
    if (component instanceof HTMLElement) {
      setTimeout(() => (component.style.opacity = '0.4'), 0);
    }
  };

  const handleDragEnd = (e: DragEvent) => {
    if (e.target instanceof HTMLElement) {
      e.target.style.opacity = '1';
    }
    setDragOverIndex(null);
    setDraggedId(null);
    setIsDragging(false);
  };

  const updateDragPosition = (e: DragEvent) => {
    if (!isDragging()) return;
    const rightColumn = document.querySelector('.right-column');
    if (!rightColumn) return;
    const rightColumnRect = rightColumn.getBoundingClientRect();
    const topDropZone = rightColumnRect.top + 20;
    const bottomDropZone = rightColumnRect.bottom - 30;
    if (e.clientY < topDropZone) {
      setDragOverIndex(0);
    } else if (e.clientY > bottomDropZone) {
      setDragOverIndex(selectedTemplates().length);
    } else {
      const dragOverItem = (e.target as HTMLElement).closest('li');
      if (dragOverItem) {
        const dragOverTemplateId = dragOverItem.getAttribute('data-id');
        const dragOverTemplate = selectedTemplates().find((t) => t.id === dragOverTemplateId);
        const draggedTemplate = selectedTemplates().find((t) => t.id === draggedId());
        if (dragOverTemplate && draggedTemplate && dragOverTemplate.id !== draggedTemplate.id) {
          const rect = dragOverItem.getBoundingClientRect();
          const midY = rect.top + rect.height / 2;
          let newIndex: number | null = null;
          if (e.clientY < midY) {
            newIndex = dragOverTemplate.order - 1;
          } else {
            newIndex = dragOverTemplate.order;
          }
          setDragOverIndex(newIndex !== draggedTemplate.order - 1 ? newIndex : null);
        }
      }
    }
  };

  const handleDragOver = (e: DragEvent) => {
    e.preventDefault();
    if (e.dataTransfer) {
      e.dataTransfer.dropEffect = 'move';
    }
    updateDragPosition(e);
  };

  const handleDrop = (e: DragEvent) => {
    e.preventDefault();
    const droppedId = e.dataTransfer?.getData('text/plain');
    const draggedTemplate = selectedTemplates().find((t) => t.id === droppedId);
    if (draggedTemplate) {
      const newIndex = dragOverIndex();
      if (newIndex !== null && newIndex !== draggedTemplate.order - 1 && newIndex !== draggedTemplate.order) {
        const newTemplates = selectedTemplates()
          .filter((t) => t.id !== draggedTemplate.id)
          .sort((a, b) => a.order - b.order);

        newTemplates.splice(newIndex, 0, draggedTemplate);
        const reorderedTemplates = newTemplates.map((t, index) => ({
          ...t,
          order: index + 1,
        }));
        setSelectedTemplates(reorderedTemplates);
      }
    }
    setDragOverIndex(null);
    setDraggedId(null);
    setIsDragging(false);
  };

  const toggleTemplate = (templateId: string) => {
    setCheckedTemplates(templateId, !checkedTemplates[templateId]);
  };

  const handleRemoveTemplate = (templateId: string) => {
    setCheckedTemplates(templateId, false);
  };

  let labelRef: any;
  const onCreateLeaseDocument = () => {
    const currentTitle = title();
    if (currentTitle === '') {
      labelRef.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }

    createDocument({
      title: title(),
      leaseId,
      leaseTemplateIds: selectedTemplates().map((t) => t.id),
      signerIds: selectedSigners(),
    });
  };

  return (
    <Show
      when={!isLoadingSigners() && !isCreatingDocument()}
      fallback={
        <div class="flex size-full h-screen flex-wrap items-center justify-center">
          <RingLoader color="#a126ec" size={125} borderWidth={5} showIcon />
        </div>
      }>
      <div class="p-8 text-left">
        <Panel title={t('Create lease document')} class="container">
          <div class="p-6">
            <label for="title" id="title" ref={labelRef} />
            <LabeledTextInput
              type="text"
              value={title()}
              onInput={(e) => setTitle(e)}
              placeholder="Enter title"
              class="min-w-56 flex-1 border-b pb-8"
              label={t('Title')}
              maxlength={150}
            />
            <div class="flex flex-1 flex-col gap-4  border-b pb-8">
              <h3 class="mt-3">Signers</h3>
              <div class="grid grid-cols-2 gap-4">
                <div class="col-span-full  p-2 sm:col-span-1">
                  <MultiSelectSection
                    class="max-h-64 overflow-y-auto"
                    title="Tenants"
                    items={
                      presentableSigners()?.tenants.map((item) => {
                        return {
                          label: item.name,
                          value: item.id,
                          isSelected: selectedSigners().includes(item.id) || false,
                        };
                      }) || []
                    }
                    onClick={toggleSigner}
                  />
                </div>
                <div class="col-span-full p-2 sm:col-span-1">
                  <MultiSelectSection
                    class="max-h-64 overflow-y-auto"
                    title="Property Managers"
                    items={
                      presentableSigners()?.pms.map((item) => {
                        return {
                          label: item.name,
                          value: item.id,
                          isSelected: selectedSigners().includes(item.id) || false,
                        };
                      }) || []
                    }
                    onClick={toggleSigner}
                  />
                </div>
              </div>
            </div>
            <div class="grid h-[calc(100%-130px)] flex-1 grid-cols-2 gap-4 py-6">
              <div class="col-span-full sm:col-span-1">
                <div class="thinscroll  flex-1 overflow-auto">
                  <For each={leasetemplatecategories()}>
                    {(category) => (
                      <MultiSelectSection
                        title={category.name}
                        items={
                          leasetemplates()
                            ?.filter((t) => t.leaseTemplateCategoryId === category.id)
                            .map((template) => {
                              return {
                                label: template.name,
                                value: template.id,
                                isSelected: checkedTemplates[template.id] || false,
                              };
                            }) || []
                        }
                        onClick={toggleTemplate}
                      />
                    )}
                  </For>
                </div>
              </div>
              <div class="right-column relative col-span-full sm:col-span-1" onDragOver={handleDragOver} onDrop={handleDrop}>
                <div class="inset-0 flex h-[calc(100%-10px)] flex-1  flex-col rounded-md border-2 border-dashed border-primary">
                  <Show
                    when={selectedTemplates().length}
                    fallback={
                      <div class="flex h-full flex-col items-center justify-center gap-4 p-3">
                        <img src={IconFile} class="h-20" />
                        <p class="font-medium normal-case text-text-level03">{t('Selected templates will appear here')}</p>
                      </div>
                    }>
                    <h2 class="mb-3 mt-4 flex justify-center px-4 normal-case	text-text-level03">{t('Order the lease template')}</h2>
                    <div class="thinscroll  flex-1 overflow-auto">
                      <ul class="space-y-2 p-4">
                        <Show when={dragOverIndex() === 0}>
                          <div class="drag-indicator my-1 ml-6 rounded-md border-2 border-dashed border-link" style={{ height: '46px' }} />
                        </Show>
                        <For each={selectedTemplates().sort((a, b) => a.order - b.order)}>
                          {(template, index) => (
                            <>
                              <Show when={index() !== 0 && index() === dragOverIndex()}>
                                <div
                                  class="drag-indicator my-1 ml-6 rounded-md border-2 border-dashed border-link"
                                  style={{ height: '46px' }}
                                />
                              </Show>
                              <div class="flex w-full gap-2">
                                <button onClick={() => handleRemoveTemplate(template.id)}>
                                  <IconRemove class="text-text-level02" />
                                </button>
                                <li
                                  data-id={template.id}
                                  class="flex w-full cursor-grab items-center space-x-2 overflow-hidden rounded-md border p-4 active:cursor-grabbing"
                                  style={{ height: '46px' }}
                                  draggable="true"
                                  onDragStart={(e) => handleDragStart(e, template)}
                                  onDragEnd={handleDragEnd}>
                                  <span class="grow truncate text-sm">{template.name}</span>
                                  <IconDrag class="text-text-level02" />
                                </li>
                              </div>
                            </>
                          )}
                        </For>
                        <Show when={dragOverIndex() === selectedTemplates().length}>
                          <div class="drag-indicator my-1 ml-6 rounded border-2 border-dashed border-link" style={{ height: '46px' }} />
                        </Show>
                      </ul>
                    </div>
                  </Show>
                </div>
              </div>
            </div>
          </div>
          <div class="sticky bottom-0 mt-auto flex justify-end gap-2 border-t border-partingline bg-white px-3 py-5 lg:px-6">
            <Button variant="outlined" onClick={() => navigate(-1)}>
              {t('Cancel')}
            </Button>
            <Button onClick={onCreateLeaseDocument}>{t('Next')}</Button>
          </div>
        </Panel>
      </div>
    </Show>
  );
};
