import { PdfLoader } from '~/pdfsigner/repositories/pdfLoader';
import { MagicUseCase } from '~/pdfsigner/usecases/baseUseCases/magicUseCase';
import { DocumentStatus } from '~/pdfsigner/usecases/types/paginatedDocumentDescriptor';
import type { PDFDocumentProxy } from 'pdfjs-dist';
import type { AnnotatedPdfPage } from '~/pdfsigner/usecases/types/annotatedPdfPage';

export class AddPdfUseCase extends MagicUseCase {
  public async runLogic(files: FileList) {
    const pdfLoader = new PdfLoader();
    const pdfs = await pdfLoader.loadPdfs(files);
    if (!pdfs) {
      return;
    }
    const finalPdfs: PDFDocumentProxy[] = [];
    let finalPdfPages: AnnotatedPdfPage[] = [];
    for (let i = 0; i < pdfs.length; i++) {
      const annotatedPages: AnnotatedPdfPage[] = await pdfLoader.getPdfPages(pdfs[i]);
      finalPdfs.push(pdfs[i]);
      finalPdfPages = this.getState().selectedDocument?.pdfPages.concat(annotatedPages) || [];
    }
    this.getState().selectedDocument = {
      pdfs: finalPdfs,
      pdfPages: finalPdfPages,
      signers: [],
      status: DocumentStatus.None,
    };
  }
}
