import { Show, createEffect, createSignal } from 'solid-js';
import IconDeleteWarning from '~/assets/images/confirm-modal/delete.svg?component-solid';
import { IconAlert } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { cn } from '~/utils/classnames';
import { TextMessage } from './Common';
import { Modal } from './Modal';
import type { Component, JSX } from 'solid-js';
import type { Promisable } from '~/utils/types';

export const DeleteModal: Component<{
  visible?: boolean;
  class?: string;
  onCancel?: () => Promisable<void>;
  onConfirm?: () => Promisable<void>;
  children?: JSX.Element;
  confirm?: JSX.Element;
}> = (props) => {
  const { t } = useLocalization();
  const [pending, setPending] = createSignal(false);
  const [confirm, setConfirm] = createSignal(true);

  createEffect(() => props.confirm && setConfirm(false));

  const handleClose = async () => {
    await props.onCancel?.();
  };

  const handleDone = async () => {
    if (!confirm()) {
      return setConfirm(true);
    }
    try {
      setPending(true);
      await props.onConfirm?.();
    } finally {
      setPending(false);
    }
  };

  return (
    <Modal
      visible={props.visible ?? true}
      class={cn(
        'w-full max-w-lg [&_[data-slot=footer]]:border-t-0 [&_[data-slot=footer]]:pt-4 [&_[data-slot=title]]:border-b-0',
        props.class
      )}
      loading={pending()}
      title={
        <span class="flex items-center gap-1.5 capitalize text-danger">
          <IconAlert class="size-4" />
          {t('Confirm delete')}
        </span>
      }
      doneText={confirm() ? t('Confirm delete') : t('Delete')}
      doneColor="warning"
      confirmation={false}
      onDone={handleDone}
      onCancel={handleClose}>
      <IconDeleteWarning class="h-28 bg-light-pink" />
      <Show when={props.confirm && confirm()} fallback={<TextMessage>{props.children}</TextMessage>}>
        <TextMessage>{props.confirm}</TextMessage>
      </Show>
    </Modal>
  );
};
