import { Show } from 'solid-js';
import { cn } from '~/utils/classnames';

interface DisplayAddressProps {
  class?: string;
  address?: MagicDoor.Api.AddressDto | null;
}

const DisplayAddress = (props: DisplayAddressProps) => {
  return (
    <div class={cn('w-full text-wrap', props.class)}>
      <Show when={props.address} fallback="--">
        {props.address?.streetAddress1}, <Show when={props.address?.streetAddress2}>#{props.address?.streetAddress2}, </Show>
        {props.address?.city}, {props.address?.state} {props.address?.zipCode}
      </Show>
    </div>
  );
};

export default DisplayAddress;
