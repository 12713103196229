import { createSignal, createContext, useContext, Show } from 'solid-js';
import { LeaseTitle } from '~/components/leases/LeaseTitle';
import { OwnerTitle } from '~/components/owner/OwnerTitle';
import { PropertyTitle } from '~/components/properties/PropertyTitle';
import { TenantTitle } from '~/components/tenants/TenantTitle';
import { UnitTitle } from '~/components/units/UnitTitle';
import { VendorTitle } from '~/components/vendors/VendorTitle';
import { LeaseRepository } from '~/repositories/leaseRepository';
import { OwnersRepository } from '~/repositories/ownersRepository';
import { PropertiesRepository } from '~/repositories/propertiesRepository';
import { TenantRepository } from '~/repositories/tenantRepository';
import { UnitRepository } from '~/repositories/unitRepository';
import { VendorRepository } from '~/repositories/vendorRepository';
import type { JSX } from 'solid-js';
import type {
  HydratedLeaseDtoPaginationDto,
  HydratedPropertyDtoPaginationDto,
  HydratedUnitDtoPaginationDto,
  HydratedTenantListPaginationDto,
  HydratedVendorDtoPaginationDto,
} from '~/swagger/Api';

export interface SearchResult {
  id: string;
  type: 'lease' | 'property' | 'unit' | 'tenant' | 'owner' | 'vendor';
  renderItem: () => JSX.Element;
  response: any;
}

export interface SearchResults {
  leases: SearchResult[];
  properties: SearchResult[];
  units: SearchResult[];
  tenants: SearchResult[];
  owners: SearchResult[];
  vendors: SearchResult[];
}

interface SearchContextProps {
  searchResults: () => SearchResults;
  performSearch: (term: string | undefined) => void;
  resetSearchResults: () => void;
  loadMore: (type: string) => Promise<void>;
  hasMore: (type: string) => boolean;
  isLoading: () => boolean;
}

const SearchContext = createContext<SearchContextProps>();

const leaseRepo = new LeaseRepository();
const propertyRepo = new PropertiesRepository();
const unitRepo = new UnitRepository();
const tenantRepo = new TenantRepository();
const ownerRepo = new OwnersRepository();
const vendorRepo = new VendorRepository();

const commonTitleClass = 'text-sm text-gray-800 h-fit flex items-center';

export const SearchProvider = (props: { children?: any }) => {
  const [searchTerm, setSearchTerm] = createSignal<string | undefined>(undefined);
  const [searchResults, setSearchResults] = createSignal<SearchResults>({
    leases: [],
    properties: [],
    units: [],
    tenants: [],
    owners: [],
    vendors: [],
  });
  const [page, setPage] = createSignal<Record<string, number>>({
    leases: 1,
    properties: 1,
    units: 1,
    tenants: 1,
    owners: 1,
    vendors: 1,
  });
  const [hasMoreResults, setHasMoreResults] = createSignal<Record<string, boolean>>({
    leases: true,
    properties: true,
    units: true,
    tenants: true,
    owners: true,
    vendors: true,
  });
  const [isLoading, setIsLoading] = createSignal<boolean>(false);

  const calculateHasMore = (result: { currentPage: number; totalPages: number }) => {
    return result.currentPage < result.totalPages;
  };

  const performSearch = async (term: string | undefined) => {
    if (!term) return;
    setSearchTerm(term);
    setIsLoading(true);
    try {
      const results = await Promise.all([
        leaseRepo.getLeases({ pageSize: 20, search: term, page: 1, ended: false }),
        propertyRepo.getProperties({ pageSize: 20, search: term, page: 1, active: true }),
        unitRepo.getUnits({ pageSize: 20, search: term, page: 1, active: true }),
        tenantRepo.getTenants({ pageSize: 20, search: term, page: 1, active: true }),
        ownerRepo.getOwners({ pageSize: 20, search: term, page: 1, active: true }),
        vendorRepo.getVendors({ pageSize: 20, search: term, page: 1 }),
      ]);

      setHasMoreResults({
        leases: calculateHasMore(results[0]),
        properties: calculateHasMore(results[1]),
        units: calculateHasMore(results[2]),
        tenants: calculateHasMore(results[3]),
        owners: calculateHasMore(results[4]),
        vendors: calculateHasMore(results[5]),
      });

      setSearchResults({
        leases: results[0].items.map(mapSearchResult('lease')),
        properties: results[1].items.map(mapSearchResult('property')),
        units: results[2].items.map(mapSearchResult('unit')),
        tenants: results[3].items.map(mapSearchResult('tenant')),
        owners: results[4].items.map(mapSearchResult('owner')),
        vendors: results[5].items.map(mapSearchResult('vendor')),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const mapSearchResult =
    (type: SearchResult['type']) =>
    (item: any): SearchResult => ({
      id: item.id,
      type,
      renderItem: () => {
        return (
          <>
            <Show when={type === 'lease'}>
              <LeaseTitle
                feedbackIconReadonly
                lease={item}
                class={commonTitleClass}
                showTenants
                customLinks={[
                  { displayName: 'Chat', href: `/leasing/leases/${item.id}/communications` },
                  { displayName: 'Transactions', href: `/leasing/leases/${item.id}/transactions` },
                  { displayName: 'Add maintenance request', href: `/maintenance/maintenance-request/add?leaseId=${item.id}` },
                  { displayName: 'Add work order', href: `/maintenance/work-orders/add?leaseId=${item.id}` },
                  { displayName: 'Add a charge', href: `/leasing/leases/${item.id}/transactions/add-charge` },
                ]}
              />
            </Show>
            <Show when={type === 'property'}>
              <PropertyTitle feedbackIconReadonly property={item} class={commonTitleClass} />
            </Show>
            <Show when={type === 'unit'}>
              <UnitTitle feedbackIconReadonly unit={item} class={commonTitleClass} />
            </Show>
            <Show when={type === 'tenant'}>
              <TenantTitle
                feedbackIconReadonly
                tenant={item}
                class={commonTitleClass}
                customLinks={[{ displayName: 'Chat', href: `/users/tenants/${item.id}/communications` }]}
              />
            </Show>
            <Show when={type === 'owner'}>
              <OwnerTitle
                owner={item}
                class={commonTitleClass}
                customLinks={[{ displayName: 'Chat', href: `/users/owners/${item.id}/communications` }]}
              />
            </Show>
            <Show when={type === 'vendor'}>
              <VendorTitle
                feedbackIconReadonly
                vendor={item}
                class={commonTitleClass}
                customLinks={[{ displayName: 'Chat', href: `/maintenance/vendors/${item.id}/communications` }]}
              />
            </Show>
          </>
        );
      },
      response: item,
    });

  const loadMore = async (type: string) => {
    const currentPage = page()[type];
    const nextPage = currentPage + 1;
    let newResults:
      | HydratedLeaseDtoPaginationDto
      | HydratedPropertyDtoPaginationDto
      | HydratedUnitDtoPaginationDto
      | HydratedTenantListPaginationDto
      | MagicDoor.Api.HydratedOwnerListPaginationDto
      | HydratedVendorDtoPaginationDto;

    setIsLoading(true);

    try {
      switch (type) {
        case 'leases':
          newResults = await leaseRepo.getLeases({ pageSize: 20, search: searchTerm(), page: nextPage, ended: false });
          break;
        case 'properties':
          newResults = await propertyRepo.getProperties({ pageSize: 20, search: searchTerm(), page: nextPage, active: true });
          break;
        case 'units':
          newResults = await unitRepo.getUnits({ pageSize: 20, search: searchTerm(), page: nextPage, active: true });
          break;
        case 'tenants':
          newResults = await tenantRepo.getTenants({ pageSize: 20, search: searchTerm(), page: nextPage, active: true });
          break;
        case 'owners':
          newResults = await ownerRepo.getOwners({ pageSize: 20, search: searchTerm(), page: nextPage, active: true });
          break;
        case 'vendors':
          newResults = await vendorRepo.getVendors({ pageSize: 20, search: searchTerm(), page: nextPage });
          break;

        default:
          return;
      }

      setPage({ ...page(), [type]: nextPage });
      setHasMoreResults({ ...hasMoreResults(), [type]: calculateHasMore(newResults) });

      const singularType = getSingularType(type);

      setSearchResults((prev) => ({
        ...prev,
        [type]: [...(prev[type] || []), ...newResults.items.map(mapSearchResult(singularType as SearchResult['type']))],
      }));
    } finally {
      setIsLoading(false);
    }
  };

  const getSingularType = (type: string): string => {
    switch (type) {
      case 'properties':
        return 'property';
      case 'leases':
        return 'lease';
      case 'units':
        return 'unit';
      case 'tenants':
        return 'tenant';
      case 'owners':
        return 'owner';
      case 'vendors':
        return 'vendor';
      default:
        return type.slice(0, -1);
    }
  };

  const resetSearchResults = () => {
    setSearchTerm('');
    setSearchResults({
      leases: [],
      properties: [],
      units: [],
      tenants: [],
      owners: [],
      vendors: [],
    });
    setPage({
      leases: 1,
      properties: 1,
      units: 1,
      tenants: 1,
      owners: 1,
      vendors: 1,
    });
    setHasMoreResults({
      leases: true,
      properties: true,
      units: true,
      tenants: true,
      owners: true,
      vendors: true,
    });
  };

  const hasMore = (type: string) => {
    return hasMoreResults()[type];
  };

  return (
    <SearchContext.Provider
      value={{
        searchResults,
        performSearch,
        resetSearchResults,
        loadMore,
        hasMore,
        isLoading,
      }}>
      {props.children}
    </SearchContext.Provider>
  );
};

export const useSearch = () => {
  const context = useContext(SearchContext);
  if (!context) {
    throw new Error('useSearch must be used within a SearchProvider');
  }
  return context;
};
