import { FormItem } from '~/components/common/BetterForm';
import { Section } from '~/components/common/FormSectionTitle';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { RentalApplicationDocuments } from '~/components/rental-application/rental-application-details/renter-report-tab/documents/DocumentsForm';
import { useLocalization } from '~/contexts/global';
import { useNewRentalApplication } from '~/contexts/local';

export const Identity = () => {
  const { t } = useLocalization();
  const { setStore } = useNewRentalApplication();
  return (
    <>
      <Section required border title={t('Income verification')}>
        <div class="flex flex-col gap-y-8">
          <FormItem
            placeholder={t('Social security number')}
            label={t('Social security number (ssn)')}
            formFieldName={['ssn']}
            component={LabeledTextInput}
            rules={[
              { required: true, message: t('Please input social security number') },
              { type: 'ssn', message: t('SSN is not valid') },
            ]}
          />
          <FormItem
            formFieldName="files"
            component={RentalApplicationDocuments}
            beforeUpload={() => setStore({ disableNext: true })}
            afterUpload={() => setStore({ disableNext: false })}
          />
        </div>
      </Section>
    </>
  );
};
