import { useNavigate, useParams } from '@solidjs/router';
import { createMemo, Show, createEffect, onMount } from 'solid-js';
import { Form, FormItem, useForm } from '~/components/common/BetterForm';
import Breadcrumb from '~/components/common/Breadcrumb';
import { Description } from '~/components/common/Description';
import DueDateInputField from '~/components/common/Inputs/DueDateInputField';
import LabeledTextArea from '~/components/common/Inputs/LabeledTextArea';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { RingLoader } from '~/components/common/Loaders';
import { MultiStepsPanel } from '~/components/common/Panels/MultiStepsPanel';
import { IconMapPin } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useViolations } from '~/contexts/local';
import { ViolationStatus } from '~/pages/violations/list-violations';
import { AssociationViolationStatus } from '~/swagger/Api';
import type { UploaderFile } from '~/components/common/Upload/Interface';

export const EditViolation = () => {
  const { t } = useLocalization();
  const navigate = useNavigate();
  const breadcrumbItems = createMemo(() => [{ label: t('Violations'), link: '/associations/violations' }, { label: t('Edit violation') }]);

  const form = useForm();
  const params = useParams<{ violationId: string }>();
  const { getViolation, violation, updateViolation, violationActions, deleteFileURL, setNewFiles } = useViolations();

  const handleSubmit = async () => {
    const { validateStatus } = form.validateForm();
    const id = params.violationId || violation()?.id;
    if (!validateStatus || !id) throw new Error();
    await updateViolation(id, form.formStore as MagicDoor.Api.UpdateAssociationViolationRequestDto);
    violationActions.refetch();
  };

  onMount(() => {
    if (params.violationId) {
      if (violation()?.id === params.violationId) violationActions.refetch();
      else getViolation(params.violationId);
    }
  });

  createEffect(() => {
    form.setFieldsValue(violation());
  });

  return (
    <>
      <Breadcrumb backLink={() => navigate(-1)} items={breadcrumbItems()} />
      <MultiStepsPanel
        onClose={() => navigate(-1)}
        title={t('Edit violation')}
        disableNext={!form.isValidate}
        onSubmit={handleSubmit}
        loading={true}>
        <div class="relative">
          <div class="mx-auto flex w-10/12 flex-col items-center">
            <div class="mt-6 w-full max-w-[1200px] rounded-lg bg-gray-50 p-4 shadow-sm">
              <div class="mb-2 flex items-center justify-between">
                <h3 class="text-lg font-semibold text-gray-800">{violation()?.description}</h3>
                <ViolationStatus status={violation()?.status ?? AssociationViolationStatus.InProgress} />
              </div>
              <div class="flex items-center">
                <IconMapPin class="mr-2 size-4 text-gray-400" />
                <p class="text-sm font-medium text-gray-700">{violation()?.property?.displayName}</p>
              </div>
            </div>
            <Form defaultForm={form} class="mx-auto my-10 flex w-full max-w-[1200px] flex-col gap-5 text-left">
              <FormItem
                class="w-full"
                label={t(`Description`)}
                rules={[{ required: true, message: t(`Please input description`) }]}
                formFieldName="description"
                component={Description}
                placeholder={t('Please enter')}
                defaultFileList={violation()?.files}
                entityFilePrefixURL={deleteFileURL()}
                onFilesListChange={(files: UploaderFile[]) => {
                  setNewFiles(files);
                }}
              />

              <div class="grid grid-cols-2 gap-5">
                <FormItem
                  class="col-span-full lg:col-span-1"
                  label={t('Violation date')}
                  placeholder={t('Please select')}
                  formFieldName="violationDate"
                  component={DueDateInputField}
                  rules={[{ required: true, message: t('Please select violation date') }]}
                />
                <FormItem
                  label={t('Violation receive date')}
                  placeholder={t('Please select')}
                  component={DueDateInputField}
                  formFieldName="violationReceivedDate"
                  class="col-span-full lg:col-span-1"
                  rules={[{ required: true, message: t('Please select violation receive date') }]}
                />
                <FormItem
                  label={t('Fix by date')}
                  placeholder={t('Please select')}
                  component={DueDateInputField}
                  formFieldName="fixByDate"
                  class="col-span-full lg:col-span-1"
                />
                <FormItem
                  label={t('Association fine')}
                  placeholder={t('Please enter')}
                  component={LabeledTextInput}
                  prepend="$"
                  formFieldName="associationFine"
                  getValueFromEvent={(e) => +e}
                  class="col-span-full lg:col-span-1"
                />
                <FormItem
                  class="col-span-full"
                  label={t('Reference')}
                  placeholder={t('Please enter')}
                  component={LabeledTextArea}
                  formFieldName="reference"
                />
              </div>
            </Form>
          </div>

          <Show when={violation.loading}>
            <div class="absolute inset-0 z-50 mx-8 flex flex-col items-center justify-center rounded-lg bg-white">
              <RingLoader color="#a126ec" />
            </div>
          </Show>
        </div>
      </MultiStepsPanel>
    </>
  );
};
