import { A } from '@solidjs/router';
import { For } from 'solid-js';
import IconBackArrow from '~/assets/images/common/backArrow.svg?component-solid';
import { useMenu } from './MenuContext';
import type { MenuItem } from './types/menuItem';

interface SubMenuItemsProps {
  setIsSidebarOpen: (value: boolean) => void;
}

const SubMenuItems = (props: SubMenuItemsProps) => {
  const menu = useMenu();

  const returnParentMenu = () => {
    menu?.setActiveMenuItemId();
    menu?.setIsManuallyCollapsed(true);
  };

  return (
    <div
      class="z-10 h-full  rounded-l-lg bg-black/20  text-left text-white transition-transform  duration-150"
      classList={{
        'translate-x-full w-0 p-0': !menu?.isCollapsed(),
        'translate-x-0 px-2 py-1 w-full ': menu?.isCollapsed(),
      }}>
      <div class="relative flex h-15 cursor-pointer items-center gap-1 p-2.5 text-white/70 hover:text-white" onClick={returnParentMenu}>
        <IconBackArrow class="scale-90" />
        {menu?.activeParentMenuTitle()}
        <div
          class="absolute bottom-0 h-0.5 w-full"
          style={{
            background:
              'linear-gradient(270deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0.40) 50.84%, rgba(255, 255, 255, 0.00) 100%)',
          }}
        />
      </div>
      <ul>
        <For each={menu?.subMenuItems()}>{(subItem) => <SubMenuItem setIsSidebarOpen={props.setIsSidebarOpen} item={subItem} />}</For>
      </ul>
    </div>
  );
};

interface SubMenuItemProps {
  item: MenuItem;
  setIsSidebarOpen: (value: boolean) => void;
}

const SubMenuItem = (props: SubMenuItemProps) => {
  const menu = useMenu();

  const clickHandler = () => {
    if (window.innerWidth <= 767) {
      props.setIsSidebarOpen(false);
    }
  };

  return (
    <li
      class="my-2 box-border w-full rounded-md border border-transparent duration-200"
      classList={{
        'bg-essential-colour font-semibold': menu?.isSelected(props.item),
        'hover:hover:border-essential-colour': !menu?.isSelected(props.item),
      }}
      onClick={clickHandler}>
      <A class="block p-2.5" href={props.item.realPath || props.item.path}>
        {props.item.text}
      </A>
    </li>
  );
};

export default SubMenuItems;
