import { Show } from 'solid-js';
import headerIcon from '~/assets/images/aiRunBooks/headerIcon.png';
import { Markdown } from '~/components/common/Mardown';
import { Panel } from '~/components/common/Panels/Panel';
import { Skeleton } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useRunBooks } from '~/contexts/local';
import { Learn } from '~/pages/ai-runbooks/runbooks-details/components/RunbookSummary';
import { RunbookTitle } from '~/pages/ai-runbooks/runbooks-details/components/RunbookTitle';
import type { Component } from 'solid-js';

export const RunbookScriptTab: Component = () => {
  const { t } = useLocalization();

  const { runBook } = useRunBooks();

  return (
    <div class="flex flex-col-reverse gap-4 lg:flex-row">
      <div class="flex flex-1 flex-col gap-5">
        <RunbookTitle />
        <Panel
          header={
            <div class="flex h-15 items-center justify-between bg-gradient-mysticViolet pl-6 text-xl font-semibold text-white">
              {t('Script')}{' '}
              <div
                class="h-full w-44"
                style={{
                  background: `url(${headerIcon})`,
                }}
              />
            </div>
          }>
          <div class="p-6">
            <Show
              when={!runBook.loading}
              fallback={
                <div class="flex flex-col gap-4">
                  <Skeleton class="w-48" />
                  <Skeleton class="w-full" />
                  <Skeleton class="w-full" />
                </div>
              }>
              <Markdown content={runBook()?.script?.replace(/\\"/g, '') ?? ''} />
            </Show>
          </div>
        </Panel>
      </div>
      <div class="w-full lg:w-[267px]">
        <Learn />
      </div>
    </div>
  );
};
