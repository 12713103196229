import { A } from '@solidjs/router';
import { Show } from 'solid-js';
import { MessageSourceIcons } from '~/components/common/MessageSource';
import { useLocalization } from '~/contexts/global';
import { formatChatDate } from '~/utils/date';

interface ParticipantInfoProps {
  participantName: string | null | undefined;
  message: MagicDoor.Api.ChatMessageDto;
  href: string;
  participantInSystem: boolean;
  printMode?: boolean;
}

export const ParticipantInfo = (props: ParticipantInfoProps) => {
  const { t } = useLocalization();
  const messageStyles = () => {
    if (props.printMode) {
      return 'text-title-gray';
    }
    switch (props.participantName) {
      case 'AI':
        return 'text-link';
      case 'Announcement':
        return 'text-warning';
    }
  };

  return (
    <div class={`flex items-center gap-1.5 font-medium ${messageStyles()} `}>
      <Show when={props.participantName}>
        <A
          class="truncate"
          classList={{
            'hover:underline': props.participantInSystem,
            'cursor-default': !props.participantInSystem,
          }}
          href={props.href}>
          {props.participantName}
        </A>
      </Show>
      <Show when={!props.participantName && props.message.messageType === 'propertyManager'}>
        <div>{t('Sending')}...</div>
      </Show>
      <span class="font-normal text-text-level03">{props.message.sentAt ? formatChatDate(props.message.sentAt, t) : t('Loading')}</span>
      <MessageSourceIcons message={props.message} t={t} />
    </div>
  );
};
