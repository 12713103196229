import { Outlet, useNavigate, useParams } from '@solidjs/router';
import { createEffect } from 'solid-js';
import { toast } from '~/components/ui';
import { usePortfolios } from '~/contexts/global';
import { PortfolioTabs } from './components/PortfolioTabs';
import type { Component } from 'solid-js';

export const PortfolioLayout: Component = () => {
  const params = useParams<{ portfolioId?: 'add' | `${number}` }>();
  const navigate = useNavigate();

  const { portfolios, current, setCurrentId } = usePortfolios();

  createEffect(() => {
    if (portfolios.loading) return;

    if (params.portfolioId && /^\d+$/.test(params.portfolioId)) {
      const isExist = portfolios()?.find((p) => p.id === params.portfolioId);
      if (!isExist) {
        toast.error('Portfolio not found, redirecting to another existing portfolio', {
          timeout: 4000,
        });
        return navigate(`/portfolios`, { replace: true });
      }
      setCurrentId(params.portfolioId);
    } else if (portfolios()?.[0]?.id) {
      const currentId = current()?.id ?? setCurrentId(portfolios()?.[0]?.id);
      params.portfolioId == null && navigate(`/portfolios/${currentId}`, { replace: true });
    }
  });

  return (
    <div class="space-y-5 p-8 pt-5">
      <PortfolioTabs portfolios={portfolios()} selected={current()} />
      <Outlet />
    </div>
  );
};
