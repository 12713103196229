import { createEffect, createMemo, createSignal } from 'solid-js';
import { Form, FormItem, FormList, useForm } from '~/components/common/BetterForm';
import { FormListRemoverWrapper } from '~/components/common/BetterForm/FormListRemover';
import { Button } from '~/components/common/Buttons';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { Modal } from '~/components/modals/Modal';
import { IconCirclePlus, toast } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { isEmptyData } from '~/utils/tool';
import type { Component } from 'solid-js';

export type KeyValuePair = Record<string, string>;

export const EditMagicTagsModal: Component<{
  type: 'unit' | 'property';
  defaultData?: KeyValuePair;
  class?: string;
  visible?: boolean;
  onSubmit: (data: KeyValuePair) => Promise<any>;
  onClose?: (tenant?: MagicDoor.Api.TenantCreatedDto) => void;
}> = (props) => {
  const { t } = useLocalization();
  const form = useForm();
  const [editMagicTagsLoading, setEditMagicTagsLoading] = createSignal<boolean>(false);

  let hasInit = true;
  const defaultlineitems: KeyValuePair[] = [
    {
      key: '',
      value: '',
    },
  ];

  const isDisabledSubmit = createMemo(() => {
    if (!hasInit) return false;
    return isEmptyData(form.formStore.lineItems);
  });

  const arrayToObject = (arr: KeyValuePair[]): KeyValuePair => {
    return arr
      .filter((item) => item.key && item.value)
      .reduce((acc, item) => {
        acc[item.key] = item.value;
        return acc;
      }, {});
  };

  const onDone = async () => {
    const { validateStatus } = form.validateForm();
    if (!validateStatus) return;

    try {
      const magicTags = arrayToObject(form.formStore.lineItems);
      setEditMagicTagsLoading(true);
      await props.onSubmit(magicTags);
      toast.success(t('Magic tags has been saved successfully'));
      props.onClose?.();
    } finally {
      setEditMagicTagsLoading(false);
    }
  };

  createEffect(() => {
    form.setFormStore({
      lineItems: defaultlineitems,
    });
  });

  createEffect(() => {
    const defaultData = props.defaultData;
    if (hasInit && defaultData && Object.keys(defaultData).length > 0) {
      const result: KeyValuePair[] = [];
      for (const [key, value] of Object.entries(defaultData)) {
        result.push({ key, value });
        form.setFormStore({
          lineItems: result.concat(defaultlineitems),
        });
      }
      hasInit = false;
    }
  });

  return (
    <Modal
      title={t(props.type === 'unit' ? 'Edit unit magic tags' : 'Edit property magic tags')}
      doneText={t('Save')}
      visible={!!props.visible}
      class="w-11/12 lg:w-modal-lg"
      confirmation={false}
      disabled={isDisabledSubmit()}
      onDone={onDone}
      loading={editMagicTagsLoading()}
      onCancel={() => {
        props.onClose?.();
      }}>
      <div class="h-[calc(100%-151px)] overflow-auto">
        <Form class="flex flex-col gap-6 p-8" defaultForm={form}>
          <FormList
            formFieldName={'lineItems'}
            renderItemsGroup={({ index }) => {
              return (
                <FormListRemoverWrapper class="grid grid-cols-2 gap-x-6 gap-y-2 border-none pb-0" index={index}>
                  <FormItem
                    class="col-span-full sm:col-span-1"
                    label={t(`Magic tag`) + ` ${index() + 1}`}
                    formFieldName="key"
                    component={LabeledTextInput}
                    placeholder={t('Please enter')}
                    getValueFromEvent={(value) => value.trim()}
                    rules={[
                      {
                        message: t('Please enter'),
                        validator: (value) => {
                          if (form.formStore.lineItems[index()].value) {
                            return !!value;
                          }
                          return true;
                        },
                      },
                    ]}
                  />
                  <FormItem
                    class="col-span-full sm:col-span-1"
                    label={t(`Value`) + ` ${index() + 1}`}
                    formFieldName="value"
                    component={LabeledTextInput}
                    placeholder={t('Please enter')}
                    getValueFromEvent={(value) => value.trim()}
                    rules={[
                      {
                        message: t('Please enter'),
                        validator: (value) => {
                          if (form.formStore.lineItems[index()].key) {
                            return !!value;
                          }
                          return true;
                        },
                      },
                    ]}
                  />
                </FormListRemoverWrapper>
              );
            }}
            autoCombineFieldName={false}>
            {({ add }) => {
              return (
                <Button size="sm" class="mt-4 flex h-10 w-full justify-center border-dashed" variant="outlined" onClick={add}>
                  <IconCirclePlus />
                  {t('Add more')}
                </Button>
              );
            }}
          </FormList>
        </Form>
      </div>
    </Modal>
  );
};
