import { Button } from '~/components/common/Buttons';
import { useLocalization } from '~/contexts/global';
import usePresenter from '~/framework/hooks/usePresenter';
import useUseCase from '~/framework/hooks/useUseCase';
import { ToolbarPresenter } from '~/pdfsigner/presenters/ToolbarPresenter';
import { TitleInput } from '~/pdfsigner/ui/components/header/TitleInput';
import { UpdateLeaseTemplateUseCase } from '~/pdfsigner/usecases/pdfEditing/updateLeaseTemplateUseCase';

export interface LeaseDraftPreviewHeaderProps {
  onStartSigning: () => void;
}

export const LeaseDraftPreviewHeader = (props: LeaseDraftPreviewHeaderProps) => {
  const { t } = useLocalization();
  const { model: presentableToolbar } = usePresenter(ToolbarPresenter);
  const { execute: updateLeaseTemplate } = useUseCase(UpdateLeaseTemplateUseCase);

  return (
    <div class="mb-5 flex w-full items-center gap-6 rounded-lg bg-white px-6 py-2.5">
      <TitleInput class="grow" title={presentableToolbar()?.title} onInput={(title) => updateLeaseTemplate({ title })} />
      <Button class="w-[200px] shrink-0" variant="solid" color="primary" block onClick={props.onStartSigning}>
        {t('Start Signing')}
      </Button>
    </div>
  );
};
