import { leaseDraftRepository } from '~/pdfsigner/repositories/leaseDraftRepository';
import { MagicUseCase } from '~/pdfsigner/usecases/baseUseCases/magicUseCase';
import type { LeaseDraftsLoadRequest } from '~/pdfsigner/usecases/types/leaseDraftsLoadRequest';

export class LoadLeaseDraftsForLeaseUseCase extends MagicUseCase {
  public async runLogic(request: LeaseDraftsLoadRequest) {
    if (!request || !request.leaseId) {
      return;
    }
    this.getState().paginatedLeaseDrafts = await leaseDraftRepository.getLeaseDrafts(request.leaseId, request.page);
  }
}
