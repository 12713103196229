import { Show, onMount } from 'solid-js';
import { LinkButton } from '~/components/common/Buttons';
import CnMoney from '~/components/common/CnMoney';
import { Empty } from '~/components/common/Empty';
import { IconPastDue, LeasePeriod, LeaseStatus, LeaseTitle } from '~/components/leases';
import { Html, ListView } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useLeasesList } from '~/contexts/local';
import usePresenter from '~/framework/hooks/usePresenter';
import useUseCase from '~/framework/hooks/useUseCase';
import { useLeaseFilter, useLocalPagination } from '~/hooks';
import { QuickLeaseNotifyBalanceDue } from '~/pages/leases/quick-lease-notify-balance-due';
import { useReportPrinter } from '~/pdfsigner/hooks/useReportPrinter';
import { LeasesWithBalancesDuePresenter } from '~/pdfsigner/presenters/reports/LeasesWithBalancesDuePresenter';
import { LeasesWithBalancesDueUseCase } from '~/pdfsigner/usecases/reports/leasesWithBalancesDueUseCase';
import { diffDates } from '~/utils/date';
import { IconLeasesOverviewHeader } from './assets';
import type { Component } from 'solid-js';
import type { LeaseListFilter } from '~/repositories/leaseRepository';
export const LeasesOverview: Component = () => {
  const { t } = useLocalization();
  const { page, pageSize, setPage, setPageSize } = useLocalPagination('LeasesOverviewListView');
  const { leases, getLeases, loading, pagination } = useLeasesList();
  const { filterValue, filterItems, parseFilter, handleFilterReset } = useLeaseFilter({ status: false, date: false });

  const necessaryParams = { ended: false, withBalance: true };

  onMount(() => getLeases({ page: page(), pageSize: pageSize(), ...necessaryParams }));

  const { execute: getLeasesWithBalancesDue, isLoading: isLoadingLeases } = useUseCase(LeasesWithBalancesDueUseCase);
  const { model: leasesWithBalancesDueReport } = usePresenter(LeasesWithBalancesDuePresenter);
  const { print } = useReportPrinter();

  const printLeasesWithBalancesDue = async () => {
    await getLeasesWithBalancesDue({ ...parseFilter(filterValue()), ...necessaryParams } as LeaseListFilter);
    print(leasesWithBalancesDueReport()?.leasesWithBalancesDueReport);
  };

  return (
    <ListView
      onPrint={printLeasesWithBalancesDue}
      onChange={({ page, pageSize, search, filter }) => {
        setPage(page);
        setPageSize(pageSize);
        getLeases({ page, pageSize, search, ...parseFilter(filter), ...necessaryParams });
      }}
      headerClass="relative bg-gradient-to-br from-[#ee3964] to-[#ec4494] text-white [&>h2]:flex [&>h2]:gap-6 [&_[data-badges]_span]:bg-white [&_[data-badges]_span]:text-danger [&_[data-filter]_button]:border-white [&_[data-filter]_button]:bg-transparent [&_[data-filter]_button]:text-white [&_[data-search]]:border-white [&_[data-search]]:bg-transparent [&_[data-search]]:text-white [&_[data-search]_input]:placeholder:text-light-gray"
      title={
        <>
          <span class="text-sm font-semibold md:text-lg">{t('Leases with balance due')}</span>
          {/* TODO: REMIND ALL LEASE WITH Balance DUE */}
          {/* <QuickLeaseNotifyBalanceDue targets={leases()?.map((x) => x.id)} btnText="Remind all" /> */}
          <IconLeasesOverviewHeader class="absolute inset-0 left-1/2 h-full" />
        </>
      }
      searchPlaceholder={t('Search leases')}
      totalPages={pagination()?.totalPages}
      page={pagination()?.currentPage}
      pageSize={pagination()?.pageSize}
      filterValue={filterValue()}
      filterItems={filterItems()}
      onFilterReset={handleFilterReset}
      rowLink={(item) => `/leasing/leases/${item.id}`}
      skeletonSize={10}
      loading={loading() || isLoadingLeases()}
      data={leases()}
      empty={<Empty description={t('No leases with balance due')} />}
      columns={[
        {
          title: t('Lease'),
          render: (item) => <LeaseTitle lease={item} />,
        },
        {
          title: t('Lease term'),
          headerClass: 'w-56',
          render: (item) => (
            <>
              <LeaseStatus lease={item} />
              <LeasePeriod lease={item} />
            </>
          ),
        },
        {
          title: t('Past due'),
          headerClass: 'w-36',
          render: (item) => (
            <div class="flex flex-col text-sm">
              <Show when={item.balance > 0 && item.pastDueDays} fallback={<div class="text-xs font-normal text-text-level03">-</div>}>
                <div class="flex items-center gap-1 whitespace-nowrap text-danger">
                  <IconPastDue class="size-4" />
                  {item.pastDueDays && t('{count} Days', { count: item.pastDueDays.toString() })}
                </div>
              </Show>
            </div>
          ),
        },
        {
          title: t('Balance'),
          headerClass: 'w-36 text-right',
          class: 'text-right',
          render: (item) => <CnMoney money={item.balance} />,
        },
        {
          title: t('Posted balance'),
          headerClass: 'w-36 text-right',
          class: 'text-right',
          render: (item) => <CnMoney money={item.postedBalance} />,
        },
        {
          title: t('Operation'),
          headerClass: 'w-72 text-right',
          class: '',
          render: (item) => (
            <div class="relative z-10 flex justify-end gap-3">
              <Show when={item.balance > 0 && diffDates(item.end, item.earliestUnpaidBillDueDate)}>
                <QuickLeaseNotifyBalanceDue targets={[item.id]} />
              </Show>

              <LinkButton
                href={`/leasing/leases/${item.id}/transactions/add-charge`}
                variant="outlined"
                rounded="full"
                class="border-link px-3 py-1 text-xs text-link hover:bg-link/10 [&_span]:whitespace-nowrap">
                {t('Post a charge')}
              </LinkButton>
            </div>
          ),
        },
      ]}
      footerSummary={
        <Html as="p" class="flex-1 text-xs text-text-level03 [&>b]:mx-1 [&>b]:font-bold [&>b]:text-essential-colour">
          {t('A total of <b>{count}</b> related lease contracts', {
            count: loading() ? '-' : pagination().totalCount.toString(),
          })}
        </Html>
      }
    />
  );
};
