import { Show } from 'solid-js';
import { Empty } from '~/components/common/Empty';
import { Section } from '~/components/common/FormSectionTitle';
import { useLocalization } from '~/contexts/global';
import { useAddOwnerDistributions } from '~/contexts/local';
import { OwnerPaymentMethodsListView } from './OwnerPaymentMethodsListView';

export const OwnerPaymentMethods = () => {
  const { t } = useLocalization();
  const { store } = useAddOwnerDistributions();

  return (
    <div class="px-[96px]">
      <p class="mb-2.5 text-xs font-thin normal-case text-text-level02">
        {t('Owner distribution fees will not be sent until you click confirm.')}
      </p>
      <Section title={t('Payment Method')} border class="mb-0 [&>*]:mb-0 [&>*]:border-b-0">
        <Show when={store.editingDistributionGroups.length} fallback={<Empty />}>
          <OwnerPaymentMethodsListView />
        </Show>
      </Section>
    </div>
  );
};
