import { Show, createEffect, createSignal } from 'solid-js';
import IconInviteUrl from '~/assets/images/settings/portal/portalInviteUrl.svg';
import IconPortalInvite from '~/assets/images/settings/portal/portalInviteUrlBanner.png';
import ThreeSectionBox from '~/components/common/ThreeSectionBox/ThreeSectionBox';
import { IconLoader, toast } from '~/components/ui';
import { useCompanies, useLocalization } from '~/contexts/global';
import { SettingTitle } from '~/pages/settings/SettingTitle';
import { SaveBtn } from '~/pages/settings/components/edit-form/SaveBtn';

export const PortalAppUrlSettings = () => {
  const { t } = useLocalization();
  const [text, setText] = createSignal<string>('');
  const { companies, validateSubUrl, updateSubUrl } = useCompanies();

  const handleCancel = () => {
    setText(companies()?.subUrl || '');
  };

  const handleSave = async () => {
    const url = text().trim();

    if (url === companies()?.subUrl) return;

    if (typeof url !== 'string' || url.length < 3) {
      return toast.error(t('Invalid subdomain, must be at least 3 characters long'));
    }

    const result = await validateSubUrl({ url });
    if (!result.isValid) {
      return toast.error(t('Subdomain already taken'));
    }

    await updateSubUrl({ url });
  };

  createEffect(handleCancel);

  return (
    <ThreeSectionBox
      class="border-0"
      topTitle={<SettingTitle title={t('Modify your MagicDoor url')} icon={IconInviteUrl} />}
      middle={
        <>
          <div class="m-4 flex flex-col gap-4 rounded-md bg-light-pink text-left">
            <Show when={!companies.loading} fallback={<IconLoader class="mx-auto my-10 animate-spin" />}>
              <div
                class="size-full bg-right-bottom bg-no-repeat bg-origin-padding px-4 py-6"
                style={{ 'background-image': `url(${IconPortalInvite})` }}>
                <div class="flex flex-col gap-2">
                  <div class="font-regular text-xs	text-text-level03 ">
                    {t('You can modify your MagicDoor url to match your company brand.')}
                  </div>
                  <div class="flex items-center gap-1">
                    <div class="relative flex items-center">
                      <input
                        class="bg-parting-line rounded-md border px-3 py-2 text-sm placeholder:text-auxiliary-text focus:outline-none focus:ring-1 focus:ring-essential-colour"
                        placeholder={t('Brand subdomain')}
                        value={text()}
                        onChange={(e) => setText(e.target.value)}
                      />
                      <Show when={validateSubUrl.pending || updateSubUrl.pending}>
                        <IconLoader class="absolute right-3 size-4 animate-spin text-text-level02" />
                      </Show>
                    </div>
                    <samp class="select-none text-text-level02">.with.magicdoor.com</samp>
                  </div>
                </div>
              </div>
            </Show>
          </div>
          <SaveBtn
            class="border-b-0"
            dirty={!companies.loading && text() !== companies()?.subUrl}
            onSave={handleSave}
            onCancel={handleCancel}
          />
        </>
      }
    />
  );
};
