import { Show, createMemo, createSignal, onMount } from 'solid-js';
import IconPlus from '~/assets/images/common/plus.svg?component-solid';
import IconRentPayment from '~/assets/images/settings/property/propertyRentPayment.svg';
import { Button } from '~/components/common/Buttons';
import ThreeSectionBox from '~/components/common/ThreeSectionBox/ThreeSectionBox';
import { toast, confirm, IconLoader } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { usePropertySettings } from '~/contexts/local';
import { SettingTitle } from '~/pages/settings/SettingTitle';
import Overrides from '~/pages/settings/components/Overrides';
import { AddOverridesModal } from '~/pages/settings/components/add-overrides-modal';
import { EditForm } from '~/pages/settings/components/edit-form/EditForm';
import type { ItemProps } from '~/pages/settings/components/edit-form/EditForm';

export const PropertySettings = () => {
  const { t } = useLocalization();
  const {
    allPropertySettings,
    getAllPropertySettings,
    isLoading,
    updatePropertySettings,
    addOverrideSettings,
    updateOverridePropertySettings,
    removeOverrideSettings,
  } = usePropertySettings();
  const sections = createMemo<ItemProps[]>(() => [
    {
      field: 'reserve',
      label: 'Reserve',
      type: 'number',
      class: 'col-span-2',
      description: 'The amount that needs to be kept in reserve when distributing to owners',
    },
    {
      field: 'ownerApprovalLimit',
      label: 'Owner approval limit',
      type: 'number',
      description: 'Any repairs over this limit needs owner approval',
    },
    {
      field: 'vendorApprovalLimit',
      label: 'Vendor approval limit',
      type: 'number',
      description: 'Any repairs over this limit the vendor need to get manager approval',
    },
  ]);
  const [isShowModal, setIsShowModal] = createSignal(false);

  const onSave = async (data: MagicDoor.Api.PropertySettingsDto, level?: string, levelId?: string) => {
    if (!level && !levelId) {
      await updatePropertySettings(data);
      toast.success(t('Property settings saved successfully'));
      getAllPropertySettings();
      return;
    }

    await updateOverridePropertySettings(level as string, levelId as string, data);
    toast.success(t('Property settings saved successfully'));
    getAllPropertySettings();
  };

  const onIsShowModalChange = () => {
    setIsShowModal((prev) => !prev);
  };

  const onAddOverrideSettingsConfirm = async (level: string, levelId: string) => {
    await addOverrideSettings(level, levelId, { ...((allPropertySettings()?.settings ?? {}) as MagicDoor.Api.PropertySettingsDto) });
    onIsShowModalChange();
    toast.success(t('Add property settings successfully'));
    getAllPropertySettings();
  };

  const onRemoveOverrideSettings = (level: string, levelId: string) => {
    confirm({
      content: t('Are you sure you want to delete this override?'),
      title: t('Delete override'),
      onResolve: async (confirmed) => {
        if (!confirmed) {
          return;
        }

        await removeOverrideSettings(level, levelId);
        toast.success(t('Property settings deleted successfully'));
        getAllPropertySettings();
      },
    });
  };

  onMount(() => getAllPropertySettings());

  return (
    <>
      <ThreeSectionBox
        class="h-fit"
        topTitle={<SettingTitle title={t('Property general')} icon={IconRentPayment} />}
        topAction={
          <Button variant="outlined" class="rounded-3xl px-3 py-1 text-sm" onClick={onIsShowModalChange}>
            <IconPlus />
            <span class="hidden font-medium md:block">{t('Add new overrides')}</span>
          </Button>
        }
        middle={
          <div class="flex flex-col gap-5 p-4">
            <Show when={!isLoading()} fallback={<IconLoader class="mx-auto my-20 animate-spin" />}>
              <div class="flex flex-col gap-4 p-2 text-left">
                <EditForm
                  sections={sections()}
                  onOk={(data) => onSave(data)}
                  model={allPropertySettings()?.settings || ({} as MagicDoor.Api.PropertySettingsDto)}
                />
              </div>
            </Show>
            <Overrides
              overrides={allPropertySettings()?.overrides ?? []}
              onDelete={onRemoveOverrideSettings}
              title={t('Property overrides')}>
              {(override) => (
                <div class="flex flex-col gap-4 p-2 text-left">
                  <EditForm
                    sections={sections()}
                    model={(override.settings ?? {}) as MagicDoor.Api.PropertySettingsDto}
                    onOk={(data) => onSave(data, override.level, override.levelId)}
                  />
                </div>
              )}
            </Overrides>
          </div>
        }
      />
      <Show when={isShowModal()}>
        <AddOverridesModal
          overrides={allPropertySettings()?.overrides || []}
          loading={isLoading()}
          onClose={onIsShowModalChange}
          onDone={onAddOverrideSettingsConfirm}
        />
      </Show>
    </>
  );
};
