import { createSignal, Show } from 'solid-js';
import { Button } from '~/components/common/Buttons';
import { Modal } from '~/components/modals';
import { TenantTitle } from '~/components/tenants';
import { IconCirclePlus, ListView } from '~/components/ui';
import { useLocalization, useTenants } from '~/contexts/global';
import { IconActiveCount, IconTenantsSummaryCard } from '~/pages/tenants/assets';
import { cn } from '~/utils/classnames';
import type { Component, ComponentProps } from 'solid-js';

const InfoCard: Component<ComponentProps<'div'>> = (props) => (
  <div
    {...props}
    class={cn(
      'relative flex h-52 flex-col items-start gap-0.5 overflow-hidden rounded-lg bg-gradient-to-bl from-white/30 to-transparent px-6 py-5 text-xs',
      props.class
    )}>
    <IconTenantsSummaryCard class="absolute right-0 top-0" />
    {props.children}
  </div>
);

export const TenantsSummary: Component = () => {
  const { t } = useLocalization();
  const { counts, inviteList, setTriggerInvite } = useTenants();

  const [showInvites, setShowInvites] = createSignal<boolean>(false);

  return (
    <div class="grid gap-5 text-left md:grid-cols-2 lg:grid-cols-3">
      <InfoCard class="bg-essential-colour text-white">
        <span class="text-sm capitalize opacity-80">{t('Active tenants')}</span>
        <div class="mb-auto text-4xl font-semibold">{counts()?.activeTenants || '-'}</div>
        <Show when={counts()?.newTenantsToday}>
          <span class="flex items-center gap-1 rounded-full bg-white/40 px-2.5 py-1 text-essential-colour">
            <IconActiveCount class="size-3" />
            {counts()?.newTenantsToday || '-'}
          </span>
          <span class="opacity-80">{t('New today')}</span>
        </Show>
        <Button class="mt-1 border-0 capitalize [&_span]:gap-1" variant="white" rounded="full" size="sm" href="/users/tenants/add">
          <IconCirclePlus class="size-5" />
          {t('Add tenant')}
        </Button>
      </InfoCard>
      <InfoCard class="bg-[#0047ff] text-white">
        <span class="text-sm capitalize opacity-80">{t('All uninvited tenants')}</span>
        <div class="mb-auto text-4xl font-semibold">{counts()?.unInvitedTenants || '-'}</div>
        <Button
          class="mt-1 border-0 text-[#0047ff] [&_span]:gap-1"
          variant="white"
          rounded="full"
          size="sm"
          onClick={() => {
            setTriggerInvite(true);
            setShowInvites(true);
          }}>
          <IconCirclePlus class="size-5" />
          {t('Invite all tenants')}
        </Button>
      </InfoCard>
      <Modal
        title={t('Newly Invited Tenants')}
        doneText={t('Confirm')}
        onCancel={() => setShowInvites(false)}
        visible={showInvites()}
        onDone={() => {
          setShowInvites(false);
          setTriggerInvite(false);
          counts();
        }}>
        <ListView
          class={cn('border-0')}
          headerClass="p-3 [&_form]:flex-1 [&_input:focus]:w-full [&_input]:w-full"
          hideFooter
          loading={inviteList.loading}
          data={inviteList()}
          columns={[
            {
              title: t('Tenant'),
              class: 'flex items-center gap-2 text-text-level01 select-none',
              render: (item) => <TenantTitle feedbackIconReadonly tenant={item} />,
            },
          ]}
        />
        <p class="px-5 py-3 text-gray-400">{t('Note: Tenants without an email or lease set cannot be invited.')}</p>
      </Modal>
    </div>
  );
};
