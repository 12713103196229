import { IconAlert } from '~/components/ui/Icons';
import { useLocalization } from '~/contexts/global';
export const NotSet = () => {
  const { t } = useLocalization();
  return (
    <div class="flex items-center text-xs text-danger">
      <IconAlert class="mr-1 size-4" />
      <span>{t('Not set')}</span>
    </div>
  );
};
