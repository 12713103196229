import { FormItem } from '~/components/common/BetterForm/Form';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { useLocalization } from '~/contexts/global';

export const NewApplicantForm = () => {
  const { t } = useLocalization();

  return (
    <div class="grid grid-cols-3 gap-2">
      <div class="text-sm text-text-level03">
        {t('If the applicant’s information is not in the system, please type in legal name here')}
      </div>
      <FormItem
        placeholder={t('First name')}
        rules={[
          { required: true, message: t('Please input first name') },
          {
            type: 'name',
            message: t('{name} should be 3-150 characters long', { name: t('First name') }),
          },
        ]}
        label={t(`First name`)}
        formFieldName={['firstname']}
        component={LabeledTextInput}
      />
      <FormItem
        rules={[
          { required: true, message: t('Please input last name') },
          {
            type: 'name',
            message: t('{name} should be 3-150 characters long', { name: t('Last name') }),
          },
        ]}
        placeholder={t('Last name')}
        label={t(`Last name`)}
        formFieldName={['lastName']}
        component={LabeledTextInput}
      />
    </div>
  );
};
