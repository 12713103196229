import { useNavigate } from '@solidjs/router';
import { createEffect, createMemo, createSignal } from 'solid-js';
import { createStore } from 'solid-js/store';
import Breadcrumb from '~/components/common/Breadcrumb';
import { MultiStepsPanel } from '~/components/common/Panels/MultiStepsPanel';
import { Stepper } from '~/components/common/Stepper';
import { toast } from '~/components/ui';
import { useLocalization, useManagementFee } from '~/contexts/global';
import { cloneDeep, isEmptyData } from '~/utils/tool';
import ChoosePropertyForm from './ChoosePropertyForm';
import FeeCalculationForm from './FeeCalculationForm';
import ManagementFeeStore from './store';
import type { ManagementFee, ManagementFeeForm, ManagementFeeFormError } from './types';

const initFormData = {
  isAllProperties: true,
  properties: [],
  dateTimeRange: 'now',
  managementFees: [],
  calculateFeesUntil: '',
};

export const AddManagementFee = () => {
  const { t } = useLocalization();
  const navigate = useNavigate();
  const breadcrumbItems = createMemo(() => [
    { label: t('Company revenue'), link: '/accounting/company-revenue' },
    { label: t('Calculate management fee') },
  ]);

  const { addManagementFee } = useManagementFee();
  const { store, getManagementFees } = ManagementFeeStore;
  const [currentStep, setCurrentStep] = createSignal<number>(0);
  const [isValidate, setIsValidate] = createSignal<boolean>(false);
  const [formData, setFormData] = createStore<ManagementFeeForm>(cloneDeep(initFormData));
  const [needToUpdate, setNeedToUpdate] = createSignal<boolean>(true);
  const [formErrors, setFormErrors] = createStore<ManagementFeeFormError>({});

  const steps = createMemo(() => [t('Choose property'), t('Fee calculation')]);

  const stepValidator = (step: number) => {
    setIsValidate(true);

    switch (step) {
      case 1: {
        const properties = !formData.isAllProperties && isEmptyData(formData.properties);
        properties ? setFormErrors('properties', t('Select property')) : setFormErrors('properties', '');
        if (properties) {
          setIsValidate(false);
        }
        break;
      }

      case 2: {
        const hasManagementFees = store.managementFeesLoading || store.managementFees?.length === 0;
        setIsValidate(!hasManagementFees);
      }
    }
  };

  const handleSubmit = async () => {
    try {
      await addManagementFee({
        calculateFeesUntil: formData.calculateFeesUntil,
        managementFees: formData.managementFees,
      });

      navigate(-1);
      toast.success(t('{name} has been added successfully', { name: t('Management fees') }));
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const onStepChange = async (step: number, isBack?: boolean) => {
    if (step === 1 && !isBack && needToUpdate()) {
      getManagementFees({
        propertyIds: formData.isAllProperties ? undefined : formData.properties.filter((item) => item),
        managementFeeDate: formData.dateTimeRange as any,
      }).then((calculateFeesUntil) => setFormData('calculateFeesUntil', calculateFeesUntil as string));

      setNeedToUpdate(false);
    }
    setCurrentStep(step);
  };

  createEffect(() => {
    const result = (store.managementFees || []).map((item: any) => ({
      propertyId: item.property?.id,
      feeAmount: item.managementFee,
      memo: item.memo || '',
    }));
    setFormData('managementFees', result as ManagementFee[]);
  });

  createEffect(() => {
    switch (currentStep()) {
      case 0:
        stepValidator(1);
        break;
      case 1:
        stepValidator(2);
        break;
    }
  });

  return (
    <>
      <Breadcrumb backLink={() => navigate(-1)} items={breadcrumbItems()} />
      <MultiStepsPanel
        stepper={
          <div class="flex items-center justify-center pb-10">
            <Stepper class="p-9" steps={steps()} step={currentStep()} />
          </div>
        }
        currentStep={currentStep()}
        showPrevious
        showSubmit={currentStep() == 1 ? isValidate() || store.managementFeesLoading : true}
        onClose={() => navigate(-1)}
        title={t('Calculate management fee')}
        disableNext={!isValidate()}
        onSubmit={handleSubmit}
        onStepChange={(step, isBack) => onStepChange(step, isBack)}>
        <ChoosePropertyForm
          store={store}
          form={formData}
          setFormData={setFormData}
          formErrors={formErrors}
          setNeedToUpdate={setNeedToUpdate}
        />
        <FeeCalculationForm store={store} form={formData} setFormData={setFormData} />
      </MultiStepsPanel>
    </>
  );
};
