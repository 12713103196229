import { MagicUseCase } from '~/pdfsigner/usecases/baseUseCases/magicUseCase';
import { DragAnnotationUseCase } from './dragAnnotationUseCase';
import { StopTransformingUseCase } from './stopTransformingUseCase';
import type { TransformRequest } from '~/pdfsigner/usecases/types/transformRequest';

export class StopDraggingUseCase extends MagicUseCase {
  public async runLogic(request: TransformRequest) {
    await new DragAnnotationUseCase().execute(request);
    await new StopTransformingUseCase().execute();
  }
}
