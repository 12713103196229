import { MagicUseCase } from '~/pdfsigner/usecases/baseUseCases/magicUseCase';

export class ChangeNumberOfSignersUseCase extends MagicUseCase {
  public async runLogic(numberOfSigners: number) {
    if (!this.getState().selectedDocument) {
      return;
    }
    const selectedDocument = this.getState().selectedDocument;
    if (!selectedDocument) {
      return;
    }
    let signers = [...selectedDocument.signers];
    if (numberOfSigners > selectedDocument.signers.length) {
      for (let i = selectedDocument.signers.length; i < numberOfSigners; i++) {
        signers.push({
          id: `${i + 1}`,
          name: `Signer ${i + 1}`,
          isPropertyManager: false,
        });
      }
    } else {
      signers = selectedDocument.signers.slice(0, numberOfSigners);
    }
    selectedDocument.signers = signers;
    let selectedSigner = selectedDocument.signers.find((signer) => signer.id === this.getState().selectedSigner.id);
    if (!selectedSigner) {
      selectedSigner = selectedDocument.signers[0];
    }
    this.getState().selectedSigner = selectedSigner;
    this.removeDeletedSignerAnnotations();
  }

  private removeDeletedSignerAnnotations() {
    const signers = new Set(this.getState().selectedDocument?.signers.map((signer) => signer.id));
    this.getState().selectedDocument?.pdfPages.forEach((page) => {
      page.annotations = page.annotations.filter((annotation) => {
        if (this.isClientInput(annotation.type) && annotation.signerId !== undefined && !signers.has(annotation.signerId)) {
          return false;
        }
        return true;
      });
    });
  }
}
