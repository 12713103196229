import { useNavigate } from '@solidjs/router';
import dayjs from 'dayjs';
import { Form, FormItem, useForm } from '~/components/common/BetterForm';
import LabeledNumberInput from '~/components/common/Inputs/LabeledNumberInput';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { Modal } from '~/components/modals/Modal';
import { LabeledOwnerSearch } from '~/components/owner/OwnerSearch';
import { CustomLevelSearch } from '~/components/search-select/LevelSearchComponents/SearchLevels';
import { LabeledLeveledSearchSelect } from '~/components/search-select/LevelSearchSelect';
import { useLocalization } from '~/contexts/global';
import { useOwnerTransfers } from '~/contexts/local';

export const AddTransferModal = () => {
  const { t } = useLocalization();
  const form = useForm();
  const navigate = useNavigate();

  const { addOwnerTransfer } = useOwnerTransfers();

  const doneAction = async () => {
    const { validateStatus } = form.validateForm();
    if (validateStatus) {
      await addOwnerTransfer(form.formStore as MagicDoor.Api.TransferOwnerFundsDto);
      navigate(-1);
    }
  };

  return (
    <Modal
      visible
      onDone={doneAction}
      doneText={t('Confirm')}
      class="lg:w-modalSm"
      title={t('Transfer funds')}
      loading={addOwnerTransfer.loading}
      onCancel={() => {
        navigate(-1);
      }}>
      <Form
        class="grid grid-cols-1 gap-2 p-5 lg:grid-cols-2"
        defaultForm={form}
        initialValues={{
          paymentDate: dayjs().format('YYYY-MM-DD'),
        }}>
        <FormItem
          component={LabeledLeveledSearchSelect}
          label={t('From property')}
          placeholder={t('Please select')}
          formFieldName="fromPropertyId"
          rules={[{ required: true, message: t('Property is required') }]}
          class="col-span-full lg:col-span-1"
          enabledTypes={[CustomLevelSearch.Property]}
          onChange={(selected: any[]) => {
            form.setValueToStore('fromPropertyId', selected[0]?.id);
          }}
        />

        <FormItem
          component={LabeledLeveledSearchSelect}
          label={t('To property')}
          placeholder={t('Please select')}
          formFieldName="toPropertyId"
          rules={[{ required: true, message: t('Property is required') }]}
          class="col-span-full lg:col-span-1"
          enabledTypes={[CustomLevelSearch.Property]}
          onChange={(selected: any[]) => {
            form.setValueToStore('toPropertyId', selected[0]?.id);
          }}
        />

        <FormItem
          filter={{
            propertyIds: [form.formStore.toPropertyId],
          }}
          component={LabeledOwnerSearch}
          label={t('Select owner')}
          placeholder={t('Please select owner')}
          formFieldName="ownerId"
          rules={[{ required: true, message: t('Owner is required') }]}
          class="col-span-full lg:col-span-1"
        />

        <FormItem
          placeholder={t('Please enter')}
          label={t(`Amount`)}
          formFieldName="amount"
          prepend="$"
          component={LabeledNumberInput}
          rules={[{ message: t(`Please enter`), required: true }]}
          class="col-span-full lg:col-span-1"
        />

        <FormItem
          class="col-span-full"
          formFieldName="memo"
          label={t('Memo')}
          component={LabeledTextInput}
          placeholder={t('Please enter')}
        />
      </Form>
    </Modal>
  );
};
