import { For, Show, createMemo } from 'solid-js';
import { Dynamic } from 'solid-js/web';
import IconBuilding from '~/assets/images/portfolio/distribution/building.svg?component-solid';
import IconCondo from '~/assets/images/portfolio/distribution/condo.svg?component-solid';
import IconMultiFamily from '~/assets/images/portfolio/distribution/multiFamily.svg?component-solid';
import IconSingleFamily from '~/assets/images/portfolio/distribution/singleFamily.svg?component-solid';
import IconTownHome from '~/assets/images/portfolio/distribution/townHome.svg?component-solid';
import { Section } from '~/components/common/Section';
import { Skeleton } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import type { Component } from 'solid-js';

export const PortfolioDistribution: Component<{
  loading: boolean;
  portfolioOverview?: MagicDoor.Api.PortfolioOverviewDto;
}> = (props) => {
  const { t } = useLocalization();

  const sections = createMemo(() => {
    const summary = props?.portfolioOverview?.summary;
    return [
      { title: t('Building'), icon: IconBuilding, count: summary?.propertiesDistribution?.commercial },
      { title: t('Single family'), icon: IconSingleFamily, count: summary?.propertiesDistribution?.singleFamily },
      { title: t('Multi family'), icon: IconMultiFamily, count: summary?.propertiesDistribution?.multiFamily },
      { title: t('Town home'), icon: IconTownHome, count: summary?.propertiesDistribution?.townHome },
      { title: t('Condo'), icon: IconCondo, count: summary?.propertiesDistribution?.condo },
    ].filter((section) => section.count);
  });

  return (
    <Section title={t('Portfolio distribution')} class="flex-1" contentClass="m-5 flex divide-x rounded-lg bg-light-gray py-6">
      <Show
        when={sections().length > 0}
        fallback={
          <div class="size-full items-center justify-center py-3.5 text-center text-xs text-text-level03">
            {t('No property has been added')}
          </div>
        }>
        <For each={sections()}>
          {(section) => (
            <div class="flex-1">
              <Show when={!props.loading} fallback={<Skeleton class="mx-auto size-7" />}>
                <h3 class="text-center text-xl font-semibold text-title-gray">{section.count ?? 0}</h3>
              </Show>
              <p class="mt-0.5 flex justify-center gap-1 text-[11px]">
                <Dynamic component={section.icon} />
                {section.title}
              </p>
            </div>
          )}
        </For>
      </Show>
    </Section>
  );
};
