import { Route, Routes } from '@solidjs/router';
import { AddDefaultPmAndVendorOverridesModal } from '~/pages/ai-runbooks/runbooks-details/components/AddDefaultPmAndVendorOverridesModal';
import { DefaultPmAndVendorView } from '~/pages/ai-runbooks/runbooks-details/components/DefaultPmAndVendorView';
import { DeleteOverwriteModal } from '~/pages/ai-runbooks/runbooks-details/components/DeleteOverwriteModal';
import { EditDefaultPmAndVendorModal } from '~/pages/ai-runbooks/runbooks-details/components/EditDefaultPmAndVendorModal';
import type { Component } from 'solid-js';

export const RunbookDefaultVendorsTab: Component = () => {
  return (
    <>
      <div class="flex flex-col gap-5">
        <DefaultPmAndVendorView source="vendor" />
      </div>
      <Routes>
        <Route path="/edit-default" element={<EditDefaultPmAndVendorModal source="vendor" />} />
        <Route path="/add-overrides" element={<AddDefaultPmAndVendorOverridesModal source="vendor" />} />
        <Route path="/:overwriteId/delete" element={<DeleteOverwriteModal source="vendor" />} />
      </Routes>
    </>
  );
};
