import { CustomLevelSearch } from '~/components/search-select/LevelSearchComponents/SearchLevels';
import { leaseRepository } from '~/repositories/leaseRepository';
import { portfolioRepository } from '~/repositories/portfolioRepository';
import { propertiesRepository } from '~/repositories/propertiesRepository';
import { tenantRepository } from '~/repositories/tenantRepository';
import { unitListingsRepository } from '~/repositories/unitListingsRepository';
import { unitRepository } from '~/repositories/unitRepository';
import { LevelSearch } from '~/swagger/Api';
import { BaseRestRepository } from './baseRestRepository';
import type { CustomLevelSearchType } from '~/components/search-select/LevelSearchComponents/SearchLevels';

const SEARCH_URL = '/api/search';

export class SearchRepository extends BaseRestRepository {
  public async search(query: Partial<MagicDoor.Api.SelectSearchRequestDto>): Promise<MagicDoor.Api.SelectSearchResultDto> {
    const params = {
      levelSearch: LevelSearch.Lease,
      includeTenants: true,
      includeEndedLeases: false,
      includeDeactivated: false,
      ...query,
    };
    const url = `${SEARCH_URL}/select`;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(params),
    });
    return this.getJsonResponse(response);
  }

  public async getPortfolios(): Promise<MagicDoor.Api.HydratedPortfolioDto[]> {
    return await portfolioRepository.getPortfolios({
      active: true,
    });
  }

  public async getUnits(propertyId: string, excludeUnlistedUnit: boolean = false) {
    const params = {
      propertyId,
      active: true,
      pageSize: 100,
      page: 1,
    };

    const [unitsList, unitListings] = await Promise.all([
      unitRepository.getUnits(params),
      excludeUnlistedUnit &&
        unitListingsRepository.getUnitListings({
          ...params,
          listed: true,
        }),
    ]);

    unitsList.items = unitsList.items
      .filter((item) => !unitListings || unitListings.items.some((listing) => listing.unitId === item.id))
      .map((item) => {
        const property = item.property;
        item.activeLeases = (item.activeLeases ?? []).map((lease) => {
          lease.tenants = [];
          return {
            ...lease,
            property: property,
            unit: {
              name: item.name,
            },
            __lazy: true,
          };
        });

        return {
          ...item,
          leases: item.activeLeases,
        };
      });

    return unitsList;
  }

  public async getTenants(leaseId: string) {
    const res = await leaseRepository.getLease(leaseId);
    return res.tenants.map((tenant) => tenant.tenant);
  }

  public async batchGetByIds(ids: string[], type: CustomLevelSearchType) {
    let res: any[] = [];
    switch (type) {
      case CustomLevelSearch.Tenant:
        res = await tenantRepository.getTenantsByIds({ tenantIds: ids });
        break;
      case CustomLevelSearch.Unit:
        res = await unitRepository.batchGetUnitsByIds(ids);
        break;
      case CustomLevelSearch.Lease:
        res = await leaseRepository.batchGetLeasesByIds(ids);
        break;
      case CustomLevelSearch.Property:
        res = await propertiesRepository.batchGetPropertyByIds(ids);
        break;
      case CustomLevelSearch.Portfolio:
        res = await portfolioRepository.batchGetPortfolioByIds(ids);
        break;
    }
    return res;
  }
}
