import { onCleanup, onMount } from 'solid-js';
import IconPrint from '~/assets/images/common/print.svg?component-solid';
import { handlePrint } from '~/components/checks/PrintCheck';
import { getOwnerFullName } from '~/components/owner';
import { ListView, toast, IconTrash } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useChecks } from '~/contexts/local';
import { useLocalPagination } from '~/hooks';
import { dateFormat } from '~/utils/date';
import { currency } from '~/utils/number';

export const ChecksTable = (props: { title: string; checkIdentity: `${MagicDoor.Api.CheckIdentity}` }) => {
  const { t } = useLocalization();
  const { getChecks, checks, deleteCheck, getCheck, checksActions } = useChecks();

  const { page, pageSize, setPage, setPageSize } = useLocalPagination('ListChecks', {
    currentPage: 1,
    pageSize: 10,
  });

  const handleDelete = async (id: string) => {
    await deleteCheck(id);
    toast.success(t('Operation successful'));
    if (checks()?.items.length === 1 && page() !== 1) {
      setPage(page() - 1);
      getChecks({ page: page(), pageSize: pageSize(), checkIdentity: props.checkIdentity });
    } else {
      checksActions.refetch();
    }
  };

  onMount(() => {
    getChecks({ page: page(), pageSize: pageSize(), checkIdentity: props.checkIdentity });
  });

  onCleanup(() => {
    checksActions.mutate(undefined);
    setPage(1);
    setPageSize(10);
  });

  const getOwnerName = (ownerId: string) => {
    const owner = checks()?.owners?.find((owner) => owner.id === ownerId);
    return owner ? getOwnerFullName(owner) : '';
  };

  const getVendorName = (vendorId: string) => {
    const vendor = checks()?.vendors?.find((vendor) => vendor.id === vendorId);
    return vendor ? vendor.name : '';
  };

  return (
    <ListView
      skeletonSize={10}
      title={t(props.title)}
      page={checks()?.currentPage}
      totalPages={checks()?.totalPages}
      pageSize={checks()?.pageSize}
      data={checks()?.items}
      loading={checks.loading}
      onChange={({ page, pageSize }) => {
        setPage(page);
        setPageSize(pageSize);
        getChecks({ page, pageSize, checkIdentity: props.checkIdentity });
      }}
      columns={[
        {
          title: t('Check number'),
          render: (item) => <div>#{item.checkNumber}</div>,
        },
        {
          title: t('Date'),
          render: (item) => <div>{dateFormat('', item.date)}</div>,
        },
        {
          title: t('Name'),
          render: (item) => <div>{getOwnerName(item.ownerId ?? '') || getVendorName(item.vendorId ?? '')}</div>,
        },
        {
          title: t('Bank account name'),
          render: (item) => <div>{item.bankAccountName}</div>,
        },
        {
          title: t('Amount'),
          render: (item) => <div>{currency(item.amount)}</div>,
        },
      ]}
      actions={(item) => [
        {
          label: t('Re-print'),
          icon: IconPrint,
          onClick: async () => {
            return handlePrint(await getCheck(item.id as string));
          },
        },
        {
          label: t('Delete'),
          icon: IconTrash,
          onClick: () => handleDelete(item.id as string),
        },
      ]}
    />
  );
};
