import { useMenu } from '~/components/layouts/Menu/MenuContext';
import { CustomLayoutButton } from './CustomLayoutButton/CustomLayoutButton';
import LocalizationButton from './LocalizationButton/LocalizationButton';
import SearchBar from './MagicSearchBar/MagicSearch';
import { SearchProvider } from './MagicSearchBar/MagicSearchContext';
import ProfileButton from './ProfileButton/ProfileButton';

interface NavigationProps {
  setIsSearchActive: (isSearchActive: boolean) => void;
}

const Navigation = (props: NavigationProps) => {
  const menu = useMenu();

  return (
    <div
      class="fixed top-0 z-30 flex h-16 w-full bg-white px-8"
      classList={{
        'md:pl-64 md:w-layout-right': !menu?.isManuallyCollapsed(),
        'md:w-layout-full': menu?.isManuallyCollapsed(),
      }}>
      <div class="ml-10 flex w-full items-center justify-between md:ml-0">
        <SearchProvider>
          <div>
            <SearchBar setIsSearchActive={props.setIsSearchActive} />
          </div>
        </SearchProvider>

        <div class="flex items-center gap-2">
          <CustomLayoutButton />
          <LocalizationButton />
          <ProfileButton />
        </div>
      </div>
    </div>
  );
};

export default Navigation;
