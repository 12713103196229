import { LeaseIdRequiredError } from '~/pdfsigner/errors/leaseIdRequiredError';
import { LeaseTemplateIdsRequiredError } from '~/pdfsigner/errors/leaseTemplateIdsRequired';
import { SignersRequiredError } from '~/pdfsigner/errors/signersRequiredError';
import { DocumentTitleRequiredError } from '~/pdfsigner/errors/titleRequiredError';
import { leaseDraftRepository } from '~/pdfsigner/repositories/leaseDraftRepository';
import { leaseTemplateRepository } from '~/pdfsigner/repositories/leaseTemplateRepository';
import { MagicUseCase } from '~/pdfsigner/usecases/baseUseCases/magicUseCase';
import type { LeaseDraftCreationRequest } from '~/pdfsigner/usecases/types/leaseDraftCreationRequest';
import type { Signer } from '~/pdfsigner/usecases/types/signer';

export class CreateLeaseDocumentDraftUseCase extends MagicUseCase {
  public async runLogic(data: LeaseDraftCreationRequest) {
    this.validateInput(data);
    const leaseTemplates = await Promise.all(data.leaseTemplateIds.map((id) => leaseTemplateRepository.getLeaseTemplate(id)));
    const signers: Signer[] = [];
    data.signerIds.forEach((id) => {
      const signer = this.getState().leaseDocumentSigners.find((signer) => signer.id === id);
      if (signer) {
        signers.push({
          ...signer,
          signatureInitials: this.getInitials(signer.name),
          signatureName: signer.name,
        });
      }
    });
    const id = await leaseDraftRepository.createLeaseDraft({
      leaseId: data.leaseId,
      title: data.title,
      leaseTemplates,
      signers,
    });
    this.navigate(`/leasing/leases/${data.leaseId}/draft/${id}`);
  }

  private getInitials(name: string): string {
    const parts = name.split(/[\s-]+/);
    const initials = parts.map((part) => (part ? part[0].toUpperCase() : '')).join('');
    return initials;
  }

  private validateInput(data?: LeaseDraftCreationRequest) {
    if (!data) {
      return;
    }
    if (!data.title || data.title.length === 0) {
      throw new DocumentTitleRequiredError();
    }
    if (!data.signerIds || data.signerIds.length === 0) {
      throw new SignersRequiredError();
    }
    if (!data.leaseId || data.leaseId.length === 0) {
      throw new LeaseIdRequiredError();
    }
    if (!data.leaseTemplateIds || data.leaseTemplateIds.length === 0) {
      throw new LeaseTemplateIdsRequiredError();
    }
  }
}
